.headerProfile{
  width:100%;
  height:auto;
  overflow: hidden;
}
.headerProfile .headerProfileContainer {
  height: 25vh;
  min-height: 244px;
  background-image: url(../../../assets/images/profile/profile-background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-around;
}

.complemento-back{
  height: 28vh;
  min-height: 244px;
  /* background-image: url(/static/media/profile-background.68bb7c2b.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-around;
  background-position: top center;
}

.back1{
  background-image: url(../../../assets/images/background1.jpg);
}.back2{
  background-image: url(../../../assets/images/background2.jpg);
}.back3{
  background-image: url(../../../assets/images/background3.jpg);
}.back4{
  background-image: url(../../../assets/images/background4.jpg);
}.back5{
  background-image: url(../../../assets/images/background5.jpg);
}.back6{
  background-image: url(../../../assets/images/background6.jpg);
}
.back7{
  background-image: url(../../../assets/images/background7.jpg);
}
.back8{background-image: url(../../../assets/images/background8.jpg);}
.back9{background-image: url(../../../assets/images/background9.jpg);}
.back10{background-image: url(../../../assets/images/background10.jpg);}
.back11{background-image: url(../../../assets/images/background11.jpg);}
.back12{background-image: url(../../../assets/images/background12.jpg);}
.back13{background-image: url(../../../assets/images/background13.jpg);}
.back14{background-image: url(../../../assets/images/background14.jpg);}
.back15{background-image: url(../../../assets/images/background15.jpg);}


.headerProfile .headerProfileContainerFilter{
  width:100%;
  height: 100%;
  background-color: rgba(0,0,0,.55);
  padding-top: env(safe-area-inset-top);
}

.headerProfile .headerMidWrapper{
  width: 90%;
  height: 100%;
  margin: 0 auto;
  max-width: 1100px;
  max-width:1055px;
  display: table;
  position: relative;
}

.headerProfile .headerMidWrapperCell{
  display: table-cell;
  vertical-align: middle;
}

.headerProfile__imageText {
  display: flex;
  align-items: center;
}

.headerProfile__faceContainer {
  display: flex;
  border-radius: 100%;
  background-color: rgba(255,255,255,.2);
  width: 120px;
  height: 120px;
  overflow: hidden;
  border: 1px solid rgba(255,255,255,.1);
}

.profileImage__headerProfile {
  width:100%;
  background-image: url(../../../assets/images/profile/default-profile.svg);
}

.headerProfile__nameContainer {
  display: flex;
  flex-direction: column;
  padding-left: 30px;
}

.headerProfile__nameContainer {
  color: white;
}

#headerProfile__name {
  font-size: 42px;
  font-weight: 500;
  margin:0;
  padding: 0;
  margin-bottom: 6px;
  text-transform: capitalize;
}

#headerProfile__fullName {
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  margin-bottom:6px;
  text-transform: capitalize;
}

#headerProfile__job {
  font-size: 14px;
  font-weight: 500;
  opacity: .7;
  text-transform: uppercase;
}

#headerProfile__job::first-letter {
  text-transform: uppercase;
}

.headerProfile__edit {
  display: flex;
  color: white;
  position: absolute;
  right: 0;
  top:50px;
  cursor: pointer;
  font-size: 12px;
}

.download-pdf{
  bottom:69px;
  top:initial;
}


.download-pdf span{
  background-color: rgba(0, 125, 195, 1);
  padding: 7px 15px;
  color: white;
  border-radius: 100px;
  border: 1px solid rgba(0, 125, 195, 1);
}


.download-pdf.headerProfile__edit:hover span {
  background-color: rgba(0,0,0,.2);
  border: 1px solid rgba(255,255,255,.3);
  padding: 7px 15px;
  color: white;
  border-radius: 100px;
}

.headerProfile__edit p,
.headerProfile__edit img{
  float: left;
  margin: 0;
  padding: 0;
}
.headerProfile__edit span.lessSpanNew{
  padding: 2px 10px;
}
.headerProfile__edit:hover span{
  background-color: rgba(255,255,255,.3);
  padding: 2px 10px;
  color:white;
  border-radius: 100px;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.headerProfile__edit i {
  margin-left: 5px;
}
#buttonReadArticleLink:hover {
  width: 153px;
  height: 50px;
  background: #e91b2f;
  border-radius: 50px;
  color: #ffffff;
  text-align: center;
}
.icon.setting {
  margin-left: 8px;
  height: 18px;
  width: 18px;
  margin-top: 2px;
}

@media screen and (max-width: 600px) {
.profileImage__headerProfile,
.headerProfile__faceContainer{
  width:90px;
  height: 90px;
}
#headerProfile__name{
  font-size: 22px;
}
#headerProfile__fullName{
  font-size: 14px;
}
.headerProfile__nameContainer{
  padding-left: 15px;
}
.headerProfile__edit{
  top:30px;
}
.download-pdf {
  height: 36px;
  top: 175px;
}
#headerProfile__job {
  font-size: 12px;
}
}
