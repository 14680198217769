.DenunciaReporta {
	width: 100%;
	height: auto;
	overflow: hidden;
	background-color: #ffe873;
	border-radius: 8px;
	box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16);
	margin-top: 30px;
	padding: 15px 20px;
	display: block;
	text-decoration: none;
	transition: 800ms cubic-bezier(0.2, 0.8, 0.2, 1), width 300ms cubic-bezier(0.2, 0.8, 0.2, 1);
	opacity: 0;
	color: #020c41;
}
.opacityChange {
	opacity: 1;
}
.DenunciaReporta .imagePlane {
	width: 50px;
	height: 50px;
	background-color: white;
	border-radius: 50%;
	float: left;
	position: relative;
	margin-right: 15px;
	margin-top: 9px;
	background-image: url(../../../../assets/logo/chat.svg);
	background-repeat: no-repeat;
	background-size: contain;
}
.DenunciaReporta p {
	margin: 0;
	padding: 0;
	color: #0b2343;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.4;
	width: calc(100% - 65px);
	float: left;
}
.DenunciaReporta p span {
	font-weight: 600;
}
.DenunciaReporta:hover {
	background-color: #ffd773;
	color: #020c41;
}
.DenunciaReporta:hover p {
	color: #020c41;
}

@media (max-width: 800px) {
	.DenunciaReporta {
		/* opacity: 1;
    position: fixed;
    z-index: 70;
    bottom: 59px;
    left: 50%;
    transform: translate(-50%,-0%);
    width: 95%;
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    bottom: calc(59px + var(--safe-area-inset-bottom)); */

		opacity: 1;
		position: fixed;
		z-index: 70;
		bottom: 49px;
		left: 50%;
		-webkit-transform: translate(-50%, -0%);
		transform: translate(-50%, -0%);
		width: 100%;
		--safe-area-inset-bottom: env(safe-area-inset-bottom);
		bottom: calc(4px + var(--safe-area-inset-bottom));
		border-radius: 0;
		padding: 7px 5%;
	}
	.DenunciaReporta .padding p {
		font-size: 12px;
		line-height: 1.2;
	}
	.DenunciaReporta .imagePlane {
		width: 30px;
		height: 30px;
		margin-right: 10px;
	}
}
