.NpsWrap:hover .tooltipCompensationsNPS {
	display: block;
}

.DOHover:hover .tooltipDO {
	display: block;
}

.AOHover:hover .tooltipAO {
	display: block;
}

.CFHover:hover .tooltipCF {
	display: block;
}

.toolTipNPS {
	margin: 0;
	padding: 4px 10px;
	color: white;
	font-size: 14px;
	z-index: 4;
	position: relative;
}

.tooltipCompensationsNPS {
	position: absolute;
	right: -100px;
	background: rgba(0, 125, 195, 1);
	border-radius: 7px;
	top: 10px;
	max-width: 300px;
	box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.09);
	display: none;
	z-index: 11;
	max-width: 200px;
}

.tooltip {
	opacity: 1;
}

.tooltip-inner {
	background: black;
	max-width: 250px;
	line-height: 1.4;
	padding: 10px 10px;
	font-weight: 500;
	font-size: 14px;
}

.tooltip span.arrow {
	color: rgba(0, 125, 195, 1);
}

.tooltipCompensationsNPS span {
	display: block;
	background: rgba(0, 125, 195, 1);
	width: 15px;
	height: 15px;
	position: absolute;
	top: 17px;
	left: -3px;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.StatesAirline {
	width: 100%;
	height: auto;
	background-color: #fff;
	border-radius: 8px;
	/*border: 2px solid #002b5c;*/
	box-shadow: 0 0.15em 0.4em 0.065em rgba(0, 0, 0, 0.075);
	margin-top: 30px;
	/*overflow: hidden;*/
	/*min-height: 300px;*/
	position: relative;
	overflow: hidden;
	/*
  position: sticky;
    top: 100px;*/
}
.StatesAirline:hover {
	box-shadow: 0 0 0.4em 0.065em rgba(0, 0, 0, 0);
	transition: all 1s ease-in-out;
}
.StatesAirline h1 {
	font-weight: 500;
	font-size: 14px;
	margin: 0;
	margin-bottom: 5px;
	padding-left: 20px;
	padding-top: 20px;
	color: rgba(0, 125, 195, 1);
	padding: 0;
	display: table;
	border-radius: 4px;

	margin-left: 20px;
	margin-top: 24px;
}
.TitleState {
	width: 100%;
	height: 50px;
	/*border-bottom: 1px solid #d6d6d6;*/
	position: sticky;
	top: 0px;
	background-color: #020c41;
	z-index: 5;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	color: white;
}
.flightplanstate {
	height: auto;
	padding: 15px 0;
	overflow: hidden;
}
.TitleState p {
	font-size: 14px;
	text-transform: uppercase;
	margin: 0;
	padding: 0;
	line-height: 50px;
	font-weight: 500;
	padding-left: 20px;
	letter-spacing: 0.7px;
	color: white;
}

.flightplanstate p {
	line-height: inherit;
	float: left;
}

.flightplanstate p:nth-child(2) {
	font-size: 12px;
	opacity: 0.7;
	padding-left: 7px;
}

.stateFlightPlane a {
	color: white;
	text-decoration: none;
	text-align: center;
	font-size: 12px;
	font-weight: 500;
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	display: block;
	padding: 10px 0;
	text-transform: uppercase;
}
.AirlineNew {
	margin: 0;
	display: block;
	padding: 20px;
	height: auto;
	overflow: hidden;
	border-bottom: 1px solid #d6d6d6;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	/*position: sticky;*/
	top: 0;
}
.AirlineNew:hover {
	background-color: rgba(0, 125, 195, 0.09);
}
.AirlineNew.a {
	color: rgba(0, 125, 195, 1);
	font-size: 14px;
	font-weight: 500;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.AirlineNew:hover a {
	background-color: rgba(0, 125, 195, 1);
	color: white;
	padding: 2px 10px;
	border-radius: 100px;
}
p.AirTitle {
	font-weight: 500;
	font-size: 20px;
	margin: 0;
	line-height: 1.2;
}
.AirIntro {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	line-height: 1.3em;
	max-height: 3em;
	width: 100%;
	margin: 0 0 1em 0;
	overflow: hidden;
}
.AirIntro p {
	color: #666;
	margin: 0;
	margin-top: 10px;
	font-size: 13px;
	line-height: 1.5;
	-webkit-box-orient: vertical;
}
.AirlineNew a {
	color: rgba(0, 125, 195, 1);
	font-size: 14px;
	font-weight: 500;
	-webkit-transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	text-decoration: none;
}
.NpsWrap {
	background: white;
	padding: 20px;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	position: relative;
	cursor: pointer;
	text-decoration: none;
	display: block;
	color: #020c41;
	overflow: hidden;
}
.NpsWrap a {
	color: #020c41;
	font-size: 14px;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	font-weight: 500;
	font-size: 14px;
	margin-top: 12px;
	display: table;
	display: none;
	/* background-color: rgba(0, 125, 195, .09);
  padding: 2px 10px;
  border-radius: 100px; */
}
.NpsWrap div p {
	color: #020c41;
}
.NpsWrap:hover a {
	background-color: rgba(2, 12, 65, 0.025);
	color: #fa0073;
	text-decoration: none;
}
.NpsWrap:hover {
	background-color: rgba(2, 12, 65, 0.025);
}

.NpsWrap:hover .tooltipnps {
	display: block;
}

.NpsNumber {
	width: 100%;
	overflow: hidden;
	height: auto;
}
.NpsNumber p {
	font-weight: 500;
	font-size: 32px;
	margin: 0;
	margin-bottom: 5px;
	margin-top: -5px;
	float: left;
}
.NpsWrap:hover p {
	color: #fa0073;
}
.img01Arrowok,
.img02Arrowok {
	display: none;
}
.IncreaseNps .img01Arrowok {
	display: block;
}
.DecreaseNps .img02Arrowok {
	display: block;
}
.NpsDescription {
	font-weight: 500;
	font-size: 14px;
	margin: 0;
	margin-bottom: 5px;
	padding-left: 20px;
	padding-top: 20px;
	color: #035cf7;
	padding: 0;
	display: table;
	border-radius: 4px;
	display: none;
}
.NpsWrap:hover div {
	color: #035cf7 !important;
}
.NpsWrap:hover div {
	color: #fa0073 !important;
}
.NpsUpdatedAt {
	color: #666;
	margin: 0;
	font-size: 13px;
	line-height: 1.5;
	display: block;
	margin-top: -9px;
}
.OpsDEpartureHover {
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	border-top: 1px solid #d6d6d6;
	display: none;
}

.DeparturesNumbers {
	padding: 10px 20px;
	position: relative;
}

.DeparturesNumbers:hover {
	background-color: rgba(0, 125, 195, 0.09);
}

.DepartureWrap {
	width: 100%;
	height: auto;
	overflow: hidden;
}
.IconImageDeparture {
	width: 40px;
	height: 40px;
	float: left;
}
.TextWrapDeparture {
	float: left;
	margin-left: 20px;
}
.NumerDeparture {
	font-weight: 500;
	font-size: 18px;
	margin: 0;
	margin-bottom: 5px;
	margin-top: -5px;
	float: left;
}

.NumerDeparture span img {
	width: 30px;
	height: 18px;
	padding-left: 0px;
	margin-top: -5px;
}

.DescriptionDeparture {
	font-size: 12px;
	color: #666;
	margin: 0;
	padding: 0;
}
.DescriptionDeparture span {
	font-weight: 600;
	color: #0b2343;
}

.StatesAirline span {
	width: 20px;
	height: 20px;
	display: block;
	float: left;
}

.wrapperOpsNumber {
	width: 100%;
	height: auto;
	overflow: hidden;
}

.SecurityBoxWrapper {
	/*height: 55px;
  overflow: hidden;*/
	border-top: 1px solid transparent;
	border-bottom: 1px solid #d6d6d6;
	display: block;
	display: none;
}

.SecurityBoxWrapper img {
	display: none;
}

.SecurityBoxWrapper .TextWrapDeparture {
	margin-left: 0;
}

.StatesAirline span.DecreaseNps,
.StatesAirline span.IncreaseNps {
	margin-left: 6px;
	margin-top: -2px;
}
@media (max-width: 800px) {
	.tooltip {
		display: inline;
	}
}
