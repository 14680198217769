.container-modal-notification {
  z-index: 99999;
    width: 100vw;
    height: 100vh;
    position: block;
    overflow: visible;
    background-color: rgba(0,0,0,.5);
    top: 150%;
    left: 50%;
    
    /* transform: translate(-50%,-50%); */
    
}



.modal-notification {
  position: fixed;
  width: 95vw;
  max-width: 700px;
  min-height: 400px;
  background: #fff;
  border-radius: 11px;
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 2 5px 10px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  overflow-y: scroll;
  max-height: 700px;
  min-height: 200px;
  border-radius: 11px;
  height: 90vh;
}
.ModalClaseBlock{
  display: none;
}
.ModalClaseBlock.AlertaTodoGAM{
  display: block;
}
.AlertaTodoGAM{
  width: 100%;
  display: block;
}
.homealertModalModification{
  height: auto;
  overflow: hidden;
}

.alertwidth .modal-notification{
  overflow: hidden;
}

.all-benefits .modal-notification{
  height: 90vh;
  position: relative;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
}

.close-button {
  border: none;
  color: rgb(10, 64, 214);
  font-size: 24px;
}

@media (max-width: 800px) {
.alertwidth .modal-notification{
  height: 60vh;
  /* transform: translate(-50%,-2.5vw); */
  border-radius: 20px;
}
.imageNotification{
  height: 24vw;
}
.modalPadding h1{
  font-size: 24px;
}
.modalPadding p{
  font-size: 16px;
}
.content p{
  font-size: 14px;
}
.all-benefits .modal-notification {
  height: 74vh;
  position: relative;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
/* .homealertModalModification{
  height: 100vh !important;
  width: 100%;
  top: 0;
  transform: translate(0,0) !important;
  left: 0;
  border-radius: 0 !important;
}
.homealertModalModification .closeModal{
  --safe-area-inset-top: env(safe-area-inset-top);
    top: 20px;
    top: calc(20px + var(--safe-area-inset-top));
} */
}
/* @media (max-height: 736px){
.homealertModalModification{
  height: 90vh;
}
} */
