.singleLine-menu-item {
	align-items: center;
	display: flex;
	height: 25px 100px !important;
	justify-content: start;

}
.singleLine-menu-item a {
	line-height: 1.5 !important;
	height: 100%;
	padding-top: 16px;
	margin-bottom: 11%;
}
