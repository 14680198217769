/* This stylesheet generated by Transfonter (http://transfonter.org) on July 1, 2016 4:31 PM */

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('NeueHaasUnicaPro-UltraLight.eot');
	src: url('NeueHaasUnicaPro-UltraLight.eot?#iefix') format('embedded-opentype'),
		url('NeueHaasUnicaPro-UltraLight.woff') format('woff'),
		url('NeueHaasUnicaPro-UltraLight.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
	font-display: block; /* Define how the browser behaves during download */
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('NeueHaasUnicaPro-Thin.eot');
	src: url('NeueHaasUnicaPro-Thin.eot?#iefix') format('embedded-opentype'),
		url('NeueHaasUnicaPro-Thin.woff') format('woff'),
		url('NeueHaasUnicaPro-Thin.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
	font-display: block; /* Define how the browser behaves during download */
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('NeueHaasUnicaPro-Light.eot');
	src: url('NeueHaasUnicaPro-Light.eot?#iefix') format('embedded-opentype'),
		url('NeueHaasUnicaPro-Light.woff') format('woff'),
		url('NeueHaasUnicaPro-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: block; /* Define how the browser behaves during download */
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('NeueHaasUnicaPro-Regular.eot');
	src: url('NeueHaasUnicaPro-Regular.eot?#iefix') format('embedded-opentype'),
		url('NeueHaasUnicaPro-Regular.woff') format('woff'),
		url('NeueHaasUnicaPro-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: block; /* Define how the browser behaves during download */
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('NeueHaasUnicaPro-Regular.eot');
	src: url('NeueHaasUnicaPro-Regular.eot?#iefix') format('embedded-opentype'),
		url('NeueHaasUnicaPro-Regular.woff') format('woff'),
		url('NeueHaasUnicaPro-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: block; /* Define how the browser behaves during download */
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('NeueHaasUnicaPro-Medium.eot');
	src: url('NeueHaasUnicaPro-Medium.eot?#iefix') format('embedded-opentype'),
		url('NeueHaasUnicaPro-Medium.woff') format('woff'),
		url('NeueHaasUnicaPro-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: block; /* Define how the browser behaves during download */
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('NeueHaasUnicaPro-Medium.eot');
	src: url('NeueHaasUnicaPro-Medium.eot?#iefix') format('embedded-opentype'),
		url('NeueHaasUnicaPro-Medium.woff') format('woff'),
		url('NeueHaasUnicaPro-Medium.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: block; /* Define how the browser behaves during download */
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('NeueHaasUnicaPro-Bold.eot');
	src: url('NeueHaasUnicaPro-Bold.eot?#iefix') format('embedded-opentype'),
		url('NeueHaasUnicaPro-Bold.woff') format('woff'),
		url('NeueHaasUnicaPro-Bold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: block; /* Define how the browser behaves during download */
}


@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('NeueHaasUnicaPro-Heavy.eot');
	src: url('NeueHaasUnicaPro-Heavy.eot?#iefix') format('embedded-opentype'),
		url('NeueHaasUnicaPro-Heavy.woff') format('woff'),
		url('NeueHaasUnicaPro-Heavy.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: block; /* Define how the browser behaves during download */
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('NeueHaasUnicaPro-Black.eot');
	src: url('NeueHaasUnicaPro-Black.eot?#iefix') format('embedded-opentype'),
		url('NeueHaasUnicaPro-Black.woff') format('woff'),
		url('NeueHaasUnicaPro-Black.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
	font-display: block; /* Define how the browser behaves during download */
}


@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('NeueHaasUnicaPro-XBlack.eot');
	src: url('NeueHaasUnicaPro-XBlack.eot?#iefix') format('embedded-opentype'),
		url('NeueHaasUnicaPro-XBlack.woff') format('woff'),
		url('NeueHaasUnicaPro-XBlack.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
	font-display: block; /* Define how the browser behaves during download */
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('NeueHaasUnicaPro-UltLightIt.eot');
	src: url('NeueHaasUnicaPro-UltLightIt.eot?#iefix') format('embedded-opentype'),
		url('NeueHaasUnicaPro-UltLightIt.woff') format('woff'),
		url('NeueHaasUnicaPro-UltLightIt.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
	font-display: block; /* Define how the browser behaves during download */
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('NeueHaasUnicaPro-XBlackIt.eot');
	src: url('NeueHaasUnicaPro-XBlackIt.eot?#iefix') format('embedded-opentype'),
		url('NeueHaasUnicaPro-XBlackIt.woff') format('woff'),
		url('NeueHaasUnicaPro-XBlackIt.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
	font-display: block; /* Define how the browser behaves during download */
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('NeueHaasUnicaPro-BlackIt.eot');
	src: url('NeueHaasUnicaPro-BlackIt.eot?#iefix') format('embedded-opentype'),
		url('NeueHaasUnicaPro-BlackIt.woff') format('woff'),
		url('NeueHaasUnicaPro-BlackIt.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
	font-display: block; /* Define how the browser behaves during download */
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('NeueHaasUnicaPro-Italic.eot');
	src: url('NeueHaasUnicaPro-Italic.eot?#iefix') format('embedded-opentype'),
		url('NeueHaasUnicaPro-Italic.woff') format('woff'),
		url('NeueHaasUnicaPro-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
	font-display: block; /* Define how the browser behaves during download */
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('NeueHaasUnicaPro-ThinItalic.eot');
	src: url('NeueHaasUnicaPro-ThinItalic.eot?#iefix') format('embedded-opentype'),
		url('NeueHaasUnicaPro-ThinItalic.woff') format('woff'),
		url('NeueHaasUnicaPro-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
	font-display: block; /* Define how the browser behaves during download */
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('NeueHaasUnicaPro-LightIt.eot');
	src: url('NeueHaasUnicaPro-LightIt.eot?#iefix') format('embedded-opentype'),
		url('NeueHaasUnicaPro-LightIt.woff') format('woff'),
		url('NeueHaasUnicaPro-LightIt.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
	font-display: block; /* Define how the browser behaves during download */
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('NeueHaasUnicaPro-MediumIt.eot');
	src: url('NeueHaasUnicaPro-MediumIt.eot?#iefix') format('embedded-opentype'),
		url('NeueHaasUnicaPro-MediumIt.woff') format('woff'),
		url('NeueHaasUnicaPro-MediumIt.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
	font-display: block; /* Define how the browser behaves during download */
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('NeueHaasUnicaPro-BoldItalic.eot');
	src: url('NeueHaasUnicaPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('NeueHaasUnicaPro-BoldItalic.woff') format('woff'),
		url('NeueHaasUnicaPro-BoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
	font-display: block; /* Define how the browser behaves during download */
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('NeueHaasUnicaPro-HeavyIt.eot');
	src: url('NeueHaasUnicaPro-HeavyIt.eot?#iefix') format('embedded-opentype'),
		url('NeueHaasUnicaPro-HeavyIt.woff') format('woff'),
		url('NeueHaasUnicaPro-HeavyIt.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
	font-display: block; /* Define how the browser behaves during download */
}


