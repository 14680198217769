.juntos-container {
	background-size: 105% !important;
	min-height: 70vh;
	overflow: hidden;
	padding: 100px 0;
	position: relative;
}

.juntos-container .juntos-bg {
	filter: blur(3px);
	height: 105%;
	width: 105%;
	background-size: 105% !important;
	background-position: center !important;
	z-index: 1;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.juntos-container .front-part {
	color: white;
	position: relative;
	z-index: 2;
}

.juntos-container h1 {
	/* color: rgba(11, 35, 67, 1); */
	font-size: 60px;
	font-weight: bold;
	line-height: 1;
	margin: 0 auto 20px;
	text-shadow: 0 10px 10px rgba(11, 35, 67, 1);
}

h1 .small-title {
	font-size: 16px !important;
	text-align: center;
	margin-top: 0.75em;
}

.juntos-container p {
	line-height: 1.3;
	font-size: 25px;
}

.juntos-container .main-video {
	padding-top: 25px;
}

.juntos-container .video-container iframe {
	width: 100%;
}

.juntons-rest-videos {
	padding: 50px 0 70px;
}

.juntons-rest-videos h2 {
	color: rgb(11, 35, 67);
	margin: 0 0 30px 0;
}

.juntons-rest-videos .video-container-holder .video-container {
	width: 100%;
}

.video-container-holder {
	padding-top: 56.25%;
	position: relative;
}

.video-container-holder .react-player {
	position: absolute;
	top: 0;
	left: 0;
}

.item--video p {
	width: 100%;
	text-align: center;
	font-size: 17px;
	margin-top: 0.5em;
	opacity: 0.5;
}

.main-header-content-juntos {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.main-header-content-juntos h1 {
	margin-bottom: 40px;
	width: 100%;
	text-align: center;
}

@media (max-width: 991px) {
	.juntos-container h1 {
		font-size: 40px;
	}
}

@media (max-width: 767px) {
	.juntos-container .juntos-bg {
		height: 150%;
		width: 105%;
		background-size: cover !important;
	}

	.juntos-container h1 {
		margin-bottom: 20px;
	}

	.video-container-holder {
		margin-bottom: 30px;
	}
}
