.compliance-header {
	align-items: flex-start;
	background-size: cover !important;
	background-position: left center !important;
	display: flex;
	flex-direction: column;
	height: 413px;
	justify-content: center;
	padding-left: 80px;
	width: 100%;
}

.compliance-header h1 img {
	height: 60px;
	margin-bottom: 10px;
	width: auto;
}

.compliance-header p {
	line-height: 1.2;
	width: 450px;
}

.compliance-documents {
	display: flex;
	justify-content: center;
}

.compliance-documents .documents-container {
	background: #202548;
	border-radius: 15px;
	overflow: hidden;
	margin-top: -50px;
	margin-bottom: 50px;
	width: 700px;
}

.compliance-documents .documents-container h2 {
	color: white;
	font-size: 16px;
	margin: 10px 0;
	text-align: center;
	text-transform: uppercase;
	width: 100%;
}

.compliance-documents .documents-container .files-holder {
	align-items: center;
	display: flex;
	justify-content: space-between;
}

.compliance-documents .documents-container .files-holder .file {
	align-items: center;
	background: #092f5d;
	box-sizing: border-box;
	cursor: pointer;
	display: flex;
	justify-content: center;
	overflow: visible;
	padding: 10px 0;
	transform: scale(1);
	transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	width: 49.5%;
}

.compliance-documents .documents-container .files-holder .file:hover {
	background: rgba(0, 0, 0, 0.25);
	transform: scale(1.05);
}

.compliance-documents .documents-container .files-holder .file img:first-child {
	height: 55px;
	margin-right: 15px;
	width: auto;
}

.compliance-documents .documents-container .files-holder .file img:last-child {
	height: 50px;
	width: auto;
}

.compliance-documents .documents-container .files-holder .file img.conducta {
	height: 40px;
	width: auto;
}

section.benefits {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: 0px !important;
}

section.benefits h2 {
	font-size: 35px;
	margin-top: 30px;
	margin-bottom: 60px;
	text-align: center;
	/* text-transform: uppercase; */
	width: 100%;
}

section.benefits .benefits-holder {
	align-self: flex-start;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 20px !important;
	justify-content: center;
	width: 90%;
}

section.benefits .benefits-holder .b-item {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	text-align: center;
	padding: 0 10px;
	width: 20%;
}

section.benefits .benefits-holder .b-item img {
	height: 60px;
	margin-bottom: 10px;
	width: auto;
}

section.benefits .benefits-holder .b-item p {
	font-size: 14px;
}

section.regulations {
	display: flex;
	justify-content: center;
}

section.regulations article {
	background: #f5f9fc;
	border: 1px solid transparent;
	border-radius: 15px;
	padding: 30px 30px;
	width: 100%;
}

section.regulations article h2 {
	color: #202548;
	font-size: 35px;
	line-height: 1.2;
	margin-bottom: 25px;
	text-align: center;
}

section.regulations article .btns-holder {
	align-items: center;
	display: flex;
	justify-content: center;
}

section.regulations article .btns-holder button {
	align-items: center;
	background: #202548;
	border-radius: 15px;
	color: white;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	font-size: 14px;
	font-weight: 700;
	justify-content: center;
	margin-right: 40px;
	padding: 15px;
	min-width: 170px;
	text-transform: uppercase;
	transform: scale(1);
	transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

section.regulations article .btns-holder button:hover {
	background: black;
	box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
	transform: scale(1.05);
}

section.regulations article .btns-holder button:nth-child(3n) {
	margin-right: 0;
}

section.regulations article .btns-holder button img {
	height: 30px;
	margin-bottom: 5px;
	width: auto;
}

section.ambitos {
	margin-bottom: 60px;
}

section.ambitos .ambitos-title {
	align-items: center;
	display: flex;
	justify-content: center;
	margin: 30px 0 40px;
	padding: 15px 0;
}

section.ambitos .ambitos-title h2 {
	color: #202548;
	font-size: 35px;
	/* font-weight: 700; */
	margin: 0 0 0;
	/* text-transform: uppercase; */
}

section.ambitos .ambitos-content {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: 70px;
}

section.ambitos .ambitos-content .intro-full {
	padding: 0 25%;
	text-align: center;
	margin-bottom: 40px;
	width: 100%;
}

section.ambitos .ambitos-content .a-item {
	align-items: center;
	background: #f5f9fc;
	border: 1px solid transparent;
	border-radius: 15px;
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0), 0 10px 10px rgba(0, 0, 0, 0);
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-right: 20px;
	margin-bottom: 30px;
	padding-bottom: 25px;
	position: relative;
	transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	width: 250px;
}

section.ambitos .ambitos-content .a-item.overflow-hidden {
	overflow: hidden;
}

section.ambitos .ambitos-content .a-item:hover {
	border: 1px solid #c1c9ff;
	/* box-shadow: 0 14px 28px rgba(0, 0, 0, 0.05),
		0 10px 10px rgba(0, 0, 0, 0.035); */
	box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
	cursor: pointer;
	transform: scale(1.05);
}

section.ambitos .ambitos-content .a-item:last-child {
	margin-right: 0;
}

section.ambitos .ambitos-content .a-item:hover button {
	background: #103c7f;
}

section.ambitos .ambitos-content .a-item img {
	border: 4px solid #f5f9fc;
	border-radius: 50%;
	height: 90px;
	margin-top: -45px;
	width: auto;
}
section.ambitos .ambitos-content .a-item img.cover-size {
	border: none;
	height: 110px;
}

section.ambitos .ambitos-content .a-item img.double {
	height: 110px;
}

.double-img {
	display: flex;
}

.double-img img:last-child {
	margin-left: -30px;
}

section.ambitos .ambitos-content .a-item h3 {
	font-size: 16px;
	font-weight: 700;
	margin: 20px 0;
	padding: 0 30px;
	text-align: center;
	color: #104c8f;
	/* text-transform: uppercase; */
}

section.ambitos .ambitos-content .a-item button {
	background: #104c8f;
	/* aquiROJO */
	border: none;
	border-radius: 10px;
	color: white;
	cursor: pointer;
	padding: 10px 30px;
}

section.line {
	align-items: center;
	background-attachment: fixed !important;
	background-size: contain !important;
	background-repeat: repeat !important;
	border-top: 1px solid #9fdadf;
	border-bottom: 1px solid #9fdadf;
	display: flex;
	justify-content: center;
	margin-bottom: 80px;
	padding: 40px 0;
}

section.line .left-side {
	align-items: center;
	background: #202548;
	border-radius: 15px;
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 20px 50px;
}

section.line .left-side a {
	color: #1c869e;
	font-size: 18px;
	margin-top: 1em;
}

section.line .left-side p {
	font-size: 18px;
	margin: 0;
}

section.line .right-side {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 20px 50px;
}

section.line .right-side p {
	font-size: 18px;
	line-height: 1.2;
	margin: 0;
	text-align: center;
}

section.line .right-side ul {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	list-style-type: none;
	margin-top: 10px;
	width: 300px;
}

section.line .right-side ul li {
	margin-right: 15px;
	position: relative;
}

section.line .right-side ul li:before {
	background: #202548;
	border-radius: 50%;
	content: '';
	height: 5px;
	left: 0;
	position: absolute;
	top: 55%;
	transform: translate(-8px, -50%);
	width: 5px;
}

section.tabber .tabber-selector {
	align-items: center;
	border-bottom: 2px solid rgba(32, 37, 72, 0.2);
	display: flex;
	justify-content: flex-start;
	list-style-type: none;
	margin-bottom: 50px;
	padding-left: 50px;
}
section.tabber .tabber-selector li {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	color: rgba(32, 37, 72, 0.3);
	cursor: pointer;
	font-size: 20px;
	padding: 18px 50px;
}

section.tabber .tabber-selector li:hover {
	background-color: rgba(32, 37, 72, 0.05);
}

section.tabber .tabber-selector li.active {
	background-color: rgba(32, 37, 72, 0.2);
	border: none;
	color: rgba(32, 37, 72, 0.6);
	font-weight: 700;
	margin-top: 0;
}

section.tabber .tabber-content {
	align-items: stretch;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin: 0 auto;
	margin-bottom: 60px;
	max-width: 930px;
	padding: 0;
	width: 70%;
}

section.tabber .tabber-content .tab-item {
	/* OLD STYLE
	border: 1px solid #202548;
	border-radius: 15px;
	color: #202548;
	padding: 20px 0;
	transition: all 0.15s linear; */
	/* NEW STYLE */
	align-items: center;
	background: white;
	border: 1px solid #e5e5e5;
	border-radius: 11px;
	/* box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075); */
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-top: 20px;
	max-width: 217px;
	/* OLD STYLE ALIVE */
	cursor: pointer;
	margin-right: 2%;
	margin-bottom: 2%;
	transform: scale(1);
	transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	width: 23.5%;
	z-index: 1;
}

section.tabber .tabber-content .tab-item:hover {
	/* background: rgba(32, 37, 72, 0.05); */
	transform: scale(1.05);
	box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
	z-index: 2;
}

section.tabber .tabber-content .tab-item:nth-child(4n) {
	margin-right: 0;
}

section.tabber .tabber-content .tab-item img {
	margin: 0 auto;
	height: 70px;
	width: auto;
}

section.tabber .tabber-content .tab-item p {
	/* OLD STYLE */
	/* font-size: 14px;
	line-height: 1.2;
	height: 32px;
	margin: 15px 0;
	overflow-y: hidden;
	padding: 0 30px;
	text-align: center; */
	/* OLD STYLE ALIVE */
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	/* NEW STYLE */
	color: #0b2343;
	font-weight: 500;
	font-size: 16px;
	line-height: 1.1;
	margin: 15px auto 20px;
	min-height: 48px;
	max-height: 48px;
	overflow: hidden;
	text-align: center;
	padding: 0 20px;
}

section.tabber .tabber-content .tab-item .download-btn {
	/* OLD STYLE */
	/*border-top: 1px solid #202548;
	margin: 0;
	padding-top: 20px; */
	/* OLD STYLE ALIVE */
	align-items: center;
	display: flex;
	justify-content: center;
	width: 100%;
	/* NEW STYLE */
	border-top: 1px solid #e5e5e5;
	height: 60px;
	max-height: 60px;
}

section.tabber .tabber-content .tab-item .download-btn img {
	height: auto;
	margin: 0;
	width: 20px;
}

section.tabber .tabber-content .tab-item .download-btn p {
	/* OLD STYLE */
	/* color: #202548;
	font-size: 18px;
	font-weight: 700;
	margin: 0;
	padding: 0;
	width: auto !important; */
	margin: 0;
	padding: 0;
	font-size: 14px;
	font-weight: 600;
	color: #007dc3;
	min-height: auto;
	margin-left: 10px;
	width: auto;
}

.compliance-modal .modal-footer-card-top,
.compliance-modal-2 .modal-footer-card-top {
	align-items: center;
	background: #202548;
	background-size: cover !important;
	background-position: center !important;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 30px 0;
}

.compliance-modal-2 .modal-footer-card-top {
	padding: 30px 0;
}

.compliance-modal .modal-footer-card-top img {
	height: 70px;
	width: auto;
}

.compliance-modal-2 .modal-footer-card-top img {
	height: 90px;
	width: auto;
}

.compliance-modal .modal-footer-card-content h3,
.compliance-modal-2 .modal-footer-card-content h3 {
	font-size: 20px;
	margin-bottom: 1em;
	text-align: center;
	text-transform: uppercase;
	width: 100%;
}

.compliance-modal .modal-footer-card-content .actual-modal-content,
.compliance-modal-2 .modal-footer-card-content .actual-modal-content {
	line-height: 1.3;
}

.compliance-modal .modal-footer-card-content .actual-modal-content p ul,
.compliance-modal-2 .modal-footer-card-content .actual-modal-content p ul {
	margin-left: 1.25em;
}

.fake-pink-button {
	background: #FA0073;
	border-radius: 100px;
	color: white !important;
	font-size: 16px;
	font-weight: 500;
	text-decoration: none;
	/* transition: all 0.15s ease-in-out; */
	transform: scale(1);
	transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	padding: 15px 47px;
}
.fake-pink-button:hover{
	box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
	transform: scale(1.05);
	color: white !important;
}

.fake-red-button {
	background: rgb(233, 27, 47, 1);
	border-radius: 100px;
	color: white !important;
	font-size: 16px;
	font-weight: 500;
	text-decoration: none;
	/* transition: all 0.15s ease-in-out; */
	transform: scale(1);
	transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	padding: 15px 47px;
}

.fake-red-button:hover {
	/* background: rgb(200, 27, 47, 1); */
	box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
	transform: scale(1.05);
}

.guerreroAguila {
	margin-left: 10px;
}
.guerreroAguilaSm {
	display: none;
}

.temp-bg-image {
	background-size: cover !important;
	background-position: center !important;
	/* left: 0;
	top: -100px;
	position: absolute; */
	height: 100px;
	width: 100%;
	/* z-index: -1; */
}

.compliance-end-alert {
	background: #f5f8f9;
	display: flex;
	flex-direction: column;
	height: 200px;
	font-size: 16px;
	justify-content: center;
	padding: 100px 0;
	align-items: center;
	text-align: center;
}

.compliance-end-alert.white-bg {
	background: white;
	font-size: 18px;
	font-weight: 700;
}

.compliance-end-alert.white-bg p {
	margin: 0 0 15px;
}

.compliance-end-alert img {
	height: 40px;
	margin-bottom: 10px;
	width: auto;
}

.benefits-title {
	margin-top: 0 !important;
	margin-bottom: 110px !important;
}

@media (max-width: 1024px) {
	section.tabber .tabber-content .tab-item {
		margin-right: 2%;
		width: 32%;
	}
	section.tabber .tabber-content .tab-item:nth-child(4n) {
		margin-right: 2%;
	}
	section.tabber .tabber-content .tab-item:nth-child(3n) {
		margin-right: 0;
	}
	.compliance-end-alert {
		height: 84px;
		padding: 29px 15px;
		margin-bottom: 100px;
	}
}

@media (max-width: 800px) {
	section.tabber .tabber-content .tab-item {
		margin-right: 2%;
		width: 48%;
	}
	section.tabber .tabber-content .tab-item:nth-child(4n) {
		margin-right: 2%;
	}
	section.tabber .tabber-content .tab-item:nth-child(3n) {
		margin-right: 2%;
	}
	section.tabber .tabber-content .tab-item:nth-child(2n) {
		margin-right: 0;
	}
}

@media (max-width: 768px) {
	.compliance-header {
		align-items: center;
		/* height: 313px; */
		padding: 0 40px;
	}
	.compliance-header p {
		width: 95%;
		text-align: center;
	}
	.compliance-documents .documents-container {
		margin-top: -50px;
		width: 400px;
	}
	.compliance-documents .documents-container h2 {
		font-size: 14px;
	}
	.compliance-documents .documents-container .files-holder {
		flex-wrap: wrap;
	}
	.compliance-documents .documents-container .files-holder .file {
		width: 100%;
	}
	/* section.benefits {
		margin-bottom: 20px;
	} */
	section.benefits .benefits-holder .b-item {
		margin-bottom: 20px;
		width: 33.33%;
	}
	section.ambitos .ambitos-content {
		flex-wrap: wrap;
	}
	section.ambitos .ambitos-content .a-item {
		margin-bottom: 70px;
	}
	section.ambitos .ambitos-content .a-item:nth-child(2n) {
		background: white;
		margin-right: 0;
	}
	section.line {
		display: block;
	}
	section.line .left-side {
		margin: 0px 70px;
	}
}

@media (max-width: 640px) {
	section.tabber .tabber-content {
		justify-content: center;
	}

	section.tabber .tabber-content .tab-item {
		margin-right: 0;
		width: 100%;
	}
	section.tabber .tabber-content .tab-item:nth-child(5n) {
		margin-right: 0;
	}
	section.tabber .tabber-content .tab-item:nth-child(4n) {
		margin-right: 0;
	}
	section.tabber .tabber-content .tab-item:nth-child(3n) {
		margin-right: 0;
	}
	section.tabber .tabber-content .tab-item:nth-child(2n) {
		margin-right: 0;
	}
	.guerreroAguila {
		display: none;
	}
	.guerreroAguilaSm {
		display: inline;
	}
	section.line .left-side {
		margin: 0px 30px;
		padding: 20px 15px;
	}
	section.benefits {
		margin-bottom: -100px !important;
	}

	section.benefits h2 {
		font-size: 30px;
	}
	section.regulations article h2 {
		font-size: 30px;
	}
	section.ambitos .ambitos-title h2 {
		font-size: 30px;
	}
	section.ambitos .ambitos-content .intro-full {
		padding: 0 10%;
	}
}

@media (max-width: 450px) {
	.compliance-header h1 img {
		width: 100%;
	}
	.compliance-header p {
		width: 100%;
		text-align: left;
	}
	.compliance-documents .documents-container {
		margin-top: 0px;
	}
	.compliance-documents {
		margin: 10px;
	}
	.benefits-title {
		margin-bottom: 70px !important;
	}
	section.benefits {
		margin-bottom: -70px !important;
	}
	section.benefits h2 {
		font-size: 25px;
	}
	section.regulations article h2 {
		font-size: 25px;
	}
	section.regulations article {
		padding: 30px 15px;
	}
	section.regulations article .btns-holder {
		display: block;
		margin-left: 20%;
	}
	section.ambitos .ambitos-title h2 {
		font-size: 25px;
		text-align: center;
		margin: 0px 5px;
	}
	section.ambitos .ambitos-content .intro-full {
		padding: 0px 5%;
	}

	section.line .left-side p {
		font-size: 15px;
	}

	section.line .left-side a {
		font-size: 15px;
	}

	.etica_title {
		font-size: 0.6em;
	}

	section.line .right-side {
		padding: 20px 15px;
	}

	section.line .right-side ul {
		width: 100%;
	}

	section.tabber .tabber-selector li {
		padding: 10px 25px;
	}

	.fake-red-button {
		font-size: 15px;
		padding: 15px 40px;
	}
}

.video-container-container {
	width: 70%;
}
@media (max-width: 640px) {
	.video-container-container {
		width: 300px;
	}
}
