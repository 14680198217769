/* Set the size of the div element that contains the map */
#mapAeromexico {
  height: calc(100vh - 60px); /* The height is 400 pixels */
  width: 100%; /* The width is the width of the web page */
  margin: 0;
  padding: 0;
}

.ourRoutes{
  margin: 0;
  padding: 0;
  font-size: 24px;
  margin: 0;
  padding: 0;
  background-color: rgba(11, 35, 67, 0.5);
  color: white;
  right: 0px;
  top: 0px;
  border-radius: 7px 7px 0px 7px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  text-align: center;
  overflow: hidden;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}

#ourRoutesAccordion{
  border-radius: 7px;
  width: 400px;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
#ourRoutesAccordion .accordion__button{
  background-color: transparent!important;
}
#ourRoutesAccordion .accordion__button::before{
  color: white;
}
#ourRoutesAccordion .accordion__panel{
  background-color: transparent;
}
.ourRoutes p{
  margin: 0;
  padding: 0;
  font-size: 20px;
}
.ourRoutes p.smallRoutes{
  font-size: 12px;
  color: rgba(255,255,255,.5);
  text-align: center;
  margin-top: -13px;
  margin-bottom: 3px;
  padding-left: 22px;
}

#ourRoutesAccordion .accordion__button p.title{
  font-size: 24px;
  color: white;
  font-weight: 500;
  padding-top: 7px;
padding-left: 22px;
}
#ourRoutesAccordion .description ul{
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(2,1fr);
}
#ourRoutesAccordion .description ul li{
  list-style: none;
  font-size: 16px;
  font-weight: 500;
}
#ourRoutesAccordion .description ul li:nth-child(odd){
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.marker-point {
  display: inline-block;
  width: 2.2em;
  text-align: center;
}
.dot-image {
  width: 50%;
}

.tooltip-map-wrapper {
  display: none;
}

.marker-point:hover .tooltip-map-wrapper {
  display: block;
  width: 150px;
  border: 1px solid #ccc;
  background: #fff;
  color: #0b2343;
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  background-color: white;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
  z-index: 1;
}
.tooltip-content {
  text-align: left;
  padding: 5px 0 0;
}

.tooltip-title {
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
}
.tooltip-content {
  display: flex;
  flex-wrap: wrap;
}

.wrapper-image-tooltip {
  width: 25%;
}

span.tooltip-map-description {
  width: 75%;
  padding-left: 10px;
}

.image-destination {
  width: 100%;
}

.routesContainer {
  width: 400px;
  position: absolute;
  top: 90px;
  right: 30px;
  z-index: 39;
}

.routesContainer:hover .ourRoutes{
  background-color: rgba(11, 35, 67, 1);
}

.routesInfo {
  border-style: solid;
  border-width: 3px;
  width: 60%;
  display: block;
  background-color:rgba(255, 255, 255, 0.6);
  border-color: rgba(11, 35, 67, 0.5);
  border-top: none;
  float: right;
  padding: 5px 5px;  
  z-index: 40;
  border-radius: 0 0 7px 7px;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.routesContainer:hover .routesInfo{
  background-color:rgba(255, 255, 255, 1);
  border-color: rgba(11, 35, 67, 1);
}

.routeType {
  width: 100%;
  display: flex;
  height: 10px;
  padding: 12px 10px 0 10px;
}

.routeItem {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 30px;
}

.routeItem p {
  font-size: 14px;
  line-height: 30px;
  vertical-align: middle;
  color: rgb(11, 35, 67);
  text-align: left;
  padding: 0 0 0 5px;
}

.routeTypeDashed, .routeTypeSolid {
  width: 100%;
  height: 4px;
  border-color: #e91b2f;
  border-width: 4px;
  background-color: rgba(255, 255, 255, 0.3);
  border-top-style: solid;
}

.routeTypeDashed {
  border-top-style: dashed;
}