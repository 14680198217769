@tailwind base;
@tailwind components;

/* HERE GOES THE APPLY */
.datalist-custom-style-myhr {
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTEuMTc4LDE5LjU2OUMxMS4zNjQsMTkuODM5LDExLjY3MiwyMCwxMiwyMHMwLjYzNi0wLjE2MSwwLjgyMi0wLjQzMWw5LTEzYzAuMjEyLTAuMzA2LDAuMjM2LTAuNzA0LDAuMDYzLTEuMDMzIEMyMS43MTMsNS4yMDcsMjEuMzcyLDUsMjEsNUgzQzIuNjI4LDUsMi4yODcsNS4yMDcsMi4xMTQsNS41MzZDMS45NDEsNS44NjUsMS45NjYsNi4yNjMsMi4xNzgsNi41NjlMMTEuMTc4LDE5LjU2OXoiLz48L3N2Zz4=");
	background-position: 95% center;
	background-repeat: no-repeat;
	background-size: 10px 10px;
	-webkit-appearance: none;
	outline: none !important;
	box-shadow: none !important;
	border-radius: 5px !important;
	@apply mt-1 block w-full rounded-sm border-1.5 px-3 py-1 ring-0 focus:border-amBlack;
}

input.datalist-custom-style-myhr::-webkit-calendar-picker-indicator {
	display: none;
}

.datalist-custom-style-myhr:focus {
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMywxOWgxOGMwLjM3MiwwLDAuNzEzLTAuMjA3LDAuODg2LTAuNTM2YzAuMTczLTAuMzI5LDAuMTQ4LTAuNzI3LTAuMDYzLTEuMDMzbC05LTEzYy0wLjM3My0wLjUzOS0xLjI3MS0wLjUzOS0xLjY0NSwwIGwtOSwxM2MtMC4yMTIsMC4zMDYtMC4yMzYsMC43MDQtMC4wNjMsMS4wMzNDMi4yODcsMTguNzkzLDIuNjI4LDE5LDMsMTl6Ii8+PC9zdmc+");
	border: 1.5px solid #0b2342 !important;
}

.myFlexBorder {
	border: 1px solid #0f4b91 !important;
}

.myownprose ul {
	@apply list-inside list-disc;
}
.myownprose ul li {
	@apply w-full px-4 py-2 text-amBlue;
}
.myownprose ul li a {
	@apply font-bold underline hover:text-amRed hover:underline;
}
.myownprose p {
	@apply w-full text-gray-500;
}
.flex-prose ul {
	@apply list-outside list-disc;
}
.flex-prose h3,
.flex-prose h4 {
	@apply font-bold;
}
.flex-prose ul li {
	@apply ml-4;
}
.flex-prose a {
	@apply text-amRed underline hover:underline;
}
.flex-prose-none li {
	@apply list-none;
}
.flex-prose-none ul li a {
	@apply text-amRed underline hover:underline;
}

.holiday-right-corner.christmas {
	/* background-color: red; */
	background-image: url("./assets/snow-r-corner.png");
	background-repeat: no-repeat;
	background-size: contain;
	background-position: 100% 0;
	z-index: 999;
	@apply pointer-events-none h-[230px] w-[350px];
}
.holiday-left-corner.christmas {
	/* background-color: red; */
	background-image: url("./assets/snow-left-corner.png");
	background-repeat: no-repeat;
	background-size: contain;
	background-position: 100% 0;
	z-index: 999;
	@apply pointer-events-none h-[175px] w-[450px];
}

@tailwind utilities;

@font-face {
	font-family: "Garnett";
	font-style: normal;
	font-weight: normal;
	src: url("./assets/fonts/Garnett-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Garnett";
	font-style: italic;
	font-weight: normal;
	src: url("./assets/fonts/Garnett-RegularItalic.ttf") format("truetype");
}

@font-face {
	font-family: "Garnett";
	font-style: normal;
	font-weight: 500;
	src: url("./assets/fonts/Garnett-Semibold.ttf") format("truetype");
}

@font-face {
	font-family: "Garnett";
	font-style: italic;
	font-weight: 500;
	src: url("./assets/fonts/Garnett-SemiboldItalic.ttf") format("truetype");
}

@font-face {
	font-family: "Navidad";
	font-style: italic;
	font-weight: normal;
	src: url("./assets/fonts/navidad/ChristmasJingle-Italic.ttf") format("truetype");
}
