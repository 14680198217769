.footerProfile__newsContainer.discount-cards-container {
	display: grid;
}
.footerProfile__newsContainer.discount-cards-container
	.footerProfile__newsCard__imageContainer {
	width: 50%;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}
.wrapper-cont-discounts {
	width: 90%;
	max-width: 1400px;
	margin: 0 auto;
	height: auto;
	margin-top: 40px;
}
.footerProfile__newsCard.backImageDiscountsProfile
	.footerProfile__newsCard__imageContainer,
.footerProfile__newsCard.backImageDiscountsProfile
	.footerProfile__newsCard__imageContainer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 11px;
	background-size: cover;
}
.footerProfile__newsCard.backImageDiscountsProfile
	.footerProfile__newsCard__textContainer {
	width: 65%;
}

.footerProfile__newsCard__textContainer {
	position: relative;
	z-index: 1;
}

.footerProfile__newsCard__textContainer.WhiteTextDiscount,
.footerProfile__newsCard__textContainer.WhiteTextDiscount
	.footerProfile__newsCard__title,
.footerProfile__newsCard__textContainer.WhiteTextDiscount
	h6.footerProfile__newsCard__information,
.footerProfile__newsCard__textContainer.WhiteTextDiscount a.link-benefitss {
	color: white;
}
.footerProfile__newsCard__textContainer.WhiteTextDiscount
	h6.footerProfile__newsCard__information {
	opacity: 1;
	font-size: 12px;
}
.PositionFixed {
	position: sticky;
	top: 160px;
}
.personalInformation {
	width: calc(100% - 30px);
	border: 1px solid #000;
	height: auto;
	overflow: hidden;
	background-color: white;
	border-radius: 7px;
	border: 1px solid #e5e5e5;
	box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	position: relative;
	margin-right: 30px;
}

.personalInformation .rowPersonal {
	width: 100%;
	height: auto;
	border-bottom: 1px solid #e5e5e5;
	overflow: hidden;
}

.personalInformation .rowPersonal:nth-child(even) {
	background-color: rgba(0, 125, 195, 0.07);
}

.personalInformation .rowPersonal p {
	display: block;
	float: left;
	width: 50%;
	line-height: 50px;
	margin: 0;
	padding: 0;
	font-size: 14px;
	font-weight: 500;
	color: #0b2343;
	padding-right: 20px;
}

.personalInformation .rowPersonal p.locationtext {
	line-height: 1.5;
	margin-top: 14px;
	margin-bottom: 13px;
}

.personalInformation .rowPersonal p.taxonomia {
	line-height: 1.5;
	padding-top: 11px;
	padding-bottom: 11px;
	padding-right: 20px;
}

.personalInformation .rowPersonal p.taxonomia span {
	font-style: italic;
	font-weight: 400;
	font-size: 11px;
}

.personalInformation .rowPersonal p:nth-child(1) {
	font-size: 14px;
	padding-left: 20px;
	font-weight: 400;
	color: #999;
}
.margintopTerms {
	margin-top: 25px;
	z-index: 1;
}

.margintopTerms p {
	font-size: 11px;
	padding: 20px;
	margin: 0;
	color: #666;
}

.margintopTerms h4 {
	font-size: 12px;
	font-weight: 500;
	padding: 20px;
	padding-bottom: 0;
	color: rgba(0, 125, 195, 1);
}

.SalarioMoney {
	width: 100%;
	height: 60px;
	overflow: hidden;
	background-color: white;
	border-radius: 7px;
	border: 1px solid #e5e5e5;
	box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	position: relative;
	margin-bottom: 0;
	margin-top: 22px;
}

.SalarioMoney.nomarginSalario {
	margin-top: 0;
}

.SalarioMoney.BlueBackground {
	background-color: rgba(0, 125, 195, 1);
	color: white;
}

.SalarioMoneySmall {
	display: none;
	grid-template-columns: repeat(2, auto);
	width: 100%;
	height: 60px;
	overflow: hidden;
	background-color: white;
	border-radius: 7px;
	border: 1px solid #e5e5e5;
	box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	position: relative;
	margin-bottom: 0;
	margin-top: 22px;
}

.SalarioMoneySmall.nomarginSalario {
	margin-top: 0;
}

.BlueBackgroundSmall {
	background-color: rgba(0, 125, 195, 1);
	color: white;
}

.BlueBackground .concepSalary,
.BlueBackground .MonthlySalary {
	border-left: 1px solid rgba(255, 255, 255, 0.2) !important;
 border-color:  rgb(61, 80, 105);
}

.SalarioMoney.backgroundBlueBlack .concepSalary p {
	line-height: inherit;
	margin: 0;
	padding: 0;
	padding-left: 20px;
	margin-top: 10px;
	margin-top: 10px;
}

.SalarioMoney.backgroundBlueBlack .concepSalary p:nth-child(2) {
	font-size: 11px;
	opacity: 0.5;
	margin-top: 0;
}

.SalarioMoney.backgroundBlueBlack {
	background-color: #0b2343;
}

.SalarioMoney.BlueBackground .concepSalary p {
	color: white;
	font-weight: 500;
}

.SalarioMoney.BlueBackground .MonthlySalary p.smallText {
	color: white;
	opacity: 0.5;
}

.SalarioMoney.BlueBackground .MonthlySalary p {
	color: white;
	line-height: 57px;
}

.SalarioMoney .concepSalary,
.SalarioMoney .MonthlySalary {
	float: left;
	width: calc(100% / 3);
	border-left: 1px solid #e5e5e5;
	height: 60px;
}

.SalarioMoney .concepSalary p {
	font-size: 14px;
	padding-left: 20px;
	font-weight: 400;
	color: #999;
	line-height: 60px;
}

.SalarioMoney .MonthlySalary p:nth-child(2)::before {
	content: "$ ";
}

.SalarioMoneySmall.backgroundBlueBlack .concepSalary p {
	line-height: inherit;
	margin: 0;
	padding: 0;
	padding-left: 20px;
	margin-top: 10px;
	margin-top: 10px;
}

.SalarioMoneySmall.backgroundBlueBlack .concepSalary p:nth-child(2) {
	font-size: 11px;
	opacity: 0.5;
	margin-top: 0;
}

.SalarioMoneySmall.backgroundBlueBlack {
	background-color: #0b2343;
}

.SalarioMoneySmall.BlueBackground .concepSalary p {
	color: white;
	font-weight: 500;
}

.SalarioMoneySmall.BlueBackground .MonthlySalary p.smallText {
	color: white;
	opacity: 0.5;
}

.SalarioMoneySmall.BlueBackground .MonthlySalary p {
	color: white;
	line-height: 57px;
}

.SalarioMoneySmall .concepSalary,
.SalarioMoneySmall .MonthlySalary {
	width: 100%;
	border-left: 1px solid #e5e5e5;
	height: 60px;
}

.SalarioMoneySmall .concepSalary p {
	font-size: 14px;
	padding-left: 20px;
	font-weight: 400;
	color: #999;
	line-height: 60px;
}

.SalarioMoneySmall .MonthlySalary p:nth-child(2)::before {
	content: "$ ";
}

.TableSalaryBonus .MonthlySalary p::before {
	content: "$ ";
}

.SalarioMoney.BlueBackground .MonthlySalary p::before {
	content: "$ ";
}

.SalarioMoney .MonthlySalary p {
	display: block;
	margin: 0;
	padding: 0;
	font-size: 14px;
	font-weight: 500;
	color: #0b2343;
	padding-left: 20px;
}
.SalarioMoney .MonthlySalary p.smallText {
	font-size: 12px;
	font-weight: 500;
	margin-top: 10px;
	color: rgba(0, 125, 195, 1);
}

.SalarioMoneySmall.BlueBackground .MonthlySalary p::before {
	content: "$ ";
}

.SalarioMoneySmall .MonthlySalary p {
	display: block;
	margin: 0;
	padding: 0;
	font-size: 14px;
	font-weight: 500;
	color: #0b2343;
	padding-left: 20px;
}
.SalarioMoneySmall .MonthlySalary p.smallText {
	font-size: 12px;
	font-weight: 500;
	margin-top: 10px;
	color: rgba(0, 125, 195, 1);
}

.TableSalaryBonus {
	width: 100%;
	height: auto;
	background-color: white;
	border-radius: 7px;
	border: 1px solid #e5e5e5;
	box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	position: relative;
}

.moreInformationComp {
	font-size: 12px;
	color: #666;
	margin-top: 27px;
}

.TableSalaryBonus .concepSalary,
.TableSalaryBonus .MonthlySalary {
	width: calc(100% / 3);
	float: left;
	height: 100%;
	border-left: 1px solid #e5e5e5;
}

.TableSalaryBonus .concepSalary p {
	font-size: 14px;
	padding-left: 10px;
	font-weight: 400;
	color: #999;
	line-height: 55px;
	float: left;
}

.TableSalaryBonus .concepSalary .iconSalary {
	float: left;
	width: 30px;
	height: 30px;
	float: left;
	margin-left: 20px;
	margin-top: 11px;
}

.TableSalaryBonus .concepSalary .iconSalary img {
	width: 100%;
	height: 100%;
}

.TableSalaryBonus .MonthlySalary p {
	display: block;
	margin: 0;
	padding: 0;
	font-size: 14px;
	font-weight: 500;
	color: #0b2343;
	padding-left: 20px;
	line-height: 55px;
}

.widthConecpSalary {
	width: 100%;
	height: 55px;
	position: relative;
}

.TableSalaryBonus .widthConecpSalary:nth-child(even) {
	background-color: rgba(0, 125, 195, 0.07);
}

.type-rewards
	.container-guaranteed
	.TableSalaryBonus
	.widthConecpSalary:nth-child(even) {
	background-color: rgba(0, 125, 195, 0.07) !important;
}
.type-rewards
	.container-guaranteed
	.TableSalaryBonus
	.widthConecpSalary:nth-child(odd) {
	background-color: rgba(255, 255, 255, 1) !important;
}

.concepComp01:hover .concepComp01tool {
	display: block;
}
.concepComp02:hover .concepComp02tool {
	display: block;
}
.concepComp03:hover .concepComp03tool {
	display: block;
}
.concepComp04:hover .concepComp04tool {
	display: block;
}
.concepComp05:hover .concepComp05tool {
	display: block;
}
.concepComp06:hover .concepComp06tool {
	display: block;
}
.concepComp07:hover .concepComp07tool {
	display: block;
}
.concepComp010:hover .concepComp010tool {
	display: block;
}

.tooltipCompensations {
	position: absolute;
	right: -100px;
	background: rgba(0, 125, 195, 1);
	border-radius: 7px;
	top: 10px;
	max-width: 300px;
	box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
	display: none;
	z-index: 4;
	max-width: 180px;
}

.tooltipCompensations p {
	margin: 0;
	padding: 4px 10px;
	color: white;
	font-size: 14px;
}

.tooltipCompensations span {
	display: block;
	background: rgba(0, 125, 195, 1);
	width: 15px;
	height: 15px;
	position: absolute;
	top: 17px;
	left: -3px;
	transform: rotate(45deg);
}

.TableSalaryBonus .widthConecpSalary {
	cursor: pointer;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.TableSalaryBonus .widthConecpSalary:hover {
	background-color: rgba(0, 125, 195, 0.07);
}

.TableSalaryBonus .widthConecpSalary:hover p {
	color: rgba(0, 125, 195, 1);
}

.heightBonosOperative {
	height: 60px;
}

.TableSalaryBonus .bonusOperative {
	width: 50%;
	background-color: rgba(0, 125, 195, 0.07);
}

.TableSalaryBonus .bonusOperative:nth-child(1) p {
	padding-left: 25px;
	font-weight: 500;
	color: #0b2343;
	line-height: 60px;
}

.TableSalaryBonus .bonusOperative:nth-child(2) p {
	background-color: #fecb2f;
	padding: 2px 15px;
	line-height: inherit;
	border-radius: 100px;
	width: auto;
	float: right;
	margin-right: 30px;
	margin-top: 17px;
}

.VariableCompsTitle {
	margin-top: 30px;
}

.TableSalaryBonus .concepSalary p.lti {
	font-size: 13px;
}

.TableSalaryBonusSmall {
	display: none;
	grid-template-columns: repeat(2, 1fr);
	width: 100%;
	height: auto;
	background-color: white;
	border-radius: 7px;
	border: 1px solid #e5e5e5;
	box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	position: relative;
}

.TableSalaryBonusSmall .MonthlySalary .amount p::before {
	content: "$ ";
}

.TableSalaryBonusSmall .MonthlySalary {
	width: 100%;
	height: 100%;
	padding-bottom: 10px;
	border-left: 1px solid #e5e5e5;
}

.TableSalaryBonusSmall .MonthlySalary p {
	font-size: 14px;
	padding-left: 10px;
	font-weight: 400;
	color: #999;
	line-height: 55px;
	width: 90%;
	margin-bottom: -1rem;
}

.TableSalaryBonusSmall .MonthlySalary .iconSalary {
	float: left;
	width: 10%;
	height: auto;
	float: left;
	margin-left: 10px;
	margin-top: 10px;
	margin-right: 10px;
}

.TableSalaryBonusSmall .MonthlySalary .iconSalary img {
	width: 100%;
	height: 100%;
}

.TableSalaryBonusSmall .MonthlySalary .amount p {
	display: block;
	margin: 0;
	padding: 0;
	font-size: 14px;
	font-weight: 500;
	color: #0b2343;
	padding-left: 10px;
	line-height: 20px;
}

.TableSalaryBonusSmall .widthConecpSalary:nth-child(even) {
	background-color: rgba(0, 125, 195, 0.07);
}

.TableSalaryBonusSmall .widthConecpSalary {
	cursor: pointer;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.TableSalaryBonusSmall .widthConecpSalary:hover {
	background-color: rgba(0, 125, 195, 0.07);
}

.TableSalaryBonusSmall .widthConecpSalary:hover p {
	color: rgba(0, 125, 195, 1);
}

.TableSalaryBonusSmall .bonusOperative {
	width: 50%;
	background-color: rgba(0, 125, 195, 0.07);
}

.TableSalaryBonusSmall .bonusOperative:nth-child(1) p {
	padding-left: 25px;
	font-weight: 500;
	color: #0b2343;
	line-height: 60px;
}

.TableSalaryBonusSmall .bonusOperative:nth-child(2) p {
	background-color: #fecb2f;
	padding: 2px 15px;
	line-height: inherit;
	border-radius: 100px;
	width: auto;
	float: right;
	margin-right: 30px;
	margin-top: 17px;
}

.TableSalaryBonusSmall .MonthlySalary p.lti {
	font-size: 13px;
}

@media (max-width: 1408px) {
	.TableSalaryBonus .concepSalary {
		width: 195px;
	}
	.TableSalaryBonus .MonthlySalary {
		width: calc(50% - (195px / 2));
	}
}

@media (max-width: 1271px) {
	.container-info-employee {
		width: 340px;
	}
	.type-rewards {
		width: calc(100% - 340px);
	}
	.personalInformation .rowPersonal p:nth-child(1) {
		font-size: 14px;
		padding-left: 20px;
		font-weight: 400;
		color: #999;
		line-height: 1.5;
		padding-top: 14px;
		padding-bottom: 13px;
	}
}

/*

<div className="TableSalaryBonus">
          <div className="concepSalary">
            <div className="iconSalary"></div>
            <p>Aguinaldo</p>
          </div>

          <div className="MonthlySalary">
            <p>24,000 MXN</p>
          </div>

          <div className="MonthlySalary">
            <p>288,000 MXN</p>
          </div>
        </div>


/*

<div className="SalarioMoney">
          <div className="concepSalary">
            <p>Salario Base</p>

          </div>
          <div className="MonthlySalary">
            <p className="smallText">Mensaul</p>
            <p>24,000 MXN</p>
          </div>

          <div className="MonthlySalary">
            <p className="smallText">Anual</p>
            <p>288,000 MXN</p>
          </div>
        </div>


 <div className="personalInformation">
                    <div className="rowPersonal">
                        <p className="subjectPersonal">Número de empleado</p>
                        <p className="resultPersonal">293116</p>
                    </div>
                    */

/* ***** Responsive Styles ***** */

@media screen and (max-width: 1300px) {
}

@media screen and (max-width: 1044px) {
	.wrapper-cont-discounts .footerProfile__newsContainer {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media screen and (max-width: 800px) {
	.wrapper-cont-discounts .footerProfile__newsContainer {
		grid-template-columns: repeat(2, 1fr);
	}

	.TableSalaryBonus .concepSalary p.lti {
		font-size: 10px;
	}
}

@media screen and (max-width: 600px) {
	.TableSalaryBonusSmall,
	.SalarioMoneySmall {
		display: grid;
	}
	.TableSalaryBonus,
	.SalarioMoney {
		display: none;
	}

	.wrapper-cont-discounts .footerProfile__newsContainer {
		grid-template-columns: repeat(1, 1fr);
	}
}

@media screen and (max-width: 424px) {
	.TableSalaryBonusSmall,
	.SalarioMoneySmall {
		grid-template-columns: repeat(1, auto);
		height: auto;
	}

	.TableSalaryBonusSmall .MonthlySalary p {
		line-height: 65px;
	}
}

/* ***** Responsive Styles ***** */
