.VentaEspecial {
	height: auto;
	overflow: hidden;
	/* border: 1px solid #f0f; */
	margin-bottom: 30px;
}
.EventaEspecialCambios {
	background-image: url(../../../assets/ventaespecial/desdeimages.png);
	height: 150px;
	width: 100%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	display: none;
}
.boletosEmbargo a.viewPricesTarifas {
	color: #035cf7;
	font-size: 12px;
	font-weight: 500;
	border-bottom: none;
	background-color: rgba(3, 92, 247, 0.09);
	border-radius: 100px;
	display: table;
	padding: 5px 10px;
	margin-top: 7px;
	width: initial;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.boletosEmbargo a.viewPricesTarifas:hover {
	color: #035cf7;
}
.ventaEspecialModalesCambios:hover a.viewPricesTarifas {
	/* background-color: #020c41; */
	background: rgba(3, 92, 247, 0.75);
	color: white;
}
.amarilloBannerTravel {
	background-color: #ffcc2f;
	padding: 20px;
	border-radius: 7px;
}
.amarilloBannerTravel p {
	font-size: 12px !important;
	color: #020c41;
}
.modalventaEspecialCookie {
	background-image: url(../../../assets/ventaespecial/backimage.jpg);
	max-height: 356px;
	padding-top: 52px;
}
.modalventaEspecialCookie .subtitle {
	max-width: 500px;
	padding: 0;
}
.acceptVentaEspecialButton {
	border-radius: 50px;
	padding: 17px 30px 17px;
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 500;
	background-color: #e91b2f;
	border: 1px solid #e91b2f;
	color: white;
	cursor: pointer;
	-webkit-appearance: none;
	-webkit-transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	opacity: 1;
	position: absolute;
	left: 50%;
	bottom: 50%;
	transform: translate(-50%, 132px);
	display: block;
	margin: 0;
}
.VentaEspecial p.ventaTittle {
	color: #020c41;
	font-size: 24px;
	font-weight: 500;
	margin: 0;
	padding: 0;
	text-align: center;
}
.VentaEspecial p.introVenta {
	text-align: center;
}
.wrapperFlexVentaEspecial {
	display: flex;
	margin-bottom: 70px;
}
.wrapperFlexVentaEspecial .leftVentaEspecial {
	width: 65%;
	margin-right: 40px;
	margin-top: 30px;
}
.wrapperFlexVentaEspecial .rightVentaEspecial {
	width: 35%;
	border: 1px solid rgba(0, 125, 195, 1);
	background-color: rgba(8, 125, 194, 0.07);
	border-radius: 7px;
	padding: 0 20px 20px;
}
.wrapperFlexVentaEspecial .rightVentaEspecial .stickyElementVenta {
	/* position: sticky;
    top: 170px; */
	box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
	background-color: white;
	border-radius: 8px;
	height: auto;
	overflow: hidden;
	border: 2px solid #020c41;
	margin-top: 20px;
}
.wrapperFlexVentaEspecial .rightVentaEspecial .stickyElementVenta.ventaespecialLinc {
	border: 1px solid #e5e5e5;
	margin-top: 20px;
	padding-bottom: 20px;
	/* top: 170px;
    position: sticky; */
	/* margin-bottom: 20px; */
}
.wrapperFlexVentaEspecial .leftVentaEspecial .gridColumnsEspecial {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	height: auto;
	grid-gap: 70px 45px;
}
.wrapperFlexVentaEspecial .leftVentaEspecial .gridColumnsEspecial .optionGrid {
	height: auto;
	overflow: hidden;
}
.wrapperFlexVentaEspecial .leftVentaEspecial .gridColumnsEspecial .optionGrid p.titleVenta {
	font-size: 18px;
	font-weight: 400;
	text-align: center;
	color: #020c41;
	line-height: 1.2;
	margin: 0;
	padding: 0;
	margin-bottom: 5px;
}
.wrapperFlexVentaEspecial .leftVentaEspecial .gridColumnsEspecial .optionGrid p.subTitleVenta {
	font-size: 12px;
	color: #666;
	text-align: center;
	line-height: 1.5;
	margin: 0;
	padding: 0;
}
.wrapperFlexVentaEspecial .leftVentaEspecial .gridColumnsEspecial .optionGrid .imgVenta {
	width: 50px;
	height: 50px;
	margin: 0 auto;
	margin-bottom: 10px;
}
.wrapperFlexVentaEspecial .leftVentaEspecial .gridColumnsEspecial .optionGrid:nth-child(1) .imgVenta {
	/* background-image: url(../../../assets/ventaespecial/iconventa-01.svg); */
	/* CALENDARIO */
}
.wrapperFlexVentaEspecial .leftVentaEspecial .gridColumnsEspecial .optionGrid:nth-child(2) .imgVenta {
	/* background-image: url(../../../assets/ventaespecial/iconventa-02.svg); */
	/* BOLETOS */
}
.wrapperFlexVentaEspecial .leftVentaEspecial .gridColumnsEspecial .optionGrid:nth-child(3) .imgVenta {
	/* background-image: url(../../../assets/ventaespecial/iconventa-03.svg); */
	/* FAMILIA */
}
.wrapperFlexVentaEspecial .leftVentaEspecial .gridColumnsEspecial .optionGrid:nth-child(4) .imgVenta {
	/* background-image: url(../../../assets/ventaespecial/iconventa-04.svg); */
	/* EMPRESAS */
}
.wrapperFlexVentaEspecial .leftVentaEspecial .gridColumnsEspecial .optionGrid.empresa .imgVenta {
	/* background-image: url(../../../assets/ventaespecial/iconventa-04.svg); */
	/* EMPRESAS */
}
.wrapperFlexVentaEspecial .leftVentaEspecial .gridColumnsEspecial .optionGrid:nth-child(5) .imgVenta {
	/* background-image: url(../../../assets/ventaespecial/iconventa-05.svg); */
	/* TRANSFERIBLE */
}
.wrapperFlexVentaEspecial .leftVentaEspecial .gridColumnsEspecial .optionGrid:nth-child(6) .imgVenta {
	/* background-image: url(../../../assets/ventaespecial/iconventa-06.svg); */
	/* RUTAS */
}
.wrapperFlexVentaEspecial .leftVentaEspecial .gridColumnsEspecial .optionGrid.ruta .imgVenta {
	/* background-image: url(../../../assets/ventaespecial/iconventa-06.svg); */
	/* RUTAS */
}
/* .wrapperFlexVentaEspecial
	.leftVentaEspecial
	.gridColumnsEspecial
	.optionGrid:nth-child(7)
	.imgVenta {
	background-image: url(../../../assets/ventaespecial/Conexiones.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
} */
.wrapperFlexVentaEspecial .leftVentaEspecial .gridColumnsEspecial .optionGrid:nth-child(7) .imgVenta {
	/* background-image: url(../../../assets/ventaespecial/vuelo-redondo.png); */
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	/* VUELO REDONDO */
}
.wrapperFlexVentaEspecial .leftVentaEspecial .gridColumnsEspecial .optionGrid.redondo .imgVenta {
	/* background-image: url(../../../assets/ventaespecial/vuelo-redondo.png); */
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	/* VUELO REDONDO */
}
.wrapperFlexVentaEspecial .leftVentaEspecial .gridColumnsEspecial .optionGrid:nth-child(8) .imgVenta {
	/* background-image: url(../../../assets/ventaespecial/clase-p.png); */
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	/* DISPONIBILIDAD CLASE G */
}
.wrapperFlexVentaEspecial .leftVentaEspecial .gridColumnsEspecial .optionGrid.disponibilidad .imgVenta {
	/* background-image: url(../../../assets/ventaespecial/clase-p.png); */
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	/* DISPONIBILIDAD CLASE G */
}
.wrapperFlexVentaEspecial .leftVentaEspecial .gridColumnsEspecial .optionGrid:nth-child(9) .imgVenta {
	/* background-image: url(../../../assets/ventaespecial/equipaje.png); */
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	/* EQUIPAJE */
}
.wrapperFlexVentaEspecial .leftVentaEspecial .gridColumnsEspecial .optionGrid.equipaje .imgVenta {
	/* background-image: url(../../../assets/ventaespecial/equipaje.png); */
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	/* EQUIPAJE */
}
.wrapperFlexVentaEspecial .leftVentaEspecial .gridColumnsEspecial .optionGrid:nth-child(10) .imgVenta {
	/* background-image: url(../../../assets/ventaespecial/fecha.png); */
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	/* APLICAN EMBARGO */
}
.wrapperFlexVentaEspecial .leftVentaEspecial .gridColumnsEspecial .optionGrid.embargo .imgVenta {
	/* background-image: url(../../../assets/ventaespecial/fecha.png); */
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	/* APLICAN EMBARGO */
}
/* .wrapperFlexVentaEspecial
	.leftVentaEspecial
	.gridColumnsEspecial
	.optionGrid:nth-child(11)
	.imgVenta {
	background-image: url(../../../assets/ventaespecial/iconventa-01.svg);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
} */
.wrapperFlexVentaEspecial .leftVentaEspecial .gridColumnsEspecial .optionGrid:nth-child(11) .imgVenta {
	background-image: url(../../../assets/ventaespecial/no_reembolsable.svg);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	/* NO REEMBOLSABLE */
}
.wrapperFlexVentaEspecial .leftVentaEspecial .gridColumnsEspecial .optionGrid.reembolso .imgVenta {
	background-image: url(../../../assets/ventaespecial/no_reembolsable.svg);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	/* NO REEMBOLSABLE */
}
.wrapperFlexVentaEspecial .leftVentaEspecial .gridColumnsEspecial .optionGrid.permite-cambio .imgVenta {
	background-image: url(../../../assets/ventaespecial/permite-cambios.svg);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	/* CON CAMBIO */
}
.wrapperFlexVentaEspecial .leftVentaEspecial .gridColumnsEspecial .optionGrid.no-infantes .imgVenta {
	background-image: url(../../../assets/ventaespecial/no-infantes.svg);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	/* NO INFANTES */
}
.wrapperFlexVentaEspecial .rightVentaEspecial .stickyElementVenta .tittleHeaderAccede {
	height: auto;
	background-color: #0000e3;
	padding: 20px;
}
.wrapperFlexVentaEspecial .rightVentaEspecial .stickyElementVenta p.titleAccede {
	font-size: 16px;
	text-transform: uppercase;
	margin: 0;
	padding: 0;
	padding-left: 0px;
	font-weight: 600;
	letter-spacing: 0.7px;
	max-width: 200px;
	color: white;
}
#noclasesBoletosEmbargo {
	margin-top: 0;
	box-shadow: none;
	border-radius: initial;
}
.wrapperFlexVentaEspecial .leftVentaEspecial .tableVentaEspecial {
	box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
	min-height: 100px;
	background-color: white;
	border-radius: 7px;
	border: 1px solid #e5e5e5;
	margin-top: 30px;
}
.modalVentaEspecial {
	width: 95vw;
	max-width: 800px;
	/* min-height: 1000px; */
	margin: 0 auto;
	padding: 0 !important;
	overflow: hidden;
}
.modalVentaEspecial p {
	float: none;
}
.wrapperDestinosVenta {
	height: auto;
	overflow: hidden;
	display: grid;
	width: 100%;
	grid-template-columns: repeat(2, 1fr);
	background-color: #f2f2f0;
	padding: 0 30px;
}
.destinoOption {
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	width: 100%;
	height: auto;
	overflow: hidden;
	padding: 0 20px;
}
.wrapperDestinosVenta .destinoOption:nth-child(odd) {
	border-right: 1px solid rgba(255, 255, 255, 0.2);
	padding-left: 0;
}
.wrapperDestinosVenta .destinoOption:nth-child(even) {
	padding-right: 0;
}
.destinoOption .iconDestino {
	width: 50px;
	height: 100px;
	float: left;
	overflow: hidden;
}
p.titleVentaDestinosTodo {
	font-size: 42px;
	text-align: center;
	font-weight: 500;
	color: #020c41;
	margin: 0 auto;
	padding: 0;
	width: 100%;
	margin-top: 40px;
	float: none;
}
.destinoOption .iconDestino img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.destinoOption .textDestino {
	height: 100px;
	width: calc(100% - 180px);
	float: left;
	display: table;
}
.destinoOption .textDestino .alignMiddleText {
	vertical-align: middle;
	display: table-cell;
}
.destinoOption .textDestino .alignMiddleText p.destinationName {
	font-weight: 500;
	font-size: 20px;
	margin: 0;
	padding: 0;
	padding-left: 20px;
	color: #493d3a;
	text-align: left;
	line-height: 1.2;
}
.destinoOption .textDestinoInter {
	height: 100px;
	width: calc(100% - 180px);
	float: left;
	display: table;
}
.destinoOption .textDestinoInter .alignMiddleText {
	vertical-align: middle;
	display: table-cell;
}
.destinoOption .textDestinoInter .alignMiddleText p.destinationName {
	font-weight: 500;
	font-size: 20px;
	margin: 0;
	padding: 0;
	padding-left: 20px;
	color: #fff;
	text-align: left;
	line-height: 1.2;
}
p.destinationNameInter{
	font-weight: 500;
	font-size: 20px;
	margin: 0;
	padding: 0;
	padding-left: 20px;
	color: #fff;
	text-align: left;
	line-height: 1.2;
}
.destinoOption .textDestino .alignMiddleText p.departurefrom {
	text-align: left;
	font-size: 12px;
	padding: 0;
	padding-left: 20px;
	color: #493d3a;
	margin: 0;
	opacity: 0.8;
}
.destinoOption .textDestino .alignMiddleText p.departurefromInter {
	text-align: left;
	font-size: 12px;
	padding: 0;
	padding-left: 20px;
	color: #fff;
	margin: 0;
	opacity: 0.8;
}
.destinoOption .textDestino .alignMiddleText p.destinationName span {
	font-weight: 300;
}
.destinoOption .textDestino .alignMiddleText p.destinationNameInter span {
	font-weight: 300;
}
.destinoOption .priceDestino {
	width: 110px;
	float: right;
	height: 100px;
	display: table;
}
.destinoOption .priceDestino .alignMiddleText {
	vertical-align: middle;
	display: table-cell;
}
.destinoOption .priceDestino .alignMiddleText p.priceDestination {
	color: #493d3a;
	font-size: 24px;
	margin: 0;
	padding: 0;
	font-weight: 300;
}
.destinoOption .priceDestino .alignMiddleText p.priceDestinationInter {
	color: #fff;
	font-size: 24px;
	margin: 0;
	padding: 0;
	font-weight: 300;
}
.destinoOption .priceDestino .alignMiddleText p.priceDestination.DestinoNacional::after {
	content: "MXN";
	font-size: 16px;
}
.destinoOption .priceDestino .alignMiddleText p.priceDestination.DestinoInternacional::after {
	content: "USD";
	font-size: 16px;
}
.destinoOption .priceDestino .alignMiddleText p.priceDestination::before {
	content: "$";
	font-size: 16px;
}
.destinoOption .priceDestino .alignMiddleText p.priceDestinationInter.DestinoNacional::after {
	content: "MXN";
	font-size: 16px;
}
.destinoOption .priceDestino .alignMiddleText p.priceDestinationInter.DestinoInternacional::after {
	content: "USD";
	font-size: 16px;
}
.destinoOption .priceDestino .alignMiddleText p.priceDestinationInter::before {
	content: "$";
	font-size: 16px;
}
.titleVentaDiv {
	background-color: #f2f2f0;
	width: 100%;
	height: auto;
	overflow: hidden;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	padding-top: 20px;
	padding-bottom: 20px;
	margin-top: 40px;
}
.titleVentaDivInter {
	background-color: #f2f2f0;
	width: 100%;
	height: auto;
	overflow: hidden;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	padding-top: 20px;
	padding-bottom: 20px;
	margin-top: 40px;
}
.titleVentaDiv p {
	color: #493d3a;
	font-size: 24px;
	font-weight: 500;
	margin: 0;
	padding: 0;
	padding-left: 20px;
	float: none;
	text-align: left;
}
.titleVentaDivInter p {
	color: #fff;
	font-size: 24px;
	font-weight: 500;
	margin: 0;
	padding: 0;
	padding-left: 20px;
	float: none;
	text-align: left;
}
.boletosEmbargo p.legalVentasDestinos {
	color: #666;
	font-weight: 300;
	text-align: center;
	margin: 0;
	padding: 0;
	width: 100%;
	font-size: 16px;
	float: none;
	max-width: 600px;
	margin: 0 auto;
}
.boletosEmbargo p.legalVentasDestinos span {
	font-weight: 500;
}

.rich-text.header-text p {
	color: #666;
	font-weight: 300;
	text-align: center;
	margin: 0;
	padding: 0;
	width: 100%;
	font-size: 16px;
	float: none;
	max-width: 600px;
	margin: 0 auto;
}

.legalesTextoGreen {
	width: 100%;
	background-color: #1c869e;
	height: auto;
	overflow: hidden;
	border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.legalesTextoGreen p {
	color: white;
	font-size: 12px;
	padding: 30px 0;
	margin: 0;
	font-weight: 400;
	padding-left: 20px;
	float: left;
}
.wrapperDestinosVenta .destinoOption:last-child
/* .wrapperDestinosVenta .destinoOption:nth-last-child(2) */ {
	border-bottom: none;
}
.titleVenta p.fromMexicoCity {
	float: none;
	text-align: center;
	margin-top: 60px;
	margin-top: 30px;
	padding: 0;
	font-size: 24px;
}

.condicionesVentaEspecialUso {
	padding: 0 20px;
	height: auto;
	overflow: hidden;
	padding-bottom: 30px;
	background-color: rgba(254, 203, 47, 1);
}
.ventaEspecialContent {
	padding: 20px 0;
}
.ventaEspecialContent a {
	border-bottom: none;
	color: white;
	font-size: 12px;
	font-weight: 600;
	display: table;
	background-color: #087dc2;
	border-radius: 100px;
	width: inherit;
	padding: 5px 10px;
	margin-top: 15px;
}
.condicionesVentaEspecialUso p {
	color: #020c41;
	text-align: left;
	font-size: 12px;
	font-weight: 400;
	margin: 0;
	padding: 0;
}
.condicionesVentaEspecialUso p.temsConditions {
	font-size: 18px;
	font-weight: 500;
	margin-top: 40px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.3);
	padding-bottom: 20px;
	color: #020c41;
}
.condicionesVentaEspecialUso h3,
.condicionesVentaEspecialUso h2,
.condicionesVentaEspecialUso h1 {
	text-align: left;
	color: #020c41;
	font-size: 16px;
	font-weight: 600;
	margin-top: 20px;
}
.condicionesVentaEspecialUso ul,
.condicionesVentaEspecialUso ol {
	padding-left: 0;
	margin-left: 20px;
}
.condicionesVentaEspecialUso ul li,
.condicionesVentaEspecialUso ol li {
	text-align: left;
	font-size: 14px;
	color: #666;
}

.internacionalBackground {
	background-color: #020c41;
}
p.linealincTitulo {
	/* background-color: rgba(8, 125, 194, .05); */
	padding: 20px;
	border-bottom: 1px solid #e5e5e5;
}
p.linealincTitulo span {
	color: rgba(8, 125, 194, 1);
	font-weight: 600;
}
p.linealincTitulo a {
	color: rgba(8, 125, 194, 1);
	display: inline !important;
	font-weight: 600;
}
p.schedule {
	margin: 0;
	padding: 0;
	font-weight: 400;
	padding: 0 20px;
}
p.schedule span {
	font-weight: 500;
}
.lineaLincImg {
	width: 130px;
	height: 55px;
	/* border: 1px solid #f0f; */
	margin-left: 20px;
	background-image: url(../../../assets/ventaespecial/linc_logo.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	margin-bottom: 15px;
	display: none;
}
.ventaespecialLinc p {
	float: none;
}
.profile-tabs-content a.periodosEmabargoLinkTravel {
	color: #087dc2;
	font-size: 14px;
	font-weight: 500;
	display: block;
	background-color: rgba(8, 125, 194, 0.06);
	padding: 6px 0;
	margin-top: 20px;
	text-align: center;
	cursor: pointer;
	border-radius: 50px;
}
.profile-tabs-content a.periodosEmabargoLinkTravel:hover {
	color: #087dc2;
}
.wrapperFlexVentaEspecial .leftVentaEspecial .gridColumnsEspecial .optionGrid .LearnmoreFamily {
	color: white;
	text-align: center;
	display: block;
	font-size: 14px;
	font-weight: 500;
	padding: 0;
	background-color: #fa0073;
	display: table;
	margin: 0 auto;
	padding: 2px 10px;
	margin-top: 10px;
	border-radius: 20px;
	cursor: pointer;
	transform: scale(1);
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.wrapperFlexVentaEspecial .leftVentaEspecial .gridColumnsEspecial .optionGrid .LearnmoreFamily:hover {
	background-color: #fa0073;
	color: white !important;
	transform: scale(1.1);
}
.modalRegistraFamiliaAhora {
	padding: 40px;
}
.modalRegistraFamiliaAhora p {
	text-align: left;
	color: #020c41;
	font-size: 16px;
}
.modalRegistraFamiliaAhora p.RegisterFamilyTitle {
	font-size: 32px;
	font-weight: 500;
	line-height: 1.2;
}
.ventaespecialDestinosModales {
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.wrapperFlexVentaEspecial .rightVentaEspecial .stickyElementVenta.ventaespecialDestinosModales:hover {
	box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
}
.ventaEspecialModalesCambios {
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	/* background-color: rgba(28,134,158,.07); */
}
.ventaEspecialModalesCambios:hover {
	background-color: rgba(28, 134, 158, 0.2);
}
.ventaEspecialModalesCambios:hover p {
	color: #1c869e;
}
.BannerEspecialDestinos {
	background-color: #0000e3;
	border-radius: 7px;
	padding: 20px 0;
	margin-bottom: 30px;
	background-image: url(../../../assets/ventaespecial/ventabg.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}
.BannerEspecialDestinos p {
	color: white;
	text-align: center;
	padding: 0;
	margin: 0;
	font-size: 24px;
	font-weight: 500;
	line-height: 1.2;
}
.BannerEspecialDestinos2 {
	background-color: transparent;
	border-radius: 7px;
	padding: 20px 0;
	margin-bottom: 30px;
}
.BannerEspecialDestinos2 p {
	color: #0b2342 !important;
	text-align: center;
	padding: 0;
	margin: 0;
	font-size: 24px;
	font-weight: bold;
	line-height: 1.2;
}

@media (max-width: 1230px) {
	.wrapperFlexVentaEspecial .leftVentaEspecial .gridColumnsEspecial {
		grid-gap: 70px 35px;
	}
}
@media (max-width: 1044px) {
	.wrapperDestinosVenta {
		grid-template-columns: repeat(1, 1fr);
	}
	.wrapperDestinosVenta .destinoOption:nth-child(odd) {
		border-right: 0px;
	}
	.destinoOption {
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
		padding: 0px;
	}
}

@media (max-width: 800px) {
	.modalventaEspecialCookie {
		padding-top: 35px;
		max-height: 290px;
	}
	.EventaEspecialCambios {
		display: none;
	}
	.acceptVentaEspecialButton {
		padding: 7px 12px;
		font-size: 12px;
		font-weight: 500;
		left: 50%;
		bottom: initial;
		transform: initial;
		bottom: 30%;
		transform: translate(-50%, -0);
	}
	.BannerEspecialDestinos p {
		font-size: 24px;
	}
	.wrapperFlexVentaEspecial {
		display: initial;
	}
	.wrapperFlexVentaEspecial .leftVentaEspecial {
		margin: 0;
		width: 100%;
	}
	.wrapperFlexVentaEspecial .leftVentaEspecial .gridColumnsEspecial {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 40px 25px;
	}
	.wrapperFlexVentaEspecial .rightVentaEspecial {
		width: 100%;
		margin-top: 50px;
	}
	.boletosEmbargo img.icon.marginTopIconNew {
		margin-top: 12px;
	}
	.boletosEmbargo p.legalVentasDestinos {
		font-size: 14px;
		width: 85%;
		margin: 0 auto;
	}
	p.titleVentaDestinosTodo {
		font-size: 24px;
		line-height: 1.2;
	}
	.titleVentaDiv p {
		font-size: 18px;
	}

	.destinoOption .textDestino .alignMiddleText p.destinationName {
		font-size: 16px;
	}
	.destinoOption .priceDestino .alignMiddleText p.priceDestination {
		font-size: 18px;
	}
	.destinoOption .priceDestino .alignMiddleText p.priceDestination::before {
		font-size: 12px;
	}
	.destinoOption .priceDestino .alignMiddleText p.priceDestination.DestinoNacional::after {
		font-size: 12px;
	}
	.destinoOption .priceDestino .alignMiddleText p.priceDestination.DestinoInternacional::after {
		font-size: 12px;
	}
	.destinoOption .textDestino .alignMiddleText p.departurefrom {
		font-size: 10px;
		line-height: 1.2;
	}
	.destinoOption .textDestino .alignMiddleText p.departurefromInter {
		font-size: 10px;
		line-height: 1.2;
	}
	.destinoOption .textDestinoInter .alignMiddleText p.destinationName {
		font-size: 16px;
	}
	.destinoOption .priceDestinoInter .alignMiddleText p.priceDestination {
		font-size: 18px;
	}
	.destinoOption .priceDestinoInter .alignMiddleText p.priceDestination::before {
		font-size: 12px;
	}
	.destinoOption .priceDestinoInter .alignMiddleText p.priceDestination.DestinoNacional::after {
		font-size: 12px;
	}
	.destinoOption .priceDestinoInter .alignMiddleText p.priceDestination.DestinoInternacional::after {
		font-size: 12px;
	}
	.destinoOption .textDestinoInter .alignMiddleText p.departurefrom {
		font-size: 10px;
		line-height: 1.2;
	}
	.destinoOption .textDestinoInter .alignMiddleText p.departurefromInter {
		font-size: 10px;
		line-height: 1.2;
	}
	.modalTextTriques p.title {
		font-size: 22px;
	}
	.modalventaEspecialCookie .subtitle {
		font-size: 14px;
	}
}
@media (max-width: 700px) {
	.destinoOption .iconDestino {
		width: 40px;
		height: 40px;
		float: none;
	}
	.destinoOption .textDestino .alignMiddleText p.destinationName {
		padding-left: 0;
	}
	.destinoOption .textDestino {
		height: auto;
		width: 55%;
	}
	.destinoOption .textDestinoInter .alignMiddleText p.destinationName {
		padding-left: 0;
	}
	.destinoOption .textDestinoInter {
		height: auto;
		width: 55%;
	}
	.destinoOption .priceDestino {
		height: auto;
		width: 45%;
	}
	.destinoOption .textDestino .alignMiddleText p.departurefrom {
		padding-left: 0;
	}
	.destinoOption .textDestinoInter .alignMiddleText p.departurefrom {
		padding-left: 0;
	}
	.destinoOption .textDestino .alignMiddleText p.departurefromInter {
		padding-left: 0;
	}
	.destinoOption .textDestinoInter .alignMiddleText p.departurefromInter {
		padding-left: 0;
	}
	.wrapperDestinosVenta .destinoOption {
		padding-top: 20px;
		padding-bottom: 20px;
	}
}

@media (max-width: 600px) {
	.modalWhiteWelcome {
		width: 85%;
		height: 50vh;
	}

	.modalventaEspecialCookie {
		padding-top: 25px;
	}

	.modalventaEspecialCookie .subtitle {
		font-size: 13px;
	}

	.rich-text.header-text p {
		font-size: 14px;
	}
}

@media (max-width: 514px) {
	.boletosEmbargo img.icon.marginTopIconNew {
		margin-top: 22px;
	}
}

@media (max-width: 400px) {
	.modalWhiteWelcome {
		width: 90%;
		height: 60vh;
	}

	.modalventaEspecialCookie {
		padding-top: 20px;
	}

	.modalTextTriques p.title {
		font-size: 20px;
	}

	.modalventaEspecialCookie .subtitle {
		font-size: 13px;
	}
}
/*

<div className="legalesTextoGreen">
                            <p>*Las tarifas varían de acuerdo a los impuestos ya incluidos.</p>
                        </div>
<div className="titleVenta">
                        <p className="fromMexicoCity">Saliendo desde MEX y hacia MEX</p>
                    </div>

<div className="destinoOption" key={destinosVentaEspecial.sys.id}>
                            <div className="iconDestino">
                                <img src={destinosVentaEspecial.fields.iconDestination.fields.file.url}></img>
                            </div>
                            <div className="textDestino">
                                <div className="alignMiddleText">
                                    <p className="destinationName">{destinosVentaEspecial.fields.destinationName} <span>{destinosVentaEspecial.fields.iataDestination}</span></p>
                                </div>
                            </div>
                            <div className="priceDestino">
                                <div className="alignMiddleText">
                                    <p className="priceDestination">{destinosVentaEspecial.fields.priceForTravel}</p>
                                </div>
                            </div>
                        </div>
<div className="VentaEspecial">
          <p className="ventaTittle">Venta Especial</p>
        </div>

        <div className="wrapperFlexVentaEspecial">
          <div className="leftVentaEspecial">
            <div className="gridColumnsEspecial">

              <div className="optionGrid">
                <div className="imgVenta"></div>
                <p className="titleVenta">Ventas Bimestrales </p>
                <p className="subTitleVenta">Las ventas será bimestralmente y te daremos a conocer los términos y condiciones de los destinos y tarifas disponibles para compra.</p>
              </div>
              */
