.swiperBenefitWrapper {
	width: 90%;
	height: auto;
	max-width: 1400px;
	margin: 0 auto;
	margin-top: 40px;
}
.featuredSwipperHolder {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 20px;
}
.smallTextDiscount {
	font-size: 12px;
	font-weight: 500;
	margin-bottom: 5px;
}
.swiperBenefitWrapper .swiper-container {
	overflow: visible;
}

.EnjoyWorkColorr {
	background: #1c869e !important;
	padding-bottom: 10px;
}

.Comiteejecutivo {
	background: #004990 !important;
	padding-bottom: 10px;
}

.beneficiosfloatright .logoTabBenefit {
	float: right;
}

.totalBenefits__container {
	box-sizing: border-box;
	margin: 0;

	margin-top: 0px;
	margin-bottom: 0px;
	border: 0;
	background-color: rgba(0, 125, 195, 0.06);
	border: 1px solid #e5e5e5;
	border-radius: 11px;
	margin-top: 50px;
	margin-bottom: 10px;
	padding: 25px;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.totalBenefits__container:hover {
	border: 1px solid rgba(0, 125, 195, 1);
	background-color: rgba(0, 125, 195, 0.08);
}

.totalBenefits__container .totalBenefits__title__container {
	margin-top: 0;
}

.totalBenefits__title__container {
	width: 100%;
	margin-top: 50px;
	margin-bottom: 20px;
	height: auto;
	//overflow: hidden;
}

.totalBenefits__title__container h1 {
	font-size: 24px;
	font-weight: 500;
	margin: 0;
	padding: 0;
	float: left;
	margin-top: 6px;
	margin-left: 10px;
	color: #020c41;
}

.totalBenefits__title__container .header_small {
	display: none;
}

.totalBenefits__title__container .iconTitleProfile {
	float: left;
	width: 40px;
	height: 40px;
}

.totalBenefits__title__container .iconTitleProfile img {
	width: 100%;
}

.totalBenefits__title__container .iconTitleProfile_small {
	float: left;
	width: 40px;
	height: 40px;
}

.totalBenefits__title__container .iconTitleProfile_small img {
	width: 100%;
}

.totalBenefits__title__container .logoTabBenefit img {
	width: 230px;
}

.totalBenefits__title__container .logoTabBenefit img.Travel {
	width: 260px;
}

.totalBenefits__title__container a {
	float: right;
	color: rgba(0, 125, 195, 1) !important;
	font-size: 14px;
	font-weight: 500;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	cursor: pointer;
}

.totalBenefits__title__container a:hover {
	background-color: rgba(0, 125, 195, 1);
	/* color: white !important; */
	padding: 2px 10px;
	border-radius: 100px;
	text-decoration: none;
	opacity: 1;
}

.totalBenefits__cards__container {
	margin: auto;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 30px;
	justify-items: center;
	height: auto;
}

.totalBenefis__card {
	width: 100%;
	height: auto;
	border-radius: 11px;
	flex-direction: column;
}

.totalBenefis__card a {
	display: block;
	width: 100%;
	height: auto;
	text-decoration: none;
	cursor: pointer;
}

.totalBenefits__card__image {
	height: 140px;
	background-size: cover;
	border-radius: 10px;
	margin-bottom: 20px;
	overflow: hidden;
	box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.totalBenefits__cards__container .totalBenefis__card:nth-child(4) .totalBenefits__card__image {
	background-image: url(../../../assets/images/profile/benefit-category-04.jpg);
}
.totalBenefits__cards__container .totalBenefis__card:nth-child(3) .totalBenefits__card__image {
	background-image: url(../../../assets/images/profile/benefit-category-03.jpg);
}
.totalBenefits__cards__container .totalBenefis__card:nth-child(2) .totalBenefits__card__image {
	background-image: url(../../../assets/images/profile/benefit-category-02.jpg);
}
.totalBenefits__cards__container .totalBenefis__card:nth-child(1) .totalBenefits__card__image {
	background-image: url(../../../assets/images/profile/benefit-category-01.jpg);
}

.totalBenefits_cards_container_disclaimer p {
	color: #020c41;
	margin-bottom: -10px;
	margin-top: 15px;
	text-align: right;
	font-size: 12px;
	font-weight: normal;
}

.totalBenefits__card__title {
	margin: 0;
	margin-left: 10px;
	margin-top: 15px;
	color: #020c41;
	font-weight: 500;
	font-size: 18px;
	line-height: 1.2;
}

.totalBenefits__card__text {
	color: #020c41;
	margin: 0;
	margin-left: 10px;
	margin-top: 10px;
	font-size: 13px;
	line-height: 1.5;
	opacity: 0.8;
	width: 100%;
	line-height: 1.3em;
	margin: 0 0 1em 0;
	color: #666;
	margin: 0;
	margin-top: 10px;
	font-size: 13px;
	line-height: 1.5;
	margin-left: 10px;
	display: none;
}

.totalBenefits__card__more {
	font-size: 12px;
	font-weight: 500;
	margin-left: 10px;
	margin-top: 15px;
	color: rgba(0, 125, 195, 1);
}

.discountPerks__container {
	box-sizing: border-box;
	margin: 0;
	border: 0;
	border-bottom: 1px solid #d6d6d6;
	margin-top: 50px;
}

.discountPerks__title {
	font-size: 24px;
	color: #020c41;
}

.discountPerks__title__container {
	margin: 20px 0;
	display: flex;
	justify-content: space-between;
}
.v_all {
	justify-self: end;
	color: rgba(0, 125, 195, 1);
	font-size: 14px;
	font-weight: 500;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	margin-left: -2%;
}
.v_all:hover {
	background-color: rgba(0, 125, 195, 1);
	color: white;
	padding: 2px 10px;
	border-radius: 100px;
	text-decoration: none;
}
.discountsPerks__list__container {
	margin: 0;
	padding: 0;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	padding-bottom: 40px;
}

.discountsPerks__list__container ul {
	width: 100%;
	color: #020c41;
	height: auto;
	padding: 0;
	margin: 0;
}
.discountsPerks__list__container ul li {
	list-style: none;
	height: 50px;
	cursor: pointer;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	display: table;
	padding: 0 20px;
	border-radius: 100px;
	border: 1px solid #f5f5f5;
	margin-bottom: 10px;
}
.discountsPerks__list__container ul li a {
	display: block;
	width: 100%;
	height: 100%;
	color: #020c41;
	text-decoration: none;
}

.discountsPerks__list__container ul li img {
	width: 25px;
	height: 25px;
	margin-top: 10px;
	margin-right: 7px;
	float: left;
}
.discountsPerks__list__container ul li span {
	height: 100%;
	display: block;
	float: left;
	line-height: 47px;
	font-size: 16px;
	font-weight: 400;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.discountsPerks__list__container ul li:hover {
	background-color: rgba(0, 125, 195, 0.07);
	border: 1px solid #035cf7;
}
.discountsPerks__list__container ul li:hover span {
	color: #035cf7;
}

.totalBenefits__card__more {
	display: inline-block;
}

.discountsPerks__list__container ul {
	list-style: none;
}
span.descountCircle.benefit-card {
	color: #fff;
	font-size: 11px;
	font-weight: 500;
	padding-top: 8px;
}
.moreTravel.link-more {
	position: absolute;
	bottom: 0;
}
.benefits-big-card.benefits .container-content-big-card {
	position: relative;
}
.benefits-big-card.benefits p.moreTravelBenefits__card__text {
	line-height: 1.5;
	padding: 10px 0;
}
.benefits-big-card.benefits {
	margin: 0;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.benefits-big-card.benefits:hover {
	transform: scale(1.01, 1.01);
	box-shadow: 0 25px 40px rgba(0, 0, 0, 0.3);
}
.benefits-big-card.bg-container-card.benefits {
	position: relative;
}
.discount_list {
	width: 20px;
}
.discount_l {
	padding-left: 5px;
}

@media (max-width: 800px) {
	.totalBenefits__card__image {
		height: 15vh;
	}
	.totalBenefits__card__title {
		font-size: 14px;
		line-height: 1.2;
		margin-top: 10px;
		margin-bottom: 15px;
	}
	.beneficiosfloatright .iconTitleProfile {
		display: none;
	}
	.totalBenefits__title__container .header_small {
		display: block;
	}

	.totalBenefits__title__container .header_small h1 {
		display: block;
	}

	.beneficiosfloatright h1 {
		display: none;
	}
	.totalBenefits__title__container .logoTabBenefit img {
		margin: 0 auto;
		float: none;
		display: block;
	}
	.featuredSwipperHolder {
		grid-template-columns: repeat(1, 1fr);
	}
	.totalBenefits__cards__container {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 20px;
	}
	.discountsPerks__list__container {
		grid-template-columns: repeat(2, 1fr);
	}
	.discountsPerks__list__container ul li {
		padding-left: 10px;
		height: 30px;
	}
	.discountsPerks__list__container ul li span {
		line-height: 28px;
	}
	.discountsPerks__list__container ul li img {
		width: 20px;
		height: 20px;
		margin-top: 6px;
	}
	.discountsPerks__list__container ul li span {
		font-size: 14px;
	}
	.beneficiosfloatright .logoTabBenefit {
		float: none;
		margin: 0 auto;
		display: block;
	}
}

@media (max-width: 600px) {
	.totalBenefits__cards__container {
		grid-template-columns: repeat(1, 1fr);
	}
}

@media (max-width: 400px) {
	.totalBenefits__title__container .iconTitleProfile_small {
		width: 25px;
		height: 25px;
	}

	.discountsPerks__list__container {
		grid-template-columns: repeat(1, 1fr);
	}
}
