#root {
	background: #edf6fb;
	max-height: 100vh;
	min-height: 100vh;
}

/* .main-container {
	height: 100vh;
	overflow: auto;
} */

h1 {
	font-size: 18px;
	opacity: 0.75;
}

.form-holder {
	box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.05);
}

.check-icon {
	fill: #012b5c;
	height: 100px;
	width: auto;
}

.thanks-title {
	color: #012b5c;
	font-weight: 700;
}

.thanks-text {
	color: rgba(0, 0, 0, 0.5) !important;
	margin: 2em 0;
}

.my-btn .btn {
	background: #012b5c;
	color: white;
}
