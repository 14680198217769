.background-benefits {
	background: #fff;
	height: 200px;
	width: 100%;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	margin-bottom: 30px;
}
.background-benefits span {
	width: auto;
	border-radius: 4px;
	display: table;
	float: left;
	margin-left: 25px;
	margin-top: 25px;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	padding: 5px 15px;
}
.content-modal {
}
.background-benefits span.enjoyBenefits {
	background-color: #1c869e;
}

.head-modal {
	display: block;
	width: 100%;
	height: auto;
	overflow: hidden;
	padding: 0 40px 0;
	border-bottom: 1px solid #d6d6d6;
	padding-bottom: 30px;
	margin-bottom: 30px;
}
.head-modal div {
	float: left;
}

.head-modal div:nth-child(1) {
	width: 60px;
}

.head-modal div:nth-child(2) {
	width: calc(100% - 100px);
}

.head-modal div img {
	width: 58px;
	height: 58px;
}
.head-modal div p:nth-child(1) {
	font-weight: 500;
	font-size: 24px;
	padding: 0;
	margin: 0;
	margin-left: 22px;
	color: #0b2343;
	line-height: 1.2;
}
.head-modal div p:nth-child(2) {
	font-weight: 400;
	font-size: 14px;
	padding: 0;
	margin: 0;
	margin-left: 22px;
	color: #666;
	margin-top: 10px;
}
.BenefitContent {
	width: 100%;
	height: auto;
	overflow: hidden;
	margin-top: 20px;
	padding: 0 40px 4px;
}
.BenefitContent p,
.BenefitContent li {
	font-weight: 400;
	font-size: 14px;
	color: #666;
	line-height: 1.5;
}

.BenefitContent h3 {
	font-size: 18px;
	font-weight: 600;
	color: #0b2343;
}
.BenefitContent h4 {
	font-size: 16px;
	color: #0b2343;
}
.BenefitContent ul,
.BenefitContent li {
	margin-left: 7px;
}

.BenefitContent a {
	color: rgba(0, 125, 195, 1);
	text-decoration: none;
	font-size: 14px;
	font-weight: 500;
	cursor: pointer;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.BenefitContent a {
	background-color: rgba(0, 125, 195, 1);
	color: white;
	padding: 2px 10px;
	border-radius: 100px;
	text-decoration: none;
	opacity: 1;
}

.BenefitContent p a {
	color: rgba(0, 125, 195, 1);
	text-decoration: none;
	font-size: 14px;
	font-weight: 500;
	cursor: pointer;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	background: transparent;
	padding: 0;
}

.BenefitContent ul a {
	background-color: transparent;
	padding: 0;
	color: #007dc3;
}

.BenefitContent ul a:hover {
	background-color: transparent;
	padding: 0;
	color: #0b2343;
}

.BenefitContent a:hover {
	background-color: #0b2343;
	padding: 2px 10px;
	color: white;
}

.noDisplayLearnMore {
	display: none;
}

.termsConditions {
	width: 100%;
	height: auto;
	padding: 0 40px 40px;
	margin-top: 20px;
	border-top: 1px solid #e5e5e5;
	background-color: rgba(0, 125, 195, 0.05);
	margin-top: 35px;
	align-self: end;
}

.termsConditions p:nth-child(1) {
	color: #007dc3;
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 10px;
	margin-top: 26px;
}

.termsConditions p:nth-child(2) {
	color: #666;
	font-size: 11px;
	font-weight: 400;
	margin-bottom: 0;
}
.CloseWrapper {
	width: 100%;
	height: auto;
	padding: 0 40px 0;
	margin-top: 35px;
	cursor: pointer;
	overflow: hidden;
}
.CloseWrapper .close-button {
	color: rgba(0, 125, 195, 1);
	font-size: 14px;
	margin-bottom: 20px;
	font-weight: 500;
	float: left;
	margin-top: 2px;
}

.CloseWrapper .CloseArrow {
	float: left;
}
.CloseWrapper .CloseArrow svg {
	width: 12px;
	height: 12px;
	margin-right: 10px;
}
.subcontainer-featured-benefits {
	display: flex;
}
@media (max-height: 800px) {
	.head-modal div p:nth-child(1) {
		font-size: 18px;
	}
	.background-benefits span {
		font-size: 12px;
	}
}

.BenefitContent a {
	float: none !important;
}

.BenefitContent a:hover {
	color: #012b5c !important;
}
