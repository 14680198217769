.titleCategoryContainer {
  width: 80%;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  display: none;
}

.textTitleCategoryContainer {
  color: #002b5c;
  font-size: 24px;
}

.filterContainer {
  display: flex;
}

.filterContainer h6 {
  color: #999999;
  font-size: 13px;
  margin: 20px 20px;
}

.filterContainer select {
  background: #ffffff;
  border: 1px solid #999999;
  border-radius: 5px;
  padding: 10px 20px;
  color: #002b5c;
  height: 60px;
}
