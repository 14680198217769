.stateFlightPlane {
	width: 100%;
	height: auto;
	background-color: #000;
	border-radius: 8px;
	/*border: 1px solid #e5e5e5;*/
	box-shadow: 0 0.15em 0.4em 0.065em rgba(0, 0, 0, 0.075);
	margin-top: 30px;
	/* overflow: hidden; */
	position: relative;
	background-image: linear-gradient(to right top, #0b2343, #0a2c55, #083668, #043f7c, #004990);
}

.stateFlightPlane .contDataFlight {
	height: auto;
	overflow: visible;
}

.stateFlightPlane .contDataFlight .DataWrapper {
	/* height: 45px; */
	height: 70px;
	width: 100%;
	/* background-color: rgba(0, 125, 195, 0.1); */
	position: relative;
	border-bottom: 1px solid #fa0073;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.stateFlightPlane .contDataFlight .DataWrapper:hover .tooltipCompensationsNPS {
	display: block;
}

.stateFlightPlane .contDataFlight .DataWrapper:nth-child(odd) {
	background-color: transparent;
}

.stateFlightPlane .contDataFlight .DataWrapper .textWrapper {
	width: 200px;
	/* height: 45px; */
	/* height: 70px; */
	height: auto;
	/* padding: 10px 0 0; */
	display: table;
	/* float: left; */
}

.stateFlightPlane .contDataFlight .DataWrapper .textWrapper .CellWrapper {
	vertical-align: middle;
	display: table-cell;
}

.stateFlightPlane .contDataFlight .DataWrapper .textWrapper p {
	font-size: 13px;
	font-weight: 400;
	margin: 0;
	padding: 0;
	line-height: 1.4;
	color: white;
}

.stateFlightPlane .contDataFlight .DataWrapper .graphWrapper {
	width: 60px;
	/* height: 45px; */
	height: 70px;
	display: flex;
	float: right;
	align-items: center;
	justify-content: center;
}

.stateFlightPlane .contDataFlight .DataWrapper .graphWrapper .graphbgColor {
	width: 20px;
	height: 20px;
	background-color: #fcfcfc;
	border-radius: 100px;
	overflow: hidden;
	position: relative;
}

.stateFlightPlane .contDataFlight .DataWrapper .graphWrapper .graphbgColor .goingGraphColor {
	background-color: #07e8cd;
	width: 100%;
	height: 100%;
}

.stateFlightPlane .contDataFlight .DataWrapper:nth-child(1) .graphWrapper .graphbgColor .goingGraphColor {
	background-color: #07e8cd;
}
.stateFlightPlane .contDataFlight .DataWrapper:nth-child(2) .graphWrapper .graphbgColor .goingGraphColor {
	background-color: #07e8cd;
}
.stateFlightPlane .contDataFlight .DataWrapper:nth-child(3) .graphWrapper .graphbgColor .goingGraphColor {
	background-color: #07e8cd;
}
.stateFlightPlane .contDataFlight .DataWrapper:nth-child(4) .graphWrapper .graphbgColor .goingGraphColor {
	background-color: #07e8cd;
}
.stateFlightPlane .contDataFlight .DataWrapper:nth-child(5) .graphWrapper .graphbgColor .goingGraphColor {
	background-color: #07e8cd;
}

.significados {
	width: 100%;
	display: grid;
	grid-template-columns: auto;
	padding: 0 20px;
	height: auto;
	border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.significados .cont {
	width: 100%;
	display: grid;
	grid-template-columns: auto;
}

.significados .cont .cir {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: white;
	margin-top: 9px;
}

.significados .cont:nth-child(1) .cir {
	background-color: #07e8cd;
}

.significados .cont:nth-child(2) .cir {
	background-color: #fecb2f;
}

.significados .cont:nth-child(3) .cir {
	background-color: #e91b2f;
}

.significados .cont p {
	color: white;
	font-size: 12px;
	opacity: 0.7;
	margin: 0;
	padding: 0;
	line-height: 27px;
	margin-left: 0;
	text-align: center;
}

/*

 <div className="cont">
                    <div className="cir"></div>
                    <p>Bueno</p>
                </div>



<div className="stateFlightPlane">
            <div className="contDataFlight">
                <div className="DataWrapper">
                    <div className="textWrapper">
                        <div className="CellWrapper">
                            <p>Seguridad</p>
                        </div>
                    </div>
                    <div className="graphWrapper">
                        <div className="graphbgColor">
                            <div className="goingGraphColor"></div>
                        </div>
                    </div>
                </div>
                */
