.footerProfile__topContainer {
  display: flex;
}

.footerProfile__titleContainer {
  display: flex;
  margin-top: 30px;
  margin-left: 0;
}

.circle__container {
  border-radius: 50%;
  border: 1px solid #1c869e;
  display: flex;
  height: 32px;
  width: 32px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 10px;
}

.circleNew {
  color: #1c869e;
  font-size: 12px;
}

.footerProfile__text {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.footerProfile__text h4 {
  font-size: 24px;
  color: #0b2343;
}

.footerProfile__text h6 {
  font-size: 12px;
  color: #666666;
}

.footerProfile__middleContainer {
  display: block;
  margin-bottom: 40px;
}

.wrapper-cont-discounts .footerProfile__newsContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 25px;
}

.footerProfile__newsContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}


.footerProfile__newsCard {
  background: #ffffff;
  width: 100%;
  display: flex;
  height: 180px;
  border-radius: 11px;
  position: relative;
  overflow: hidden;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  border: 1px solid #e5e5e5;
  margin-bottom: 10px;
  box-shadow: 0 0.15em 0.4em 0.065em rgba(0, 0, 0, 0.075);
  cursor: pointer;
  position: relative;
}

.footerProfile__newsCard:hover {
  transform: scaleY(1.06) scaleX(1.06);
  box-shadow: 0 30px 40px rgba(0, 0, 0, 0.2);
  /* box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5); */
}
.footerProfile__newsCard__textContainer {
  width: 56%;
  padding: 20px;
  /* flex-direction: column; */
}

.footerProfile__newsCard__title {
  color: #0b2343;
  line-height: 1.5;
  height: min-content;
}

.footerProfile__newsCard__information {
  font-size: 11px;
  color: #666666;
  position: relative;
  height: min-content;
  position: relative;
  padding: 0;
  margin-top: 6px;
  opacity: .8;
  font-weight: 400;
}

.descountCircle {
  border-radius: 50%;
  background: red;
  height: 32px;
  width: 32px;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.featuredSwipperHolder .descountCircle{
  margin-top: 10px;
}
.descountCircle h6{
  line-height: 32px;
  font-size: 11px;
}
.descountCircle h6::before{
  content: "-"
}
.descountCircle h6::after{
  content: "%"
}
.descountCircle.ShowDiscount{
  display: block!important;
}

.footerProfile__newsCard__descount {
  font-size: 12px;
  color: white;
}

.footerProfile__newsCard__more {
  font-size: 12px;
}

.footerProfile__newsCard__image {
  width: 130px;
  height: 167px;
}
.footerProfile__newsCard__img {
  width: 100px;
  height: 127px;
  margin: 20px 10px;
}

/* .footerProfile__newsCard__imageContainer {
    outline: 1px solid blue;
} */
div.footerProfile__newsCard {
  width: 100%;
}

.footerProfile__newsCard__title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  display: block;
  line-height: 1.2;
}


.footerProfile__newsCard a.link-benefitss {
  margin: 0;
  display: block;
  width: 100%;
}

.footerProfile__newsCard a.link-benefitss h6.footerProfile__newsCard__more {
  display: inline-block;
  color:#007dc3;
}

.footerProfile__newsCard.backImageDiscountsProfile .footerProfile__newsCard__imageContainer,
.footerProfile__newsCard.backImageDiscountsProfile .footerProfile__newsCard__imageContainer{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 11px;
  background-size: cover;
}
.footerProfile__newsCard.backImageDiscountsProfile .footerProfile__newsCard__textContainer{
  width:60%;
}

.footerProfile__newsCard__textContainer{
  position: relative;
  z-index: 1;
}

.footerProfile__newsCard__textContainer.WhiteTextDiscount,
.footerProfile__newsCard__textContainer.WhiteTextDiscount .footerProfile__newsCard__title,
.footerProfile__newsCard__textContainer.WhiteTextDiscount h6.footerProfile__newsCard__information,
.footerProfile__newsCard__textContainer.WhiteTextDiscount a.link-benefitss{
  color:white;
}
.footerProfile__newsCard__textContainer.WhiteTextDiscount h6.footerProfile__newsCard__information{
  opacity: 1;
}


.footerProfile__newsCard__information{
  color: #0b2343;
  font-size: 12px;
  line-height: 1.4;
}
@media (max-width: 800px) {
.footerProfile__newsContainer {
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
}
}