.wrapperPageGamTalks{
    width: 100%;
    margin: 0 auto;
    height: auto;
    display: flex;
    position: relative;
    background: #002b5c;
    background: white;
    /* border-bottom: 1px solid rgba(255,255,255,.1); */
}
.wrapperPageGamTalks .leftGamTalks{
    float: left;
    width: 400px;
    z-index: 2;
}

.wrapperPageGamTalks .leftGamTalks .middleGam{
    position: sticky;
    top: 60px;
    height: calc(100vh - 60px);
    color: white;
    display: table;
    padding: 0 70px;
    background-repeat: no-repeat;
    /* background-image: url(../../../assets/gam-talks/planes.png); */
    background-position: center;
    background-size: cover;
    width: 100%;
    background-color: #e91b2f;
    /* background: linear-gradient(152deg, #002b5c, #081c33); */
    /* background-image: url(../../../assets/gam-talks/planes.png); */
    border-right: 1px solid rgba(255,255,255,.1);
    box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.2);
}
.wrapperPageGamTalks .leftGamTalks .middleGam .middleGamCell{
    display: table-cell;
    vertical-align: middle;

}
.wrapperPageGamTalks .leftGamTalks .middleGam .middleGamCell .logoGam{
    background-color: #e91b2f;
    width: 150px;
    height: 150px;
    border-radius: 5px;
    overflow: hidden;
    background-image: url(../../../assets/gam-talks/gamlogo.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 2px solid white;
}
.wrapperPageGamTalks .leftGamTalks .middleGam .middleGamCell .title{
    font-size: 44px;
    color: white;
    font-weight: 600;
    margin: 0;
    line-height: 1.2;
    padding: 0;
    margin-top: 20px;
    margin-bottom: 20px;
}
.wrapperPageGamTalks .leftGamTalks .middleGam .middleGamCell .subtitle{
    font-size: 20px;
    font-weight: 300;
    line-height: 1.4;
    margin: 0;
    padding: 0;
}
.rightGamTalks{
    width: calc(100% - 400px);
    float: left;
    height: auto;
    overflow: hidden;
    background-image: url(../../../assets/gam-talks/planes-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #002b5c;
    border-bottom: 1px solid rgba(255,255,255,.1);
}

.rightGamTalks .gamRightMiddle{
    width: 80%;
    margin: 0 auto;
    height: auto;
    /* overflow: hidden; */
}
.rightGamTalks .gamRightMiddle .topGrid{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 30px;
    padding: 100px 0 78px;
}
.rightGamTalks .gamRightMiddle .topGrid .squareGamTakls{
    width: 100%;
    height: 13.5vw;
    border-radius: 7px;
    /* box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15); */
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.4);
    position: relative;
    overflow: hidden;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    cursor: pointer;
}
.rightGamTalks .gamRightMiddle .topGrid .squareGamTakls:hover{
    transform: scale(1.1, 1.1);
    box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5);
}
.rightGamTalks .gamRightMiddle .topGrid .squareGamTakls .containerGamTalks{
    width: 100%;
    height: 100%;
    background-color: rgba(7, 28, 51, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.rightGamTalks .gamRightMiddle .topGrid .squareGamTakls:hover .containerGamTalks{
    background-color: rgba(7, 28, 51, 0.6);
}
.rightGamTalks .gamRightMiddle .topGrid .squareGamTakls .imgBackground{
    width: 100%;
    height: 110%;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.rightGamTalks .gamRightMiddle .topGrid .squareGamTakls:hover .imgBackground{
    transform: translateY(-10px);
}
.rightGamTalks .gamRightMiddle .topGrid .squareGamTakls .containerGamTalks .padding{
    padding: 18px;
    display: grid;
    grid-template-rows: min-content;
    height: 100%;
    width: 100%;
}
.rightGamTalks .gamRightMiddle .topGrid .squareGamTakls .containerGamTalks .padding p{color: white;padding: 0;margin: 0;}
.rightGamTalks .gamRightMiddle .topGrid .squareGamTakls .containerGamTalks .padding .bottom{
    align-self: end;
    position: relative;
}
.rightGamTalks .gamRightMiddle .topGrid .squareGamTakls .containerGamTalks .padding .bottom p.title{
    font-size: 18px;
    font-weight: 600;
    /* padding-right: 20px; */
    line-height: 1.2;
}
.rightGamTalks .gamRightMiddle .topGrid .squareGamTakls .containerGamTalks .padding .bottom p.name{
    font-weight: 400;
    font-size: 16px;
    opacity: .8;
}
.rightGamTalks .gamRightMiddle .topGrid .squareGamTakls .containerGamTalks .padding p.area{
    font-size: 14px;
    font-weight: 600;
}
.rightGamTalks .gamRightMiddle .topGrid .squareGamTakls .containerGamTalks .padding p.area span{
    display: table;
    background-color: #e91b2f;
    padding: 4px 7px;
    border-radius: 4px;
    line-height: 1.4;
}
.rightGamTalks .gamRightMiddle .topGrid .squareGamTakls .containerGamTalks .padding .bottom .floatText{
    width:calc(100% - 40px);
    height: auto;
    overflow: hidden;
    float: left;
}
.rightGamTalks .gamRightMiddle .topGrid .squareGamTakls .containerGamTalks .padding .bottom .play{
    width: 40px;
    height: 40px;
    border: 2px solid rgba(255,255,255,.5);
    border-radius: 50%;
    float: left;
    position: absolute;
    bottom:0;
    right: 0;
    background-image: url(../../../assets/gam-talks/play.svg);
    opacity: .5;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.rightGamTalks .gamRightMiddle .topGrid .squareGamTakls:hover .containerGamTalks .padding .bottom .play{
    opacity: 1;
}

@media (max-width: 1568px) {
.rightGamTalks .gamRightMiddle .topGrid{
    grid-gap: 20px;
}
}

@media (max-width: 1492px) {
    .rightGamTalks .gamRightMiddle .topGrid{
        grid-template-columns: repeat(2,1fr);
    }
}

@media (max-width: 1044px) {
    .wrapperPageGamTalks .leftGamTalks{
        width: 100%;
        float: none;
    }
    .rightGamTalks{
        width: 100%;
        float: none;
    }
    .wrapperPageGamTalks{
        display: block;
    }

    .wrapperPageGamTalks .leftGamTalks .middleGam {
        height: calc(70vh - 60px);
    }

    .wrapperPageGamTalks .leftGamTalks .middleGam .middleGamCell .logoGam {
        width: 140px;
        height: 140px;
    }

    .rightGamTalks .gamRightMiddle .topGrid {
        grid-template-columns: repeat(2,1fr);
        padding: 25px 0 78px;
    }

    .rightGamTalks .gamRightMiddle .topGrid .squareGamTakls {
        height: 25vw;
    }
}

@media (max-width: 800px) {
    .wrapperPageGamTalks .leftGamTalks{
        width: 100%;
        height: auto;
        float: none;
    }
    .rightGamTalks{
        width: 100%;
        float: none;
    }
    .wrapperPageGamTalks{
        display: block;
    }

    .wrapperPageGamTalks .leftGamTalks .middleGam {
        padding: 20px;
    }

    .wrapperPageGamTalks .leftGamTalks .middleGam .middleGamCell .title {
        font-size: 40px;
    }

    .wrapperPageGamTalks .leftGamTalks .middleGam .middleGamCell .logoGam {
        width: 130px;
        height: 130px;
    }

    .rightGamTalks .gamRightMiddle {
        width: 90%;
    }
    .rightGamTalks .gamRightMiddle .topGrid {
        grid-template-columns: repeat(2,1fr);
        padding: 25px 0 78px;
    }

    .rightGamTalks .gamRightMiddle .topGrid .squareGamTakls {
        height: 30vw;
    }
}

@media (max-width: 550px) {
    .wrapperPageGamTalks .leftGamTalks .middleGam {
        padding: 20px;
    }

    .wrapperPageGamTalks .leftGamTalks .middleGam .middleGamCell .title {
        font-size: 30px;
    }

    .wrapperPageGamTalks .leftGamTalks .middleGam .middleGamCell .logoGam {
        width: 120px;
        height: 120px;
    }

    .rightGamTalks .gamRightMiddle .topGrid {
        grid-template-columns: repeat(1,1fr);
        padding: 25px 0 78px;
    }

    .rightGamTalks .gamRightMiddle .topGrid .squareGamTakls {
        height: 40vw;
    }
}