.SliderAppWrap {
  width: 100%;
  height: auto;
  display: none;
  overflow: hidden;
  position: relative;
  display: grid;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  margin-bottom: 30px;
}
.WrapApp {
  width: 100%;
  height: 100%;
}

.Sliderfilter {
  background: #0b2343;
  height: 110%;
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
  opacity: 0.75;
}
.BackFilter {
  height: 110%;
  width: 100%;
  z-index: -2;
  position: absolute;
  top: 0;
}
.img-slider {
  width: 100%;
}
.sliderCenter {
  max-width: 1400px;
  width: 90%;
  margin: 0 auto;
  display: table;
  height: 100%;
}
.SliderCenterCell {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}
.WrappTextS {
  align-self: end;
  margin-bottom: 10px;
}
p.TitleSw {
  font-size: 62px;
  font-weight: 500;
  color: white;
  margin: 0;
  padding: 0;
  line-height: 1;
  max-width: 49%;
}
.CX-evolution-container .WrapApp {
  height: 400px;
  position: relative;
}

.CX-evolution-container .BackFilter {
  background: #0b2343;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 0;
  width: 100%;
  opacity: 0.75;
}
.SliderAppWrap.CX-evolution-container .WrappTextS {
  color: #fff;
}

.SliderAppWrap.CX-evolution-container .sliderCenter {
  z-index: 1;
  position: relative;
}
p.TitleSw {
  font-size: 62px;
  font-weight: 500;
  color: white;
  margin: 0;
  padding: 0;
  line-height: 1;
  max-width: 49%;
}

p.IntroSw {
  font-size: 16px;
  font-weight: 400;
  color: white;
  margin: 0;
  padding: 0;
  line-height: 1.5;
  margin-top: 20px;
  max-width: 55%;
}
span.slider-r p.subtitle {
  margin: 20px 0;
  font-weight: bold;
  font-size: 20px;
}
.container-middle-cx {
  display: flex;
}

.cards-cx {
  width: 75%;
}

.side-bar-cx {
  width: 25%;
}
.container-cx {
  width: 80%;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
  margin-top: 30px;

  position: relative;
  margin-left: auto;
  padding: 10px;
  margin-right: auto;
}
span.bar-avance {
  width: 100%;
  height: 10px;
  border: 1px solid red;
  display: block;
  position: relative;
  margin-bottom: 15px;
}
span.bar-fill {
  width: 20%;
  display: inline-block;
  background: red;
  height: 100%;
  position: absolute;
}
span.bar-mount {
  background: red;
  padding: 5px;
  color: #fff;
}
.container-data-avance {
  display: flex;
  margin: 20px 0;
}

.items-data-avance {
  display: flex;
}
.item-avance {
  font-size: 12px;
  line-height: 1;
}
.items-avance {
  font-size: 12px;
  line-height: 1;
  padding: 0 5px 5px 5px;
}
.container-cx h3 {
  text-align: center;
  color: #0b2343;
}
.side-bar-cx {
  margin-top: -100px;
}
