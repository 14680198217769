.search-container {
  min-height: 300px;
  /* background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)),
    url("../../../assets/images/search/aeromexico-search.jpg"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #004990;
  height: 350px;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.a-search {
  color: white;
  font-size: 16px;
}
.title-search {
  color: #ffffff;
  font-size: 54px;
  font-weight: 500;
  width: auto;
}
.search-sub-container {
  background-color: #ffffff;
  border: none;
  display: inline-block;
  width: 100%;
  max-width: 600px;
  border-radius: 50px;
  padding: 10px 20px;
  text-align: left;
  position: relative;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
  border: 1px solid #d6d6d6;
}
.form-search {
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.box-container-search {
  width: 90%;
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap; */
  max-width: 1200px;
}
.input-search {
  border: none;
  outline-width: 0;
  width: 100%;
  color: #0b2343;
  font-size: 24px;
  font-weight: 500;
}
.input-search:focus{
  font-weight: 500;
  color: #007dc3;
}
.search-container:focus-within{
  background-color: #007dc3;
  height: 350px;
}
.search-sub-container:focus-within{
  box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28);
}
.button-search {
  position: absolute;
  right: 8px;
  background: #e91b2f;
  border: none;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 5;
  top: 6px;
}
.button-search-icon {
  color: #fff;
  font-size: 20px;
}

.input-search::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #0b2343;
}

.input-search::-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #0b2343;
}

.input-search::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #0b2343;
}
.Info-search {
  height: 75px;
  text-align: center;
  width: 100%;
}

li.search-list {
  color: white;
  font-size: 14px;
  list-style: none;
  cursor: pointer;
  font-weight: 500;
  height: 100%;
  line-height: 35px;
  opacity: 0.7;
  margin: 2%;
  padding: 0;
  letter-spacing: 0.4px;
}

/* Responsive */
@media (max-width: 1044px) {
  ul.item-list-search {
    display: block;
    padding: 0;
  }
  p.title-search {
    font-size: 45px;
  }
}

.page-search .button-search-icon {
  margin: 0 auto;
}
.form-search .button-search .button-search-icon {
  margin: 0 auto;
}
.info-search {
  max-width: 90%;
  margin: 0 auto;
  max-width: 1200px;
  margin-top: 40px;
}
.Info-search-result {
  min-height: 300px;
}
.item-list-search {
  width: 90%;
  margin: 0 auto;
  list-style-type: none;
  max-width: 1200px;
  margin-top: 40px;
  margin-bottom: 80px;
}
.qty-results {
  color: #ccc;
  font-size: 1.8rem;
}

.item-search-result {
  display: flex;
  margin-bottom: 25px;
  justify-content: space-between;
  border-radius: 0;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 25px;
}

.visit-site-wrapper {
  background: #007ec4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  color: #fff;
  border-radius: 7px;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.visit-site-wrapper:hover {
  background: #004990;
}
.visit-site-wrapper:hover a:hover{
  color: white;
}
.item-result-wrapper p{
  font-weight: 500;
  font-size: 18px;
  color: #0b2343;
  margin-bottom: 10px;
}
.item-result-wrapper a{
  text-decoration: underline;
  color: #007dc3;
  font-weight: 500;
}
.item-result-wrapper a:before{
  content: "https://miaeromexico.com";
}
.visitLink {
  color: #fff;
}

.visitLink:hover {
  color: #fff;
}

.blue-arrow-search {
  max-width: 20px;
  display: block;
  margin: 0 auto;
}
.item-result-link {
  color: #fff;
}
