.titleTriques {
	display: none;
}
.titleTriques p {
	font-size: 50px;
	text-align: center;
	font-weight: 600;
	color: #0b2343;
	margin-top: 30px;
}

.responsiveLayout2 {
	width: auto;
	box-shadow: 0 0 0px 0px rgba(0, 0, 0, 0.13);
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 60px);
	overflow: auto; 
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	display: flexbox;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
  }

.container{
	display: flex;
}

.containerVacante{
	display: flex; 
	max-height: auto;
}
.column {
	flex: 0.1;   
}
.column2 {
	flex: 1.2;  
}
  
  .box {
	background-color: #f0f0f0;
	padding: 10px;	
	margin-bottom: 10px;
  }
  .scrollable {
	max-width: auto;
	max-height: 100%; /* Altura máxima para que aparezca la barra de desplazamiento */
	overflow-y: auto; /* Hace que el contenido sea desplazable verticalmente */
  }
  .scrollable::-webkit-scrollbar {
	display: none;
  }

/*

<div className="titleTriques">
            <p>Triques y Trueques</p>
          </div>

*/
.menuSelectionTriques {
	height: auto;
	overflow: hidden;
	width: 100%;
	border-bottom: 1px solid #e5e5e5;
	/* margin-bottom: 40px; */
	background-color: #f5f8f9;
}
.menuSelectionTriques .wrapSelection {
	max-width: 1400px;
	margin: 0 auto;
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	width: 100%;
	margin: 0 auto;
}
.menuSelectionTriques .wrapSelection .contOptionSelection {
	width: 100%;
	cursor: pointer;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.menuSelectionTriques .wrapSelection .contOptionSelection:hover {
	background-color: rgba(0, 125, 195, 0.05);
}
.menuSelectionTriques .wrapSelection .contOptionSelection.active {
	background-color: rgba(0, 125, 195, 0.05);
	width: 100%;
	cursor: pointer;
	border: none !important;
}
.menuSelectionTriques .wrapSelection .contOptionSelection img {
	width: 70px;
	height: 70px;
	margin: 0 auto;
	object-fit: contain;
	display: block;
	margin-top: 10px;
}
.menuSelectionTriques .wrapSelection .contOptionSelection p {
	text-align: center;
	margin-top: 5px;
	font-weight: 400;
	color: #020c41;
}

.menuSelectionTriques .wrapSelection .contOptionSelection .activeBar {
	height: 4px;
	width: 100%;
	background-color: #fa0073;
	opacity: 0;
	margin-top: 30px;
}

.menuSelectionTriques .wrapSelection .contOptionSelection.active .activeBar {
	opacity: 1;
}

.ventaOptions {
	width: 100%;
	/* height: auto; */
	/* overflow: hidden; */
	/* box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075); */
	border-radius: 11px;
	cursor: pointer;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	position: relative;
	/* border: 1px solid #f5f5f5; */
}
.ventaOptions .padding {
	padding: 20px;
	display: grid;
	grid-template-rows: repeat(3, auto);
}
.ventaOptions:hover {
	-webkit-transform: scaleY(1.03) scaleX(1.03);
	transform: scaleY(1.03) scaleX(1.03);
	box-shadow: 0 30px 60px rgba(0, 0, 0, 0.1);
}
.ventaOptions .padding .newProduct {
	background-color: #004990;
	display: table;
	width: auto;
	height: auto;
	padding: 4px 10px;
	color: #fff;
	font-weight: 500;
	font-size: 12px;
	border-radius: 7px;
	position: absolute;
	top: 10px;
	left: 10px;
	opacity: 0;
}
.firstChild:nth-child(1) .ventaOptions .padding .newProduct {
	opacity: 1;
}
.ventaOptions .padding .textWrap .buyButton {
	height: 40px;
	width: 100%;
	border-radius: 100px;
	overflow: hidden;
	box-sizing: border-box;
}

.ventaOptions .padding .textWrap .buyButton p.price {
	float: left;
	width: 50%;
	border: 1px solid #fa0073;
	height: 40px;
	border-radius: 400px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-right: none;
	font-size: 15px;
	font-weight: 500;
	text-align: center;
	line-height: 37px;
	margin: 0;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	padding: 0;
}
.ventaOptions .padding .textWrap .buyButton p.price::before {
	content: "$";
	font-size: 12px;
}
.ventaOptions .padding .textWrap .buyButton p.price::after {
	content: " MXN";
	font-weight: 400;
	font-size: 12px;
}
.ventaOptions .padding .textWrap .buyButton a {
	float: right;
	width: 50%;
	background-color: #fa0073;
	display: table;
	margin: 0;
	padding: 0;
	color: white;
	height: 40px;
	line-height: 40px;
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	cursor: pointer;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.ventaOptions .padding .textWrap .buyButton a p {
	color: white;
	float: left;
	margin: 0;
	padding: 0;
	margin-left: 24px;
}

.ventaOptions .padding .textWrap .buyButton a span {
	width: 15px;
	height: 15px;
	display: block;
	float: left;
	margin-top: 13px;
	margin-left: 7px;
	background-image: url(../../../assets/triques/buynow.svg);
	background-repeat: no-repeat;
	background-position: center;
}
.ventaOptions .padding .textWrap .buyButton:hover a {
	background-color: #0b2343;
}

.ventaOptions .padding .textWrap .buyButton:hover p.price {
	border: 1px solid #0b2343;
}
.ventaOptions .padding .textWrap p.title {
	font-weight: 400;
	font-size: 20px;
	color: #0b2343;
	margin: 0;
	padding: 0;
	line-height: 1.2;
}

.ventaOptions .padding .textWrap p.price {
	font-weight: 500;
	font-size: 18px;
	color: #666666;
	margin: 0;
	padding: 0;
	line-height: 1.3;
	margin-top: 7px;
	color: #fa0073;
}

.ventaOptions .padding .textWrap p.price::before {
	content: "$ ";
	font-size: 14px;
}

.ventaOptions .padding .textWrap p.price::after {
	content: "MXN";
	font-size: 14px;
}

.ventaOptions .padding .textWrap .viewDetails {
	color: #035cf7;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 0;
	display: block;
	cursor: pointer;
	margin-top: 7px;
}

.ventaOptions .imageWrap {
	/* width: 100%; */
	height: 160px;
	/* border-bottom: 1px solid #e5e5e5; */
	/* background-color: #f5f5f5; */
	overflow: hidden;
}

.ventaOptions .imageWrap img {
	width: 110%;
	height: 110%;
	/* object-fit: contain; */
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	transform: scale(1);
	margin: 0 auto;
	display: block;
}

.ventaOptions:hover .imageWrap img {
	transform: scale(1.15);
}

.wrapperBoxesTriques {
	padding: 20px;
}
.profile-tabs-content .midWrapTriques {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 20px;
	margin: 0 auto;
}

.midWrapBlueTriques {
	max-width: 1050px;
	margin: 0 auto;
}

.firstChild {
	display: block;
}

/* #autosBoxTriques .automovilTriques {
	display: block;
}
#computoBoxTriques .computerTriques {
	display: block;
}
#electroBoxTriques .hogarTriques {
	display: block;
}
#phonesBoxTriques .phoneTriques {
	display: block;
}
#ropaBoxTriques .clothesTriques {
	display: block;
}
#otrosBoxTriques .othersTriques {
	display: block;
} */

.wrapperBoxesTriques h2 {
	color: #0b2343;
	font-size: 24px;
	margin-bottom: 40px;
	margin-top: 20px;
	font-weight: 500;
}
.windowWhiteTrique {
	position: fixed;
	width: 900px;
	min-height: 400px;
	background: #fff;
	border-radius: 11px;
	box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 2 5px 10px 0px rgba(0, 0, 0, 0.1);
	-o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
	-ms-box-shadow: 0 5px 1;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	max-height: 500px;
	min-height: 200px;
	border-radius: 11px;
	height: 90vh;
	overflow: hidden;
}
.windowWhiteTrique .leftWrap,
.windowWhiteTrique .rightWrap {
	width: 50%;
	float: left;
	height: 100%;
}
.windowWhiteTrique .leftWrap img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.windowWhiteTrique .rightWrap .scroll {
	overflow-y: scroll;
	overflow-x: hidden;
	padding: 40px;
	border-left: 1px solid #e5e5e5;
}
.windowWhiteTrique .rightWrap .scroll .modaltitle {
	font-size: 24px;
	line-height: 1.2;
	margin: 0;
	padding: 0;
	font-weight: 500;
	color: #0b2343;
	padding-right: 30px;
}
.windowWhiteTrique .rightWrap .scroll .modalPrice {
	font-size: 32px;
	font-weight: 500;
	color: #0b2343;
	margin: 0;
	padding: 0;
	margin-top: -7px;
}
.windowWhiteTrique .rightWrap .scroll .modalPrice::before {
	content: "$";
	font-size: 22px;
	margin-right: 6px;
}
.windowWhiteTrique .rightWrap .scroll .bestprice {
	margin: 0;
	padding: 0;
	margin-top: 20px;
	font-size: 14px;
	font-weight: 500;
	color: #007dc3;
}
.windowWhiteTrique .rightWrap .scroll .buynow {
	height: 50px;
	color: white;
	background-color: #fa0073;
	border-radius: 100px;
	text-align: center;
	line-height: 50px;
	padding: 0 20px;
	display: table;
	margin-top: 16px;
	font-size: 14px;
	font-weight: 500;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.windowWhiteTrique .rightWrap .scroll .buynow:hover {
	background-color: #0b2343;
	transform: translateY(-4px);
}
.windowWhiteTrique .rightWrap .scroll .buynow p {
	float: left;
	margin: 0;
	padding: 0;
}
.windowWhiteTrique .rightWrap .scroll .buynow span {
	width: 15px;
	height: 15px;
	display: block;
	float: left;
	margin-top: 19px;
	margin-left: 7px;
	background-image: url(../../../assets/triques/buynow.svg);
	background-repeat: no-repeat;
	background-position: center;
}

.windowWhiteTrique .rightWrap .descriptionTitle {
	font-size: 12px;
	color: #999;
	text-transform: uppercase;
	font-weight: 600;
	margin-top: 25px;
}
.windowWhiteTrique .rightWrap .detailsWrap {
	padding-bottom: 30px;
	border-top: 1px solid #e5e5e5;
	padding-top: 30px;
}
.windowWhiteTrique .rightWrap .detailsWrap p {
	color: #0b2343;
	font-size: 16px;
}
.windowWhiteTrique .rightWrap .detailsWrap ol,
.windowWhiteTrique .rightWrap .detailsWrap ul {
	color: #0b2343;
	font-size: 16px;
	margin-left: 17px;
	margin-bottom: 0;
}
.windowWhiteTrique .rightWrap .detailsWrap li {
	padding-left: 10px;
}
.bluemidWrap {
	background-color: #f5f8f9;
	border-radius: 0;
	padding: 30px 20px;
	margin: 0 0 40px 0;
	width: 100%;
}

.bluemidWrap .totalBenefits__title__container {
	align-items: center;
	display: flex;
	justify-content: center;
	margin: 0;
}

.bluemidWrap .totalBenefits__title__container .iconTitleProfile {
	margin: 0 10px 0 0;
	height: 30px;
	width: auto;
}

.bluemidWrap .totalBenefits__title__container h1 {
	font-size: 18px;
	margin: 0;
	text-align: left;
}

.bluemidWrap p.des {
	max-width: 650px;
	padding: 0;
	margin: 15px auto;
	text-align: center;
	font-size: 16px;
	font-weight: 300;
}
.bluemidWrap p.des b.bluew {
	color: rgba(0, 125, 195, 1);
}
.bluemidWrap a {
	height: 40px;
	color: white;
	background-color: #fa0073;
	border-radius: 100px;
	text-align: center;
	line-height: 50px;
	padding: 0 20px;
	display: table;
	margin-top: 16px;
	font-size: 14px;
	font-weight: bold;
	transform: scale(1);
	transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	margin: 0 auto;
}
.bluemidWrap a:hover {
	transform: scale(1.1);
}
.bluemidWrap a p {
	margin: 0;
	padding: 0;
}
.bluemidWrap a span {
	width: 15px;
	height: 15px;
	display: block;
	float: left;
	margin-top: 19px;
	margin-left: 7px;
	background-image: url(../../../assets/triques/buynow.svg);
	background-repeat: no-repeat;
	background-position: center;
}
.windowWhiteTrique .rightWrap.smallOption {
	display: none;
}

.windowWhiteTrique .rightWrap.smallOption .modaltitle {
	font-size: 24px;
	line-height: 1.2;
	margin: 0;
	padding: 0;
	font-weight: 500;
	color: #0b2343;
	padding-right: 30px;
}
.windowWhiteTrique .rightWrap.smallOption .modalPrice {
	font-size: 32px;
	font-weight: 500;
	color: #0b2343;
	margin: 0;
	padding: 0;
	margin-top: -7px;
}
.windowWhiteTrique .rightWrap.smallOption .modalPrice::before {
	content: "$";
	font-size: 22px;
	margin-right: 6px;
}
.windowWhiteTrique .rightWrap.smallOption .bestprice {
	margin: 0;
	padding: 0;
	margin-top: 20px;
	font-size: 14px;
	font-weight: 500;
	color: #007dc3;
}

.windowWhiteTrique .rightWrap.smallOption .buynow {
	height: 50px;
	color: white;
	background-color: #fa0073;
	border-radius: 100px;
	text-align: center;
	line-height: 50px;
	padding: 0 20px;
	display: table;
	margin-top: 16px;
	font-size: 14px;
	font-weight: 500;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.windowWhiteTrique .rightWrap.smallOption .buynow:hover {
	background-color: #0b2343;
	transform: translateY(-4px);
}
.windowWhiteTrique .rightWrap.smallOption .buynow p {
	float: left;
	margin: 0;
	padding: 0;
}
.windowWhiteTrique .rightWrap.smallOption .buynow span {
	width: 15px;
	height: 15px;
	display: block;
	float: left;
	margin-top: 19px;
	margin-left: 7px;
	background-image: url(../../../assets/triques/buynow.svg);
	background-repeat: no-repeat;
	background-position: center;
}

@media (max-width: 1044px) {
	.profile-tabs-content .midWrapTriques {
		grid-template-columns: repeat(3, 1fr);
	}

	.bluemidWrap {
		border-radius: 0;
	}

	.windowWhiteTrique {
		display: grid;
		position: fixed;
		width: 500px;
		overflow: scroll;
	}

	.windowWhiteTrique .leftWrap,
	.windowWhiteTrique .rightWrap {
		width: 100%;
		padding: 50px;
	}

	.windowWhiteTrique .rightWrap.defaultOption {
		display: none;
	}

	.windowWhiteTrique .rightWrap.smallOption {
		display: block;
		padding: 0px 40px;
	}
}

@media (max-width: 800px) {
	.menuSelectionTriques .wrapSelection {
		grid-template-columns: repeat(3, 1fr);
	}

	.profile-tabs-content .midWrapTriques {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (max-width: 600px) {
	.windowWhiteTrique {
		width: 400px;
	}
}

@media (max-width: 424px) {
	.menuSelectionTriques .wrapSelection {
		grid-template-columns: repeat(2, 1fr);
	}

	.profile-tabs-content .midWrapTriques {
		grid-template-columns: repeat(1, 1fr);
	}

	.windowWhiteTrique .rightWrap.smallOption {
		padding: 0px 15px;
	}

	.windowWhiteTrique .rightWrap.smallOption .modaltitle {
		font-size: 20px;
		padding-right: 0px;
	}

	.windowWhiteTrique .rightWrap.smallOption .modalPrice {
		font-size: 30px;
	}

	.windowWhiteTrique .rightWrap.smallOption .detailsWrap li {
		padding-left: 0px;
	}

	.windowWhiteTrique .rightWrap.smallOption .detailsWrap ul {
		font-size: 13px;
		margin-left: 18px;
	}

	.windowWhiteTrique {
		width: 300px;
	}
}

/*

<div className="windowWhiteTrique">
            <div className="leftWrap"></div>
            <div className="rightWrap">
                <div className="scroll">
                    <p className="modaltitle">{this.props.data.fields.titleTrique}</p>
                    <CurrencyFormat value={this.props.data.fields.priceTriques} displayType={'text'} thousandSeparator={true} decimalScale={"2"} prefix={''} renderText={value => <p className="modalPrice">{value} </p>} />
                    <a href={'mailto:' + this.props.data.fields.emailContacto + '?subject=Triques y Trueques - ' + this.props.data.fields.titleTrique + '&body=Escribe tu mensaje para completar la compra con ' + this.props.data.fields.nombreContacto}><p>Comprar ahora</p> <span></span></a>
                    <p className="descriptionTitle">Detalles</p>
                    <div
                          className="detailsWrap"
                          dangerouslySetInnerHTML={{
                            __html: this.props.data.fields
                              .carcateristicasTriques
                          }}
                        />
                </div>
            </div>
        </div>


<div className="ventaOptions">
            <div className="padding">
                <p className="newProduct">Nuevo</p>
                <div className="imageWrap">
                    <img src=""></img>
                </div>
                <div className="textWrap">
                    <p className="title">Pontiac Matiz 2005</p>
                    <div className="buyButton">
                        <p>2000</p>
                        <a href="/">Comprar ahora <span></span></a>
                    </div>
                </div>
            </div>
          </div>


<div>
          <div className="menuSelectionTriques">
            <div className="wrapSelection">
                <div className="contOptionSelection">
                    <img src=""></img>
                    <p>Automóviles</p>
                    <div className="activeBar"></div>
                </div>
            </div>
          </div>
      </div>
      */
.windowWhiteTrique {
	display: flex;
	align-items: center;
	justify-center: flex-start;
	flex-wrap: wrap;
	padding: 0;
}

.triques-modal-image-container {
	display: flex;
	flex-wrap: wrap;
	height: 100%;
	width: 50%;
}
.triques-modal-image-container img {
	height: 80%;
}

.gridder {
	height: 19%;
	gap: 0 10px;
	overflow: hidden;
}

.gridder img {
	background-position: center;
	cursor: pointer;
}

@media (max-width: 1044px) {
	.triques-modal-image-container {
		width: 100%;
	}
	.rightWrap.smallOption {
		border-top: 1px solid rgba(0, 0, 0, 0.15);
		margin-top: 25px !important;
		padding: 25px 25px 0 !important;
	}
}
