.headerResponsabilidad {
  height: auto;
  overflow: hidden;
  width: 100%;
  /* background-color:#f5f5f5; */
  border-bottom: 1px solid #e5e5e5;
  /* background-image: url(../../../assets/vuela-verde/wall-header1.jpg); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.headerResponsabilidad .midWrapper {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  height: auto;
  padding: 140px 0;
  position: relative;
  z-index: 2;
}
.headerResponsabilidad .midWrapper .logoAlas {
  width: 224px;
  height: 90px;
  background-image: url(../../../assets/vuela-verde/responsabilidad.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.headerResponsabilidad .midWrapper p.title {
  font-size: 60px;
  max-width: initial;
  font-weight: 600;
  color: white;
  line-height: 1.2;
  text-align: left;
  color: #0b2343;
  text-align: left;
}
.headerResponsabilidad .midWrapper p.subtitle {
  font-size: 22px;
  font-weight: 300;
  color: white;
  line-height: 1.4;
  margin-top: 15px;
  text-align: left;
  color: #0b2343;
  max-width: 500px;
  text-align: left;
}
.headerResponsabilidad .midWrapper p.subBig {
  max-width: 600px;
  font-size: 56px;
  font-weight: 600;
  line-height: 1.1;
}
.headerResponsabilidad .midWrapper p.subtitle b {
  font-weight: 600;
  font-family: "Neue Haas Unica Pro Medium";
}
.headerResponsabilidad .midWrapper p.subtitle01 {
  font-size: 18px;
  font-weight: 300;
  color: white;
  line-height: 1.4;
  margin-top: 15px;
  text-align: left;
  color: white;
  max-width: 600px;
}
.scroll {
  height: 500px;
}
.pilaresResponsabilidad {
  border-bottom: 1px solid #e5e5e5;
  margin-top: 60px;
}
.pilaresResponsabilidad .midPilares {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}
.pilaresResponsabilidad .midPilares .pilaresTitle {
  font-size: 36px;
  text-align: center;
  font-weight: 600;
  color: #0b2343;
  margin-bottom: 10px;
  line-height: 1.3;
}
.pilaresResponsabilidad .midPilares .pilaresIntro {
  color: #666;
  font-size: 20px;
  line-height: 1.4;
  text-align: center;
  margin: 0 auto;
  margin-top: 0;
}
.pilaresResponsabilidad .midPilares .wrapperPilaresGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  height: auto;
  overflow: hidden;
  width: 100%;
  padding: 60px 0 70px;
}
.pilaresResponsabilidad .midPilares .wrapperPilaresGrid .opcPilarBox {
  position: relative;
}
.pilaresResponsabilidad
  .midPilares
  .wrapperPilaresGrid
  .opcPilarBox
  .imagePilar {
  float: left;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: rgba(72, 168, 71, 0.08);
  margin-right: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.pilaresResponsabilidad
  .midPilares
  .wrapperPilaresGrid
  .opcPilarBox:nth-child(1)
  .imagePilar {
  background-image: url(../../../assets/vuela-verde/icon-01.svg);
}
.pilaresResponsabilidad
  .midPilares
  .wrapperPilaresGrid
  .opcPilarBox:nth-child(2)
  .imagePilar {
  background-image: url(../../../assets/vuela-verde/icon-02.svg);
}
.pilaresResponsabilidad
  .midPilares
  .wrapperPilaresGrid
  .opcPilarBox:nth-child(3)
  .imagePilar {
  background-image: url(../../../assets/vuela-verde/icon-04.svg);
}
.pilaresResponsabilidad
  .midPilares
  .wrapperPilaresGrid
  .opcPilarBox:nth-child(4)
  .imagePilar {
  background-image: url(../../../assets/vuela-verde/icon-03.svg);
}
.pilaresResponsabilidad
  .midPilares
  .wrapperPilaresGrid
  .opcPilarBox
  .textPilar {
  float: left;
  width: calc(100% - 70px);
  height: auto;
}
.pilaresResponsabilidad
  .midPilares
  .wrapperPilaresGrid
  .opcPilarBox
  .textPilar
  p:nth-child(1) {
  color: #0b2343;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.3;
  margin-bottom: 15px;
}
.pilaresResponsabilidad
  .midPilares
  .wrapperPilaresGrid
  .opcPilarBox
  .textPilar
  p:nth-child(2) {
  font-weight: 400;
  font-size: 14px;
  max-width: 320px;
  margin: 0 auto;
  color: #666;
}

.pilaresResponsabilidad.reportes-sustentabilidad {
  background-color: #ffffff;
  margin-top: 0;
  padding-top: 60px;
  padding-bottom: 30px;
}
.pilaresResponsabilidad.reportes-sustentabilidad .midPilares .pilaresTitle {
  text-align: left;
}
.pilaresResponsabilidad.reportes-sustentabilidad
  .midPilares
  .pilaresTitle
  span {
  width: 75px;
  height: 75px;
  float: left;
  margin-top: 14px;
  margin-right: 15px;
  background-image: url(../../../assets/vuela-verde/politicas.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.pilaresResponsabilidad.reportes-sustentabilidad .midPilares .pilaresIntro {
  text-align: left;
  max-width: 600px;
  margin-left: initial;
  margin-right: initial;
}

.tabsReporteWrapper {
  border-bottom: 2px solid rgba(72, 168, 71, 1);
  height: 60px;
  margin-top: -18px;
  margin-bottom: 30px;
}

#securityTabs .tabsReporteWrapper {
  border-bottom: 2px solid rgba(28, 134, 158, 1);
}

.tabsReporteWrapper .tabResponsive {
  float: right;
  width: calc(100% - 600px);
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
}

#securityTabs{
  display: none;
}

#securityTabs .tabsReporteWrapper .tabResponsive {
  grid-template-columns: repeat(2, 1fr);
}

.midTabs {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}
.tabsReporteWrapper .tabResponsive .tabBox {
  border: 2px solid rgba(72, 168, 71, 1);
  border-radius: 11px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: -2px;
  cursor: pointer;
  overflow: hidden;
}

#securityTabs .tabsReporteWrapper .tabResponsive .tabBox{
  border: 2px solid rgba(28, 134,158,1);;
}

.tabsReporteWrapper .tabResponsive .tabBoxActive {
  border-bottom: 2px solid #ffffff;
}

#securityTabs .tabsReporteWrapper .tabResponsive .tabBoxActive{
  border-bottom: 2px solid #f1f6f8;
}

.tabsReporteWrapper .tabResponsive .tabBox p {
  text-align: center;
  margin: 0;
  padding: 0;
  line-height: 56px;
  color: #666;
  font-size: 14px;
  font-weight: 500;
}

.tabsReporteWrapper .tabResponsive .tabBox:hover p {
  color: rgba(72, 168, 71, 1);
  background-color: rgba(72, 168, 71, 0.07);
}

#securityTabs .tabsReporteWrapper .tabResponsive .tabBox:hover p {
  color: rgba(28, 134,158,1);
  background-color: rgba(28, 134,158,.07);
}

.tabsReporteWrapper .tabResponsive .tabBoxActive:hover p {
  background-color: #fcfdfc;
}

#securityTabs .tabsReporteWrapper .tabResponsive .tabBoxActive:hover p {
  background-color: #f1f6f8;
}

.tabsReporteWrapper .tabResponsive .tabBoxActive p {
  color: rgba(72, 168, 71, 1);
}
#securityTabs .tabsReporteWrapper .tabResponsive .tabBoxActive p {
  color: rgba(28, 134,158,1);
}
#midWrapperPdfSocial {
  width: calc(90% + 40px);
  max-width: calc(1200px + 40px);
  height: auto;
  overflow: hidden;
  margin: 0 auto;
  padding-bottom: 38px;
  padding-top: 30px;
  padding-right: 20px;
  padding-left: 20px;
}
#midWrapperPdfSocial .wrapperIdenditad {
  width: 100%;
  max-width: initial;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 0;
  grid-gap: 10;
}

#midWrapperPdfSocial.wrapperDownload .wrapperIdenditad .wrapperbuttonBig {
  display: none;
}
#midWrapperPdfSocial.wrapperDownload
  #reportesResponsabilidad
  .ReporteSostenibilidad {
  display: block;
}
#midWrapperPdfSocial.wrapperDownload
  #reportesPoliticas
  .PoliticaSostenibilidad {
  display: block;
}
#midWrapperPdfSocial.wrapperDownload
  #reportesDeclaracion
  .DeclaracionSostenibilidad {
  display: block;
}
#midWrapperPdfSocial.wrapperDownload .wrapperIdenditad .wrapperbuttonBig {
  width: 100%;
}

#midWrapperPdfSocial.wrapperDownload
  .wrapperIdenditad
  .wrapperbuttonBig
  .wrapperTop {
  height: 500px;
  max-height: 200px;
}

.vuelaverdeAlas {
  height: 50vh;
  width: 100%;
  overflow: hidden;
  min-height: 500px;
  max-height: 700px;
}

.AlasMundoWrapper {
  width: 100%;
  border: 1px solid #f5f5f5;
  height: auto;
  /* overflow: hidden; */
  position: relative;
  z-index: 3;
  background-color: #f2f2f2;
}

.AlasMundoWrapper .midCenterAlas {
  max-width: 1200px;
  margin: 0 auto;
  width: 90%;
  height: auto;
  /* overflow: hidden; */
}
.AlasMundoWrapper .midCenterAlas .whiteAlas {
  min-height: 400px;
  border-radius: 11px;
  background-color: white;
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-top: -150px;
  margin-bottom: 100px;
}
.AlasMundoWrapper .midCenterAlas .whiteAlas p.title {
  font-size: 36px;
  text-align: center;
  font-weight: 600;
  color: #0b2343;
  margin-bottom: 10px;
  line-height: 1.2;
  margin-top: 80px;
}

.AlasMundoWrapper .midCenterAlas .whiteAlas p.subtitle {
  color: #666;
  font-size: 20px;
  line-height: 1.4;
  text-align: center;
  margin: 0 auto;
  margin-top: 0px;
  margin-top: 0;
  max-width: 650px;
}

.AlasMundoWrapper .midCenterAlas .whiteAlas .alasBoxGrid {
  width: calc(100% - 60px);
  margin: 0 auto;
  height: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin-top: 60px;
  margin-bottom: 30px;
}

.AlasMundoWrapper .midCenterAlas .whiteAlas .alasBoxGrid .BoxContWrapper {
  border-radius: 11px;
  padding-top: 100px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  background: linear-gradient(#7ea9b0 0%, #5b8695 100%);
  text-decoration: none;
  cursor: pointer;
}
.AlasMundoWrapper
  .midCenterAlas
  .whiteAlas
  .alasBoxGrid
  .BoxContWrapper:nth-child(2) {
  cursor: initial;
}
.AlasMundoWrapper
  .midCenterAlas
  .whiteAlas
  .alasBoxGrid
  .BoxContWrapper
  .bottomText {
  width: 100%;
  height: auto;
  overflow: hidden;
  max-width: 402px;
  padding: 0 35px;
  margin-bottom: 50px;
  align-self: end;
  color: white;
}
.AlasMundoWrapper
  .midCenterAlas
  .whiteAlas
  .alasBoxGrid
  .BoxContWrapper
  p:nth-child(1) {
  font-size: 30px;
  text-align: left;
  font-weight: 600;
  color: white;
  margin-bottom: 10px;
  line-height: 1.2;
  margin-top: 80px;
}
.AlasMundoWrapper
  .midCenterAlas
  .whiteAlas
  .alasBoxGrid
  .BoxContWrapper
  p:nth-child(2)
  span {
  text-decoration: underline;
}
.AlasMundoWrapper
  .midCenterAlas
  .whiteAlas
  .alasBoxGrid
  .BoxContWrapper
  p:nth-child(2) {
  font-size: 18px;
  line-height: 1.4;
  font-weight: 400;
}

.AlasMundoWrapper
  .midCenterAlas
  .whiteAlas
  .alasBoxGrid
  .BoxContWrapper:nth-child(2) {
  background-image: url(../../../assets/vuela-verde/voluntariado.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.AlasMundoWrapper
  .midCenterAlas
  .whiteAlas
  .alasBoxGrid
  .BoxContWrapper:nth-child(1) {
  background-image: url(../../../assets/vuela-verde/voluntariado01.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/*
<div className="alasBoxGrid">
                      <div className="BoxContWrapper">
                        <div className="bottomText">
                          <p>Compensa tu huella y aporta al planeta. Vuela Verde</p>
                          <p>Vuela Verde es una contribución voluntaria que puedes realizar al comprar tu vuelo, la cual se destina a proyectos que apoyan al medio ambiente.</p>
                        </div>
                      </div>
                    </div>
                    */

/*


    <div className="AlasMundoWrapper">
              <div className="midCenterAlas">
                <div className="whiteAlas">
                  <div className="paddingAlas">
                    <p className="title">Sostenibilidad <br/> Generando oportunidades de valor y <br/>contribuyendo al desarrollo sostenible</p>
                  </div>
                </div>
              </div>
            </div>


    <div className="tabsReporteWrapper">
                  <div className="tabBox"><p>Reportes</p></div>
                  <div className="tabBox"><p>Políticas</p></div>
                  <div className="tabBox"><p>Declaraciones</p></div>
                </div>
    
    <div className="pilaresResponsabilidad">
              <div className="midPilares">
                <p className="pilaresTitle">Nuestros pilares</p>
                <p className="pilaresIntro"> Los cuatro pilares de nuestra estrategia de Responsabilidad Social Corporativa son:
</p>
                <div className="wrapperPilaresGrid">
                  <div className="opcPilarBox">
                    <div className="imagePilar"></div>
                    <div className="textPilar">
                      <p>Responsabilidad con los clientes</p>
                      <p>En Aeroméxico nos comprometemos a cuidar la seguridad de nuestros clientes, ofrecer información clara sobre los servicios que ofrecemos, y respetar en todo momento los derechos de los pasajeros establecidos en las leyes aplicables. </p>
                    </div>
                  </div>

                  </div>
                </div>
              </div>
            </div>


        */

/* ***** Responsive Styles ***** */

@media screen and (max-width: 1300px) {
}

@media screen and (max-width: 1044px) {
  .headerResponsabilidad .midWrapper {
    padding: 20px 0;
  }
  .headerResponsabilidad .midWrapper p.subBig {
    font-size: 35px;
  }
  .pilaresResponsabilidad .midPilares .wrapperPilaresGrid {
    grid-template-columns: repeat(2, 1fr);
  }

  .tabsReporteWrapper .tabResponsive {
    width: calc(100% - 350px);
  }
  #midWrapperPdfSocial .wrapperIdenditad {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 800px) {
  .AlasMundoWrapper .midCenterAlas .whiteAlas .alasBoxGrid {
    grid-template-columns: repeat(1, 1fr);
  }

  #midWrapperPdfSocial .wrapperIdenditad {
    grid-template-columns: repeat(3, 1fr);
  }

  .tabsReporteWrapper .tabResponsive {
    width: calc(100% - 180px);
  }
}

@media screen and (max-width: 600px) {
  .pilaresResponsabilidad .midPilares .wrapperPilaresGrid {
    grid-template-columns: repeat(1, 1fr);
  }

  #midWrapperPdfSocial .wrapperIdenditad {
    grid-template-columns: repeat(2, 1fr);
  }

  .tabsReporteWrapper .tabResponsive {
    width: calc(100% - 0px);
  }
}

@media screen and (max-width: 424px) {
  #midWrapperPdfSocial .wrapperIdenditad {
    grid-template-columns: repeat(1, 1fr);
  }

  .tabsReporteWrapper .tabResponsive .tabBox p {
    font-size: 12px;
  }

  .headerResponsabilidad .midWrapper p.subBig {
    font-size: 28px;
  }

  .headerResponsabilidad {
    background-image: url(../../../assets/vuela-verde/wall-header-small.jpg);
  }

  .AlasMundoWrapper .midCenterAlas .whiteAlas {
    margin-top: -350px;
  }

  .AlasMundoWrapper .midCenterAlas .whiteAlas .alasBoxGrid .BoxContWrapper {
    padding-top: 30px;
  }

  .AlasMundoWrapper .midCenterAlas .whiteAlas .alasBoxGrid .BoxContWrapper p:nth-child(1) {
    margin-top: 0px;
  }
  .AlasMundoWrapper .midCenterAlas .whiteAlas p.title {
    font-size: 25px;
    margin-bottom: 0px;
    margin-top: 30px;
  }
  .pilaresResponsabilidad .midPilares .pilaresTitle {
    font-size: 23px;
  }
}

/* ***** Responsive Styles ***** */