.loader-container {
	background: rgba(0, 0, 0, 0.025);
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 15px;
	position: relative;
	height: 250px;
}
.this-global-loader {
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%) rotate(0deg);
	animation: rotating 1s linear 0s infinite;
}

@keyframes rotating {
	from {
		transform: translate(-50%, -50%) rotate(0deg);
	}
	to {
		transform: translate(-50%, -50%) rotate(360deg);
	}
}
