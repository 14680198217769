/* This stylesheet generated by Transfonter (http://transfonter.org) on July 1, 2016 4:31 PM */
/*
@import url('https://vuela.aeromexico.com/whatsapp/fonttrans/stylesheet.css');

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('https://vuela.aeromexico.com/whatsapp/fonttrans/NeueHaasUnicaPro-Medium.eot');
	src: url('https://vuela.aeromexico.com/whatsapp/fonttrans/NeueHaasUnicaPro-Medium.eot?#iefix') format('embedded-opentype'),
		url('https://vuela.aeromexico.com/whatsapp/fonttrans/NeueHaasUnicaPro-Medium.woff') format('woff'),
		url('https://vuela.aeromexico.com/whatsapp/fonttrans/NeueHaasUnicaPro-Medium.ttf') format('truetype');
	font-weight: bolder;
	font-style: normal;
}

@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('https://vuela.aeromexico.com/whatsapp/fonttrans/NeueHaasUnicaPro-Medium.eot');
	src: url('https://vuela.aeromexico.com/whatsapp/fonttrans/NeueHaasUnicaPro-Medium.eot?#iefix') format('embedded-opentype'),
		url('https://vuela.aeromexico.com/whatsapp/fonttrans/NeueHaasUnicaPro-Medium.woff') format('woff'),
		url('https://vuela.aeromexico.com/whatsapp/fonttrans/NeueHaasUnicaPro-Medium.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}


@font-face {
	font-family: 'Neue Haas Unica Pro';
	src: url('https://vuela.aeromexico.com/whatsapp/fonttrans/NeueHaasUnicaPro-Regular.eot');
	src: url('https://vuela.aeromexico.com/whatsapp/fonttrans/NeueHaasUnicaPro-Regular.eot?#iefix') format('embedded-opentype'),
		url('https://vuela.aeromexico.com/whatsapp/fonttrans/NeueHaasUnicaPro-Regular.woff') format('woff'),
		url('https://vuela.aeromexico.com/whatsapp/fonttrans/NeueHaasUnicaPro-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
*/

.title-print {
  color: blue;
}

.wrapper-print {
  display: none;
}
.fontsWrapper{
	font-family: 'Neue Haas Unica Pro';
	/* font-family: Arial,Helvetica Neue,Helvetica,sans-serif;  */
}
.fontAMNewText{
	font-family: 'Neue Haas Unica Pro';
	/* font-family: Arial,Helvetica Neue,Helvetica,sans-serif;  */
}

button#print-button {
  position: relative;
}