.footerProfile__newsCard__img {
  visibility: hidden;
}
.footerProfile__newsCard__imageContainer {
  background-size: contain;
  width:44%;
  background-repeat: no-repeat;
  background-position: center;
  border-left: 1px solid #e5e5e5;
}

.modal-container-main{
  width:100%;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(0,0,0,.5);
  position: fixed;
  top:0;
  left: 0;
  z-index: 9999;
}

.container-footer-card-modal{
  /* background-color: white; */
  border-radius: 11px;
  width:95vw;
  max-width: 600px;
  /* height: 90vh; */
  min-height: 200px;
  max-height: 500px;
  overflow: hidden;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  position: absolute;
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  overflow-y:scroll;
}

.container-footer-card-modal .modal-footer-card-top{
  border-bottom: 1px solid #e5e5e5;
  width:100%;
  height: auto;
  overflow: hidden;
  padding: 20px 30px;
  background-color: rgba(255,255,2555,.8);
}
.container-footer-card-modal .modal-footer-card-content{
  margin: 0;
  padding: 0;
  border: 0;
  padding: 30px 30px 20px;
  background-color: white;
}
.container-footer-card-modal .modal-footer-card-top .modal-image-container{
  width:110px;
  height: 110px;
  float: left;
  margin-right: 20px;
  position: relative;
}
.container-footer-card-modal .modal-footer-card-top .modal-image-container img{
  border-radius: 50%;
  height: 100%;
  width: 100%;
  border:1px solid #e5e5e5;
  widows: 100%;
  object-fit: cover;
}

.container-footer-card-modal .modal-footer-card-top .modal-image-container .discountNumberImg{
  width:40%;
  height: 40%;
  background-color: #e91b2f;
  width:40px;
  height:40px;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  color: white;
  font-size: 12px;
  margin: 0;
  padding: 0;
  text-align: center;
  line-height: 40px;
  font-weight: 500;
  display: none;
}

.container-content-big-card .DonnotShowDiscount{
  display: none;
}

.container-content-big-card .ShowDiscount{
  display: block;
}

.DonnotShowDiscount .footerProfile__newsCard__information{
  display: block;
}

.DonnotShowDiscount .descountCircle{
  display: none;
}

.ShowDiscount .footerProfile__newsCard__information{
  display: none;
}

.container-footer-card-modal .modal-footer-card-top .modal-image-container .discountNumberImg.ShowDiscount{
  display: block;
}

.container-footer-card-modal .modal-footer-card-top .container-content-top-modal{
  float: left;
  width: calc(100% - 130px);
  height:auto;
  overflow: hidden;
  padding-top: 10px;
}
.container-footer-card-modal .modal-footer-card-top .container-content-top-modal h4{
  line-height: initial;
  font-weight: 600;
  margin: 0;
  padding: 0;
}
.container-footer-card-modal .modal-footer-card-top .container-content-top-modal h3{
  font-size: 24px;
  color: #0b2343;
  line-height: initial;
  margin: 0;
  padding: 0;
}
.container-footer-card-modal .modal-footer-card-top .container-content-top-modal p{
  font-weight: 400;
  color: #666;
  font-size: 14px;
  line-height: 1.4;
}

@media (max-height: 530px) {
  .container-footer-card-modal{
    height: 90vh;
    background-color: white;
  }
  .container-footer-card-modal .modal-footer-card-top .container-content-top-modal h3{
    font-size: 20px;
  }
}
