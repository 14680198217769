

.download {
	text-decoration: none;
	color: white;
	font-size: 16px;
	background: url('../../../assets/images/download-icon.svg');
	display: block;
	background-repeat: no-repeat;
	background-position: left;
	margin-top: 20px;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.download:hover {
	color: white;
	opacity: 0.5;
}

.download p {
	padding-left: 30px;
}
/*
<a className="download" href=""><p>Descagar Plan de Vuelo</p></a>

*/

.downloadFlightPlan {
	position: absolute;
	bottom: 60px;
	left: 0;
	background-color: white;
	border-radius: 8px;
	display: grid;
	grid-template-columns: 60px auto;
	grid-gap: 10px;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	cursor: pointer;
}

.downloadFlightPlan:hover {
	box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
}

.downloadFlightPlan .downloadIcon {
	width: 60px;
	height: 60px;
	background-image: url(../../../assets/pdfdoc.svg);
	background-size: contain;
	background-repeat: no-repeat;
	margin-top: 10px;
	margin-left: 7px;
}

.downloadFlightPlan .textdownload h6 {
	margin-top: 10px;
	margin-bottom: 0;
	display: table;
}

.downloadFlightPlan .textdownload p {
	font-size: 12px;
	color: #666;
	max-width: 200px;
	margin-top: 5px;
	padding-right: 20px;
	display: table;
}

/*
 <div className="downloadFlightPlan">
                <div className="downloadIcon"></div>
                <div className="textdownload">
                  <h6>Descarga el Plan de Vuelo 2019</h6>
                  <p>Nullam id dolor id nibh ultricies vehicula ut id elit. </p>
                </div>
              </div>

              */
.languageFlightPlan {
	width: auto;
	height: auto;
	position: absolute;
	top: 60px;
	right: 0;
	display: grid;
	grid-template-columns: auto 40px;
	grid-gap: 15px;
	padding: 10px;
	border-radius: 100px;
	cursor: pointer;
	text-decoration: none;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	background-color: rgba(255, 255, 255, 0.4);
	border: 1px solid rgba(255, 255, 255, 0.2);
}

.languageFlightPlan:hover {
	background-color: rgba(255, 255, 255, 0.2);
	border: 1px solid rgba(255, 255, 255, 0.5);
	box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
	transform: translateY(-4px);
}

.languageFlightPlan .languageFlag {
	width: 40px;
	height: 40px;
	display: block;
	background: url(../../../assets/images/mexico-flag.svg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	border-radius: 50%;
	background-color: white;
}
.languageFlightPlan .languageFlag.japan {
	background-image: url(../../../assets/images/japon-flag.svg);
}
.languageFlightPlan .languageFlag.korea {
	background-image: url(../../../assets/images/corea-flag.svg);
}
.languageFlightPlan .languageFlag.usa {
	background-image: url(../../../assets/images/usa-flag.svg);
}
.languageFlightPlan .languageFlag.francia {
	background-image: url(../../../assets/images/francia-flag.svg);
}
.languageFlightPlan .languageFlag.holandflag {
	background-image: url(../../../assets/images/holanda-flag.svg);
}
.languageFlightPlan .languageFlag.porflag {
	background-image: url(../../../assets/images/brazil-flag.svg);
}
.languageFlightPlan .languageFlag.chinaflag {
	background-image: url(../../../assets/images/china-flag.svg);
}

.languageFlightPlan p {
	color: white;
	text-align: right;
	line-height: 40px;
	margin-bottom: 0;
	margin-left: 10px;
}
/*
<div className="languageFlightPlan">
                <p>Español - México</p>
                <div className="languageFlag"></div>
              </div>
*/

.flightVideoModal {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	position: relative;
	z-index: 9999;
	background-color: rgba(0, 0, 0, 0.85);
	position: fixed;
	top: 0;
	left: 0;
}

.flightVideoModal .videoModalWindow {
	width: 90%;
	height: 53vw;
	background-color: black;
	border-radius: 11px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	box-shadow: 0 30px 60px rgba(0, 0, 0, 0.7);
	overflow: hidden;
	max-height: 600px;
	max-width: 1067px;
}

.closeModal {
	position: absolute !important;
	top: 20px;
	right: 20px;
	width: 40px !important;
	height: 40px !important;
	opacity: 0.8;
	cursor: pointer;
	z-index: 60;
	background-color: rgba(0, 0, 0, 0.2);
	border-radius: 50%;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	display: block;
}

.closeModal:hover {
	background-color: rgba(0, 0, 0, 0.4);
}

.closeModal img {
	width: 30px;
	height: 30px;
	margin: 0 auto;
	display: block;
	margin-top: 6px;
}

.flightplanWrapper {
	width: 100%;
	height: calc(100vh - 60px);
	overflow: hidden;
	background-color: #0d2b59;
	background-image: linear-gradient(
		to right top,
		#0b2343,
		#0a2c55,
		#083668,
		#043f7c,
		#004990
	);
	min-height: 800px;
	min-width: 320px;
}

.flightplanWrapper .midflightplan {
	max-width: 1400px;
	width: 90%;
	height: 100%;
	position: relative;
	margin: 0 auto;
	overflow: hidden;
}

.flightplanWrapper .midflightplan h1 {
	color: white;
	font-size: 52px;
	line-height: 1;
	margin-top: 60px;
}

.flightplanWrapper .midflightplan .circlePlaneFlight {
	width: 450px;
	height: 450px;
	position: absolute;
	top: 55%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 50%;
}

.flightplanWrapper .midflightplan .circlePlaneFlight .planebg {
	/* background-image: url(../../../assets/flightplan/bgcirclesflight.png); */
	background-image: url(../../../assets/plan-vuelo/circulo-es.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	width: 450px;
	height: 450px;
	transition: 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
	border-radius: 50%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
}

.flightplanWrapper .midflightplan .circlePlaneFlight .planebgjapan {
	background-image: url(../../../assets/flightplan/bgcirclesflightjapan.png);
}
.flightplanWrapper .midflightplan .circlePlaneFlight .planebgusa {
	/* background-image: url(../../../assets/flightplan/bgcirclesflightusa.png); */
	background-image: url(../../../assets/plan-vuelo/circulo-en.png);
}
.flightplanWrapper .midflightplan .circlePlaneFlight .planebgfrance {
	background-image: url(../../../assets/flightplan/bgcirclesflightfr.png);
}
.flightplanWrapper .midflightplan .circlePlaneFlight .planebgholanda {
	background-image: url(../../../assets/flightplan/bgcirclesflightholanda.png);
}
.flightplanWrapper .midflightplan .circlePlaneFlight .planebgpt {
	background-image: url(../../../assets/flightplan/bgcirclesflightpt.png);
}
.flightplanWrapper .midflightplan .circlePlaneFlight .planebgchina {
	background-image: url(../../../assets/flightplan/bgcirclesflightchino.png);
}
.flightplanWrapper .midflightplan .circlePlaneFlight .planebgKorea {
	background-image: url(../../../assets/flightplan/bgcirclesflightkorea.png);
}

.flightplanWrapper .midflightplan .circlePlaneFlight:hover .planebg {
	transform: scale(1.07, 1.07);
	/*box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5);*/
}

.flightplanWrapper .midflightplan .circlePlaneFlight .planeitem {
	background-image: url(../../../assets/flightplan/planeitem.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	width: 450px;
	height: 450px;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	border-radius: 50%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
}

.flightplanWrapper .midflightplan .circlePlaneFlight:hover .planeitem {
	transform: scale(1.6);
}

.cutomerExperience {
	color: white;
	text-align: left;
	position: absolute;
	right: -362px;
	font-size: 21px;
	line-height: 1.3;
	font-weight: 300;
	top: 20%;
	width: 300px;
}

/* .cutomerExperience span {
	width: 30px;
	height: 30px;
	display: block;
	position: absolute;
	left: -40px;
	transform: translate(-50%, -0%);
	top: 7px;
	background-image: url(../../../assets/flightplan/orangeflight.svg);
} */

.employeeExperience {
	color: white;
	text-align: right;
	position: absolute;
	left: -319px;
	font-size: 21px;
	line-height: 1.3;
	font-weight: 300;
	top: 20%;
	width: 261px;
}

/* .employeeExperience span {
	width: 30px;
	height: 30px;
	display: block;
	position: absolute;
	right: -70px;
	transform: translate(-50%, -0%);
	top: 7px;
	background-image: url(../../../assets/flightplan/blueflight.svg);
} */

.aerolineaFavorita {
	color: white;
	text-align: right;
	position: absolute;
	line-height: 1.3;
	font-weight: 300;
	font-size: 21px;
	right: -364px;
	bottom: 20%;
	width: 300px;
	text-align: left;
}

.crearEficiencia {
	color: white;
	text-align: right;
	position: absolute;
	left: -352px;
	font-size: 21px;
	line-height: 1.3;
	font-weight: 300;
	bottom: 15%;
	width: 310px;
}

/* .crearEficiencia span {
	width: 30px;
	height: 30px;
	display: block;
	position: absolute;
	right: -70px;
	transform: translate(-50%, -0%);
	top: 7px;
	background-image: url(../../../assets/flightplan/yellowflight.svg);
} */

.ejecucionImpecable {
	color: white;
	text-align: center;
	position: absolute;
	right: 50%;
	font-size: 21px;
	line-height: 1.3;
	font-weight: 300;
	bottom: -25%;
	width: 300px;
	transform: translateX(50%);
}

span.arrow {
	width: 0;
	height: 0;
	/* border-radius: 20px; */
	position: absolute;
}

span.arrow.down {
	border-left: 13px solid transparent;
	border-right: 13px solid transparent;
	border-top: 13px solid #009dc5;
	left: 50%;
	transform: translateX(-50%);
	bottom: -30px;
}

span.arrow.upper {
	border-left: 13px solid transparent;
	border-right: 13px solid transparent;
	border-bottom: 13px solid #e91b2f;
	left: 50%;
	transform: translateX(-50%);
	top: -20px;
}

span.arrow.right.top {
	border-top: 13px solid transparent;
	border-bottom: 13px solid transparent;
	border-left: 13px solid #009aff;
	right: -30px;
	transform: translateY(-50%);
	top: 50%;
}

span.arrow.right.bottom {
	border-top: 13px solid transparent;
	border-bottom: 13px solid transparent;
	border-left: 13px solid #fecb2f;
	right: -30px;
	transform: translateY(-50%);
	top: 50%;
}

span.arrow.left.top {
	border-top: 13px solid transparent;
	border-bottom: 13px solid transparent;
	border-right: 13px solid #f97722;
	left: -30px;
	transform: translateY(-50%);
	top: 50%;
}
span.arrow.left.bottom {
	border-top: 13px solid transparent;
	border-bottom: 13px solid transparent;
	border-right: 13px solid #4eac5b;
	left: -30px;
	transform: translateY(-50%);
	top: 50%;
}

/* .ejecucionImpecable span {
	width: 30px;
	height: 30px;
	display: block;
	position: absolute;
	left: -40px;
	transform: translate(-50%, -0%);
	top: 7px;
	background-image: url(../../../assets/flightplan/redflight.svg);
} */

.securityFirst {
	color: white;
	text-align: left;
	position: absolute;
	font-size: 21px;
	line-height: 1.3;
	font-weight: 300;
	top: -18%;
	left: 50%;
	display: table;
	transform: translate(-50%, -0%);
	width: 100%;
	text-align: center;
}

/* .securityFirst span {
	width: 30px;
	height: 30px;
	display: block;
	position: absolute;
	left: 50%;
	transform: translate(-50%, -0%);
	bottom: -40px;
	background-image: url(../../../assets/flightplan/greenflight.svg);
} */

.flightplanWrapper .midflightplan .circlePlaneFlight p strong {
	font-weight: 600;
	opacity: 1;
}

.circlePlaneFlight .title {
	display: none;
}

.linenamebottom {
	display: none;
}

 

.selectLangugeModal .whiteModal {
	width: 600px;
	height: auto;
	overflow: hidden;
	background-color: white;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 11px;
	max-width: 600px;
	margin-top: 60%;
}
.selectLangugeModal .whiteModal .padding {
	padding: 40px 20px 50px;
}
.selectLangugeModal .whiteModal .padding h4 {
	font-size: 32px;
	text-align: center;
	font-weight: 500;
	color: #002b5c;
}

.selectLangugeModal .whiteModal .padding .gridFlags {
	width: 100%;
	height: auto;
	overflow: visible;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 40px;
	align-items: center;
	justify-items: center;
	margin: 0 auto;
	margin-top: 30px;
	max-width: 450px;
}

.selectLangugeModal .whiteModal .padding .gridFlags .flags {
	width: 80px;
	height: 80px;
	border: 1px solid #e5e5e5;
	border-radius: 50%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	cursor: pointer;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	background-color: rgba(0, 125, 195, 0.08);
}

.selectLangugeModal .whiteModal .padding .gridFlags p {
	text-align: center;
	margin-top: 10px;
	color: #002b5c;
}

.selectLangugeModal .whiteModal .padding .gridFlags .flags:hover {
	transform: translateY(-3px);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	border: 1px solid rgba(0, 125, 195, 1);
}

.selectLangugeModal .whiteModal .padding .gridFlags .flags.spanish {
	background-image: url(../../../assets/images/mexico-flag.svg);
}
.selectLangugeModal .whiteModal .padding .gridFlags .flags.portuguese {
	background-image: url(../../../assets/images/brazil-flag.svg);
}
.selectLangugeModal .whiteModal .padding .gridFlags .flags.english {
	background-image: url(../../../assets/images/usa-flag.svg);
}
.selectLangugeModal .whiteModal .padding .gridFlags .flags.french {
	background-image: url(../../../assets/images/francia-flag.svg);
}
.selectLangugeModal .whiteModal .padding .gridFlags .flags.dutch {
	background-image: url(../../../assets/images/holanda-flag.svg);
}
.selectLangugeModal .whiteModal .padding .gridFlags .flags.mandarin {
	background-image: url(../../../assets/images/china-flag.svg);
}
.selectLangugeModal .whiteModal .padding .gridFlags .flags.korean {
	background-image: url(../../../assets/images/corea-flag.svg);
}
.selectLangugeModal .whiteModal .padding .gridFlags .flags.japan {
	background-image: url(../../../assets/images/japon-flag.svg);
}

.contFlag {
	text-decoration: none;
	display: block;
	padding: 0;
	margin: 0;
}

/*
<div className="selectLangugeModal">
            <div className="whiteModal">
              <div className="padding">
                <h4>Selecciona un idioma</h4>
                <div className="gridFlags">
                  <div className="flags spanish"></div>
                  <div className="flags english"></div>
                  <div className="flags french"></div>
                  <div className="flags dutch"></div>
                  <div className="flags portuguese"></div>
                  <div className="flags mandarin"></div>
                  <div className="flags korean"></div>
                  <div className="flags japan"></div>
                </div>
              </div>
            </div>
          </div>
          */

@media (max-width: 1310px) {
	.flightplanWrapper .midflightplan .circlePlaneFlight,
	.flightplanWrapper .midflightplan .circlePlaneFlight .planeitem,
	.flightplanWrapper .midflightplan .circlePlaneFlight .planebg {
		width: 350px;
		height: 350px;
	}

	.securityFirst {
		width: 100%;
		top: -22%;
		font-size: 20px;
	}
	.cutomerExperience {
		font-size: 20px;
		right: -308px;
		width: 250px;
	}
	.ejecucionImpecable {
		font-size: 20px;
		right: 50%;
		width: 288px;
		bottom: -110px;
	}
	.crearEficiencia {
		font-size: 20px;
		left: -350px;
		width: 290px;
	}
	.employeeExperience {
		font-size: 20px;
		left: -277px;
		width: 217px;
	}
}

@media (max-width: 1022px) {
	.securityFirst {
		width: 100%;
		top: -22%;
		font-size: 16px;
		text-align: center;
		width: 200px;
	}
	.cutomerExperience {
		font-size: 16px;
		right: -253px;
		width: 200px;
	}
	.ejecucionImpecable {
		font-size: 16px;
		right: 50%;
		width: 200px;
	}
	.crearEficiencia {
		font-size: 16px;
		left: -239px;
		width: 200px;
	}
	.employeeExperience {
		font-size: 16px;
		left: -227px;
		width: 180px;
	}
}

@media (max-width: 896px) {
	.flightplanWrapper .midflightplan .circlePlaneFlight,
	.flightplanWrapper .midflightplan .circlePlaneFlight .planeitem,
	.flightplanWrapper .midflightplan .circlePlaneFlight .planebg {
		position: relative;
		width: 270px;
		height: 270px;
	}
}

@media (max-width: 889px) {
	.flightplanWrapper .midflightplan .circlePlaneFlight p strong {
		font-weight: 500;
	}
	.ejecucionImpecable,
	.crearEficiencia,
	.employeeExperience,
	.cutomerExperience,
	.securityFirst,
	.aerolineaFavorita {
		position: relative;
		text-align: left;
		max-width: initial;
		left: auto;
		top: auto;
		right: auto;
		bottom: auto;
		width: 300px;
		margin-bottom: 25px;
		display: block;
		margin-top: 5px;
		font-size: 16px;
	}
	.planeholder {
		display: block;
		margin-top: 310px;
		position: relative;
	}
	.planeholder .planeitem,
	.planeholder .planebg {
		position: absolute !important;
	}

	.ejecucionImpecable {
		transform: translateX(0);
	}

	.flightplanWrapper .midflightplan .circlePlaneFlight,
	.flightplanWrapper .midflightplan .circlePlaneFlight {
		position: relative;
		width: 100%;
		height: auto;
		margin: 0 auto;
		overflow: hidden;
		border-radius: 0;
		top: initial;
		left: initial;
		transform: translate(0, 0);
		padding-bottom: 285px;
	}
	/* .circlePlaneFlight p span {
		display: none;
	} */
	span.arrow {
		display: none;
	}
	.securityFirst {
		display: block;
		transform: translate(0, 0);
	}
	.flightplanWrapper .midflightplan .circlePlaneFlight .planeitem,
	.flightplanWrapper .midflightplan .circlePlaneFlight .planebg {
		bottom: 0;
		top: initial;
	}
	.flightplanWrapper .midflightplan h1 {
		font-size: 42px;
	}

	.circlePlaneFlight .title {
		display: block;
		color: white;
		margin-top: 10px;
		margin-bottom: 0;
		font-size: 20px;
	}

	.circlePlaneFlight .title.color01 {
		color: #009dc5;
	}
	.circlePlaneFlight .title.color02 {
		color: #009aff;
	}
	.circlePlaneFlight .title.color03 {
		color: #f97722;
	}
	.circlePlaneFlight .title.color04 {
		color: #fecb2f;
	}
	.circlePlaneFlight .title.color05 {
		color: #4eac5b;
	}
	.circlePlaneFlight .title.color06 {
		color: #e91b2f;
	}

	.languageFlightPlan p {
		display: none;
	}

	.languageFlightPlan {
		width: 52px;
		top: 45px;
	}
	.languageFlightPlan .languageFlag {
		width: 30px;
		height: 30px;
	}
	.flightplanWrapper {
		height: auto;
		overflow: hidden;
		width: 100%;
	}
	.flightplanWrapper .midflightplan {
		padding-bottom: 140px;
	}
	.linenamebottom {
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
		display: block;
		width: 100%;
		height: 1px;
		margin-bottom: 20px;
	}
	.flightplanWrapper .midflightplan h1 {
		margin-bottom: 0;
		margin-top: 40px;
	}
	.planeitem,
	.planebg {
		position: relative;
	}
}

@media (max-width: 383px) {
	.flightplanWrapper .midflightplan h1 {
		font-size: 32px;
	}
}

@media (max-width: 600px) {
	.selectLangugeModal .whiteModal .padding .gridFlags .flags {
		width: 55px;
		height: 55px;
		margin: 0 auto;
	}
	.selectLangugeModal .whiteModal .padding .gridFlags {
		grid-gap: 16px;
	}
	.selectLangugeModal .whiteModal .padding h4 {
		font-size: 24px;
	}
}

@media (max-width: 428px) {
	.selectLangugeModal .whiteModal .padding .gridFlags {
		grid-gap: 10px;
	}
	.selectLangugeModal .whiteModal .padding .gridFlags p {
		font-size: 12px;
	}
	.selectLangugeModal .whiteModal .padding .gridFlags .flags {
		width: 45px;
		height: 45px;
	}
	.selectLangugeModal .whiteModal .padding {
		padding: 30px 11px 20px;
	}
	.selectLangugeModal .whiteModal .padding h4 {
		font-size: 18px;
	}
	.closeModal {
		top: 15px;
		right: 15px;
	}
	.selectLangugeModal .whiteModal {
		height: auto;
		bottom: 10px;
		top: initial;
		width: 95%;
		transform: translate(-50%, 0%);
		border-radius: 30px;
	}
	.selectLangugeModal .whiteModal .padding .gridFlags {
		grid-template-columns: repeat(3, 1fr);
		margin-top: 35px;
		grid-gap: 2px;
	}
	.selectLangugeModal .whiteModal .padding .gridFlags .flags {
		width: 55px;
		height: 55px;
	}
	.selectLangugeModal .whiteModal .padding .gridFlags p {
		font-size: 14px;
	}
	.selectLangugeModal .whiteModal .padding h4 {
		font-size: 24px;
	}
}
