.swiperFirst {
	overflow: hidden;
	/* height: calc(45vh + 60px); */
	height: 45vh;
	min-height: 470px;
	/* margin-top:-60px; */
	margin-top: 0;
	background-color: #f5f5f5;
	/* padding-top: 60px; */
}

.swiper-container {
	height: 100%;
}

.img-slider {
	width: 100%;
}

.SwipeWrapApp {
	width: 100%;
	height: 100%;
}

.SwipeCenterCell {
	display: table-cell;
	vertical-align: middle;
	width: 100%;
	height: 100%;
}

.VideoFilter {
	height: 100%;
	width: 100%;
	z-index: -3;
	position: absolute;
	top: 0;
	overflow: hidden;
}

video {
	/*  making the video fullscreen  */
	position: absolute;
	left: 0;
	bottom: 0;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	z-index: 3;
}

.VideoFilter video {
	position: absolute;
	left: 0;
	bottom: 0;
	min-width: auto;
	min-height: auto;
	width: 100%;
	height: auto;
	z-index: 3;
}

@media (max-width: 414px) {
	.SwipeWrapApp {
		height: 100%;
	}
}

.SwipeWrap {
	height: 60vh;
	width: 100%;
	overflow: hidden;
	position: relative;
	min-height: 600px;
	max-height: 800px;
	padding-top: 60px;
	display: block;
}

.Swiperfilter {
	background: #020c41;
	/* height: calc(100% + 60px); */
	height: 100%;
	position: absolute;
	/* top: -60px; */
	top: 0;
	z-index: -1;
	width: 100%;
	opacity: 0.65;
}

.BackFilterBlur {
	height: 100%;
	width: 100%;
	z-index: -2;
	position: absolute;
	top: 0;
}

.VideoBack {
	position: absolute;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	z-index: -3;
	transform: translateX(-50%) translateY(-50%);
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.SwiperImage {
	/* height: calc(110% + 60px); */
	height: 110%;
	position: absolute;
	/* top: -60px; */
	top: 0;
	z-index: -4;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	object-fit: cover;
	width: 100%;
}

.SwiperImage.noDesktop {
	display: none;
}

.SwiperImage.noMobile {
	display: block;
}

.SwipeCenter {
	max-width: 1400px;
	width: 90%;
	margin: 0 auto;
	display: table;
	height: 100%;
}

.SwipeCell {
	display: table-cell;
	vertical-align: middle;
	padding-right: 50%;
}

.height {
	height: 100%;
}

.TagSwipe {
	font-size: 14px;
	color: white;
	font-weight: 600;
	padding: 0;
	margin: 0;
	margin-top: 15px;
}

.TitleSwipe {
	font-size: 48px;
	font-weight: 600;
	color: white;
	margin: 0;
	padding: 0;
	line-height: 1.1;
	max-width: 53%;
}

.IntroSwipe {
	font-size: 20px;
	font-weight: 300;
	color: white;
	margin: 0;
	padding: 0;
	line-height: 1.4;
	margin-top: 14px;
	max-width: 47%;
}

.ButtonSwipe {
	border-radius: 50px;
	padding: 22px 50px 23px;
	font-size: 14px;
	margin-top: 10px;
	text-transform: uppercase;
	font-weight: 500;
	background-color: #fa0073;
	border: 1px solid #fa0073;
	color: white;
	cursor: pointer;
	-webkit-appearance: none;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	opacity: 1;
	animation: textanimating 3s 0.6s forwards cubic-bezier(0.2, 0.8, 0.2, 1);
}

.SwipeWrap:hover .ButtonSwipe {
	box-shadow: 0 8px 8px rgba(0, 0, 0, 0.25);
}

.ButtonSwipe:hover {
	transform: translateY(-4px);
	border: 1px solid #fff;
	background-color: rgba(11, 35, 67, 0.2);
	color: #fff;
}

.WrapperTextSwipe {
	align-self: end;
	margin-bottom: 10px;
}

.SwipperAppWrap {
	width: 100%;
	height: auto;
	display: none;
	overflow: hidden;
	position: relative;
	display: grid;
	box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
	margin-bottom: 30px;
}

.blue-arrow {
	width: 20px;
	margin-left: 4px;
}

.slider-read {
	width: 153px;
	height: 50px;
	background: rgba(255, 255, 255, 0.1);
	border: 1px solid rgba(255, 255, 255, 0.5);
	border-radius: 50px;
	color: #ffffff;
	text-align: center;
	padding: 10px;
	display: inline-block;
	margin-top: 25px;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	cursor: pointer;
	text-decoration: none;
}

.slider-read a {
	color: white;
	text-decoration: none;
	font-size: 14px;
	font-weight: 600;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.slider-read:hover {
	width: 153px;
	height: 50px;
	background: #fa0073;
	border-radius: 50px;
	color: #ffffff;
	text-align: center;
	padding: 10px;
	display: inline-block;
	transform: translateY(-4px);
}

.slider-read:hover a {
	color: #fff;
}

.SwipeHome .swiper-container.swiper-container-horizontal {
	overflow: hidden;
}

@media (max-width: 1366px) {
	.IntroSwipe,
	.TitleSwipe {
		max-width: 50%;
	}
	ol.buttonsLinks {
		grid-gap: 20px;
	}
	.TitleSwipe {
		font-size: 42px;
	}
}

@media (max-width: 1044px) {
	.SwipeHome {
		height: 50vh;
		min-height: inherit;
		margin-top: 0;
	}
}

@media (max-width: 800px) {
	.swiperFirst {
		height: 55vh;
		margin-top: 0;
		min-height: 400px;
	}
	.SwipeCenterCell {
		vertical-align: bottom;
		padding-bottom: 70px;
	}
	.LatestWrap .sub-menu-container {
		display: none;
	}
	.swiper-button-prev,
	.swiper-button-next {
		display: none;
	}
	video {
		display: none;
	}

	.SwiperImage.noDesktop {
		display: block;
	}
	.SwiperImage.noMobile {
		display: none;
	}
	.ArticleTitle p {
		height: 37px;
		margin-bottom: 0;
	}
	.modal-notification {
		/* height: 50vh; */
		min-width: initial;
		min-height: initial;
		max-width: initial;
		max-height: initial;
		/* transform: translate(-50%, 50%); */
		bottom: initial;
		top: initial;
	}
	.modalPadding {
		padding: 20px 30px;
	}
	.HomeAlertMid p {
		text-align: center;
		float: none;
		margin-bottom: 0;
		margin-top: 0;
		margin-left: auto;
		margin-right: auto;
		line-height: initial;
	}
	.HomeAlertMid {
		width: 100%;
		margin: 0 auto;
	}
	.HomeAlertMid span {
		line-height: initial;
		margin: 0;
		float: left;
		display: none;
	}
	.alertScrolled {
		display: block;
	}
	.alertScrolled .HomeAlertWrap {
		top: -150px;
	}
	.ArticleImageWrap {
		z-index: 1;
	}
	.TagSwipe {
		margin-bottom: 10px;
		text-align: center;
		font-weight: 400;
	}
	.IntroSwipe {
		display: none;
	}
	.slider-read {
		background: #fa0073;
		border: 1px solid #fa0073;
	}
	.swiperFirst .SwipeCenter .slider-read {
		margin: 0 auto;
		margin-top: 20px;
		display: table;
		height: 40px;
		padding-top: 6px;
	}
	.swiper-pagination {
		display: block;
	}
	.slider-read:hover {
		transform: translateY(0px);
	}
}

.bigTitleSlider {
	font-size: 67px !important;
	line-height: 0.9 !important;
}
