.comunicadosPage {
	background: #0b2342;
	min-height: 50vh;
	padding: 70px 0;
}

.comunicadosPage h1 {
	color: white;
	font-size: 30px;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
	width: 100%;
}

.comunicadosPage .iframe-holder-container {
	box-shadow: 0 20px 10px -10px rgba(0, 0, 0, 0.25);
	margin: 0 auto;
	max-width: 700px;
}

.comunicadosPage .video-container {
	overflow: hidden;
	padding-top: 56.25%;
	position: relative;
}

.comunicadosPage .video-container iframe {
	border: 0;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}
