.imoportantMesaggePrestacion {
	width: 100%;
	height: auto;
	overflow: hidden;
	background-color: #67aa5e;
	margin-bottom: 17px;
	border-radius: 7px;
	padding: 10px 20px;
}
.imoportantMesaggePrestacion.flex {
	display: flex;
	display: -webkit-flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: row;
}
.termsVentaEspecialConsulta {
	/* background-color: rgba(0, 125, 195, 0.05); */
	background-color: #020c41;
	padding: 10px 20px;
	text-align: center;
	border-radius: 100px;
	/* color: #020C41; */
	color: white;
	font-weight: 400;
	font-size: 16px;
	margin-top: 45px;
	margin-bottom: 0;
}
.termsVentaEspecialConsulta span {
	color: #67aa5e;
	font-weight: 500;
}
.imoportantMesaggePrestacion.yellow {
	background-color: #ffe873;
}
.imoportantMesaggePrestacion.yellow p,
.imoportantMesaggePrestacion.blue p {
	color: #020c41;
	margin-top: 0;
}
.imoportantMesaggePrestacion.yellow p a,
.imoportantMesaggePrestacion.blue p a {
	color: #035cf7;
	font-weight: 700;
	font-size: 13px;
	/* text-decoration: underline; */
}
.imoportantMesaggePrestacion.blue {
	/* background-color: #030d75; */
	background: #0000e3;
	color: black;
}
.imoportantMesaggePrestacion p a {
	color: #ffd773;
	font-weight: 700;
	font-size: 13px;
	/* text-decoration: underline; */
}
.imoportantMesaggePrestacion.yellow p span.fakeAnchor {
	color: #035cf7;
	cursor: pointer;
	font-weight: 500;
	font-size: 13px;
	/* text-decoration: underline; */
}
.imoportantMesaggePrestacion.yellow .iconImpornt {
	margin-top: 6px;
}
.imoportantMesaggePrestacion .iconImpornt {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	float: left;
	background-image: url(../../../assets/travel/important.svg);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}
.imoportantMesaggePrestacion .iconImpornt.green {
	background-image: url(../../../assets/travel/green.svg);
}

.imoportantMesaggePrestacion p {
	color: white;
	font-size: 13px;
	float: left;
	padding: 0;
	margin: 0;
	margin-left: 15px;
	margin-top: 3px;
	width: calc(100% - 50px);
}
/**

<div className="imoportantMesaggePrestacion">
          <div className="iconImpornt"></div>
          <p>En el caso de que no cuentes con la nueva prestación, toma en cuenta esta información para cuando llegue tu renovación.</p>
        </div>


        **/

.downloadPolitica {
	color: #020c41;
	text-align: center;
	font-size: 14px;
	border-radius: 4px;
	background-color: rgba(0, 125, 195, 0.07);
	padding: 10px 0;
	cursor: pointer;
	text-decoration: none;
}
.okBenefitsChange {
	width: 100%;
	display: flex;
}
.totalLeftBenefitsContacts {
	float: left;
	width: 30%;
	padding-left: 30px;
	padding-bottom: 50px;
}

.totalLeftBenefitsContacts .fixedBoletosEmbargo {
	position: sticky;
	top: 170px;
	height: auto;
	margin-top: 30px;
}

.totalLeftBenefitsContacts .acceslinks {
	width: 100%;
	height: auto;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
	margin-top: 25px;
	/* overflow: hidden; */
}

.boletosEmbargo {
	box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
	background-color: white;
	border-radius: 8px;
	margin-top: 110px;
	height: auto;
	overflow: hidden;
	cursor: pointer;
	position: relative;
}

.boletosEmbargo a {
	border-bottom: 1px solid #e5e5e5;
	height: auto;
	overflow: hidden;
	display: block;
	position: relative;
	cursor: pointer;
}

.boletosEmbargo .ticketsText {
	color: white;
	padding: 20px;
	padding-left: 20px;
	bottom: 0;
	z-index: 3;
	float: left;
	padding-left: 10px;
	width: calc(100% - 58px);
	height: 60px;
}

.boletosEmbargo .ticketsText.iconTextnoNecesario {
	height: auto;
	width: calc(100% - 94px);
}

.boletosEmbargo .ticketsText.iconTextnoNecesario p {
	margin: 0;
	padding: 0;
	float: none;
}
.boletosEmbargo .ticketsText.iconTextnoNecesario p {
	color: #666;
	font-size: 12px;
}
.boletosEmbargo .ticketsText.iconTextnoNecesario p:nth-child(1) {
	color: #020c41;
	font-size: 14px;
}

.boletosEmbargo img.icon.marginTopIconNew {
	margin-top: 24px;
}

.boletosEmbargo img.icon {
	height: 35px;
	float: left;
	margin-top: 12px;
	margin-left: 20px;
}

.boletosEmbargo a {
	width: 100%;
	height: 100%;
}

.boletosEmbargo img.arrow {
	width: 20px;
	height: 20px;
	position: absolute;
	top: 50%;
	right: 20px;
	z-index: 1;
	object-fit: cover;
	display: block;
	-webkit-transform: translate(-0%, -50%);
	transform: translate(-0%, -50%);
}

.boletosEmbargo p {
	font-size: 14px;
	font-weight: 500;
	color: #020c41;
	float: left;
	margin-top: -1px;
}

.boletosEmbargo p.small {
	float: none;
}

.totalLeftBenefitsContacts .acceslinks .title {
	width: 100%;
	height: 50px;
	border-bottom: 1px solid #d6d6d6;
	background-color: white;
	z-index: 5;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}

.totalLeftBenefitsContacts .acceslinks .title p {
	font-size: 14px;
	text-transform: uppercase;
	margin: 0;
	padding: 0;
	line-height: 50px;
	font-weight: 500;
	padding-left: 20px;
	letter-spacing: 0.7px;
}

.totalLeftBenefitsContacts .acceslinks .cardWrapperBoletos {
	width: 100%;
	height: auto;
	margin: 0 auto;
	margin-bottom: 0;
	position: relative;
	overflow: hidden;
	border-bottom: 1px solid #e5e5e5;
}

.totalLeftBenefitsContacts .acceslinks .cardWrapperBoletos .ticketsText {
	color: white;
	padding: 20px;
	bottom: 0;
	z-index: 3;
	float: left;
	padding-left: 10px;
	width: calc(100% - 58px);
}

.totalLeftBenefitsContacts .acceslinks .cardWrapperBoletos .ticketsText p:nth-child(1) {
	font-size: 14px;
	font-weight: 500;
	margin: 0;
	padding: 0;
	display: table;
	border-radius: 4px;
	display: block;
	color: #020c41;
}

.totalLeftBenefitsContacts .acceslinks .cardWrapperBoletos .ticketsText p:nth-child(2) {
	font-size: 12px;
	font-weight: 400;
	opacity: 0.7;
	margin: 0;
	padding: 0;
	display: none;
	color: #666;
	padding-right: 30px;
}

.totalLeftBenefitsContacts .acceslinks a:nth-child(4) .ticketsText p:nth-child(2),
.totalLeftBenefitsContacts .acceslinks a:nth-child(3) .ticketsText p:nth-child(2) {
	display: block;
	padding-left: 0;
	padding-top: 0;
}

.totalLeftBenefitsContacts .acceslinks .cardWrapperBoletos img.arrow {
	width: 20px;
	height: 20px;
	position: absolute;
	top: 50%;
	right: 20px;
	z-index: 1;
	object-fit: cover;
	display: block;
	transform: translate(-0%, -50%);
}

.totalLeftBenefitsContacts .acceslinks .cardWrapperBoletos img.icon {
	height: 35px;
	float: left;
	margin-top: 20px;
	margin-left: 20px;
}

.totalLeftBenefitsContacts a {
	display: block;
	text-decoration: none;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.totalLeftBenefitsContacts a:hover {
	background-color: rgba(0, 125, 195, 0.07);
}

.totalLeftBenefitsContacts .acceslinks a:nth-child(2) .cardWrapperBoletos img.icon {
	margin-top: 15px;
}

.totalLeftBenefitsContacts .acceslinks a:nth-child(3) .cardWrapperBoletos img.icon {
	margin-top: 15px;
}

/**
<div className="acceslinks">
              <div className="title"><p>Tramita tus boletos</p></div>

              <a href="#">
              <div className="cardWrapperBoletos">
                <div className="ticketsText">
                  <p>Autoservicio CORE</p>
                  <p>Adquiere tu prestación a través de CORE</p>
                </div>
                <img src={}></img>
              </div>
              </a>

              <a href="#">
              <div className="cardWrapperBoletos">
                <div className="ticketsText">
**/

.TravelCompanion {
	width: 100%;
	height: auto;
	background-color: white;
	border-radius: 7px;
	/*border: 1px solid #e5e5e5;*/
	/*box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);*/
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	position: relative;
	margin-right: 30px;
	/* border: 2px solid white; */
	cursor: pointer;
	display: block;
	text-decoration: none;
	overflow: hidden;
	display: flex;
}

.TravelCompanionCell:hover {
	background-color: rgba(0, 125, 195, 0.04);
}

.imageBGTravel {
	width: 60%;
	height: 198%;
	position: absolute;
	top: 54%;
	right: -24px;
	background-image: url(../../../assets/images/travelcompanion.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	-webkit-transform: translate(0%, -50%);
	transform: translate(0%, -50%);
	display: none;
}

.TravelCompanionCell {
	width: 50%;
	height: auto;
	/* padding: 20px 35px; */
	float: left;
	border-right: 1px solid #e5e5e5;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	display: grid;
	grid-template-rows: repeat(3, auto);
}

.TravelCompanion .TravelCompanionCell:nth-child(3) {
	border-right: none;
}

.TravelCompanionCell h1 {
	margin: 0;
	height: 150px;
	width: 100%;
}

.TravelCompanionCell h1 p {
	float: left;
	width: calc(60% - 25px);
	color: #020c41;
	font-weight: 500;
	font-size: 18px;
	margin: 0;
	padding: 0;
	line-height: 1.3;
	margin-top: 15px;
}

.TravelCompanionCell h1 span {
	width: 40%;
	height: 100%;
	display: block;
	float: left;
	margin-right: 15px;
	background-image: url(../../../assets/monitos.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.TravelCompanion .TravelCompanionCell:nth-child(3) h1 span {
	background-image: url(../../../assets/sujeto-espacio.jpg);
}

.TravelCompanionCell p {
	color: #666666;
	font-size: 12px;
	line-height: 120%;
	margin-left: 0px;
	margin-bottom: 5px;
	color: #020c41;
	margin: 0;
	margin-left: 10px;
	margin-top: 10px;
	font-size: 13px;
	line-height: 1.5;
	opacity: 0.8;
	width: 100%;
	line-height: 1.3em;
	margin: 0 0 1em 0;
	color: #666;
	margin: 0;
	margin-top: 10px;
	font-size: 14px;
	line-height: 1.5;
	max-width: 326px;
}

.TravelCompanionCell .totalBenefitsTravel__card__more {
	margin-left: 0;
}

.travel-benefit-cards {
	display: flex;
	flex-wrap: wrap;
}

.totalBenefitsMidWrapper {
	width: 70%;
	height: auto;
	float: left;
}

.benefits-big-card.bg-container-card.travel {
	width: 32%;
	margin: 5px;
	position: relative;
	padding: 20px;
}
.link-travel-benefits {
	position: absolute;
	bottom: 0;
}
a.link-travel-benefits {
	padding-bottom: 10px;
}

.totalBenefitsTravel__container {
	box-sizing: border-box;
	margin: 0;
	margin-bottom: 40px;
	border: 0;
	margin-top: 20px;
}

.totalBenefitsTravel__title__container {
	margin: 20px 0;
}

.totalBenefitsTravel__title__container h1 {
	font-size: 24px;
}

.totalBenefitsTravel__cards__container {
	margin: auto;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 30px;
	justify-items: center;
}

.totalBenefisTravel__card {
	width: 100%;
	border-radius: 10px;
	margin: 10px 0px;
	display: grid;
	grid-template-rows: repeat(4, auto);
	height: 100%;
}

.totalBenefitsTravel__card__image {
	height: 120px;
	background-image: url(../../../assets/images/profile/travel-benefits-01.jpg);
	background-size: cover;
	border-radius: 10px;
	margin-bottom: 7px;

	height: 140px;
	background-size: cover;
	border-radius: 10px;
	margin-bottom: 10px;
	overflow: hidden;
	box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
}
.totalBenefisTravel__card.upgradePremier .totalBenefitsTravel__card__image {
	background-image: url(../../../assets/images/profile/travel-benefits-01.jpg);
}
.totalBenefisTravel__card.seatsfree .totalBenefitsTravel__card__image {
	background-image: url(../../../assets/images/profile/travel-benefits-02.jpg);
}
.totalBenefisTravel__card.baggagefree .totalBenefitsTravel__card__image {
	background-image: url(../../../assets/images/profile/travel-benefits-03.jpg);
}

.totalBenefisTravel__card a.travel-link {
	align-self: end;
}

.totalBenefitsTravel__card__image:hover {
	-webkit-transform: scale(1.1, 1.1);
	transform: scale(1.1, 1.1);
	/* box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5); */
}
.modalWindowAncillaries {
	position: fixed;
	max-width: 500px;
	width: 95vw;
	min-height: 400px;
	border-radius: 11px;
	box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 2 5px 10px 0px rgba(0, 0, 0, 0.1);
	-o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
	-ms-box-shadow: 0 5px 1;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	overflow-y: scroll;
	max-height: 700px;
	min-height: 200px;
	border-radius: 11px;
	/* height: 90vh; */
}

.upgradeModalHeight {
	height: 90vh;
	max-width: 700px;
	width: 95vw;
}

.upgradeModalHeightEmbargo {
	height: 90vh;
	max-width: 500px;
	width: 95vw;
}

.upgradeModalHeight .contentType ul {
	margin-left: 20px;
	color: #666;
	font-size: 16px;
}

.upgradeModalHeight .contentType .columnPremier span {
	color: #020c41;
	font-weight: 500;
	float: left;
}
.upgradeModalHeight .contentType .columnPremier p {
	float: right;
	margin: 0;
	padding: 0;
	text-align: left;
	width: 64px;
	font-size: 14px !important;
}

.modalWindowAncillaries .topBox p.title {
	color: #020c41;
	font-size: 24px;
	font-weight: 500;
	margin: 0;
	padding: 0;
	margin-bottom: 5px;
}
.modalWindowAncillaries .topBox .header {
	border-bottom: 1px solid #e5e5e5;
	padding: 40px;
	background-color: rgba(255, 255, 255, 0.8);
	height: auto;
	overflow: hidden;
}
.modalWindowAncillaries .topBox .contentType {
	background-color: white;
	height: auto;
	overflow: hidden;
	padding: 40px;
}
.modalWindowAncillaries .topBox .contentType.upgrade {
	display: flex;
	flex-direction: column;
}
.modalWindowAncillaries .topBox .contentType h3 {
	font-size: 18px;
	color: #020c41;
}
.modalWindowAncillaries .topBox .contentType p {
	color: #666;
	font-size: 16px;
}
.modalWindowAncillaries .topBox .contentType p.small {
	font-size: 14px;
	font-weight: 400;
}
.modalWindowAncillaries .topBox .contentType p.small span {
	color: #020c41;
	font-weight: 500;
}
.modalWindowAncillaries .topBox .contentType p.importantNote {
	background-color: #035cf7;
	padding: 20px;
	border-radius: 7px;
	color: white;
	font-size: 14px;
	margin-bottom: 0;
}
.modalWindowAncillaries .topBox .contentType hr {
	margin-top: 30px;
	margin-bottom: 30px;
}
.modalWindowAncillaries .topBox .contentType p.title {
	margin: 0;
	padding: 6px 0;
	margin-left: 30px;
	color: #020c41;
	font-weight: 500;
}
.modalWindowAncillaries .topBox .contentType .tableWidthPremier {
	width: 100%;
	height: auto;
	overflow: hidden;
	border: 1px solid #e5e5e5;
	border-radius: 5px;
	display: flex;
	margin-bottom: 30px;
	margin-top: 30px;
}
.modalWindowAncillaries h5 {
	color: #020c41;
	position: relative;
	margin-bottom: 25px;
}
.modalWindowAncillaries h5:after {
	left: 0;
	color: #666;
	bottom: -20px;
	opacity: 0.8;
	position: absolute;
	font-weight: bold;
	font-size: 16px;
	content: attr(subtitle);
}
.modalWindowAncillaries .fakeAlert {
	display: flex;
	align-items: center;
	background: #ffcd47;
	border-radius: 10px;
	margin-top: 1.5em;
	padding: 5px 20px;
	justify-content: flex-start;
	width: 100%;
}

.modalWindowAncillaries .fakeAlert .fakeIcon {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	float: left;
	margin-right: 20px;
	background-image: url(../../../assets/travel/important.svg);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}

.modalWindowAncillaries .topBox .contentType .tableWidthPremier .columnPremier {
	float: left;
	width: 50%;
}
.modalWindowAncillaries .topBox .contentType .tableWidthPremier .columnPremier:nth-child(2) {
	border-left: 1px solid #e5e5e5;
}
.modalWindowAncillaries .topBox .contentType .tableWidthPremier .columnPremier ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.modalWindowAncillaries .topBox .contentType .tableWidthPremier .columnPremier ul li {
	height: auto;
	border-bottom: 1px solid #e5e5e5;
	padding: 10px 15px;
	overflow: hidden;
}
.modalWindowAncillaries .topBox .contentType .tableWidthPremier .columnPremier ul li:nth-child(even) {
	background-color: rgba(0, 125, 195, 0.07);
}
.modalWindowAncillaries .topBox .contentType .tableWidthPremier .columnPremier ul li.noborder {
	border-bottom: none;
}
.modalWindowAncillaries .topBox p.subtitle {
	font-size: 16px;
	color: #020c41;
	margin: 0;
	font-weight: 400;
	padding: 0;
	padding-right: 20px;
}
.modalWindowAncillaries .topBox .imageCircle {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	float: left;
	background-color: rgba(0, 125, 195, 0.07);
	margin-right: 20px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}
.modalWindowAncillaries .topBox .imageCircle.maletaimage {
	background-image: url(../../../assets/travel/maletasincosto.svg);
}
.modalWindowAncillaries .topBox .imageCircle.asientoupgrade {
	background-image: url(../../../assets/travel/asientoupgrade.svg);
}
.modalWindowAncillaries .topBox .imageCircle.calendarioembargo {
	background-image: url(../../../assets/travel/calendario.svg);
}

.modalWindowAncillaries .topBox .textLeftCircle {
	float: left;
	width: calc(100% - 120px);
	height: auto;
	overflow: hidden;
}
.totalBenefitsTravel__card__title {
	font-size: 18px;
	color: #020c41;

	margin: 0;
	margin-left: 10px;
	margin-top: 7px;
	color: #020c41;
	font-weight: 500;
	font-size: 18px;
	line-height: 1.2;
}

.totalBenefitsTravel__card__text {
	color: #666666;
	font-size: 12px;
	line-height: 120%;
	margin-left: 0px;
	margin-bottom: 5px;

	color: #020c41;
	margin: 0;
	margin-left: 10px;
	margin-top: 10px;
	font-size: 13px;
	line-height: 1.5;
	opacity: 0.8;
	width: 100%;
	line-height: 1.3em;
	margin: 0 0 1em 0;
	color: #666;
	margin: 0;
	margin-top: 0;
	font-size: 13px;
	line-height: 1.5;
	margin-left: 10px;
}

.totalBenefitsTravel__card__more {
	font-size: 12px;
	font-size: 12px;
	font-weight: 500;
	margin-left: 10px;
	/* margin-top: 15px; */
	color: #035cf7;
}
.button-download.travel {
	padding: 0;
	width: 100%;
}

.button-download.travel a.download-button {
	width: 100%;
	text-decoration: none;
}
.travel-link .totalBenefitsTravel__card__more {
	display: inline-block;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}
p.info-benefits {
	color: #666666;
	font-size: 12px;
	line-height: 18px;
	width: 60%;
}

.benefits-container-cards {
	display: flex;
	justify-content: space-around;
}

.benefits-big-card.benefits {
	width: 100%;
}
.discount-container {
	float: right;
}
.table-Compensation.travel-table {
	width: 100%;
	border-collapse: initial;
}
tr.travel-row > td {
	border-right: 1px solid #ccc;
	padding: 0;
}
tr.travel-row > td td {
	width: 100%;
	padding: 20px;
	border-bottom: 1px solid #ccc;
}
tr.travel-row > td td.no-brdr-btn {
	border: none;
}
.benefits-big-card.benefits {
	display: flex;
}

.travel-link.travelflight .totalBenefitsTravel__card__more {
	font-size: 14px;
	background-color: rgba(0, 125, 195, 1);
	color: #fff;
	padding: 7px 15px;
	border-radius: 100px;
}

.travel-link.travelflight {
	align-self: end;
}

.TravelCompanion .blue-arrow {
	opacity: 0;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	transform: translateX(-10px);
}

.TravelCompanion:hover .blue-arrow {
	opacity: 0;
	transform: translateX(0);
}

.TravelCompanionCell:hover .travel-link.travelflight .totalBenefitsTravel__card__more {
	background-color: #020c41;
}

/*More information*/
.moreInformationWrap {
	/* display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 30px; */
	width: 100%;
	height: auto;
	padding: 10px 0;
	overflow: hidden;
}
.moreInformationWrap .boxMore {
	border-radius: 11px;
	/* box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15); */
	box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	background-color: white;
	overflow: hidden;
	border: 1px solid #e5e5e5;
	min-height: 165px;
	width: calc(46% - 15px);
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}
.moreInformationWrap .boxMore:hover {
	/* transform: scale(1.04, 1.04); */
	/* box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5); */
	border: 1px solid rgba(0, 125, 195, 1);
}

.moreInformationWrap .boxMore:hover .textMoreInformation {
	background-color: rgba(0, 125, 195, 0.04);
}
.moreInformationWrap .boxMore .pictureInformation {
	float: left;
	width: 100%;
	height: 100%;
	background-color: #020c41;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	position: relative;
}
.moreInformationWrap .boxMore:nth-child(1) .pictureInformation {
	background-image: url(../../../assets/cambios.jpg);
}
.moreInformationWrap .boxMore:nth-child(2) .pictureInformation {
	background-image: url(../../../assets/reembolso.jpg);
}
.moreInformationWrap .boxMore:nth-child(3) .pictureInformation {
	background-image: url(../../../assets/companero-viaje.jpg);
}
.moreInformationWrap .boxMore:nth-child(4) .pictureInformation {
	background-image: url(../../../assets/sujeto.jpg);
}
.moreInformationWrap .boxMore:nth-child(5) .pictureInformation {
	background-image: url(../../../assets/assets-premier.jpg);
}

.moreInformationWrap .boxMore .PlayButton {
	width: 50px;
	height: 50px;
	transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;
	position: absolute;
	opacity: 0.5;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	background-image: url(../../../assets/play.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}
.moreInformationWrap .boxMore:hover .PlayButton {
	opacity: 0.8;
}
.moreInformationWrap .boxMore .textMoreInformation {
	width: 100%;
	padding: 20px;
	float: left;
	display: table;
	height: 100%;
}
.moreInformationWrap .boxMore .textMoreInformation .textMoreInformationCell {
	display: table-cell;
	vertical-align: middle;
}
.moreInformationWrap .boxMore .textMoreInformation p.link {
	align-self: end;
	margin: 0;
	padding: 0;
	color: rgba(0, 125, 195, 1);
	font-size: 12px;
	font-weight: 500;
}
.moreInformationWrap .boxMore .textMoreInformation p.title {
	color: #020c41;
	font-weight: 500;
	font-size: 16px;
	line-height: 1.2;
	margin-bottom: 7px;
}
.moreInformationWrap .boxMore .textMoreInformation p.subtitle {
	color: #666;
	font-size: 12px;
	line-height: 1.4;
	margin-bottom: 0;
}

/* #wrapperAccordionThis .accordion__item:nth-child(even) .accordion__button{
  background-color: rgba(0, 125, 195, .005)!important;
} */

.boletosEmbargo #wrapperAccordionThis .accordion__item:nth-child(odd) .accordion__button {
	background-color: rgba(0, 125, 195, 0.05) !important;
}

#accordion__heading-0,
#accordion__heading-1,
#accordion__heading-2,
#accordion__heading-3,
#accordion__heading-4,
#accordion__heading-5,
#accordion__heading-6,
#accordion__heading-7,
#accordion__heading-8,
.accordion__button {
	height: auto;
	overflow: hidden;
	padding: 0 !important;
	background-color: #fff !important;
}
#accordion__heading-0:hover,
#accordion__heading-1:hover,
#accordion__heading-2:hover,
#accordion__heading-3:hover,
#accordion__heading-4:hover,
#accordion__heading-5:hover,
#accordion__heading-6:hover,
#accordion__heading-7:hover,
#accordion__heading-8:hover,
.accordion__button:hover {
	background-color: rgba(0, 125, 195, 0.08) !important;
}
.accordion__button::before {
	margin-top: 33px;
}

.totalLeftBenefitsContacts .accordion__button::before {
	margin-top: 23px;
}

.totalLeftBenefitsContacts {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

#wrapperAccordionThis {
	border-radius: 7px;
	overflow: hidden;
	box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
	margin-bottom: 20px;
}

#AncillariesTitle {
	margin-top: 40px;
}

.tableBoletosHeader {
	height: auto;
	overflow: hidden;
	width: calc(100% - 40px);
	float: left;
}
.tableBoletosHeader .floatDiv {
	float: left;
	background-color: rgba(0, 125, 195, 0.07);
	border-right: 1px solid #d6d6d6;
	min-width: 250px;
}

.tableBoletosHeader p {
	float: left;
	margin: 0;
	padding: 0;
	line-height: 40px;
	padding: 18px;
}
.tableBoletosHeader img {
	width: 30px;
	height: 30px;
	float: left;
	margin-left: 19px;
	margin-top: 23px;
}
.tableBoletosHeader p.title {
	margin: 0;
	color: #020c41;
	font-weight: 500;
	font-size: 14px;
}
.tableBoletosHeader p.subtitle {
	font-size: 14px;
	font-weight: 400;
	color: #666;
	display: none;
}
.tableBoletosHeader p.impuestos {
	font-size: 14px;
	font-weight: 500;
	color: #020c41;
	float: right;
}
.tableBoletosHeader p.cantidad {
	float: right;
	color: rgba(0, 125, 195, 1);
	font-size: 15px;
	font-weight: 500;
}

#wrapperAccordionThis ul {
	margin-left: 30px;
	font-size: 14px;
	color: #020c41;
	margin-bottom: 0;
}

#wrapperAccordionThis ul li {
	padding-left: 10px;
}

#wrapperAccordionThis ul span {
	font-weight: 500;
}
.accordion__panel {
	background-color: #fff;
	border-top: 1px solid #d6d6d6;
}

@media (max-width: 1044px) {
	.upgradeModalHeight {
		height: 70vh;
		width: 70vw;
	}
	.modalWindowAncillaries .topBox .contentType .tableWidthPremier {
		display: inline-grid;
	}

	.modalWindowAncillaries .topBox .contentType .tableWidthPremier .columnPremier {
		width: 100%;
	}
}

@media (max-width: 800px) {
	.modalWindowAncillaries .topBox .imageCircle {
		width: 70px;
		height: 70px;
		float: none;
	}
	.modalWindowAncillaries .topBox .textLeftCircle {
		width: 100%;
		float: none;
		margin-top: 20px;
	}
	.modalWindowAncillaries .topBox p.title {
		line-height: 1.2;
	}
	.modalWindowAncillaries .topBox .contentType {
		padding: 20px;
	}
	.modalWindowAncillaries .topBox .header {
		padding: 20px;
	}
	.upgradeModalHeight {
		height: 70vh;
		width: 70vw;
	}
}

@media (max-width: 600px) {
	.modalWindowAncillaries .topBox .contentType .tableWidthPremier .columnPremier {
		width: 100%;
	}
	.upgradeModalHeight {
		height: 60vh;
		width: 90vw;
	}
	.upgradeModalHeight .contentType ul {
		font-size: 13px;
	}
	.upgradeModalHeight .contentType .columnPremier p {
		font-size: 13px !important;
	}
}

@media (max-width: 400px) {
	.upgradeModalHeight {
		height: 50vh;
		width: 95vw;
	}
}

/*

<div className="moreInformationWrap">
            <a className="boxMore information" href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/eabautista_aeromexico_com/ERCGPuIBs1tNo61PH2KmuUMBmf-e5_qgSUEEnyjD16LXag?e=JhU40z" target="_blank">
              <div className="pictureInformation"></div>
              <div className="textMoreInformation">
                <p className="title">Compañero de Viaje</p>
                <p className="subtitle">El compañero de viaje es cualquier persona a la cual puedes transferir tus boletos beneficio.</p>
                <p className="link">Leer más</p>
              </div>
            </a>
            <a className="boxMore information" href="https://na01.safelinks.protection.outlook.com/?url=https%3A%2F%2Faeromexico.us12.list-manage.com%2Ftrack%2Fclick%3Fu%3D6a28845fe1113ef952d2d1aa9%26id%3D5f317af67b%26e%3D265738d7da&data=01%7C01%7Ceabautista%40aeromexico.com%7Cfe32ac1f27e94b19e1ce08d666ccb155%7C25596fbb603c46b29ae1a967e162a2ff%7C1&sdata=b32XMDliWWFgocnYcjR6t41p0nEU6onV%2BwrzTNbDGts%3D&reserved=0" target="_blank">
              <div className="pictureInformation"></div>
              <div className="textMoreInformation">
                <p className="title">Viajar sujeto a espacio es más fácil de lo que tú crees</p>
                <p className="subtitle"></p>
                <p className="link">Leer más</p>
              </div>
            </a>
          </div>

*/
