.main-container {
	display: flex;
	flex-direction: row;
}

.navigation-bazar {
	position: relative;
	width: 320px;
}

@media (max-width: 767px) {
	.navigation-bazar {
		position: fixed;
		left: 0;
		top: 0;
		width: 100vw !important;
	}
	.navigation-bazar ul {
		flex-direction: row;
		overflow-x: scroll;
	}
	.navigation-bazar ul li {
		margin-left: 0.5rem;
	}
	.the-actual-content {
		padding-top: 10rem !important;
	}
}

/* .the-actual-content {
	width: calc(100% - 320px);
} */

.darkBlue-shadow {
	box-shadow: 0 20px 15px -15px rgba(1, 43, 92, 0.5);
}

.card-shadow {
	box-shadow: 0 20px 15px -15px rgba(233, 28, 48, 0.5);
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
