.IndexWrapper {
	width: 100%;
	height: 100vh;
	background-color: #0b2343;
}
.LoginPage {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	position: relative;
}
.LoginPage p,
.LoginPage h1,
.LoginPage a {
	color: white;
}
.BgLoginPage {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
}
.FilterLoginPage {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 2;
	opacity: 0.85;
	top: 0;
	left: 0;
}
.TopLoginPage {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 3;
	top: 0;
	left: 0;
}
.MidLoginContent {
	width: 80%;
	max-width: 1000px;
	height: 100%;
	margin: 0 auto;
	display: table;
	position: relative;
}
@media (max-width: 414px) {
	.MidLoginContent {
		width: 85%;
	}
}
.LogoMiAM1 {
	width: 300px;
	object-fit: cover;
}
/* @media (max-width: 800px) {
  .LogoMiAM {
    position: relative;
    margin-top: 30%;
  }
} */
.MidLoginContentCell {
	display: table-cell;
	vertical-align: middle;
}
@media (max-width: 800px) {
	.MidLoginContentCell {
		/* position: absolute; */
		width: 100%;
		height: 100%;
		bottom: 0;
	}
}
.ContentWrap {
	width: 100%;
	height: auto;
}
.WelcomeSite {
	margin: 0;
	padding: 0;
	font-size: 80px;
	line-height: 0.95556;
	font-weight: 500;
	letter-spacing: -0.015em;
	opacity: 0;
	animation: textanimating 3s 0.4s forwards cubic-bezier(0.2, 0.8, 0.2, 1);
}
.WelcomeSite div {
	display: inline;
}
.WelcomeSite div span {
	animation: animationText 10s cubic-bezier(0.2, 0.4, 0.2, 1) infinite 0s;
	-webkit-animation: animationText 10s cubic-bezier(0.2, 0.4, 0.2, 1) infinite 0s;
	position: absolute;
	opacity: 0;
	/* color: #3bafff; */
}
.WelcomeSite div span:nth-child(2) {
	animation-delay: 2.5s;
	-webkit-animation-delay: 2.5s;
}
.WelcomeSite div span:nth-child(3) {
	animation-delay: 5s;
	-webkit-animation-delay: 5s;
}
.WelcomeSite div span:nth-child(4) {
	animation-delay: 7.5s;
	-webkit-animation-delay: 7.5s;
}
@media (max-width: 800px) {
	.WelcomeSite {
		font-size: 62px;
		font-weight: 600;
	}
}
@media (max-width: 414px) {
	.WelcomeSite {
		font-size: 42px;
		font-weight: 600;
	}
}
.WelcomeIntro {
	font-size: 21px;
	line-height: 1.38105;
	font-weight: 400;
	letter-spacing: 0.011em;
	/* margin-top: 50px; */
	opacity: 0;
	animation: textanimating 3s 0.5s forwards cubic-bezier(0.2, 0.8, 0.2, 1);
}

.WelcomeIntro a {
	text-decoration: underline;
}
.WelcomeIntro a:hover {
	color: #007cc2;
	text-decoration: underline;
}

.WelcomeButton {
	border-radius: 50px;
	padding: 22px 50px 23px;
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 500;
	background-color: #e91b2f;
	border: 1px solid #e91b2f;
	color: white;
	cursor: pointer;
	-webkit-appearance: none;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	opacity: 0;
	animation: textanimating 3s 0.6s forwards cubic-bezier(0.2, 0.8, 0.2, 1);
}

.MagicLink {
	color: white;
	-webkit-appearance: none;
	background-color: transparent;
	border: none;
	text-align: left;
	font-size: 16px;
	margin-top: 10px;
	cursor: pointer;
	margin-left: 20px;
	font-weight: 500;
}
.WelcomeButton span {
	width: 20px;
	height: 20px;
	display: block;
	float: left;
	margin-right: 10px;
	background-image: url("../../../assets/login/logo-office.png");
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	margin-top: 2px;
}
.WelcomeButton:hover {
	border: 1px solid #fff;
	background-color: rgba(0, 0, 0, 0.2);
	transform: translateY(-3px);
}

.ImgLoginPageLogin {
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
}
.VideoLogin {
	position: absolute;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	max-width: none !important;
	width: auto;
	height: auto;
	transform: translateX(-50%) translateY(-50%);
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	z-index: 3;
}
.CloseStyleNew {
	color: #007cc2 !important;
	font-weight: 500;
	font-size: 14px;
	text-align: left;
	margin: 0 auto;
	display: block;
	text-transform: uppercase;
	margin-top: 0px;
	margin-left: 10px;
	margin-bottom: 40px;
}
p.failed-user {
	color: #0b2343;
	font-weight: 500;
	font-size: 20px;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	display: block;
	background-color: #fecb2f;
	margin: 0;
	padding: 0;
	padding-left: 0px;
	height: 50px;
	line-height: 50px;
	padding-left: 30px;
	font-size: 15px;
	text-align: center;
}
.wrapper-disclaimer {
	margin: 50px 0;
}
.disclaimer {
	font-size: 20px;
}

.LogoMiAM1Mobile {
	display: none;
}

.WelcomeSiteMobile {
	display: none;
}
@media (max-width: 800px) {
	.WelcomeButton {
		padding: 16px 60px;
		margin: 0 auto;
		margin-top: 15px;
		display: block;
	}
}

@media (max-width: 800px) {
	p.failed-user {
		/* padding-top: 40px; */
		height: auto;
		text-align: center;
		padding-left: 0;
		padding-top: env(safe-area-inset-top);
	}
	.FilterLoginPage {
		background: rgba(0, 30, 66, 1);
		background-image: linear-gradient(to top, #0b2343, #0a2c55, #083668, #043f7c, #004990);
	}
	.WelcomeIntro {
		/* margin-top: 30px; */
		text-align: center;
		font-size: 14px;
	}
	.LogoMiAM1 {
		width: 220px;
		display: block;
		margin: 0 auto;
	}
	.LogoMiAM1 {
		display: none;
	}
	.LogoMiAM1Mobile {
		display: block;
		margin: 0 auto;
		width: 120px;
		border-radius: 20px;
		overflow: hidden;
		object-fit: cover;
		box-shadow: 0 0.15em 0.4em 0.065em rgba(0, 0, 0, 0.075);
	}
	.LoginPage h1 {
		margin: 0 auto;
		text-align: center;
		margin-top: 26px;
	}
	.WelcomeSiteMobile {
		display: block;
		font-size: 34px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
		padding-bottom: 35px;
	}
	.WelcomeSite {
		display: none;
	}
	.wrapper-disclaimer {
		margin: 0;
	}
	.FilterLoginPage {
		height: 66vh;
		bottom: 0;
		top: initial;
	}
	.BgLoginPage {
		height: 50vh;
	}

	.WelcomeButton:hover {
		background-color: #e91b2f;
		border: 1px solid #e91b2f;
		transform: translateY(-0px);
	}
}

@media (min-width: 801px) {
	.languageFlightPlan.login.movil {
		display: none;
	}
}

@media (max-width: 800px) {
	.languageFlightPlan.login.desktop {
		display: none;
	}
}
.languageFlightPlan.login.movil {
	right: 10px;
	top: 40px !important;
	margin-top: 5px !important;
}

.languageFlightPlan.login {
	right: 10px;
	top: auto;
	margin-top: -20px;
}

.languageFlightPlan.login.desktop {
	width: auto;
}
.languageFlightPlan.login.desktop p {
	display: block;
}

.languageFlightPlan.login.desktop .languageFlag {
	width: 40px;
	height: 40px;
}

.login .languageFlag.es {
	background-image: url(../../../assets/images/usa-flag.svg) !important;
}

.login .languageFlag.en {
	background-image: url(../../../assets/images/mexico-flag.svg) !important;
}

.login.movil .container-language.activeLang {
	height: 105px;
	overflow: hidden;
	opacity: 1;
	top: 65px;
	border: 1px solid #e5e5e5;
}

.languageFlightPlan.login.movil .container-language .notification-text {
	display: block;
	color: #0b2343;
	line-height: 24px;
	margin-bottom: 5px;
	margin-left: 0px;
}

.buttons-holder {
	display: flex;
	align-items: center;
}
.buttons-holder .gate-btn {
	border-radius: 50px;
	padding: 22px 30px 23px;
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 500;
	background-color: #035cf7;
	border: 1px solid #035cf7;
	margin-right: 1em;
	margin-top: 1em;
	color: white;
	cursor: pointer;
	-webkit-appearance: none;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	opacity: 0;
	animation: textanimating 3s 0.6s forwards cubic-bezier(0.2, 0.8, 0.2, 1);
}
.buttons-holder .gate-btn:hover {
	background: transparent;
	border: 1px solid #fff;
}
.buttons-holder .bot-btn {
	background: #11b1a2;
	border-radius: 30px;
	border: 1px solid #11b1a2;
	box-sizing: border-box;
	display: flex;
	height: 69px;
	margin-left: 1em;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	opacity: 0;
	position: relative;
	width: 237.55px;
	animation: textanimating 3s 0.6s forwards cubic-bezier(0.2, 0.8, 0.2, 1);
}

.buttons-holder .bot-btn svg {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	height: 30px;
	width: auto;
}

.buttons-holder .bot-btn:hover {
	background: transparent;
}

.buttons-holder .bot-btn:hover svg path {
	fill: #11b1a2 !important;
}

@media (max-width: 800px) {
	.buttons-holder .gate-btn {
		margin-top: 0;
		padding: 15px 20px 16px;
	}
	.buttons-holder .WelcomeButton {
		margin: 0 0 1em;
	}
	.buttons-holder {
		flex-direction: column;
	}
	.buttons-holder .bot-btn {
		height: 56px;
		margin: 0;
		width: 257.55px;
	}
}
