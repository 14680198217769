.imageNotification {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
}
.alertwidth{
  width: 100%;
}
.imageNotification p {
  position: absolute;
  top: 40px;
  left: 40px;
  background-color: rgba(0, 125, 195, 1);
  color: white;
  padding: 4px 10px;
  border-radius: 6px;
}

.imageNotification img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.modalPadding {
  padding: 40px 50px;
}

.scrollModalAlert {
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  height:100%;
}

.modalPadding h1 {
  font-size: 32px;
  font-weight: 600;
  color: #002b5c;
}

.modalPadding .tableGamContact {
  border: 1px solid #e5e5e5;
  border-radius: 7px;
  margin-top: 30px;
}

.modalPadding .tableGamContact ol{
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;
  height: auto;
  overflow: hidden;
}
.modalPadding .tableGamContact ol li{
  border-bottom: 1px solid #e5e5e5;
  height: auto;
  overflow: hidden;
  padding: 20px 20px;
}
.modalPadding .tableGamContact ol li:nth-child(even){
  background-color: rgba(0, 125, 195, .05);
}
.modalPadding .tableGamContact ol li:last-child{
  border-bottom: none;
}
.modalPadding .tableGamContact ol .leftTable{
  width: 50%;
  height: auto;
  overflow: hidden;
  float: left;
}
.modalPadding .tableGamContact ol .leftTablePhone{
  width: 50%;
  height: auto;
  overflow: hidden;
  float: left;
}

.modalPadding .tableGamContact ol li p{
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  color: #002b5c;
}

.modalPadding .tableGamContact ol li p.small{
  font-size: 16px;
  line-height: 1.4;
}

.modalPadding .tableGamContact ol li p.smallWhite{
  color: #666;
  font-weight: 400;
}

.modalPadding .tableGamContact ol.lugaresDirecciones .leftTable{
  width: 35%;
}
.modalPadding .tableGamContact ol.lugaresDirecciones .leftTablePhone{
  width: 65%;
  border-left: 1px solid #e5e5e5;
  padding-left: 20px;
}

.modalPadding .tableGamContact ol li a{
  margin: 0;
  padding: 0;
  font-size: 18px;
  color: #007dc3;
  text-decoration: none;
  cursor: pointer;
}

.modalPadding .tableGamContact ol li a:hover{
  color: #004990;
}

.modalPadding img{
  object-fit: cover;
  height: auto;
  width: 100%;
  border-radius: 7px;
  margin-top: 10px;
  overflow: hidden;
}

.modalPadding p {
  font-size: 18px;
  font-weight: 500;
  margin-top: 15px;
  font-weight: 400;
  color: #0b2343;
}
.content {
  font-size: 16px;
  font-weight: 500;
  margin-top: 15px;
  font-weight: 400;
  color: #666;
}

.content p{
  font-weight: 400;
  color: #666;
  font-size: 16px;
}

.content a{
  background-color: #007dc3;
  padding: 2px 15px;
  color: white;
  border-radius: 40px;
  margin-bottom: 20px;
  display: table;
  cursor: pointer;
  text-decoration: none!important;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
  padding: 10px 30px;
  font-weight: 600;
}

.content p a{
  background-color: transparent;
  color: #007dc3;
  padding: 0;
  display: initial;
}

.content a:hover{
  color: #002b5c;
}

.HomeAlertWrap {
  background-color: #004990;
  position: relative;
  overflow: hidden;
  animation: alertAnimation 2s 0.6s forwards cubic-bezier(0.2, 0.8, 0.2, 1);
  display: none;
}
.HomeAlertWrap a {
  color: rgba(0, 125, 195, 1);
  font-size: 13px;
  font-weight: 500;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
  line-height: 40px;
  margin-left: 7px;
  padding-left: 30px;
  padding-right: 30px;
}


.HomeAlertWrap:hover a {
  text-decoration: underline;
}

.HomeAlertMid {
  width: auto;
  height: 100%;
  padding: 0px 5%;
  display: block;
}

.HomeAlertMid .ImportantNote {
  width: auto;
  padding: 3px 9px;
  background-color: #fff;
  border-radius: 20px;
  line-height: inherit;
  margin-top: 13px;
  font-weight: 600;
  color: #004990;
  font-size: 12px;
  float: left;
  margin-right: 10px;
}


.HomeAlertMid .TextImportant {
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
  line-height: 50px;
  font-size: 12px;
  color: white;
  font-weight: 400;
}
.HomeAlertMid span {
  color: white;
  font-size: 14px;
  font-weight: 600;
  -webkit-transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
  text-decoration: none;
  line-height: 50px;
  margin-left: 10px;
  cursor: pointer;
}
.HomeAlertMid span:hover {
  background-color: rgba(0, 125, 195, 1);
  color: white;
  padding: 2px 10px;
  border-radius: 100px;
}

.container-modal-notification {
  z-index: 99999;
  position: fixed;

  width: 100%;
  height: 100vh;
  top: 220%;
  left: 0;
}

.noDefaulttShowAlert{
  display: none;
}

.modal-notification {
  z-index: 9999999;
  background-color: rgba(0, 125, 195, 1);
  width: 50%;
  margin: auto;
  height: 50vh;
}

.TextImportant {
  margin-left: 0;
  font-weight: 400;
}

/* .nivelClaseAlert .ShowHome{
  display: none;
} */

.nivelClaseAlert1 .AlertaNivel1,
.nivelClaseAlert2 .AlertaNivel2,
.nivelClaseAlert3 .AlertaNivel3,
.nivelClaseAlert4 .AlertaNivel4,
.nivelClaseAlert5 .AlertaNivel5,
.nivelClaseAlert6 .AlertaNivel6,
.nivelClaseAlert7 .AlertaNivel7,
.nivelClaseAlert8 .AlertaNivel8,
.nivelClaseAlert9 .AlertaNivel9,
.nivelClaseAler10 .AlertaNivel10,
.nivelClaseAler11 .AlertaNivel11,
.nivelClaseAlert12 .AlertaNivel12 {
  display: block;
}


.HideHome {
  display: none;
}

@media (max-width: 1044px) {
  .HomeAlertWrap a {
    line-height: 60px;
  }
  .HomeAlertWrap {
    position: fixed;
    z-index: 7;
    top: 60px;
  }  
}

@media (max-width: 400px) {
  .HomeAlertMid .ImportantNote {
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
}

