.blue {
  background-color: blue;
}
.teal {
  background-color: teal;
}
.green {
  background-color: green;
}
.yellow {
  background-color: yellow;
}
.orange {
  background-color: orange;
}
.red {
  background-color: red;
}

.blue-active{
  background-color: blue;
  transform: scale(1.1, 1.1);
  border: solid #ec3549 3px;
}
.teal-active{
  background-color: teal;
  transform: scale(1.1, 1.1);
  border: solid #ec3549 3px;
}
.green-active{
  background-color: green;
  transform: scale(1.1, 1.1);
  border: solid #ec3549 3px;
}
.yellow-active{
  background-color: yellow;
  transform: scale(1.1, 1.1);
  border: solid #ec3549 3px;
}
.orange-active{
  background-color: orange;
  transform: scale(1.1, 1.1);
  border: solid #ec3549 3px;
}
.red-active{
  background-color: red;
  transform: scale(1.1, 1.1);
  border: solid #ec3549 3px;
}

.circle-form {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}


.circle-form:hover {
  transform: scale(1.1, 1.1);
  border: solid #ec3549 3px;
}

.modal-circles {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 20px;
}
