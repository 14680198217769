@import url("./assets/fonttrans/stylesheet.css");

@tailwind base;
@tailwind components;
@tailwind utilities;


.main-container{
	display: block !important;
}

  

body {
	color: #020c41;
	font-size: 16px;
	padding: 0;
	margin: 0;
	/* font-family: "Neue Haas Unica Pro"; */
	font-family: "Garnett", Helvetica, Arial, "Neue Haas Unica Pro", sans-serif;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-width: 320px;
	/* padding-bottom: env(safe-area-inset-bottom); */
}

a {
	text-decoration: none;
	color: #035cf7;
}
p {
	margin-bottom: 1rem;
}
b,
strong {
	font-weight: 600;
}

.modalWhiteWelcome {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	border-radius: 7px;
	padding: 40px;
	padding-block-end: 90px;
	width: 100%;
	height: 90vh;
	max-width: 600px;
	max-height: 420px;
	min-width: 320px;
}
.modalTextTriques {
	max-height: 290px;
	max-width: 650px;
	border-radius: 7px;
	background-image: url(./assets/triques/bg-triques.jpg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.buttonAcceptCookieTriques {
	border-radius: 50px;
	padding: 17px 30px 17px;
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 500;
	background-color: #fa0073;
	border: 1px solid #fa0073;
	color: white;
	cursor: pointer;
	-webkit-appearance: none;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	opacity: 1;
	position: absolute;
	left: 50%;
	bottom: 50%;
	transform: translate(-50%, 114px);
	display: block;
	margin: 0;
}

.buttonAcceptCookieTriques:hover {
	background-color: #020c41;
	border: 1px solid #020c41;
}

.modalTextTriques h1,
.modalTextTriques p {
	color: #020c41;
	text-align: center;
}

.modalTextTriques p.title {
	font-weight: 500;
	line-height: 1.2;
	font-size: 32px;
}

.modalTextTriques .subtitle {
	max-width: 350px;
	margin: 0 auto;
}

.buttonAcceptCookie {
	border-radius: 50px;
	padding: 17px 60px 17px;
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 500;
	background-color: #fa0073;
	border: 1px solid #fa0073;
	color: white;
	cursor: pointer;
	-webkit-appearance: none;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	opacity: 1;
	/* animation: textanimating 3s 0.6s forwards cubic-bezier(0.2, 0.8, 0.2, 1); */
	position: absolute;
	left: 50%;
	bottom: 50%;
	transform: translate(-50%, 170px);
	display: block;
	margin: 0;
}

#idAcceptCookieAlert {
	height: 0;
	width: 0;
	overflow: hidden;
	display: block;
	opacity: 0;
	cursor: initial;
}

.buttonAcceptCookie:hover {
	background-color: #020c41;
	border: 1px solid #020c41;
}

.containerCoookie {
	position: fixed;
	/* background-color: white; */
}

@keyframes textanimating {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}

@keyframes animationText {
	0% {
		opacity: 0;
	}
	6% {
		opacity: 0;
		transform: translateY(-30px);
		-webkit-transform: translateY(-30px);
	}
	10% {
		opacity: 1;
		transform: translateY(0px);
		-webkit-transform: translateY(0px);
	}
	25% {
		opacity: 1;
		transform: translateY(0px);
		-webkit-transform: translateY(0px);
	}
	29% {
		opacity: 0;
		transform: translateY(30px);
		-webkit-transform: translateY(30px);
	}
	80% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

@media screen and (max-width: 1000px) {
	video {
		display: none;
	}
	.modalWhiteWelcome {
		bottom: 25%;
		width: 70%;
		max-width: initial;
		top: initial;
		transform: translate(-50%, 0);
		max-height: initial;
		height: 90vh;
		border-radius: 20px;
	}
	.buttonAcceptCookie {
		top: 12vh;
		transform: translate(0, 0);
		bottom: initial;
		padding: 7px 15px;
		right: 15px;
		left: initial;
		font-size: 12px;
		text-transform: capitalize;
	}
}
@media screen and (max-width: 800px) {
	.modalWhiteWelcome {
		bottom: 25%;
		width: 70%;
		max-width: initial;
		top: initial;
		transform: translate(-50%, 0);
		max-height: initial;
		height: 90vh;
		border-radius: 20px;
	}
}

@media screen and (max-width: 600px) {
	body {
		-webkit-user-select: none;
	}
}

/*
body {
  padding-top: 4.5rem;
}

.alert-pre {
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
}*/
