.wrapperDownload {
	width: 100%;
	margin: 0 auto;
	height: auto;
}

.wrapperDownload .wrapperIdenditad {
	width: 90%;
	max-width: 1200px;
	margin: 0 auto;
	/*overflow: hidden;*/
	height: auto;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	padding: 20px 0 60px;
	grid-gap: 30px;
}

.wrapperDownload .wrapperIdenditad.four-columns {
	grid-template-columns: repeat(4, 1fr);
}

.wrapperDownload .wrapperIdenditad .fulltitle {
	text-align: left;
	grid-column: 1 / -1;
	width: 100%;
}

.wrapperDownload .wrapperIdenditad .wrapperbuttonBig {
	border: 1px solid #e5e5e5;
	border-radius: 11px;
	overflow: hidden;
	box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	display: grid;
	background-color: white;
}
.wrapperDownload .wrapperIdenditad .wrapperbuttonBig:hover {
	box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
}
.wrapperDownload .wrapperIdenditad .wrapperbuttonBig .wrapperTop {
	height: auto;
	min-height: 150px;
	padding: 20px 0;
	display: table;
	width: 100%;
}

.wrapperDownload .wrapperIdenditad .wrapperbuttonBig .wrapperTop .wrapperTopCell {
	display: table-cell;
	vertical-align: middle;
}

.wrapperDownload .wrapperIdenditad .wrapperbuttonBig .wrapperTop .wrapperTopCell img {
	width: 128px;
	height: 50px;
	margin: 0 auto;
	display: block;
}

.wrapperDownload .wrapperIdenditad .wrapperbuttonBig .wrapperTop .wrapperTopCell img.noTextImg {
	object-fit: contain;
	width: 142px;
	height: 70px;
}

.wrapperDownload .wrapperIdenditad .wrapperbuttonBig .wrapperTop .wrapperTopCell img.noTextImgComp {
	object-fit: contain;
	width: 142px;
	height: 95px;
}

.wrapperDownload .wrapperIdenditad .wrapperbuttonBig .wrapperTop .wrapperTopCell p {
	color: #020c41;
	font-weight: 500;
	font-size: 16px;
	line-height: 1.3;
	margin: 0 auto;
	text-align: center;
	padding: 0 20px;
	margin-top: 15px;
}

.wrapperDownload .wrapperIdenditad .wrapperbuttonBig .wrapperTop .wrapperTopCell p.noText {
	display: none;
}

.wrapperDownload .wrapperIdenditad .wrapperbuttonBig .wrapperBottom {
	border-top: 1px solid #e5e5e5;
	height: 60px;
	overflow: hidden;
	width: 100%;
	cursor: pointer;
	display: block;
	text-decoration: none;
	align-self: end;
}
.wrapperDownload .wrapperIdenditad .wrapperbuttonBig .wrapperBottom:hover {
	background-color: rgba(0, 125, 195, 0.07);
}
.wrapperDownload .wrapperIdenditad .wrapperbuttonBig .wrapperBottom .downloadIdenti {
	width: auto;
	height: auto;
	margin: 0 auto;
	overflow: hidden;
	margin-top: 19px;
	display: table;
}
.wrapperDownload .wrapperIdenditad .wrapperbuttonBig .wrapperBottom .downloadIdenti span {
	float: left;
	width: 22px;
	height: 22px;
	overflow: hidden;
	border: 1px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	background-image: url(../../../assets/identidad/download-icon.svg);
}
.wrapperDownload .wrapperIdenditad .wrapperbuttonBig .wrapperBottom .downloadIdenti p {
	float: left;
	line-height: 20px;
	margin: 0;
	padding: 0;
	font-size: 14px;
	font-weight: 600;
	color: #035cf7;
	margin-left: 10px;
}

#logotiposIdentidad {
	max-width: 1200px;
	width: 90%;
	height: auto;
	overflow: hidden;
	margin: 0 auto;
}

#logotiposIdentidad .totalBenefits__title__container h1 {
	margin: 0;
	padding: 0;
}

#logotiposIdentidad .totalBenefits__title__container {
	margin-top: 30px;
}

#logotiposIdentidad .totalBenefits__title__container.anyquestions {
	margin: 0;
	padding: 0;
	margin-bottom: 70px;
}

#logotiposIdentidad .totalBenefits__title__container.anyquestions p {
	font-size: 14px;
	padding: 10px 30px;
	background-color: rgba(0, 125, 195, 0.07);
	border-radius: 100px;
	color: #020c41;
}

#logotiposIdentidad .totalBenefits__title__container.anyquestions p a {
	color: #035cf7;
	font-size: 14px;
	float: none;
}

/****ALFABETO*****/

/****ALFABETO*****/

/****ALFABETO*****/
/* 
#alfabetoIdentidad {
  height: auto;
  display: flex;
  width:100%;
  border: 1px solid #f0f;
}
#alfabetoIdentidad .nosotrosWrapper{
  height: initial;
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

#alfabetoIdentidad .nosotrosWrapper .nosotrosHeader h4{

} */
.alphaWrapperMid {
	margin: 0;
	padding: 0;
	width: 90%;
	max-width: 1200px;
	height: auto;
	overflow: hidden;
	margin: 0 auto;
	margin-top: 60px;
	margin-bottom: 80px;
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	grid-gap: 20px;
}

.alphaWrapperMid li {
	list-style: none;
	width: 100%;
	display: block;
	height: auto;
	overflow: hidden;
}

.alphaWrapperMid li p.spanAlphabet {
	color: #0b2343;
	font-size: 42px;
	font-weight: 500;
	margin: 0;
	padding: 0;
	margin-top: 10px;
	text-align: center;
}

.alphaWrapperMid li p.text {
	color: #0b2343;
	font-size: 16px;
	font-weight: 400;
	margin: 0;
	padding: 0;
	margin-top: -5px;
	color: #035cf7;
	text-align: center;
}

/****ALFABETO*****/

/****ALFABETO*****/

/****ALFABETO*****/

@media screen and (max-width: 1300px) {
}

@media screen and (max-width: 1044px) {
	.wrapperDownload .wrapperIdenditad {
		grid-template-columns: repeat(4, 1fr);
	}

	.alphaWrapperMid {
		grid-template-columns: repeat(5, 1fr);
	}
}

@media screen and (max-width: 800px) {
	.wrapperDownload .wrapperIdenditad {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media screen and (max-width: 600px) {
	.wrapperDownload .wrapperIdenditad {
		grid-template-columns: repeat(2, 1fr);
	}

	.alphaWrapperMid {
		grid-template-columns: repeat(4, 1fr);
	}
}

@media screen and (max-width: 424px) {
	.wrapperDownload .wrapperIdenditad {
		grid-template-columns: repeat(1, 1fr);
	}

	.alphaWrapperMid {
		grid-template-columns: repeat(3, 1fr);
	}
}

 