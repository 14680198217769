.swal-overlay {
  width:auto;
  height: 200px;
  top: -10px;
  left: 0;
  background-color: transparent;
}
.swal-modal {
background-color: #1c869e;
width: auto;
height: 60px;
border-radius: 0;
top: 0;
left: 0;
margin: 0;
padding: 0 25px;
border-radius: 4px;
width: 650px;
box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
}
.swal-title,
.swal-text{
float: left;
color: white;
padding: 0;
margin: 0;
line-height: 56px;
font-family: 'Neue Haas Unica Pro';
}
.swal-title:not(:last-child){
margin: 0;
margin-right: 7px;
}
.swal-title:first-child{
margin: 0;
margin-right: 7px;
}
.swal-title{
font-size: 14px;
margin-right: 7px;
font-weight: 600;
}
.swal-text{
font-size: 13px;
font-weight: 400;
}
.swal-icon{
float: left;
width:40px;
height: 40px;
display: none;
overflow: hidden;
}
.swal-icon:first-child {
margin-top: 11px;
margin-right: 10px;
}