.swiperDiscountsCont {
  height: auto;
}
.swiper-container,
.swiper-container-horizontal {
  overflow: visible;
}

.moreTravelBenefits__container {
  background: rgba(214, 214, 214, 0.5);
  box-sizing: border-box;
  margin: 0;
  border: 0;
  border-left: 1px solid #d6d6d6;
}

.moreTravelBenefits__title__container {
  margin-top: 30px;
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 35px;
}

.moreTravelBenefits__title__container h1 {
  margin: 0;
  font-size: 26px;
  display: block;
  color: #0b2343;
  font-size: 24px;
  font-weight: 500;
}

.moreTravelBenefits__cards__container {
  margin: 0px 58px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.totalBenefits__card__image:hover {
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.3);
}

.moreTravelBenefits__card__image {
  height: 120px;
  background-image: url(../img/month1.jpg);
  background-size: cover;
  border-radius: 10px;
  margin-bottom: 20px;
}

.moreTravelBenefits__card__title {
  font-size: 18px;
  font-weight: 500;
  color: #0b2343;
  line-height: 1.2;
}

.moreTravelBenefits__card__text {
  color: #0b2343;
  margin: 0;
  font-size: 13px;
  line-height: 1.5;
  opacity: 0.8;
  width: 100%;
  line-height: 1.3em;
  margin: 0 0 1em 0;
  color: #666;
  margin: 0;
  font-size: 13px;
  line-height: 1.5;
}

.moreTravelBenefits__card__more {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 125, 195, 1);
}

.total-benefits-big {
  width: 100%;
  background-color: white;
  border-radius: 11px;
  border: 1px solid #e5e5e5;
  box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  cursor: pointer;
  height: 190px;
  display: none;
  position: relative;
}

.total-benefits-big01 {
  display: none;
  width: 100%;
}

.grupoAMTodos {
  display: block;
}

.Aeroméxico1 .AMX01 {
  display: block;
}
.Aeroméxico2 .AMX02 {
  display: block;
}
.Aeroméxico3 .AMX03 {
  display: block;
}
.Aeroméxico4 .AMX04 {
  display: block;
}
.Aeroméxico5 .AMX05 {
  display: block;
}
.Aeroméxico6 .AMX06 {
  display: block;
}
.Aeroméxico7 .AMX07 {
  display: block;
}
.Aeroméxico8 .AMX08 {
  display: block;
}
.Aeroméxico9 .AMX09 {
  display: block;
}
.Aeroméxico10 .AMX10 {
  display: block;
}
.Aeroméxico11 .AMX11 {
  display: block;
}
.Aeroméxico12 .AMX12 {
  display: block;
}
.Aeroméxico12 .AMX13 {
  display: block;
}
.Aeroméxico12 .AMX14 {
  display: block;
}
.Aeroméxico12 .AMX15 {
  display: block;
}
.Aeroméxico12 .AMX16 {
  display: block;
}

.AeroméxicoCargo1 .container-all-benefits .AMCargo01 {
  display: block;
}
.AeroméxicoCargo2 .container-all-benefits .AMCargo02 {
  display: block;
}
.AeroméxicoCargo3 .container-all-benefits .AMCargo03 {
  display: block;
}
.AeroméxicoCargo4 .container-all-benefits .AMCargo04 {
  display: block;
}
.AeroméxicoCargo5 .container-all-benefits .AMCargo05 {
  display: block;
}
.AeroméxicoCargo6 .container-all-benefits .AMCargo06 {
  display: block;
}
.AeroméxicoCargo7 .container-all-benefits .AMCargo07 {
  display: block;
}
.AeroméxicoCargo8 .container-all-benefits .AMCargo08 {
  display: block;
}
.AeroméxicoCargo9 .container-all-benefits .AMCargo09 {
  display: block;
}
.AeroméxicoCargo10 .container-all-benefits .AMCargo10 {
  display: block;
}
.AeroméxicoCargo11 .container-all-benefits .AMCargo11 {
  display: block;
}
.AeroméxicoCargo12 .container-all-benefits .AMCargo12 {
  display: block;
}

.AeroméxicoConnect1 .container-all-benefits .AMConnect01 {
  display: block;
}
.AeroméxicoConnect2 .container-all-benefits .AMConnect02 {
  display: block;
}
.AeroméxicoConnect3 .container-all-benefits .AMConnect03 {
  display: block;
}
.AeroméxicoConnect4 .container-all-benefits .AMConnect04 {
  display: block;
}
.AeroméxicoConnect5 .container-all-benefits .AMConnect05 {
  display: block;
}
.AeroméxicoConnect6 .container-all-benefits .AMConnect06 {
  display: block;
}
.AeroméxicoConnect7 .container-all-benefits .AMConnect07 {
  display: block;
}
.AeroméxicoConnect8 .container-all-benefits .AMConnect08 {
  display: block;
}
.AeroméxicoConnect9 .container-all-benefits .AMConnect09 {
  display: block;
}
.AeroméxicoConnect10 .container-all-benefits .AMConnect10 {
  display: block;
}
.AeroméxicoConnect11 .container-all-benefits .AMConnect12 {
  display: block;
}
.AeroméxicoConnect12 .container-all-benefits .AMConnect12 {
  display: block;
}

.AeroméxicoFormación1 .container-all-benefits .AMFormacion01 {
  display: block;
}
.AeroméxicoFormación2 .container-all-benefits .AMFormacion02 {
  display: block;
}
.AeroméxicoFormación3 .container-all-benefits .AMFormacion03 {
  display: block;
}
.AeroméxicoFormación4 .container-all-benefits .AMFormacion04 {
  display: block;
}
.AeroméxicoFormación5 .container-all-benefits .AMFormacion05 {
  display: block;
}
.AeroméxicoFormación6 .container-all-benefits .AMFormacion06 {
  display: block;
}
.AeroméxicoFormación7 .container-all-benefits .AMFormacion07 {
  display: block;
}
.AeroméxicoFormación8 .container-all-benefits .AMFormacion08 {
  display: block;
}
.AeroméxicoFormación9 .container-all-benefits .AMFormacion09 {
  display: block;
}
.AeroméxicoFormación10 .container-all-benefits .AMFormacion10 {
  display: block;
}
.AeroméxicoFormación11 .container-all-benefits .AMFormacion11 {
  display: block;
}
.AeroméxicoFormación12 .container-all-benefits .AMFormacion12 {
  display: block;
}

.AeroméxicoServicios1 .container-all-benefits .AMServicios01 {
  display: block;
}
.AeroméxicoServicios2 .container-all-benefits .AMServicios02 {
  display: block;
}
.AeroméxicoServicios3 .container-all-benefits .AMServicios03 {
  display: block;
}
.AeroméxicoServicios4 .container-all-benefits .AMServicios04 {
  display: block;
}
.AeroméxicoServicios5 .container-all-benefits .AMServicios05 {
  display: block;
}
.AeroméxicoServicios6 .container-all-benefits .AMServicios06 {
  display: block;
}
.AeroméxicoServicios7 .container-all-benefits .AMServicios07 {
  display: block;
}
.AeroméxicoServicios8 .container-all-benefits .AMServicios08 {
  display: block;
}
.AeroméxicoServicios9 .container-all-benefits .AMServicios09 {
  display: block;
}
.AeroméxicoServicios10 .container-all-benefits .AMServicios10 {
  display: block;
}
.AeroméxicoServicios11 .container-all-benefits .AMServicios11 {
  display: block;
}
.AeroméxicoServicios12 .container-all-benefits .AMServicios12 {
  display: block;
}

.Compartidos1 .container-all-benefits .AMCsc01 {
  display: block;
}
.Compartidos2 .container-all-benefits .AMCsc02 {
  display: block;
}
.Compartidos3 .container-all-benefits .AMCsc03 {
  display: block;
}
.Compartidos4 .container-all-benefits .AMCsc04 {
  display: block;
}
.Compartidos5 .container-all-benefits .AMCsc05 {
  display: block;
}
.Compartidos6 .container-all-benefits .AMCsc06 {
  display: block;
}
.Compartidos7 .container-all-benefits .AMCsc07 {
  display: block;
}
.Compartidos8 .container-all-benefits .AMCsc08 {
  display: block;
}
.Compartidos9 .container-all-benefits .AMCsc09 {
  display: block;
}
.Compartidos10 .container-all-benefits .AMCsc10 {
  display: block;
}
.Compartidos11 .container-all-benefits .AMCsc11 {
  display: block;
}
.Compartidos12 .container-all-benefits .AMCsc12 {
  display: block;
}

.total-benefits-big:hover {
  transform: scaleY(1.03) scaleX(1.03);
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.1);
}

.benefits-simple-card {
  overflow: hidden;
  padding: 25px;
}

.benefits-simple-card i {
  position: relative;
}
.benefits-big-card {
  height: 250px;
  width: 100%;
  background-color: white;
  border-radius: 11px;
  border: 1px solid #d6d6d6;
  width: 100%;
  background-color: white;
  border-radius: 11px;
  border: 1px solid #e5e5e5;
  box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  cursor: pointer;
  display: none; /*NONE*/
  position: relative;
}
.grupoAMTodos {
  display: block;
}
.carousel-benefit-cards {
  /* height: 500px; */
  display: flex;
  padding: 0 5%;
  margin-top: 40px;
}

.download-button {
  width: 100%;
  margin: 10px auto;
  padding: 10px 20px;
  border-radius: 11px;
  display: flex;
  background-color: rgba(0, 70, 200, 0.2);
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
  text-decoration: none;
  cursor: pointer;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}
.download-button a {
  text-decoration: none;
}

.download-button:hover {
  box-shadow: 0 0.1em 0.3em 0.09em rgba(0, 0, 0, 0.3);
}
.link-benefitss {
  position: absolute;
  display: block;
  width: calc(100% - 50px);
  bottom: 25px;
}
.moreTravel {
  float: left;
  margin-top: 2px;
}
.link-benefitss img {
  float: right;
}
.link-benefitss img.blue-arrow {
  margin-left: 4px;
  margin-top: 5px;
}
.container-all-benefits {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  justify-items: center;
  margin-top: 0;
  max-width: 1400px;
  width: 90%;
  margin: 0 auto;
  height: auto;
}
.all-benefits {
  padding: 0;
  width: 100%;
  height: auto;
  margin-bottom: 70px;
}
.button-download {
  margin-bottom: 70px;
  width: 90%;
  max-width: 1400px;
  height: auto;
  margin: 0 auto;
  margin-bottom: 80px;
}
.down-benefits {
  color: #ffffff;
  padding: 7px 0 14px;
  margin-left: 7px;
}
h3.download-benefits {
  font-size: 24px;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}
h5.download-bnf {
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

h2.download {
  font-size: 14px;
  color: white;
  font-weight: 500;
  margin-right: 25px;
  margin-top: 4px;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.download-button h2.download {
  background-image: none;
  padding: 3px 10px;
}

.download-button:hover h2.download {
  background: rgba(255,255,255,.2);
  padding: 2px 10px;
  border-radius: 100px;
  background-image: none;
}

.container-image-big-card {
  width: 55%;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  height: 250px;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}

.img-big-card {
  width: 100%;
  visibility: hidden;
}

.benefits-big-card.bg-container-card {
  display: block;
  padding: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-position-x: right;
}
.container-download {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.download-button {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.download_img {
  margin-right: 20px;
  font-size: 30px;
  color: white;
  height: 49px;
  width: 40px;
}
.icon-benefits {
  width: 35px;
}
.icondownload {
  float: left;
  margin-right: 15px;
}

.icondownload img {
  width: 50px;
  height: 50px;
}

.container-content-big-card {
  width: 45%;
  padding: 25px;
}

.benefitBackgroundImage {
  position: relative;
  border-radius: 11px;
}

.benefitBackgroundImage .container-image-big-card {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 11px;
}

.benefitBackgroundImage .container-image-big-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: none;
}

.benefitBackgroundImage .container-content-big-card {
  width: 100%;
  position: relative;
  z-index: 2;
  height: 250px;
}

.WhiteTextBenefit {
  color: white;
}

.benefitBackgroundImage .moreTravelBenefits__card__text {
  max-width: 200px;
}

.WhiteTextBenefit .moreTravelBenefits__card__title {
  color: white;
  max-width: 200px;
}
.WhiteTextBenefit .moreTravelBenefits__card__text {
  color: white;
  max-width: 200px;
}
.WhiteTextBenefit .moreTravelBenefits__card__more {
  color: white;
}
.benefitBackgroundImage .filterBenefitWhiteText {
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 250px;
  left: 0;
  top: 0;
  border-radius: 11px;
}

/* ***** Responsive Styles ***** */

@media screen and (max-width: 1300px) {
}

@media screen and (max-width: 1044px) {
  .container-all-benefits {
    grid-template-columns: repeat(3, 1fr);
  }

  .container-image-big-card {
    background-position: center;
    height: 200px;
  }
}

@media screen and (max-width: 800px) {
  .container-all-benefits{
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }
  .swiperBenefitWrapper{
    margin-bottom: 0;
    display: none;
  }
  .all-benefits{
    margin-bottom: 40px;
  }
  .button-download{
    --safe-area-inset-top: env(safe-area-inset-bottom);
    margin-top: 100px;
    margin-top: calc(100px + var(--safe-area-inset-bottom));
  }
  .down-benefits {
    padding: 9px 0 11px;
  }
  .download-button{
    background-image: none !important;
    background-color: #004990;
  }
  .featuredSwipperHolder .benefits-big-card{
    height: 55vh;
  }
  .benefitBackgroundImage .container-content-big-card{
    height: 55vh;
  }
  .totalBenefits__card__image {
    height: 30vh;
  }
}


@media screen and (max-width: 618px) {
  .moreTravelBenefits__card__text{
    display: none;
  }
  .moreTravelBenefits__card__title{
    font-size: 13px;
    margin-bottom: 0;
  }
  .moreTravelBenefits__card__more{
    font-size: 12px;
  }
  .benefits-simple-card{
    padding: 20px;
    padding-bottom: 10px;
  }
  .container-all-benefits .link-benefitss{
    position: relative;
    top: initial;
    bottom: initial;
    display: block;
    height: auto;
    overflow: hidden;
    width: 100%;
  }
  .link-benefitss img{
    float: none;
  }
  .moreTravel{
    float: none;
  }
  .total-benefits-big{
    height: 19vh;
    padding-bottom: 20px;
  }

  .featuredSwipperHolder .benefits-big-card {
    height: 45vh;
  }
  .benefitBackgroundImage .container-content-big-card{
    height: 45vh;
  }
}

@media screen and (max-width: 400px) {
  .featuredSwipperHolder .benefits-big-card {
    height: 30vh;
  }
  .benefitBackgroundImage .container-content-big-card{
    height: 30vh;
  }

  .container-image-big-card {
    background-position: center;
    height:160px;
  }
  .container-content-big-card {
    width: 50%;
    padding: 20px;
  }
  .link-benefitss {
    width: calc(100% - 35px);
    bottom: 25px;
  }
}

/* ***** Responsive Styles ***** */
