.news-banner-sticky .text-news-banner {
	animation-name: newsAnimation;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	transform: translateX(100%);
}

@keyframes newsAnimation {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(-90%);
	}
}
