* {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
}

.asideContainer {
	/* width: 20%; */
	display: flex;
	/* border-right: 1px solid #D6D6D6; */
	flex-direction: column;
}

.asideHeader {
	height: 168px;
	width: 270px;
	display: block;
	height: auto;
	overflow: hidden;
	padding: 35px 0;
}

.asideHeader__profile {
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
	align-items: center;
}

.faceContainer {
	display: flex;
	width: 50px;
	height: 50px;
	border-radius: 100%;
	overflow: hidden;
	margin-left: 20px;
	background-color: #035cf7;
}

.profileImage {
	width: 100%;
	height: 100%;
	background-image: url(../../../assets/images/profile/default-profile.svg);
}

.nameContainer {
	display: flex;
	flex-direction: column;
	float: left;
}

.nameContainer h6 {
	color: rgba(3, 92, 247, 1);
	font-size: 14px;
	margin: 10px 0 0 18px;
	font-size: 18px;
}

.nameContainer h6:nth-child(2) {
	margin-top: 5px;
	font-size: 16px;
	text-transform: capitalize;
}

.asideMenu1 {
	height: auto;
	display: block;
	border-top: 1px solid #d6d6d6;
	overflow: hidden;
}

.asideMenu1 .accessLinks {
	width: 100%;
	height: auto;
	overflow: hidden;
	margin-top: 15px;
	margin-bottom: 1rem;
}

.asideMenu1 .accessLinks li {
	height: 50px;
	width: 100%;
	overflow: hidden;
	height: 50px;
	width: 87%;
	overflow: hidden;
	margin: 0 auto;
	border-radius: 7px;
}

.side-profile-active {
	background-color: rgba(3, 92, 247, 1);
	color: white;
}

.asideMenu1 .accessLinks .side-profile-active p {
	color: white;
}

.asideMenu1 .accessLinks li:hover {
	background-color: rgba(3, 92, 247, 0.15);
}

.asideMenu1 .accessLinks li.active {
	background-color: rgba(28, 134, 158, 0.2);
}

.asideMenu1 .accessLinks li a {
	width: 100%;
	height: 100%;
	display: block;
}

.asideMenu1 .accessLinks li span {
	float: left;
	width: 30px;
	height: 30px;
	margin-left: 20px;
	margin-top: 9px;
	margin-right: 10px;
}

.asideMenu1 .accessLinks li p {
	float: left;
	line-height: 50px;
	margin: 0;
	padding: 0;
	font-size: 14px;
	font-weight: 400;
	/*color:#666;*/
	color: #0b2343;
}

.asideMenu1 .accessLinks li img.NewTab {
	float: left;
	width: 12px;
	height: 12px;
	margin-top: 20px;
	margin-left: 5px;
	opacity: 0.6;
	display: none;
}

.asideMenu1 .accessLinks li strong svg {
	width: 100%;
	stroke-width: 2px;
}

.asideMenu1 h1 {
	width: 100%;
	height: auto;
	display: block;
	font-size: 16px;
	font-weight: 400;
	margin-top: 30px;
	margin-left: 20px;
	color: #020c41;
}

.menuItems {
	color: #035cf7;
	font-size: 14px;
	padding: 15px;
	text-decoration: none;
	flex-wrap: wrap;
	display: flex;
	color: #035cf7;
}

.asideLists {
	display: flex;
	flex-direction: column;
	width: 100%;
	font-weight: 500;
}

.fa {
	margin-right: 20px;
	font-size: 30px;
}

.asideMenu2 {
	align-items: center;
	border-top: 1px solid #d6d6d6;
	position: absolute;
	bottom: 10px;
	height: 80px;
	width: 100%;
	background-color: white;
	background-color: #ecf0f4;
}

.asideMenu2 ul {
	font-weight: 500;
	padding: 0;
	margin: 0;
}

.asideLinks:hover {
	text-decoration: none;
	background: rgba(214, 214, 214, 0.5);
	border-left: 5px solid #1c869e;
}
.icon_hr {
	margin-right: 20px;
	height: 23px;
	width: 16px;
}

.scrolloverImage {
	width: 100%;
	height: calc(100vh - 150px);
	overflow-x: hidden;
	overflow-y: scroll;
}

@media screen and (max-height: 800px) {
	.asideMenu2 {
		display: none;
	}

	.scrolloverImage {
		height: calc(100vh - 60px);
	}
	.asideMenu1:nth-child(3) {
		padding-bottom: 40px;
	}
}
