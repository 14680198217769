.workToolsSliderWrapper {
  width: 100%;
  height: auto;
  margin-top: 0;
  padding: 20px 0 8px;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 15px;
}

.slideMore {
  float: right;
  display: block;
  text-align: right;
  font-size: 12px;
  color: rgba(0, 125, 195, 1);
  background-color: rgba(0, 125, 195, 0.07);
  padding: 4px 10px;
  border-radius: 4px;
  display: table;
  font-weight: 500;
  display: none;
}

.slideMore img {
  width: 20px;
  height: 20px;
}

.workToolsSliderWrapper .swiper-container-horizontal {
  overflow: visible;
}

.workToolsSliderWrapper
  .swiper-container-horizontal
  > .swiper-pagination-bullets {
  bottom: -25px;
}

.workToolsSliderWrapper
  .swiper-container-horizontal
  > .swiper-pagination-bullets
  span {
  background: #999;
}

.LatestWrap {
  width: 100%;
  height: auto;
  margin-bottom: 0;
}
.LatestGrid {
  width: 100%;
  height: auto;
  overflow: hidden;
  display: block;
  grid-template-columns: repeat(2, auto);
  margin-top: 58px;
}
.LatestGrid a {
  justify-self: end;
  color: rgba(0, 125, 195, 1);
  font-size: 14px;
  font-weight: 500;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
  margin-left: -2%;
}
.LatestGrid a:hover {
  background-color: rgba(0, 125, 195, 1);
  color: white;
  padding: 2px 10px;
  border-radius: 100px;
  text-decoration: none;
}
.LatestTitle {
  margin: 0;
  font-size: 26px;
  display: block;
  color: #0b2343;
  font-size: 24px;
  font-weight: 500;
  float: left;
}

.WorkToolsWrap {
  margin: 30px 0 30px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  justify-items: center;
}
/* @media (max-width: 500px) {
  .WorkToolWrap {
    height: 40vw;
    min-height: 128px;
  }
} */
.WorkToolWrap {
  width: 100%;
  /* width: 270px!important; */
  height: auto;
  border-radius: 11px;
  position: relative;
  display: table;
  overflow: hidden;
  -webkit-transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  padding: 0;
  border: 1px solid #e5e5e5;
  box-shadow: 0 0.15em 0.4em 0.065em rgba(0, 0, 0, 0.075);
  background-color: #fff;
}

.WorkToolWrap:hover {
  border:1px solid rgba(0, 125, 195, 1);
}


.WorkToolWrap .descriptionWorkTool {
  width: 100%;
  height: auto;
  overflow: hidden;
  border-top: 1px solid #e5e5e5;
  min-height: 89px;
}

.WorkToolWrap .descriptionWorkTool .padding {
  padding: 15px 20px;
}

.WorkToolWrap .descriptionWorkTool .padding h4 {
  font-size: 16px;
  margin: 0;
  padding: 0;
  margin-bottom: 6px;
  color: #666;
}

.WorkToolWrap .descriptionWorkTool .padding p {
  font-size: 13px;
  margin: 0;
  padding: 0;
  color: #666;
}

@media (max-width: 1044px) {
  .WorkToolWrap {
    height: 20vw;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 12px;
  }
}
@media (max-width: 800px) {
.LatestTitle{
  font-size: 18px;
  margin-left: 18px;
}
.LatestGrid{
  margin-top: 40px;
}
.newsWrapperWidth{
  /*height: 49vw;*/
  overflow: hidden;
  margin-bottom: 30px;
  margin-top: -40px;
  min-width: 320px;
  min-height: 186px;
  padding: 20px;
}
}

@media (max-width: 500px) {
  .WorkToolWrap {
    height: 40vw;
    min-height: 128px;
  }
}

.WorkToolWrap a {
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
/*
.WorkToolWrap:hover {
  transform: scale(1.1, 1.1);
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5);
}*/
.ImageWorkTool {
  height: 110%;
  position: absolute;
  top: 0;
  z-index: -1;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  object-fit: cover;
  width: 100%;
  display: none;
}
.img-woork-tool {
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  height: 136px;
  width: 160px;
  padding: 10px;
  margin: 0 auto;
  object-fit: contain;
  display: block;
}

@media (max-width: 800px) {
  .WorkToolWrap:hover{
    border: 1px solid #e5e5e5;
  }
  .NpsWrap:hover{
    background-color: transparent;
  }
  .DenunciaReporta{
    display: table;
  }
  .DenunciaReporta .padding{
    vertical-align: middle;
    display: table-cell;
    width: calc(100% - 65px);
  }
  .DenunciaReporta .padding p{
    width: 100%;
  }
  .DenunciaReporta .imagePlane{
    margin-top: 5px;
  }
}

