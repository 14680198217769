.container-notifications {
  position:absolute;
  top: 63px;
  right:-100px;
  width: 420px;
  border: 1px solid #e5e5e5;
  box-shadow: 2px 3px 2px rgba(0, 0, 0, 0.08);
  border-radius: 7px;
  background: white;
  opacity: 0;
  transition: 300ms cubic-bezier(0.2, 0.8, 0.2, 1);
  height: 0;
  overflow: hidden;
  border: 0 solid #e5e5e5;
}

.Language .container-notifications {
  right: -10px;
  width: 80px;
}

.notify-container:hover .container-notifications {
  height: auto;
  overflow: hidden;
  opacity: 1;
  top: 59px;
  border: 1px solid #e5e5e5;
}
.notification-item {
  border-bottom: 1px solid #e5e5e5;
  display: block;
  width: 100%;
  padding: 20px 10px;
}
.notification-item:last-child{
  border-bottom: none;
}
.notification-item:hover{
  background-color:rgba(0, 125, 195, .05);
}
.notification-item a{
  font-size:14px;
  font-weight: 500;
}

.Language .notification-item {
  padding: 10px 10px;
}

.notification-link p{
  float: right;
  color: #666;
  font-size: 14px;
  margin: 0;
  padding: 0;
  font-weight: 400;
}

.notification-link a{
  float: left;
}

.notification-text-small{
  font-size: 13px;
  color: #666;
  padding: 0 20px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.title-notification{
  margin:0;
  font-size:12px;
  letter-spacing: 0.8px;
  font-weight: 500;
  color:#666;
  display: block;
  padding:0;
  width:100%;
}
.notification-text {
  margin: 0;
  color: #0b2343;
  font-weight: 500;
  font-size: 16px;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
  padding: 0 20px;
  margin-bottom: 5px;
  padding-right: 40px;
}

.Language .notification-text {
  padding: 0px 10px;
}

.title-notification-wrap {
  text-align: center;
  padding: 0;
  text-transform: uppercase;
}
.title-notification-wrap{
  background-color: rgba(0, 125, 195, .05);
  padding: 13px 0;
  border-bottom: 1px solid #e5e5e5;
  display: none;
}
.notification-link {
  color:#007dc3;
  padding: 0 20px;
  height: auto;
  overflow: hidden;
  width: 100%;
  display: block;
  margin-top: 10px;
}
.notification-link a:hover{
  color: #007dc3;
}
