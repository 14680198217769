.mainContainerSearchBar {
  padding-bottom: 50px;
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-bottom: 40px;
  margin-top: 50px;
  z-index: 1;
  position: relative;
  background: white;
}

.containerSearchBar {
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 20px;
}

.containerSearchBar input {
  width: calc(100% - 60px);
  border: none;
  font-weight: 500;
  font-size: 52px;
  color: #0b2343;
}

.containerSearchBar input:focus {
  outline: none;
}

.containerSearchBar input::placeholder {
  color: #d6d6d6;
  /* font-size: 52px; */
}

.containerSearchBar i {
  font-size: 52px;
  color: #d6d6d6;
  width: 60px;
  margin-right: 0;
}
.news-search-container .containerSearchBar {
  width: 100%;
}

@media (max-width: 800px) {
.mainContainerSearchBar {
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 50px;
  padding: 10px 0;
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
    padding-bottom: 50px;
    padding-bottom: calc(50px + var(--safe-area-inset-bottom));
}
.containerSearchBar input{
  font-size: 24px;
}
div.containerSearchBar {
  width: 90%;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}
.containerSearchBar i{
  font-size: 23px;
}
.fa-search:before{
  float: right;
}
}