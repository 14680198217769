.fake-clicker {
	cursor: pointer;
}

.fake-clicker:hover .totalBenefits__card__image {
	transform: scale(1.1, 1.1);
	box-shadow: 0 30px 60px rgba(0, 0, 0, 0.3);
}

.fake-clicker .totalBenefits__card__image {
	box-shadow: 0 5px 40px rgba(0, 0, 0, 0.05);
	margin-bottom: 10px;
}

.fake-clicker h5 {
	margin: 0;
	text-align: center;
	width: 100%;
}

.totalBenefis__card .fake-clicker .ownImage {
	background-size: cover !important;
	background-position: center !important;
}

.modal-footer-card-top.benefitsCanada {
	background-size: cover !important;
	background-position: center !important;
	height: 151px;
}
.modal-footer-card-content.benefitsCanada {
	padding: 30px 0 !important;
}
.modal-footer-card-content .modal-card-content__header {
	border-bottom: 2px solid rgba(0, 0, 0, 0.12);
	padding: 0 30px 30px;
}

.modal-footer-card-content article.modal-card-content__body {
	padding: 30px 30px 0;
}

header.modal-card-content__header {
	align-items: center;
	display: flex;
	justify-content: flex-start;
}

header.modal-card-content__header .header--icon-img {
	background-size: contain !important;
	background-position: center !important;
	background-repeat: no-repeat !important;
	height: 35px;
	margin-right: 10px;
	width: 20%;
}

header.modal-card-content__header .header--text-content {
	width: 100%;
}

header.modal-card-content__header .header--text-content h2,
header.modal-card-content__header .header--text-content p {
	margin: 0;
}

header.modal-card-content__header .header--text-content h2 {
	color: #0b2343;
	font-size: 18px;
}

header.modal-card-content__header .header--text-content p {
	font-size: 14px;
	opacity: 0.75;
}

article.modal-card-content__body h3,
article.modal-card-content__body h4 {
	color: #0b2343;
	margin-bottom: 0;
}

article.modal-card-content__body h3 {
	font-size: 25px;
}

article.modal-card-content__body h4 {
	font-size: 20px;
	margin-bottom: 10px;
	opacity: 0.5;
}

article.modal-card-content__body img {
	height: auto;
	width: 100%;
}

article.modal-card-content__body ul {
	margin-left: 18px;
}

article.modal-card-content__body p:last-child {
	margin-bottom: 0;
}

/* .totalBenefits__cards__container.benefitsCanada {
	align-items: flex-start;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.totalBenefits__cards__container.benefitsCanada .totalBenefis__card {
	width: 23%;
}

@media (max-width: 800px) {
	.totalBenefits__cards__container.benefitsCanada .totalBenefis__card {
		width: 45%;
	}
	.totalBenefits__cards__container.benefitsCanada
		.totalBenefis__card:first-child {
		margin-bottom: 20px;
	}
}

@media (max-width: 540px) {
	.totalBenefits__cards__container.benefitsCanada .totalBenefis__card {
		margin-bottom: 20px;
		width: 100%;
	}
} */
