.skyTeamImage {
	width: 100%;
	border-radius: 11px;
	height: 300px;
	margin-top: 40px;
	background-image: url(../../../assets/nosotros/skyteampeople.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}
.topOurPage {
	width: 100%;
	height: auto;
	overflow: hidden;
	background-color: #002b5c;
	padding-top: 70px;
	/* background-image: linear-gradient(to right top, #020c41, #030d75, #0000e3, #035cf7); */
	background-image: linear-gradient(to right top, #020c41, #030d75);
}
.topOurPage .padding {
	padding: 100px;
	height: auto;
	overflow: hidden;
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	padding-top: 60px;
}
.topOurPage .padding .headerTitle {
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	height: auto;
	overflow: hidden;
	padding-bottom: 20px;
}
.topOurPage .padding .textWrapperGpo {
	float: left;
	width: calc(100% - 200px);
	height: auto;
	overflow: hidden;
	padding-right: 20px;
}
.topOurPage .padding .titleGpoAM {
	font-size: 52px;
	font-weight: 600;
	color: white;
	margin: 0;
	padding: 0;
}
.topOurPage .padding .smallText {
	font-size: 17px;
	color: white;
	max-width: 600px;
}

.topOurPage .padding .imgGpo {
	width: 200px;
	height: 71px;
	float: right;
	background-image: url("../assets/gam-white.png");
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	margin-top: 6px;
}

/**

<div className="headerTitle">
              <p className="titleGpoAM">Grupo Aeroméxico</p>
              <div className="imgGpo"></div>
            </div>

            **/
.topOurPage .padding .visionPageLeft {
	float: left;
	width: 36%;
	border-right: 1px solid rgba(255, 255, 255, 0.2);
	padding-right: 35px;
	height: auto;
	overflow: hidden;
	padding-top: 30px;
}
.topOurPage .padding .paddingRightVision {
	float: left;
	width: 64%;
	height: auto;
	overflow: hidden;
	padding-left: 55px;
	padding-top: 23px;
	padding-bottom: 50px;
}
.topOurPage .padding .paddingRightVision .titlevis {
	color: white;
	font-weight: 600;
	font-size: 38px;
	line-height: 1.2;
	margin-top: 20px;
}
.topOurPage .padding .paddingRightVision .titlevis.comportamientosTitle {
	margin-top: 40px;
}
.topOurPage .padding .paddingRightVision .visionBody {
	color: white;
	font-weight: 300;
	font-size: 24px;
}
.topOurPage .padding .paddingRightVision .visionBody span {
	font-weight: 600;
}
.comportamientosCX {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 20px;
	margin-top: 20px;
}
.comportamientosCX .imgcomportamiento {
	width: 110px;
	height: 110px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	cursor: pointer;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	border-radius: 50%;
	border: 1px solid #0b2343;
}
.comportamientosCX .imgcomportamiento:hover {
	/* transform: scale(1.1,1.1); */
	border: 1px solid #fff;
	box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
}
.comportamientosCX .imgcomportamiento.es:nth-child(1) {
	background-image: url("../assets/valores/seguridad-circle.png");
}
.comportamientosCX .imgcomportamiento.es:nth-child(2) {
	background-image: url("../assets/valores/integridad-circle.png");
	/* background-image: url("../assets/valores/disfruta-circle.png"); */
}
.comportamientosCX .imgcomportamiento.es:nth-child(3) {
	background-image: url("../assets/valores/excelencia-circle.png");
}
.comportamientosCX .imgcomportamiento.es:nth-child(4) {
	/* background-image: url("../assets/valores/integridad-circle.png"); */
	background-image: url("../assets/valores/lidera.png");
	background-size: 120%;
}
.comportamientosCX .imgcomportamiento.es:nth-child(5) {
	background-image: url("../assets/valores/agil-circle.png");
	background-size: 120%;
}
.comportamientosCX .imgcomportamiento.es:nth-child(6) {
	background-image: url(../../../assets/nosotros/disfruta.png);
	background-size: 120%;
}

.comportamientosCX .imgcomportamiento.en:nth-child(1) {
	background-image: url(../../../assets/nosotros/en_seguridad.png);
}
.comportamientosCX .imgcomportamiento.en:nth-child(2) {
	background-image: url(../../../assets/nosotros/en_integridad.png);
}
.comportamientosCX .imgcomportamiento.en:nth-child(3) {
	background-image: url(../../../assets/nosotros/en_equipo.png);
}
.comportamientosCX .imgcomportamiento.en:nth-child(4) {
	background-image: url(../../../assets/nosotros/en_excelencia.png);
}

/****

<div className="comportamientosCX">
                <div className="imgcomportamiento"></div>
                <div className="imgcomportamiento"></div>
                <div className="imgcomportamiento"></div>
                <div className="imgcomportamiento"></div>
              </div>

<div className="paddingRightVision">
              <p className="titlevis">Nuestros valores</p>
              <p className="visionBody">Asegurar el crecimiento sostenido brindando un servicio consistente desde el corazón y creando experiencias únicas con seguridad y disciplina</p>
              <p className="titlevis comportamientosTitle">Nuestros valores</p>
            </div>
****/

.topOurPage .padding .visionPageLeft p.titlevis {
	color: white;
	font-weight: 600;
	font-size: 38px;
	line-height: 1.2;
	max-width: 200px;
}

.topOurPage .padding .visionPageLeft p.visionBody {
	color: white;
	font-weight: 300;
	font-size: 20px;
	margin-bottom: 8px;
}
.topOurPage .padding .visionPageLeft p.visionBody span {
	font-weight: 600;
}
.topOurPage .padding .visionPageLeft .numberone {
	height: 70px;
	width: 70px;
	margin-top: 0;
	background-image: url(../../../assets/nosotros/one.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.topOurPage .padding .visionPageLeft .propositoIMG {
	height: 70px;
	width: 70px;
	margin-top: 0;
	background-image: url(../../../assets/nosotros/one.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}
/***

<div className="topOurPage">
          <div className="padding">
            <div className="visionPageLeft">
              <p className="titlevis">Nuestra visión</p>
              <p className="visionBody">Ser la opción #1 por brindar la mejor experiencia de vuelo personalizada y consistente con un espíritu de calidez y servicio</p>
              <div className="numberone"></div>
            </div>
          </div>
        </div>


        */

.nosotrosWrapper {
	width: 100%;
	height: auto;
	overflow: hidden;
	padding-top: 50px;
	margin-bottom: 40px;
	padding-bottom: 50px;
	border-bottom: 1px solid #d6d6d6;
	background-color: #edf6fb;
}
.tablewrapper h4 {
	margin-top: 40px;
	font-size: 24px;
}
.nosotrosWrapper p,
.nosotrosWrapper a,
.nosotrosWrapper ul li {
	color: #0b2343;
}
.nosotrosWrapper .nosotrosHeader {
	width: 90%;
	max-width: 1400px;
	height: auto;
	overflow: hidden;
	margin: 0 auto;
}
.nosotrosWrapper .nosotrosHeader h4 {
	font-size: 50px;
	text-align: center;
	font-weight: 600;
	color: #0b2343;
}
.nosotrosWrapper .nosotrosHeader .LogoCaballero {
	width: 100px;
	height: 100px;
	margin: 0 auto;
	margin-top: 70px;
	background-image: url(../../../assets/identidad/caballero.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.2));
}
.nosotrosWrapper .nosotrosHeader p {
	text-align: center;
	font-size: 20px;
	font-weight: 400;
	color: #0b2343;
	max-width: 800px;
	margin: 0 auto;
}

.rightWrappermenu span.ourhovera {
	width: 153px;
	height: 50px;
	background: #e91b2f;
	border-radius: 50px;
	color: #ffffff;
	text-align: center;
	padding: 10px;
	display: block;
	margin-top: 20px;
	display: table;
	width: auto;
	padding-left: 20px;
	padding-right: 20px;
}

.tablewrapper .wrapperMidTable {
	width: 90%;
	max-width: 1000px;
	height: auto;
	margin: 0 auto;
}
.wrapperFlex {
	width: 100%;
	display: flex;
	margin-bottom: 80px;
}
.fixedMenuNosotros {
	width: 250px;
	margin-top: 10px;
	display: none;
}
.tablewrapper .wrapperMidTable .leftWrapperMenu {
	position: sticky;
	top: 90px;
}
.tablewrapper .wrapperMidTable .leftWrapperMenu ol {
	padding-left: 20px;
	list-style: disc;
}

.tablewrapper .wrapperMidTable .leftWrapperMenu ol li {
	font-weight: 500;
	font-size: 16px;
	margin: 11px 0;
	cursor: pointer;
	padding-left: 0;
}
.tablewrapper .wrapperMidTable .leftWrapperMenu ol li a {
	color: #0b2343;
	padding: 4px 10px;
	border-radius: 7px;
}
.tablewrapper .wrapperMidTable .leftWrapperMenu ol ul {
	display: block;
	list-style: none;
	color: #666;
	margin-bottom: 30px;
}

.tablewrapper .wrapperMidTable .leftWrapperMenu ol ul a {
	color: #666;
	font-weight: 400;
}

.tablewrapper .wrapperMidTable .leftWrapperMenu ol li a:hover {
	background-color: rgba(0, 125, 195, 0.04);
}
.tablewrapper .wrapperMidTable .leftWrapperMenu ol li ul li a {
	color: #666;
}
.tablewrapper .wrapperMidTable .leftWrapperMenu ol li ul {
	margin-bottom: 30px;
	list-style: none;
}
.tablewrapper .wrapperMidTable .leftWrapperMenu ol li ul li {
	font-weight: 400;
	color: #666;
	padding-left: 0;
}
.tablewrapper .wrapperMidTable .rightWrappermenu {
	width: 100%;
	height: auto;
	margin: 0 auto;
}
.tablewrapper .wrapperMidTable .rightWrappermenu div.sections {
	width: 100%;
	height: auto;
	margin-top: 30px;
	border-bottom: 1px solid #e5e5e5;
	padding-bottom: 40px;
}
.tablewrapper .wrapperMidTable .rightWrappermenu div#caballero {
	margin-top: 0;
	border-bottom: 1px solid #e5e5e5;
	padding-bottom: 40px;
}

.titleNosotros {
	width: 100%;
	height: auto;
	overflow: hidden;
	margin-bottom: 15px;
	background-color: white;
	/* position: sticky; */
	top: 120px;
	/* z-index: 30; */
	padding-top: 20px;
	padding-bottom: 10px;
}
.titleNosotros .circuloImage {
	width: 50px;
	height: 50px;
	background-color: red;
	float: left;
	border-radius: 50%;
	margin-right: 15px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

#vision .titleNosotros .circuloImage {
	background-color: #004990;
	background-image: url(../../../assets/nosotros/ourvision.svg);
}

#caballero .titleNosotros .circuloImage {
	background-color: #0b2343;
	background-image: url(../../../assets/identidad/caballero-blanco.svg);
}

#cultura .titleNosotros .circuloImage {
	background-color: #1c869e;
	background-image: url(../../../assets/nosotros/culture.svg);
}

#aeropuertos .titleNosotros .circuloImage {
	background-color: #007dc3;
	background-image: url(../../../assets/nosotros/plane.svg);
}

#skyteam .titleNosotros .circuloImage {
	background-color: #5f3fb1;
	background-image: url(../../../assets/nosotros/skyteam.svg);
}

#subsidiarias .titleNosotros .circuloImage {
	background-color: #002b5c;
	background-image: url(../../../assets/nosotros/clouds.svg);
}

#flota-destinos .titleNosotros .circuloImage {
	background-color: #e91b2f;
	background-image: url(../../../assets/nosotros/rutas.svg);
}

#flota-destinos {
	border-bottom: none;
	padding-bottom: 0;
}

.titleNosotros h3 {
	font-weight: 600;
	float: left;
	margin-top: 3px;
	font-size: 34px;
}

.tablewrapper p {
	font-size: 18px;
}

.tablewrapper a.link {
	color: #007dc3;
	font-weight: 600;
	font-size: 16px;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	padding-left: 7px;
}

.tablewrapper a.link:hover {
	background-color: rgba(0, 125, 195, 1);
	color: white;
	padding: 2px 10px;
	border-radius: 100px;
	text-decoration: none;
	opacity: 1;
}
#cultura {
	margin-top: 30px;
	border-bottom: 1px solid #e5e5e5;
	padding-bottom: 30px;
}

.visionFrase {
	height: auto;
	overflow: hidden;
	width: 100%;
	margin-top: 25px;
}
.visionFrase .numberOne {
	width: 100px;
	height: 100px;
	float: left;
	margin-right: 15px;
	background-image: url(../../../assets/nosotros/numero.svg);
}

.visionFrase .numberVision {
	background-image: url(../../../assets/nosotros/numero01.svg);
	display: none;
}

.visionFrase .fraseVision {
	font-weight: 500;
	font-size: 22px;
	margin-top: 0;
	float: left;
	width: calc(100% - 115px);
	margin-top: 13px;
}

/* .visionFrase .cxTyping{
    padding-left: 116px;
} */

.visionFrase .frasevisionOK {
	margin-top: 0;
}

.visionFrase .fraseVision strong {
	font-weight: 600;
	font-style: italic;
}

#aeropuertos {
	margin-top: 30px;
	padding-bottom: 0;
}

#aeropuertos .airportsGrid {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 20px;
	margin-bottom: 50px;
}
#aeropuertos .airportsGrid .gridOption {
	width: 100%;
	border: 1px solid #d6d6d6;
	border-radius: 11px;
	height: 168px;
	background-color: white;
	box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
	text-decoration: none;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	cursor: pointer;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	position: relative;
	overflow: hidden;
	margin-top: 30px;
}
#aeropuertos .airportsGrid .gridOption .gridText {
	position: absolute;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.9);
	width: 100%;
}
#aeropuertos .airportsGrid .gridOption .gridText p {
	font-size: 18px;
	font-weight: 500;
	padding: 10px 0;
	margin: 0;
	text-align: center;
	color: #0b2343;
}
#aeropuertos .airportsGrid .gridOption:nth-child(1) {
	background-image: url(../../../assets/nosotros/cancun.png);
}
#aeropuertos .airportsGrid .gridOption:nth-child(2) {
	background-image: url(../../../assets/nosotros/monterrey.png);
}
#aeropuertos .airportsGrid .gridOption:nth-child(3) {
	background-image: url(../../../assets/nosotros/tijuana.png);
}
#aeropuertos .airportsGrid .gridOption:nth-child(4) {
	background-image: url(../../../assets/nosotros/guadalajara.png);
}

#aeropuertos .airportsGrid .gridOption:hover {
	transform: scale(1.04, 1.04);
	box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
}

#aeropuertos .imgCDMX {
	width: 100%;
	border-radius: 11px;
	height: 300px;
	margin-top: 40px;
	background-image: url(../../../assets/nosotros/cdmx.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

#skyteam .planesSkyteam {
	background-image: url(../../../assets/nosotros/skyteam-team.jpg);
	width: 100%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	border-radius: 11px;
	height: 134px;
	margin: 30px 0;
}

#subsidiarias .logoSubsidiarias {
	width: 100%;
	height: auto;
	overflow: hidden;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 20px;
}

#subsidiarias .logoSubsidiarias .SubsidiariaCont {
	width: 100%;
	height: auto;
	overflow: hidden;
}

#subsidiarias .logoSubsidiarias .SubsidiariaCont p {
	font-size: 14px;
	line-height: 1.4;
}

#subsidiarias .logoSubsidiarias .SubsidiariaCont .imgSub {
	width: 220px;
	height: 90px;
	margin-bottom: 10px;
	background-position: left center;
	background-repeat: no-repeat;
	background-size: contain;
}

#subsidiarias .logoSubsidiarias .SubsidiariaCont:nth-child(4) .imgSub {
	width: 202px;
	height: 70px;
	margin-bottom: 10px;
	background-position: left center;
	background-repeat: no-repeat;
	background-size: contain;
}

#subsidiarias .logoSubsidiarias .SubsidiariaCont:nth-child(5) .imgSub {
	width: 162px;
	height: 70px;
	margin-bottom: 10px;
	background-position: left center;
	background-repeat: no-repeat;
	background-size: contain;
}

#subsidiarias .logoSubsidiarias .SubsidiariaCont:nth-child(6) .imgSub {
	width: 162px;
	height: 70px;
	margin-bottom: 10px;
	background-position: left center;
	background-repeat: no-repeat;
	background-size: contain;
}

#subsidiarias .logoSubsidiarias .SubsidiariaCont:nth-child(1) .imgSub {
	background-image: url("../assets/subsidiarias/amx-cargo.png");
}
#subsidiarias .logoSubsidiarias .SubsidiariaCont:nth-child(2) .imgSub {
	background-image: url("../assets/subsidiarias/amx-connect.png");
}
#subsidiarias .logoSubsidiarias .SubsidiariaCont:nth-child(3) .imgSub {
	background-image: url("../assets/subsidiarias/amx-formacion.png");
}
#subsidiarias .logoSubsidiarias .SubsidiariaCont:nth-child(4) .imgSub {
	background-image: url("../assets/subsidiarias/amx-servicios.png");
}
#subsidiarias .logoSubsidiarias .SubsidiariaCont:nth-child(5) .imgSub {
	background-image: url("../assets/subsidiarias/amx-rewards.png");
}
#subsidiarias .logoSubsidiarias .SubsidiariaCont:nth-child(6) .imgSub {
	background-image: url(../../../assets/nosotros/csc.png);
}

#cultura .comportamientoWrapper {
	width: 100%;
	margin-top: 30px;
	height: auto;
	overflow: hidden;
	display: block;
}
#cultura .comportamientoWrapper .leftComportaImage {
	width: 200px;
	float: left;
	height: 200px;
}
#cultura .comportamientoWrapper .leftComportaImage .circuloCompo {
	width: 170px;
	height: 170px;
	border-radius: 50%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}
#cultura .comportamientoWrapper.seguridad-comp .leftComportaImage .circuloCompo.es {
	background-image: url("../assets/valores/seguridad-circle.png");
}
#cultura .comportamientoWrapper.excelencia-comp .leftComportaImage .circuloCompo.es {
	background-image: url("../assets/valores/excelencia-circle.png");
}
#cultura .comportamientoWrapper.integridad-comp .leftComportaImage .circuloCompo.es {
	background-image: url("../assets/valores/integridad-circle.png");
}
#cultura .comportamientoWrapper.inte-comp .leftComportaImage .circuloCompo.es {
	background-image: url("../assets/valores/integridad-circle.png");
}
#cultura .comportamientoWrapper.lidera-comp .leftComportaImage .circuloCompo.es {
	background-image: url("../assets/valores/lidera.png");
}
#cultura .comportamientoWrapper.agil-comp .leftComportaImage .circuloCompo.es {
	background-image: url("../assets/valores/agil-circle.png");
}

#cultura .comportamientoWrapper.seguridad-comp .leftComportaImage .circuloCompo.en {
	background-image: url(../../../assets/nosotros/en_seguridad.png);
}
#cultura .comportamientoWrapper.excelencia-comp .leftComportaImage .circuloCompo.en {
	background-image: url(../../../assets/nosotros/en_excelencia.png);
}
#cultura .comportamientoWrapper.equipo-comp .leftComportaImage .circuloCompo.en {
	background-image: url(../../../assets/nosotros/en_equipo.png);
}
#cultura .comportamientoWrapper.inte-comp .leftComportaImage .circuloCompo.en {
	background-image: url(../../../assets/nosotros/en_integridad.png);
}
#cultura .comportamientoWrapper.agil-comp .leftComportaImage .circuloCompo {
	background-image: url("../assets/valores/agil-circle.png");
	/* width: 200px;
	height: 200px;
	position: absolute;
	right: 10px;
	top: -20px; */
}
#cultura .comportamientoWrapper.fomenta-comp .leftComportaImage .circuloCompo {
	background-image: url(../../../assets/nosotros/fomenta.png);
	width: 200px;
	height: 200px;
	position: absolute;
	right: 10px;
	top: -20px;
}
#cultura .comportamientoWrapper.disfruta-comp .leftComportaImage .circuloCompo {
	background-image: url("../assets/valores/disfruta-circle.png");
	/* width: 200px;
	height: 200px;
	position: absolute;
	right: 10px;
	top: -20px; */
}

#cultura .comportamientoWrapper .textRightWrap h6 {
	font-weight: 600;
	/* color: #999; */
}

#cultura .comportamientoWrapper .textRightWrap h6.smallgray {
	font-weight: 600;
	color: #999;
}

#cultura .en_seguridad-comp .textRightWrap h6 {
	color: #018c91 !important;
}

#cultura .excelencia-comp .textRightWrap h6 {
	color: #f97722;
}

#cultura .equipo-comp .textRightWrap h6 {
	color: #fbaf28;
}

#cultura .inte-comp .textRightWrap h6 {
	color: #e91b2f;
}

#cultura .comportamientoWrapper .textRightWrap {
	width: calc(100% - 200px);
	float: left;
	height: auto;
	overflow: hidden;
}

#cultura .comportamientoWrapper .textRightWrap ol {
	padding-left: 20px;
}

.imageCultura {
	width: 100%;
	border-radius: 11px;
	height: 300px;
	margin-top: 40px;
	background-image: url(../../../assets/nosotros/places.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	margin-bottom: 40px;
}
p.values {
	font-size: 22px;
	font-weight: 500;
}
p.values strong {
	font-style: italic;
	font-weight: 600;
}
/*

      */

.comportamientosModal {
	position: fixed;
	background-color: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100vh;
	z-index: 99999;
}
.comportamientosModal .modalWindow {
	width: 700px;
	height: 500px;
	background-color: white;
	border-radius: 11px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
}

.comportamientosModal .modalWindow .padding {
	padding: 40px;
}
.culturaVision {
	width: 80%;
	/*border: 1px solid #000;*/
	height: auto;
	overflow: hidden;
	background-color: white;
	border-radius: 7px;
	/*border: 1px solid #e5e5e5;*/
	/*box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);*/
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	position: relative;
	padding: 20px;
	margin: 0 auto;
	position: relative;
	/* z-index: 6; */
	margin-top: -100px;
	padding: 30px 60px;
	margin-top: 35px;
}

.culturaVision h4 {
	font-size: 46px;
	font-weight: 600;
	line-height: 1;
	text-align: left;
	margin-bottom: 20px;
	text-transform: uppercase;
}

.culturaVision h4.Small {
	font-size: 24px;
	/*border-top: 1px solid #d6d6d6;*/
	padding-top: 30px;
	text-transform: capitalize;
}

.culturaVision p,
.culturaVision h2 {
	font-size: 20px;
	font-weight: 400;
	color: #666666;
	text-align: left;
	/* max-width: 90%; */
	/* margin-left: auto; */
	/* margin-right: auto; */
}

.culturaVision p:nth-child(3) {
	margin-bottom: 30px;
}

.culturaVision h2 {
	color: #0b2343;
	font-size: 25px;
	/*max-width: 90%;*/
	margin: 0 auto;
	line-height: 1.5;
}

/*
<div className="culturaVision">
                <h4>Nuestra Cultura</h4>
                <p>En Grupo Aeroméxico <strong>vivimos nuestra Cultura de Excelencia</strong> a través de los <strong>valores y comportamientos</strong> que guían nuestro negocio y dirigen nuestro trabajo.</p>
                <p>Y aunque nuestra compañía se encuentra en constante evolución, estos valores y comportamientos permanecen estáticos, pues se espera que cada uno de nosotros los <strong>adoptemos y vivamos día con día para llegar a ser la opción #1 de nuestros clientes.</strong></p>
                <h2>Asegurar el <strong>crecimiento sostenido</strong> brindando <strong>un servicio consistente</strong> desde el corazón y creando experiencias únicas con <strong>seguridad y disciplina</strong></h2>
          </div>
*/
.logoAM {
	width: 100%;
	max-width: 1400px;
	margin: 0 auto;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	padding: 50px 0;
}

.subText {
	width: 100%;
	text-align: center;
	font-size: 32px;
	margin-top: 48px;
	text-transform: uppercase;
}

.logoAM .wrapperLogo {
	padding-right: 40px;
}

.logoAM .wrapperLogo .img {
	width: 90%;
	height: 100px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	background-image: url(../../../assets/cargo.jpg);
}

.logoAM .wrapperLogo:nth-child(2) .img {
	background-image: url(../../../assets/connect.jpeg);
}
.logoAM .wrapperLogo:nth-child(3) .img {
	background-image: url(../../../assets/forma.jpeg);
}
.logoAM .wrapperLogo:nth-child(4) .img {
	background-image: url(../../../assets/serv.jpeg);
}
.logoAM .wrapperLogo:nth-child(5) .img {
	background-image: url(../../../assets/cb.png);
}

.imageDummy {
	width: 100%;
	margin: 0 auto;
	height: 6700px;
	background-repeat: no-repeat;
	background-position: top top;
	background-size: contain;
}

.ImgLoginPage {
	width: 100%;
	height: 600px;
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
	/* z-index: 2; */
}

.transformation {
	width: 100%;
	height: auto;
	overflow: hidden;
	position: relative;
}

.transformation .headertransformation {
	height: 600px;
	width: 100%;
	background-color: rgba(0, 30, 66, 0.7);
	position: relative;
	/* z-index: 4; */
}

.transformation .headertransformation .wrapperMidTable {
	min-width: 1400px;
	width: 80%;
	margin: 0 auto;
	height: 100%;
	display: table;
	/*display: grid;
    grid-template-columns: repeat(2,auto);*/
}

.transformation .headertransformation .wrapperMidTable .transformationCell {
	width: 100%;
	height: 100%;
	display: table-cell;
	vertical-align: middle;
}

.transformation .headertransformation .wrapperMidTable .transformationCell,
.transformation .headertransformation .wrapperMidTable .tranformationright {
	width: 100%;
	height: 100%;
	position: relative;
}

.transformation .headertransformation .wrapperMidTable .tranformationright {
	width: 40%;
	border-left: 1px solid rgba(255, 255, 255, 0.5);
	padding-left: 50px;
}

.transformation .headertransformation .wrapperMidTable .transformationCell h1 {
	font-size: 72px;
	font-weight: 500;
	color: white;
	margin: 0;
	padding: 0;
	line-height: 1;
	max-width: 45%;
	height: min-content;
}
.transformation .headertransformation .wrapperMidTable .transformationCell p {
	font-weight: 400;
	font-size: 26px;
	color: white;
	max-width: 700px;
	line-height: 1.4;
	margin-top: 40px;
}

.transformation .headertransformation .wrapperMidTable .transformationCell ul {
	list-style: none;
	color: white;
	font-size: 32px;
	font-weight: 500;
	display: block;
	cursor: pointer;
	margin-top: 40px;
	position: absolute;
	bottom: 50px;
}

.transformation .headertransformation .wrapperMidTable .transformationCell ul li {
	margin-top: 10px;
	font-size: 26px;
}

.transformation .headertransformation .wrapperMidTable .tranformationright p:nth-child(1) {
	font-weight: 500;
	font-size: 26px;
	color: white;
	max-width: 350px;
	line-height: 1.4;
	margin-top: 20vh;
}

.transformation .headertransformation .wrapperMidTable .tranformationright p:nth-child(2) {
	font-weight: 300;
	font-size: 18px;
	color: white;
	max-width: 350px;
	line-height: 1.4;
	margin-top: 40px;
}

.misiondiv {
	width: 100%;
	height: auto;
	overflow: hidden;
	background-color: white;
	border-radius: 7px;
	box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	position: relative;
	padding: 15px;
	margin: 0 auto;
	position: relative;
	z-index: 6;
	margin-top: -100px;
	background-color: #0b2343;
}

.misiondiv .misionpadding {
	padding: 20px 40px;
	width: 90%;
	margin: 0 auto;
	height: auto;
	overflow: hidden;
}

.misiondiv .misionpadding .missionon {
	float: left;
	width: 50%;
}

.misiondiv .misionpadding .missionon .texttitle {
	font-size: 46px;
	font-weight: 600;
	float: left;
	padding-left: 30px;
	width: 30px;
	line-height: 1;
	margin-top: 42px;
	color: white;
	text-transform: uppercase;
}

.misiondiv .misionpadding .missionon .textsub {
	font-size: 24px;
	font-weight: 400;
	color: white;
	float: left;
	opacity: 1;
}

.misiondiv .misionpadding .missionon:nth-child(1) .textsub {
	padding-right: 100px;
	margin-top: 25px;
	color: white;
	opacity: 0.6;
	font-size: 20px;
}

.misiondiv .misionpadding .missionon .textsub strong {
	color: white;
	opacity: 0.8;
}

.misiondiv .misionpadding .floatmission {
	float: left;
	width: 300px;
	border: 1px solid #f0f;
	height: 300px;
}

.misiondiv .misionpadding .floatmission01 {
	float: left;
	width: calc(100% - 300px);
	height: 300px;
	padding-left: 40px;
}
.Visonlogo {
	width: 170px;
	height: 170px;
	float: left;
}

.misiondiv .misionpadding .floatmission01 p:nth-child(1) {
	font-size: 32px;
	font-weight: 500;
}

.misiondiv .misionpadding .floatmission01 p:nth-child(2) {
	font-size: 18px;
	font-weight: 400;
}

.comportamientos {
	width: 100%;
	height: auto;
	overflow: hidden;
	background-color: #0b2343;
	/*margin-top: -200px;*/
}

.comportamientos .padding {
	width: 80%;
	height: auto;
	margin: 0 auto;
	grid-template-columns: repeat(4, 1fr);
	display: grid;
}

.comportamientos .padding .comportamiento01 {
	border-left: 1px solid rgba(255, 255, 255, 0.2);
	padding: 0 30px;
}

.comportamientos .padding .comportamiento01:nth-child(1) {
	border-left: none;
}

.comportamientos .padding .comportamiento01 p {
	text-align: center;
	font-size: 16px;
	padding-bottom: 20px;
	color: white;
}

.comportamientos .padding .comportamiento01 h3 {
	color: white;
	font-size: 18px;
	font-weight: 600;
}

.comportamientos .padding .comportamiento01 ul {
	color: white;
	margin: 0;
	padding: 0;
	font-size: 12px;
	margin-bottom: 50px;
}

.comportamientos .padding .comportamiento01 img {
	width: 200px;
	height: 200px;
	margin: 0 auto;
	display: block;
	margin-bottom: 20px;
	box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.1);
	border-radius: 100%;
	background-color: white;
}

.TituloComportamiento {
	font-size: 24px;
	text-align: left;
	margin-top: 50px;
	margin-bottom: 40px;
	color: white;
	/* margin-top: 245px; */
	width: 72%;
	margin: 40px auto 20px;
	font-weight: 600;
}

.lograrlo {
	height: auto;
	overflow: hidden;
	background-color: rgba(0, 125, 195, 0.05);
	height: 28.51vw;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	width: 100%;
}

.oknew {
	height: auto;
	overflow: hidden;
	background-color: rgba(0, 125, 195, 0.05);
	height: 28.51vw;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	width: 100%;
}

.lograrlo .midlograrlo {
	width: 80%;
	height: 100%;
	overflow: hidden;
	margin: 0 auto;
}

.lograrlo .midlograrlo p:nth-child(1) {
	font-size: 24px;
	text-align: center;
	font-weight: 500;
	margin-top: 60px;
}

.lograrlo .midlograrlo p:nth-child(2) {
	font-size: 16px;
	color: #666;
	text-align: center;
	max-width: 700px;
	margin: 0 auto;
}

.lograrlo .midlograrlo img {
	border: 1px solid #d6d6d6;
	margin: 0 auto;
	display: block;
	border-radius: 11px;
	height: 290px;
	margin-top: 40px;
	margin-bottom: 70px;
	width: 1000px;
	background-color: white;
}

.destinosFlota {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 30px;
}
.destinosFlota .gridDestinosFlota {
	width: 100%;
	border: 1px solid #d6d6d6;
	border-radius: 11px;
	height: 100px;
	background-color: white;
	box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
	text-decoration: none;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	cursor: pointer;
	-webkit-transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	position: relative;
	overflow: hidden;
	padding: 20px;
	margin-bottom: 14px;
}

.destinosFlota .gridDestinosFlota:hover {
	transform: scale(1.01, 1.01);
	box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
}
.destinosFlota .gridDestinosFlota .iconDestino {
	float: left;
	width: 60px;
	height: 60px;
	margin-right: 25px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.destinosFlota .gridDestinosFlota:nth-child(1) .iconDestino {
	background-image: url("../../../assets/nosotros/nuestraflota.svg");
}
.destinosFlota .gridDestinosFlota:nth-child(2) .iconDestino {
	background-image: url("../../../assets/nosotros/nuestrosdestinos.svg");
}

.destinosFlota .gridDestinosFlota a {
	font-weight: 500;
	text-transform: 14px;
	color: #007dc3;
}
.destinosFlota .gridDestinosFlota .tableDestino {
	width: calc(100% - 85px);
	height: 100%;
	float: left;
	display: table;
}
.destinosFlota .gridDestinosFlota .tableDestino .tableCellDestino {
	display: table-cell;
	vertical-align: middle;
}
.destinosFlota .gridDestinosFlota .tableDestino .tableCellDestino p,
.destinosFlota .gridDestinosFlota .tableDestino .tableCellDestino a {
	margin: 0;
	padding: 0;
}

.destinosFlota .gridDestinosFlota .tableDestino .tableCellDestino p {
	font-size: 18px;
	font-weight: 500;
	color: #0b2343;
}

/*
<div className="destinosFlota">
                    
                    <div className="gridDestinosFlota">
                      <div className="iconDestino"></div>
                      <div className="tableDestino">
                        <div className="tableCellDestino">
                          <p>Destinos</p>
                          <a href="https://www.aeromexico.com/es-mx/destinos" target="_blank">Conoce más</a>
                        </div>
                      </div>
                    </div>
*/

@media (max-width: 1044px) {
	.titleNosotros {
		top: 0;
	}
}

@media (max-width: 970px) {
	#subsidiarias .logoSubsidiarias {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (max-width: 794px) {
	#cultura .comportamientoWrapper .textRightWrap {
		width: 100%;
		float: left;
	}
}

@media (max-width: 784px) {
	.fixedMenuNosotros {
		width: 198px;
		margin-top: 10px;
	}
	.tablewrapper .wrapperMidTable .rightWrappermenu {
		width: calc(100% - 198px);
	}
}

@media (max-width: 600px) {
	.fixedMenuNosotros {
		display: none;
	}
	.tablewrapper .wrapperMidTable .rightWrappermenu {
		width: 100%;
	}
	#aeropuertos .airportsGrid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 20px;
	}
	.nosotrosWrapper .nosotrosHeader h4 {
		font-size: 30px;
	}
	.nosotrosWrapper .nosotrosHeader p {
		font-size: 16px;
	}
	.titleNosotros h3 {
		font-size: 24px;
		margin-top: 11px;
	}
	.tablewrapper p {
		font-size: 16px;
	}
	#vision .visionFrase .numberOne {
		float: none;
	}
	#vision .visionFrase .fraseVision {
		float: none;
	}
	#cultura .comportamientoWrapper .textRightWrap {
		width: 100%;
		float: left;
	}
	#subsidiarias .logoSubsidiarias {
		grid-template-columns: repeat(1, 1fr);
	}
	p.values {
		font-size: 22px;
		font-weight: 500;
	}
}

@media (max-width: 1044px) {
	.topOurPage {
		padding-top: 20px;
	}
	.topOurPage .padding .titleGpoAM {
		font-size: 42px;
	}
	.topOurPage .padding .visionPageLeft p.titlevis {
		font-size: 32px;
	}
	.topOurPage .padding .paddingRightVision .titlevis {
		font-size: 28px;
	}
	.topOurPage .padding .visionPageLeft p.visionBody {
		font-size: 20px;
	}
	.topOurPage .padding .paddingRightVision .visionBody {
		font-size: 20px;
	}
	.topOurPage .padding .visionPageLeft .numberone {
		width: 60px;
		height: 60px;
	}
	.comportamientosCX {
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 15px;
	}
	.comportamientosCX .imgcomportamiento {
		width: 100px;
		height: 100px;
	}
	.topOurPage .padding {
		padding: 70px;
	}

	#cultura .comportamientoWrapper .leftComportaImage .circuloCompo {
		width: 140px;
		height: 140px;
	}

	.titleNosotros h3 {
		font-size: 28px;
	}

	.tablewrapper p {
		font-size: 16px;
	}

	.visionFrase .fraseVision {
		font-size: 18px;
	}

	.visionFrase .numberOne {
		width: 80px;
		height: 80px;
	}

	.titleNosotros {
		position: static;
	}
}

.topOurPage .padding .imgGpo-res {
	width: 0px;
	height: 0px;
}

@media (max-width: 900px) {
	.topOurPage .padding {
		padding: 60px;
	}
	.comportamientosCX .imgcomportamiento {
		width: 90px;
		height: 90px;
	}
	.comportamientosCX {
		grid-gap: 10px;
	}

	.topOurPage .padding .paddingRightVision {
		padding-bottom: 20px;
	}
}

@media (max-width: 800px) {
	.imgGpo {
		display: none;
	}
	.topOurPage .padding .visionPageLeft p.visionBody {
		font-size: 18px;
	}
	.topOurPage .padding .paddingRightVision .visionBody {
		font-size: 18px;
	}

	.topOurPage .padding .imgGpo-res {
		width: 200px;
		height: 71px;
		float: left;
		/* background-image: url(/static/media/gpowhite.03c16e69.svg); */
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}
	.topOurPage .padding .textWrapperGpo {
		float: center;
		width: 100%;
	}

	.comportamientosCX {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 15px;
	}
	.comportamientosCX .imgcomportamiento {
		width: 120px;
		height: 120px;
	}

	.topOurPage .padding {
		padding: 10px 40px 30px 40px;
	}

	.tablewrapper .wrapperMidTable .rightWrappermenu {
		width: 100%;
	}
	#cultura .comportamientoWrapper .leftComportaImage {
		height: 160px;
	}

	.destinosFlota {
		grid-gap: 20px;
	}
	.destinosFlota .gridDestinosFlota .iconDestino {
		width: 40px;
		height: 40px;
	}
}
@media (max-width: 600px) {
	.topOurPage .padding .visionPageLeft {
		width: 100%;
	}
	.topOurPage .padding .visionPageLeft {
		border: none;
	}

	.topOurPage .padding .paddingRightVision {
		float: right;
		padding-left: 0px;
		width: 100%;
	}

	.comportamientosCX {
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 15px;
	}

	.comportamientosCX .imgcomportamiento {
		width: 70px;
		height: 70px;
	}

	#vision .visionFrase .numberOne {
		width: 60px;
		height: 60px;
	}

	.destinosFlota .gridDestinosFlota .iconDestino {
		display: none;
	}

	.destinosFlota .gridDestinosFlota {
		padding: 10px 10px 10px 15px;
	}

	.destinosFlota .gridDestinosFlota .tableDestino .tableCellDestino p {
		font-size: 15px;
	}

	.destinosFlota .gridDestinosFlota .tableDestino {
		width: 100%;
	}

	.destinosFlota .gridDestinosFlota .tableDestino .tableCellDestino p,
	.destinosFlota .gridDestinosFlota .tableDestino .tableCellDestino a {
		font-size: 14px;
	}
}

@media (max-width: 400px) {
	.comportamientosCX {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 15px;
	}
	.comportamientosCX .imgcomportamiento {
		width: 70px;
		height: 70px;
	}

	.destinosFlota .gridDestinosFlota .tableDestino .tableCellDestino p {
		font-size: 15px;
	}
	.destinosFlota .gridDestinosFlota {
		padding: 20px;
	}
	.destinosFlota .gridDestinosFlota .tableDestino .tableCellDestino p,
	.destinosFlota .gridDestinosFlota .tableDestino .tableCellDestino a {
		font-size: 14px;
	}
}

@media (max-width: 1044px) {
	.new-circles-nosotros .leftComportaImage .circuloCompo {
		width: 170px !important;
		height: 170px !important;
		right: auto !important;
		top: 0px !important;
		left: -13px !important;
	}
}

@media (max-width: 794px) {
	.new-circles-nosotros .leftComportaImage {
		margin-bottom: 20px !important;
	}
}

.text-color-seguridad {
	color: #018c91;
}

.text-color-vive {
	color: #e52a32;
}

.text-color-lidera {
	color: #fbaf29;
}

.text-color-agil {
	color: #037dc3;
}

.hover-text-white:hover {
	color: white !important;
}
