#golfoAirlines {
	display: flex;
	min-width: 320px;
	background-color: #035cf7;
}

.blueBackEmirates {
	background-color: #020c41;
	padding-top: 70px;
	padding-bottom: 40px;
}
.paddingTopBlueHeader {
	padding-top: 70px;
	padding-bottom: 40px;
}
.headerAirleGolfo {
	height: 100vh;
	overflow: hidden;
	width: 600px;
	background-color: #020c41;
	background-image: url(../../../assets/golfoairlines.jpg);
	background-repeat: no-repeat;
	background-position: top;
	background-size: cover;
	position: sticky;
	top: 0;
	float: right;
	display: none;
}
.MostrarBoton {
	display: block;
}
.headerAirleGolfo .wrapMidTable {
	height: 100%;
	width: 100%;
	display: table;
	vertical-align: middle;
}
.headerAirleGolfo .wrapMid {
	margin: 0 auto;
	height: auto;
	overflow: hidden;
	/* display: table-cell; */
	vertical-align: middle;
}
.headerAirleGolfo .wrapMid .wrapMidElement {
	width: 80%;
	padding: 60px 0 60px;
	margin: 0 auto;
	padding-top: 100px;
}
.headerAirleGolfo .wrapMid p.title {
	font-size: 32px;
	color: white;
	font-weight: 600;
	max-width: 600px;
	line-height: 1.3;
	margin: 0;
	padding: 0;
	margin-bottom: 20px;
}
.headerAirleGolfo .wrapMid p.intro {
	font-size: 15px;
	font-weight: 500;
	color: white;
	margin: 0;
	padding: 0;
	margin-top: 30px;
}
.headerAirleGolfo .wrapMid p.intro.contamos {
	font-size: 20px;
}
.headerAirleGolfo .wrapMid p.text {
	font-size: 18px;
	font-weight: 300;
	color: white;
	margin: 0;
	padding: 0;
	margin-top: 10px;
	max-width: 600px;
}
.contentMnager {
	/* height: 4000px; */
	/* width: calc(100% - 500px); */
	width: 100%;
	/* margin-top: 70px; */
	float: left;
	background-color: white;
}
.headerAirleGolfo .wrapMid a {
	display: table;
	color: white;
	background-color: #fa0073;
	border-radius: 100px;
	padding: 15px 25px;
	margin-top: 20px;
	border: 1px solid #fa0073;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	font-size: 14px;
	font-weight: 600;
}
.headerAirleGolfo .wrapMid a:hover {
	background-color: #0b2343;
	border: 1px solid rgba(255, 255, 255, 0.3);
}
.fixedTop {
	position: fixed;
	bottom: 0;
	width: 100%;
	height: auto;
	overflow: hidden;
	display: flex;
}
.fixedTop .leftBanner {
	width: 500px;
	height: auto;
	overflow: hidden;
	float: left;
	display: none;
}
.fixedTop .rightBanner {
	/* width: calc(100% - 500px); */
	width: 100%;
	height: auto;
	overflow: hidden;
	float: right;
}
.fixedTop .rightBanner .bannerTopWrapper {
	width: calc(100% - 20px);
	border: 1px solid #007dc3;
	border-radius: 11px;
	margin: 10px auto;
	background-color: white;
	box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
	overflow: hidden;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.fixedTop .rightBanner .bannerTopWrapper:hover {
	box-shadow: 0 30px 50px rgba(0, 0, 0, 0.25);
}
.wrapperButtons {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
}
.wrapperButtons a.sqDownload.smallOption {
	display: none;
}
.wrapperButtons a.sqDownload {
	display: block;
	border-right: 1px solid #e5e5e5;
	border-radius: 0;
	margin: 0;
	padding: 0;
	padding: 12px 0;
	cursor: pointer;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	text-decoration: none;
}
.wrapperButtons a.sqDownload:hover {
	background-color: rgba(0, 125, 195, 0.07);
}
.wrapperButtons a.sqDownload p {
	margin: 0;
	padding: 0;
	text-align: center;
	font-size: 14px;
	font-weight: 500;
	color: #0b2343;
	padding: 0 20px;
}
.wrapperButtons a.sqDownload .iconText {
	width: 60%;
	height: 30px;
	margin: 0 auto;
	margin-bottom: 5px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	z-index: 9;
	position: relative;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.wrapperButtons a.sqDownload:nth-child(1) .iconText {
	background-image: url(../../../assets/golfoairelines/qas.svg);
}
.wrapperButtons a.sqDownload:nth-child(2) .iconText {
	background-image: url(../../../assets/golfoairelines/info.svg);
}
.wrapperButtons a.sqDownload:nth-child(3) .iconText {
	background-image: url(../../../assets/golfoairelines/news.svg);
}
.wrapperButtons a.sqDownload:nth-child(4) .iconText {
	background-image: url(../../../assets/golfoairelines/play.svg);
	opacity: 0.6;
}
.wrapperButtons a.sqDownload:nth-child(5) .iconText {
	background-image: url(../../../assets/golfoairelines/play.svg);
	opacity: 0.6;
}
.wrapperButtons a.sqDownload:nth-child(6) .iconText {
	background-image: url(../../../assets/golfoairelines/news.svg);
}

.wrapperButtons a.sqDownload:nth-child(3):hover .iconText {
	opacity: 1;
}
.wrapperButtons a.sqDownload:hover p {
	color: rgba(0, 125, 195, 1);
}
.whiteWindowAsk {
	background-color: white;
	padding: 30px 30px 10px 30px;
	width: 90%;
	height: 80vh;
	max-height: 550px;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	border-radius: 11px;
	max-width: 900px;
	overflow-y: scroll;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.whiteWindowAsk.modalheightInherithAsk {
	max-height: inherit;
}
.whiteWindowAsk .tableBoletosHeader p.title {
	line-height: 1.5;
	font-size: 16px;
}
.whiteWindowAsk .accordion__button::before {
	margin-top: 22px;
}
.whiteWindowAsk .accordion__panel p {
	font-size: 16px;
	color: #0b2343;
}
.whiteWindowAsk #wrapperAccordionThis ul {
	font-size: 16px;
}
.whiteWindowAsk .accordion__panel {
	padding: 30px;
}
h4.titlePreguntas {
	font-size: 32px;
	font-weight: 500;
	color: #0b2343;
	margin-bottom: 20px;
}
.videoDeltaThis {
	position: relative;
}
.videoDeltaThis .filterimage {
	background-color: rgba(11, 35, 67, 0.4);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.videoDeltaThis:hover .filterimage {
	background-color: rgba(11, 35, 67, 0.8);
}
.wrapperButtons a.sqDownload.videoDeltaThis:nth-child(5) {
	background-image: url(../../../assets/golfoairelines/deltavideo.png);
}
.wrapperButtons a.sqDownload.videoDeltaThis:nth-child(4) {
	background-image: url(../../../assets/golfoairelines/andres.png);
}
.wrapperButtons a.videoDeltaThis p {
	color: white;
	z-index: 6;
	position: relative;
}
.wrapperButtons a.videoDeltaThis p:hover {
	opacity: 0.8;
	color: white;
}
.wrapperButtons a.videoDeltaThis:hover {
	color: white;
}
.wrapperButtons a.videoDeltaThis:hover p {
	color: white;
	opacity: 1;
}
.midWarpperContent {
	max-width: 1000px;
	margin: 0 auto;
	width: 85%;
	position: relative;
}
.logoGpoAM {
	width: 200px;
	position: absolute;
	top: 0;
	right: 0;
	height: 70px;
	background-image: url(../../../assets/golfoairelines/logogpo.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}
.midwarpperTop {
	margin-top: 50px;
	height: auto;
	overflow: hidden;
}
.midWrapperLine {
	border-top: 1px solid #e5e5e5;
}
.midWarpperContent h2,
.midWarpperContent p {
	color: #0b2343;
}
.midWarpperContent h2.subtitle {
	font-size: 22px;
	max-width: 640px;
	line-height: 1.3;
}
.midWarpperContent h2.title {
	max-width: 700px;
	font-weight: 500;
	font-size: 40px;
	margin-bottom: 20px;
	line-height: 1.2;
}
.midWarpperContent p.subtitle {
	font-weight: 600;
	font-size: 18px;
	margin: 0;
	margin-bottom: 10px;
}
.midWarpperContent p.biggerText {
	max-width: 670px;
	margin-top: 7px;
	margin-bottom: 0;
}
.midWarpperContent p.biggerText span {
	font-weight: 600;
}
.background-blue {
	background-color: rgba(0, 125, 195, 0.05);
	width: 100%;
	height: 200px;
	/* margin-top: 40px; */
	position: relative;
}
.background-blue .map {
	background-image: url(../../../assets/golfoairelines/map.png);
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	border-top: 1px solid #e5e5e5;
	border-bottom: 1px solid #e5e5e5;
	background-position: center;
}
.background-blue .mapaingles {
	background-image: url(../../../assets/golfoairelines/mapen.png);
}
.meaning2 {
	width: 160px;
	height: 75px;
	position: absolute;
	bottom: 20px;
	left: 40px;
	background-image: url(../../../assets/golfoairelines/meaning.png);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
	background-color: white;
	border-radius: 11px;
}
.meaning2en {
	background-image: url(../../../assets/golfoairelines/meaning-en.png);
}
.wrapperQuintas {
	/* border: 1px solid #f0f; */
	overflow: hidden;
	height: auto;
}
.logoEmirates {
	width: 240px;
	height: 70px;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	margin-top: 50px;
	margin-bottom: 20px;
	background-image: url(../../../assets/golfoairelines/emirates.png);
}
.wrapperQuintas p:nth-child(1) {
	margin-right: 40px;
}

.midWarpperContent .leftLogoEmirates {
	width: 300px;
	float: left;
	height: auto;
	overflow: hidden;
	margin-right: 80px;
}
.midWarpperContent .rightLogoEmirates {
	width: calc(100% - 380px);
	float: left;
	height: auto;
	overflow: hidden;
	margin-top: 75px;
}

.midWarpperContent .rightLogoEmirates .wrapperGridPermite {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 40px;
}

.midWarpperContent .rightLogoEmirates .wrapperGridPermite .optionGrid {
	height: auto;
	overflow: hidden;
}

.midWarpperContent .rightLogoEmirates .wrapperGridPermite .optionGrid .img {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	background-color: rgba(0, 125, 195, 0.05);
	margin: 0 auto;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}
.midWarpperContent .rightLogoEmirates .wrapperGridPermite .optionGrid:nth-child(1) .img {
	background-image: url(../../../assets/golfoairelines/low.svg);
}
.midWarpperContent .rightLogoEmirates .wrapperGridPermite .optionGrid:nth-child(2) .img {
	background-image: url(../../../assets/golfoairelines/low02.svg);
}
.midWarpperContent .rightLogoEmirates .wrapperGridPermite .optionGrid:nth-child(3) .img {
	background-image: url(../../../assets/golfoairelines/low03.svg);
}

.midWarpperContent .rightLogoEmirates .wrapperGridPermite .optionGrid p {
	text-align: center;
	font-size: 16px;
	margin-top: 20px;
}

.leftQuintaLibertad {
	float: left;
	width: 400px;
}
.rightQuintaLibertad {
	float: left;
	width: calc(100% - 400px);
	overflow: hidden;
	height: auto;
}
.imgQuinta {
	width: 96%;
	height: 40px;
	background-image: url(../../../assets/golfoairelines/quinta.png);
	background-repeat: no-repeat;
	background-position: left;
	background-size: contain;
}
.imgQuintaen {
	background-image: url(../../../assets/golfoairelines/quinta-en.png);
}
.bluMapCompetencia {
	border-top: 1px solid #e5e5e5;
	border-bottom: 1px solid #e5e5e5;
	background-color: rgba(0, 125, 195, 0.05);
	height: 300px;
	margin-top: 60px;
	background-image: url(../../../assets/golfoairelines/mapa-low.png);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
}
.bluMapCompetencia .midWrapperTop {
	position: absolute;
	max-width: 1000px;
	margin: 0 auto;
	margin-top: 0px;
	width: 85%;
	height: 100%;
	left: 50%;
	top: 0;
	transform: translate(-50%, -0%);
}

.bluMapCompetencia .midWrapperTop h2.subtitle {
	font-size: 18px;
	max-width: 327px;
	line-height: 1.3;
	margin-top: 60px;
	font-weight: 600;
}
.bluMapCompetencia .midWrapperTop p {
	max-width: 400px;
	margin-top: 20px;
	font-weight: 400;
}
.bluMapCompetencia .midWrapperTop p strong {
	color: #007dc3;
}

.bluMapCompetencia .midWrapperTop h2.subtitle span {
	color: #087dc2;
}

.bluMapCompetencia .midWrapperTop .fixedAbsolute {
	position: absolute;
	right: 0;
	top: 70px;
	width: 270px;
	height: 100px;
	background-image: url(../../../assets/golfoairelines/text.png);
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	display: none;
}
.bluMapCompetencia .midWrapperTop .fixedAbsolute.fixedingles {
	background-image: url(../../../assets/golfoairelines/text-en.png);
}
.gridFlags {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	margin-top: 20px;
}
.gridFlags .gridOption {
	border-left: 1px solid #e5e5e5;
}
.gridFlags .gridOption:nth-child(1) {
	border-left: none;
}
.gridFlags p {
	text-align: center;
	margin: 0;
	padding: 0;
}
.gridFlags p.title {
	font-weight: 600;
	font-size: 18px;
	margin-bottom: 10px;
}
.gridFlags p.subti {
	text-align: center;
	font-size: 14px;
	padding: 0 40px;
	margin-bottom: 50px;
}

.gridFlags .flag {
	width: 150px;
	height: 90px;
	margin: 0 auto;
	margin-bottom: 20px;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}
.gridFlags .gridOption:nth-child(1) .flag {
	background-image: url(../../../assets/golfoairelines/flag1.png);
}
.gridFlags .gridOption:nth-child(2) .flag {
	background-image: url(../../../assets/golfoairelines/flag2.png);
}
.gridFlags .gridOption:nth-child(3) .flag {
	background-image: url(../../../assets/golfoairelines/flag3.png);
}

.blueTextbg {
	background-color: #0b2343;
	padding-bottom: 50px;
	height: auto;
	overflow: hidden;
	padding-top: 50px;
	background-color: rgba(0, 125, 195, 0.07);
	border-top: 1px solid #e5e5e5;
	padding-bottom: 140px;
}
.textWrapper {
	max-width: 1000px;
	width: 90%;
	height: auto;
	overflow: hidden;
	margin: 0 auto;
}
.textWrapper .leftWrapperTexto {
	float: left;
	width: 50%;
}

.blueTextbg p.biggerPText {
	text-align: left;
	font-size: 20px;
}

.blueTextbg .textWrapper h2.subtitle {
	text-align: center;
	color: #0b2343;
	font-size: 20px;
	margin-top: 10px;
	font-weight: 600;
}

.blueTextbg p {
	color: #0b2343;
	text-align: center;
	max-width: 800px;
	margin: 0 auto;
	font-size: 16px;
	margin-top: 20px;
	padding: 0 30px;
	text-align: left;
}
.blueTextbg p.blueLineTop {
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	padding-top: 20px;
}
.blueTextbg p.clearText {
	opacity: 0.8;
	font-weight: 600;
	background-color: rgba(255, 255, 255, 0.05);
	border-radius: 100px;
	padding: 15px 12px;
	padding-left: 25px;
	display: table;
}
.blueTextbg a {
	margin: 0 auto;
	text-align: center;
	margin-top: 20px;
	color: white;
	background-color: #fa0073;
	padding: 7px 20px;
	border-radius: 50px;
	margin-left: 15px;
	font-weight: 500;
	font-size: 14px;
	display: table;
	margin: 0;
	margin-left: 30px;
	margin-top: 20px;
}
.blueTextbg a:hover {
	background-color: #087dc2;
}
.blueTextbg .imgLogo {
	width: 212px;
	height: 60px;
	margin: 0 auto;
	margin-top: 60px;
	background-image: url(../../../assets/golfoairelines/logogpo.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	display: none;
}

.noticiasligas {
	border-top: 1px solid #e5e5e5;
}
.noticiasligas ul {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	text-decoration: none;
	color: #0b2343;
	list-style: none;
	margin: 0;
	padding: 0;
	margin-top: 30px;
}
.noticiasligas ul li {
	padding: 20px 0;
	border-bottom: 1px solid #e5e5e5;
	border-right: 1px solid #e5e5e5;
	padding-right: 30px;
	padding: 20px 30px;
}
.noticiasligas ul li:nth-child(even) {
	border-right: none;
}
.noticiasligas ul li a {
	color: #0b2343;
	font-weight: 400;
	font-size: 16px;
}
.noticiasligas ul li:hover {
	background-color: rgba(0, 125, 195, 0.05);
}
.noticiasligas ul li a span {
	color: #666;
}
.noticiasligas ul li:hover a {
	color: #087dc2;
}
.midWarpperContent .youKnowWhat {
	width: 30%;
	height: auto;
	overflow: hidden;
	float: left;
	margin-right: 30px;
}
.midWarpperContent .yellowAlert {
	float: left;
	width: calc(70% - 30px);
	overflow: hidden;
	height: auto;
}
.midWarpperContent .yellowAlert .wrapperAlert {
	border-radius: 7px;
	background-color: #ffcc2f;
	padding: 20px;
	height: auto;
	overflow: hidden;
}
.midWarpperContent .yellowAlert .wrapperAlert .imgAlert {
	width: 40px;
	height: 40px;
	float: left;
	margin-right: 20px;
	background-image: url(../../../assets/golfoairelines/alert.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	margin-top: 4px;
}
.midWarpperContent .yellowAlert .wrapperAlert p {
	margin: 0;
	padding: 0;
	float: left;
	width: calc(100% - 60px);
}
.midWarpperContent .yellowAlert .wrapperAlert p p {
	width: 100%;
	margin-bottom: 10px;
}
.midWarpperContent .yellowAlert .wrapperAlert p p:nth-child(2) {
	border-top: 1px solid rgba(0, 125, 195, 0.3);
	padding-top: 10px;
}
.midWarpperContent .youKnowWhat p {
	color: #0b2343;
	font-size: 18px;
	line-height: 1.2;
}
.midWarpperContent .youKnowWhat p em,
.midWarpperContent .youKnowWhat p span.biggerSpan {
	font-size: 26px;
	font-weight: 600;
	font-style: normal;
}
.midWarpperContent .youKnowWhat p strong,
.midWarpperContent .youKnowWhat p span.bluecolor {
	color: #087dc2;
}
.noticiasligas .midWarpperContent {
	width: 100%;
	margin-top: 0;
}
.noticiasligas .midWarpperContent ul {
	margin-top: 0;
}

.blueBackEmirates .midWarpperContent h2.title {
	color: white;
}
.blueBackEmirates .midWarpperContent p.biggerText {
	color: white;
}
.blueBackEmirates .midWarpperContent p.biggerText p {
	color: white;
}
.impaktante {
	/* background-color: rgba(0,125,195,.07); */
	text-align: center;
	padding-top: 20px;
}
.impaktante p {
	font-weight: 500;
	font-size: 24px;
	text-align: center;
	line-height: 1.2;
	padding: 0 20px;
	color: #007dc3;
}

.midWarpperContent .smallred {
	color: #1a97dd;
	font-size: 20px;
	margin: 0;
	margin-bottom: 0px;
	padding: 0;
	display: table;
	font-weight: 500;
	border-radius: 100px;
	margin-bottom: 10px;
}

.languageCambio {
	position: fixed;
	top: 80px;
	right: 20px;
	border-radius: 4px;
	background-color: white;
	z-index: 9999;
	width: 130px;
	height: 49px;
	cursor: pointer;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	overflow: hidden;
	box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
}
.languageCambio:hover,
.languageCambio:active {
	/* height: 100px; */
}
.languageCambio a.list {
	text-decoration: none;
	width: 100%;
	display: block;
	height: 50px;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	border-bottom: 1px solid #e5e5e5;
}
.languageCambio a.list:hover {
	background-color: rgba(0, 125, 195, 0.08);
}
.languageCambio a.list .image {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	float: right;
	color: white;
	margin-top: 7px;
	margin-right: 9px;
	font-size: 12px;
	font-weight: 600;
	text-align: center;
	line-height: 34px;
}
.languageCambio a.list .image.es {
	background-color: #48a847;
}
.languageCambio a.list .image.en {
	background-color: #134b8e;
}
.languageCambio a.list p.idiom {
	font-size: 14px;
	color: #0b2343;
	line-height: 48px;
	float: right;
	margin: 0;
	margin-right: 0px;
	padding: 0;
	margin-right: 13px;
	font-weight: 500;
}

.temsConditionsText {
	border-top: 1px solid #e5e5e5;
	margin-top: 50px;
	padding-top: 20px;
}
.temsConditionsText p,
.temsConditionsText ul,
.temsConditionsText ol {
	max-width: 1000px;
	font-size: 12px;
	margin: 0 auto;
	color: #0b2343;
	width: 90%;
}
.temsConditionsText a {
	color: #0b2343;
	font-size: 12px;
	background-color: transparent;
	padding: 0;
	margin: 0;
	display: initial;
	cursor: pointer;
}
.temsConditionsText a:hover {
	color: #0b2343;
	font-size: 12px;
	background-color: transparent;
	padding: 0;
	margin: 0;
	display: initial;
	text-decoration: underline;
}

/*

<div className="languageCambio">
            <a className="list" href="/defendamos-nuestro-cielo">
              <div className="image">ES</div>
              <p className="idiom">Español</p>
            </a>
            <a className="list" href="/lets-defend-our-sky">
              <div className="image">EN</div>
              <p className="idiom">English</p>
            </a>
          </div>

          */

@media (max-width: 1410px) {
	.midWarpperContent h2.subtitle {
		font-size: 20px;
	}
	.midWarpperContent .leftLogoEmirates {
		width: 250px;
		margin-right: 40px;
	}
	.midWarpperContent .rightLogoEmirates {
		width: calc(100% - 290px);
	}
	.midWarpperContent .rightLogoEmirates .wrapperGridPermite .optionGrid p {
		font-size: 14px;
	}
	.gridFlags p.subti {
		padding: 0 30px;
	}
	.midWarpperContent {
		width: 90%;
	}
	.bluMapCompetencia .midWrapperTop {
		width: 90%;
	}
	.noticiasligas ul li a {
		font-size: 16px;
	}
}

@media (max-width: 1044px) {
	h4.titlePreguntas {
		font-size: 24px;
	}
	.whiteWindowAsk {
		padding: 30px 10px 10px;
	}
	.whiteWindowAsk .accordion__panel p {
		font-size: 14px;
	}
	.whiteWindowAsk .accordion__panel ol li {
		font-size: 14px;
	}
	.whiteWindowAsk .accordion__panel ul li {
		font-size: 14px;
	}
	.whiteWindowAsk .accordion__panel {
		padding: 15px;
	}
	.noticiasligas ul li {
		padding-left: 0;
	}
	.impaktante {
		padding-bottom: 100px;
	}
	.midWarpperContent .smallred {
		text-align: center;
		margin-left: auto;
		margin-right: auto;
	}
	.logoGpoAM {
		position: relative;
		margin: 0 auto;
	}
	#golfoAirlines {
		display: block;
	}
	.headerAirleGolfo {
		width: 100%;
		height: auto;
		overflow: hidden;
	}
	.fixedTop {
		display: block;
	}
	.fixedTop .leftBanner {
		display: none;
	}
	.fixedTop .rightBanner {
		width: 100%;
	}
	.wrapperButtons a.sqDownload .iconText {
		height: 15px;
		margin-bottom: 10px;
	}
	.wrapperButtons a.sqDownload p {
		font-size: 10px;
		line-height: 1.4;
		padding: 0 20px;
	}
	.wrapperButtons a.sqDownload {
		padding: 20px 0;
	}
	.headerAirleGolfo .wrapMid .wrapMidElement {
		padding: 50px 0 125px;
	}
	.headerAirleGolfo .wrapMid p.title {
		font-size: 47px;
	}
	.wrapperButtons {
		grid-template-columns: repeat(5, 1fr);
	}
	.headerAirleGolfo .wrapMid p.text {
		font-size: 14px;
		padding-right: 40px;
	}
	.contentMnager {
		width: 100%;
	}
	.midWarpperContent h2.title {
		font-size: 24px;
		max-width: 400px;
		margin-bottom: 20px;
		text-align: center;
		margin: 0 auto;
	}
	.background-blue {
		height: 150px;
	}
	.meaning2 {
		width: 130px;
		height: 60px;
	}
	.leftQuintaLibertad {
		width: 100%;
	}
	.rightQuintaLibertad {
		width: 100%;
	}
	.midWarpperContent p.subtitle {
		text-align: center;
		margin-bottom: 20px;
	}
	.midWarpperContent p.biggerText {
		text-align: center;
		margin: 0;
		margin-top: 20px;
		max-width: inherit;
	}
	.imgQuinta {
		width: 320px;
		margin: 0 auto;
	}
	.midWarpperContent p.subtitle {
		margin-right: 0;
	}
	.logoEmirates {
		height: 30px;
		margin: 0 auto;
		margin-top: 40px;
		margin-bottom: 20px;
	}
	.midWarpperContent .leftLogoEmirates {
		width: 100%;
		margin-right: 0;
	}
	.midWarpperContent h2.subtitle {
		text-align: center;
		font-size: 17px;
	}
	.midWarpperContent .rightLogoEmirates {
		width: 100%;
		margin-top: 30px;
	}
	.midWarpperContent .rightLogoEmirates .wrapperGridPermite {
		grid-template-columns: repeat(1, 1fr);
	}
	.midWarpperContent .rightLogoEmirates .wrapperGridPermite .optionGrid p {
		padding: 0 40px;
	}
	.midWarpperContent .rightLogoEmirates .wrapperGridPermite .optionGrid {
		border-bottom: 1px solid #e5e5e5;
	}
	.midWarpperContent .youKnowWhat {
		width: 100%;
	}
	.midWarpperContent .yellowAlert {
		width: 100%;
	}
	.midWarpperContent .youKnowWhat p {
		text-align: center;
	}
	.midWarpperContent .youKnowWhat p span.biggerSpan {
		font-size: 18px;
	}
	.midWarpperContent .yellowAlert .wrapperAlert p {
		font-size: 14px;
	}
	.blueTextbg {
		padding-bottom: 70px;
	}
	.blueTextbg p {
		padding: 0;
	}
	.noticiasligas ul {
		grid-template-columns: repeat(1, 1fr);
	}
	.noticiasligas ul li {
		border-right: none;
	}
	.noticiasligas ul li a {
		font-size: 14px;
	}
	.gridFlags {
		grid-template-columns: repeat(1, 1fr);
	}
	.gridFlags .flag {
		width: 120px;
		height: 40px;
		margin-top: 20px;
	}
	.bluMapCompetencia {
		height: auto;
		background-image: none;
		padding: 20px 0;
	}
	.bluMapCompetencia .midWrapperTop {
		height: auto;
		position: relative;
	}
	.bluMapCompetencia .midWrapperTop h2.subtitle {
		margin-top: 24px;
	}
	.bluMapCompetencia .midWrapperTop .fixedAbsolute {
		display: none;
	}
	.headerAirleGolfo .wrapMid p.title {
		font-size: 24px;
	}
	.headerAirleGolfo .wrapMid .wrapMidElement {
		padding: 50px 0 100px;
	}
	.headerAirleGolfo {
		background-image: none;
		background-color: #087dc2;
	}
	.gridFlags .gridOption {
		border-bottom: 1px solid #e5e5e5;
		border-left: none;
	}
	.gridFlags p.subti {
		margin-bottom: 20px;
	}
	.bluMapCompetencia .midWrapperTop h2.subtitle {
		margin: 0 auto;
		text-align: center;
		max-width: 500px;
	}
	.bluMapCompetencia .midWrapperTop {
		transform: translate(0, 0);
		left: 0;
		top: 0;
	}
	.bluMapCompetencia .midWrapperTop p {
		text-align: center;
		margin: 0 auto;
		margin-top: 10px;
	}
	.wrapperButtons a.sqDownload {
		padding: 10px 0;
	}
	.wrapperButtons a.sqDownload .iconText {
		margin-bottom: 5px;
	}
	.textWrapper .leftWrapperTexto {
		width: 100%;
	}
	.blueTextbg p.biggerPText {
		font-size: 16px;
		text-align: center;
	}
	.blueTextbg p {
		text-align: center;
	}
	.blueTextbg a {
		margin-top: 30px;
	}
	.blueTextbg p.biggerPText {
		font-size: 14px;
	}
	.blueTextbg p {
		font-size: 14px;
	}
	.blueTextbg a {
		display: table;
		margin: 0 auto;
		margin-top: 30px;
	}
	.blueTextbg {
		padding-bottom: 200px;
	}
	.temsConditionsText a {
		color: #0b2343;
		font-size: 12px;
		background-color: transparent;
		padding: 0;
		margin: 0;
		display: initial;
		cursor: pointer;
	}

	.fixedTop .rightBanner .bannerTopWrapper {
		width: calc(90% - 20px);
		margin: 70px auto;
	}
}

@media (max-width: 800px) {
	.fixedTop {
		position: fixed;
		bottom: 55px;
		width: 100%;
		height: auto;
		overflow: hidden;
		display: flex;
	}
	.fixedTop .rightBanner .bannerTopWrapper {
		width: calc(100% - 20px);
		margin: 5px auto;
	}
}

@media (max-width: 690px) {
	.meaning2 {
		display: none;
	}
}

@media (max-width: 517px) {
	.blueTextbg p.clearText {
		padding-right: 25px;
	}
	.blueTextbg p.clearText {
		background-color: transparent;
	}
	.wrapperButtons {
		display: flex;
		flex-wrap: wrap;
	}
	.wrapperButtons a.sqDownload p {
		padding: 0px;
	}
	.fixedTop .rightBanner .bannerTopWrapper {
		width: calc(100% - 20px);
		margin: 5px auto;
	}

	.wrapperButtons a.sqDownload:nth-child(1) {
		flex-basis: calc(100% / 3);
	}
	.wrapperButtons a.sqDownload:nth-child(2) {
		flex-basis: calc(100% / 3);
	}
	.wrapperButtons a.sqDownload:nth-child(3) {
		flex-basis: calc(100% / 3);
	}
	.wrapperButtons a.sqDownload:nth-child(4) {
		flex-basis: calc(100% / 2);
	}
	.wrapperButtons a.sqDownload:nth-child(5) {
		flex-basis: calc(100% / 2);
	}

	.wrapperButtons a.sqDownload.smallOption {
		display: block;
	}

	.wrapperButtons a.sqDownload.defaultOption {
		display: none;
	}

	.blueTextbg {
		margin-bottom: 50px;
	}
}

@media (max-height: 645px) and (min-width: 1000px) {
	.fixedTop {
		position: relative;
	}
}

/*
div className="wrapperButtons">
                    <a className="sqDownload">
                      <div className="iconText"></div>
                      <p>Infografía</p>
                    </a>

<div className="fixedTop">
              <div className="leftBanner"></div>
              <div className="rightBanner">
                <div className="bannerTopWrapper"></div>
              </div>
            </div>

<div className="headerAirleGolfo">
              <div className="wrapMid">
                    <p className="title">Trade Agreement Enforcement & State Subsidies</p>
              </div>
          </div>


          */
