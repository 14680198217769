.contMenuFixed {
	width: 100%;
	height: auto;
	position: relative;
	/* min-height: 60px; */
}
.hoverCompensacionesMenu {
	width: 200px;
	position: absolute;
	right: -185px;
	background-color: white;
	top: -1px;
	border-radius: 4px;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	border: 1px solid #e5e5e5;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	opacity: 0;
	z-index: 1;
	display: none;
}
.hoverherramientasMenu,
.viajesmenuhover {
	width: 0;
	position: absolute;
	right: -175px;
	background-color: white;
	top: -1px;
	border-radius: 4px;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	border: 1px solid #e5e5e5;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	opacity: 0;
}

.hoverMenu ul li.toolsOffice,
.toolsOfficeOtros,
.toolsOfficenosotros {
	position: relative;
}

.hoverMenu ul li.viajescom {
	position: relative;
}
.hoverMenu ul li.toolsOfficenosotros:hover .hoverherramientasMenu {
	opacity: 1;
	width: 213px;
	top: initial;
	bottom: -30px;
}

.hoverMenu ul li.toolsOffice:hover .hoverherramientasMenu {
	opacity: 1;
	width: 230px;
	z-index: 30;
	right: -210px;
}

.hoverMenu ul li.toolsOfficeOtros:hover .hoverherramientasMenuOtros {
	opacity: 1;
	width: 213px;
	top: initial;
	bottom: -30px;
}

.hoverMenu ul li.viajescom:hover .viajesmenuhover {
	opacity: 1;
	width: 200px;
}

.hoverMenu ul li.menuRewards:hover .hoverCompensacionesMenu {
	opacity: 1;
	width: 200px;
	display: block;
}
.alertScrolled {
	display: none;
}
.TopMiAmWrap {
	background-color: #fff;
	width: 100%;
	/* position: fixed; */
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	z-index: 999;
}

.MenuWrap {
	width: 100%;
	height: 60px;
	margin: 0 auto;
	display: grid;
	grid-template-columns: repeat(3, auto);
}
.MenuWrap .christmas-items {
	display: none;
	bottom: -65px;
	left: 0;
	position: absolute;
	width: 100%;
	/* background-color: red; */
	background-image: url("../../../assets/header-navideno.png");
	background-repeat: repeat-x;
	background-size: 50%;
	height: 65px;
	overflow: hidden;
	z-index: 1;
	pointer-events: none;
}

.MenuWrap.christmas .christmas-items {
	display: block;
}

.hoverAeromexico {
	width: 100%;
	height: 90px;
	position: absolute;
	top: 60px;
	left: 0;
	background-color: rgba(255, 255, 255, 1);
	display: none;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
	border-top: 1px solid #e5e5e5;
}

.oursHoverMenu:hover .hoverAeromexico {
	display: block;
}

.hoverAeromexico .contAeromexico {
	width: 100%;
	height: 100%;
}

.hoverAeromexico .contAeromexico ul {
	padding: 0;
	margin: 0;
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: repeat(8, auto);
}

.hoverAeromexico .contAeromexico ul li {
	display: block;
	border-left: 1px solid rgba(255, 255, 255, 0.2);
	height: 100%;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.leftTest {
	float: none;
	border-right: none;
}
.menuRewards {
	height: 63px !important;
	position: relative;
}

.toolsOffice.develop.en {
	height: 63px !important;
}

.hoverAeromexico .contAeromexico ul li:hover {
	background-color: rgba(0, 125, 195, 0.09);
}

.hoverAeromexico .contAeromexico ul li a {
	display: block;
	width: 100%;
	height: 100%;
	position: relative;
}

.hoverAeromexico .contAeromexico ul li a div.paddingAeromexico {
	display: table;
	margin: 24px auto 24px;
}

.hoverAeromexico .contAeromexico ul li a div.paddingAeromexico img {
	width: 25px;
	height: 25px;
	float: left;
	margin-top: 8px;
}
.hoverAeromexico .contAeromexico ul li a div.paddingAeromexico p {
	color: #0b2343;
	text-align: left;
	font-size: 13px;
	font-weight: 400;
	float: left;
	margin: 0;
	padding: 0;
	padding-left: 10px;
	line-height: 40px;
	font-weight: 500;
	letter-spacing: 0.2px;
}

.hoverAeromexico .contAeromexico ul li:nth-child(8) a div.paddingAeromexico p {
	line-height: 1.3;
	margin-top: 1px;
}

.hoverAeromexico .contAeromexico ul li:nth-child(8) a div.paddingAeromexico p b {
	font-size: 12px;
	font-weight: 400;
}

.hoverMenu {
	width: 220px;
	height: auto;
	overflow: hidden;
	position: absolute;
	background-color: white;
	border-radius: 4px;
	border: 0 solid #d6d6d6;
	box-shadow: 2px 3px 2px rgba(0, 0, 0, 0.08);
	right: -70px;
	top: 63px;
	transition: 300ms cubic-bezier(0.2, 0.8, 0.2, 1);
	opacity: 0;
	height: 0;
	box-sizing: border-box;
}
.hoverMenuExperience:hover {
	overflow: visible;
}

.ListMenu a:hover .hoverMenu {
	top: 59px;
	opacity: 1;
	height: auto;
	border: 1px solid #d6d6d6;
}

.hoverMenu ul {
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
}

.hoverMenu ul li {
	text-align: left;
	padding-left: 20px;
	height: 44px;
	line-height: 44px;
	color: #020c41;
	list-style: none;
	padding-right: 25px;
}

.hoverMenu ul li.tall {
	height: 62px;
}

.hoverMenu ul li a {
	width: 100%;
	height: 100%;
	text-decoration: none;
	line-height: 44px;
	color: #020c41;
	display: block;
	text-decoration: none;
}

.hoverMenu ul li a.reportaGam {
	color: #020c41;
	font-weight: 600;
}

.hoverMenu ul li.reportaGamli {
	background-color: #ffe873;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.hoverMenu ul li:hover {
	/* background-color: rgba(0, 125, 195, 0.07); */
	background: rgba(3, 92, 247, 0.05);
	color: #fa0073;
}

.hoverMenu ul li.codigoMenu {
	background-color: #ffe873;
	color: #fa0073;
}

.hoverMenu ul .codigoMenu:hover {
	background-color: rgba(0, 125, 195, 0.05);
}

.hoverMenu ul li.codigoMenu a {
	color: #fa0073;
}

.hoverMenu ul li a {
	color: #020c41;
}
/* .hoverMenu ul li:hover a{
  color: #035cf7!important;
} */
/*
<div class="hoverAeromexico">
                  <div class="contAeromexico">
                    <ul>
                      <li>
                        <a href="#">
                          <div className="paddingAeromexico">
                            <img src=""></img>
                            <p>Organigrama</p>
                          </div>
                        </a>
                      </li>
                      */
.LogoMiAM {
	/*width: 275px;*/
	height: 60px;
	justify-self: start;
	margin-left: 20px;
}
.LogoMiAM img {
	margin-top: 0;
	height: 60px;
	width: 205px;
}

.ListMenu {
	display: grid;
	align-items: center;
	grid-template-columns: repeat(7, auto);
	height: 60px;
	text-align: center;
	align-items: center;
	justify-items: center;
	/*max-width: 700px;*/
	grid-gap: 22px;
}

.ListMenu a {
	color: #0b2343;
	font-size: 13px;
	text-decoration: none;
	cursor: pointer;
	font-weight: 400;
	height: 100%;
	line-height: 60px;
	opacity: 1;
	margin: 0;
	padding: 0;
	letter-spacing: 0.2px;
}
/*
.ListMenu a.menuhoverline{
  border-right: 1px solid #e5e5e5;
  margin: 0;
  padding: 0;
  border:1px solid #f0f;
}

/*.ListMenu a:nth-child(1) {
  border-bottom: 3px solid #FA0073;
  box-sizing: border-box;
  opacity: 1;
}*/

.ListMenu a span {
	height: 100%;
	display: block;
	margin: 0;
	padding: 0;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.ListMenu a:hover span {
	opacity: 1;
	transform: translateY(-4px);
	color: #fa0073;
	opacity: 1;
}

.MoreHover:hover .hoverMenuMore {
	display: block;
}

.areasHover:hover .hoverMenuAreas {
	display: block;
}

.hoverMenuExperience {
	width: 240px;
	left: -50px;
	right: initial;
}

.experienceHover:hover .hoverMenuExperience {
	display: block;
}

.oursmenuhover:hover {
	overflow: visible;
}

.seguridadhover:hover .seguridadhovermore {
	display: block;
}

.noticiasMenu:hover .noticiashover {
	display: block;
}

.hoverCX:hover .hoverCXList {
	display: block;
}

.oursHoverMenu02:hover .oursmenuhover {
	display: block;
}

.MoreHover,
.areasHover,
.experienceHover,
.hoverCX,
.seguridadhover,
.oursHoverMenu02,
.noticiasMenu {
	position: relative;
}

.separadorMenu {
	display: block;
}

.ExtraMenu {
	width: 374px;
	height: 60px;
	justify-self: end;
}

.NotificationMenu {
	height: 100%;
	width: 40px;
	float: right;
	display: table;
	cursor: pointer;
	position: relative;
}

.NotifyWrap {
	display: table-cell;
	vertical-align: middle;
}

.Language .NotifyWrap {
	font-weight: 500;
	text-align: center;
}

.NotificationMenu:hover .RedNumer {
	opacity: 0;
}

.IconNotify {
	width: 34px;
	height: 34px;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	margin: 0 auto;
}

.LanguageWrap {
	display: contents;
	font-size: 13px;
	font-family: "Neue Haas Unica Pro";
	font-weight: 500;
}

.IconNotify:hover {
	/* opacity: 0.5; */
}

.RedNumer {
	position: absolute;
	font-size: 12px;
	background-color: #fa0073;
	z-index: 1;
	top: 14px;
	right: 5px;
	color: #fff;
	width: 15px;
	height: 15px;
	text-align: center;
	line-height: 1.4;
	border-radius: 50%;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.ProfileUser {
	display: grid;
	padding: 0 5px 0 7px;
	grid-template-columns: repeat(3, auto);
	align-items: center;
	justify-items: end;
	height: 100%;
	width: 209px;
	float: right;
	cursor: pointer;
	position: relative;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	border-left: 1px solid #d6d6d6;
	padding-right: 20px;
	padding-left: 15px;
	margin-left: 15px;
	background-color: rgba(0, 125, 195, 0.1);
}

.ProfileUser:hover {
	background-color: rgba(0, 0, 0, 0);
}

.ImageUser {
	height: 40px;
	width: 40px;
	border-radius: 50%;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	background-color: #035cf7;
	background-image: url(../../../assets/images/profile/default-profile.svg);
}

.WelcomeUser {
	font-size: 14px;
	color: #020c41;
	margin: 0;
	padding: 0;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	margin-right: 7px;
	text-transform: capitalize;
	min-width: 100px;
}
.WelcomeUser span.minus {
	text-transform: lowercase;
}

.ProfileUser:hover .WelcomeUser {
	color: #fa0073;
}

.MenuUserProfile {
	position: absolute;
	top: 58px;
	background-color: rgba(255, 255, 255, 1);
	width: 208px;
	border-radius: 2px;
	overflow: hidden;
	-webkit-backdrop-filter: blur(20px);
	backdrop-filter: blur(20px);
	border: 1px solid #e5e5e5;
	box-shadow: 2px 3px 2px rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	transition: 300ms cubic-bezier(0.2, 0.8, 0.2, 1);
	opacity: 0;
	top: 63px;
	height: 0;
	overflow: hidden;
}

.ProfileUser:hover .MenuUserProfile {
	height: auto;
	opacity: 1;
	top: 59px;
}

.MenuUserProfile a {
	color: #020c41;
	font-weight: 500;
	font-size: 12px;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	text-decoration: none;
}

.MenuUserProfile a:hover {
	color: #fa0073;
}

.MenuUserProfile a span {
	height: 30px;
	line-height: 30px;
	margin-left: 10px;
}

.MenuContainer {
	height: 60px;
	width: 100%;
	position: fixed;
	top: 0;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	z-index: 30;
	background: #fff;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08);
	z-index: 99;
}

#MenuContainerInicio {
	background: rgba(11, 35, 67, 0.3);
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	display: none;
}
#MenuContainerInicio:hover {
	background: rgba(11, 35, 67, 0.6);
}
#MenuContainerInicio a.textMenu {
	color: white;
}
#MenuContainerInicio .WelcomeUser {
	color: white;
}
#MenuContainerInicio .ProfileUser {
	border-left: 1px solid rgba(255, 255, 255, 0.2);
}

#MenuContainerInicio .HeaderScrolled a.textMenu {
	color: #0b2343;
}
#MenuContainerInicio .HeaderScrolled .WelcomeUser {
	color: #0b2343;
}
#MenuContainerInicio .HeaderScrolled .ProfileUser {
	border-left: 1px solid #d6d6d6;
}
#MenuContainerInicio .HeaderScrolled .ListMenu a.menu-active {
	color: white;
}

.BreakWrap {
	padding: 20px;
	width: calc(100% - 40px);
	border-bottom: 1px solid #e5e5e5;
	display: grid;
	grid-gap: 10px;
}

.BreakWrap:nth-child(3) {
	padding: 15px 20px;
}

.BreakWrap:nth-child(4) {
	border-bottom: none;
	padding: 15px 20px;
}
.FlagLanguage {
	background-color: #d5d5d5;
	width: 26px;
	height: 26px;
	margin-top: 3px;
	border-radius: 50%;
	float: left;
	overflow: hidden;
	border: 1px solid #e5e5e5;
}

.ImageMenu {
	width: 30px;
	height: 30px;
	float: left;
}

.LanguageText {
	height: 30px;
	line-height: 30px;
	margin-left: 10px;
}

.SearchToolTip {
	position: absolute;
	top: 75px;
	right: 0;
	background-color: #fff;
	border-radius: 2px;
	padding: 10px 20px;
	display: none;
}

.LittleArrow .SearchToolTip {
	right: 17%;
}

.NotificationMenu:hover .SearchToolTip {
	display: none;
}

.TextSearch {
	margin: 0;
	padding: 0;
	font-size: 12px;
	position: relative;
	z-index: 1;
	font-weight: 500;
}

.HeaderScrolled {
	position: fixed;
	backdrop-filter: blur(20px);
	background-color: rgba(255, 255, 255, 0.95);
	top: 0;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08);
	/* border-bottom: 1px solid #e5e5e5; */
}
/*
.MenuContainer:hover {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  -moz-backdrop-filter: blur(20px);
  -ms-backdrop-filter: blur(20px);
  background: rgba(11, 35, 67, 0.9);
}
*/

.LogoDesktop {
	display: block;
}

.LogoPWA {
	display: none;
}

.displayNotifyDesktop {
	display: block;
}

.displayNotifyApp {
	display: none;
}

.displaySearch {
	display: table;
}
ul.teams-list {
	list-style: none;
}
ul.footer-others {
	list-style: none;
}

.ListMenu a.menu-active {
	padding: 0;
	font-size: 13px;
	line-height: 30px;
	background-color: #fa0073;
	color: #fff;
	height: 25px;
	line-height: 25px;
	padding: 0 10px;
	border-radius: 40px;
	display: block;
	margin-top: 17px;
	position: initial;
	overflow: hidden;
}

.MenuUserProfile .BreakWrap {
	width: 100%;
}

.MenuUserProfile a span {
	display: inherit;
}

img.FlagLanguage {
	margin-right: 13px;
}

.BrNotifyWrapeakWrap {
	padding: 15px 20px;
	background-color: rgb(0, 125, 195, 0.05);
}
.noBluBack {
	background-color: white;
	border-bottom: 1px solid #e5e5e5;
}
.noiconsListMenu img {
	display: none;
}
.BreakWrap .ImageMenu {
	margin-right: 8px;
}

.hoverMenu ul li a.activeDrop {
	color: #035cf7;
	font-weight: 500;
}

.hoverMenu ul li a p.meaning {
	color: #666;
	font-size: 12px;
	line-height: 0.1;
	margin: 0;
	padding: 0;
	margin-top: -5px;
	opacity: 0.6;
}
.ListMenu a:hover .menu-active span {
	color: white;
	transform: translateY(0);
}

.hoverherramientasMenu a {
	color: #020c41 !important;
}

.hoverCompensacionesMenu a {
	color: #020c41 !important;
}

.hoverCompensacionesMenu a:hover {
	color: #fa0073 !important;
}

.hoverherramientasMenu a:hover {
	color: #fa0073 !important;
}
a:hover {
	color: #fa0073 !important;
}

.MenuUserProfile .noiconsListMenu a span {
	margin-left: 6px;
}

@media (max-width: 1306px) {
	.ListMenu {
		grid-gap: 15px;
	}
	.displaySearch {
		display: none;
	}
	.ListMenu a {
		font-size: 12px;
	}
	.ExtraMenu {
		width: 324px;
	}
}

@media (max-width: 1151px) {
	.notify-container {
		display: none;
	}
	.notify-container.Language {
		display: table;
	}

	.ExtraMenu {
		width: 270px;
	}
	.LogoMiAM img {
		width: 177px;
	}
}

@media (max-width: 1044px) {
	.contMenuFixed {
		height: 0;
	}
	.MenuContainer {
		display: none;
	}
}

.selectLangugeModal.menuModal .whiteModal .padding .gridFlags {
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 0px;
}

.selectLangugeModal.menuModal .whiteModal .padding {
	padding: 40px 40px;
}

.selectLangugeModal.menuModal .whiteModal {
	width: 350px;
	transform: translate(-50%, -90%);
}

.selectLangugeModal.menuModal.movil .whiteModal {
	width: 350px;
	transform: translate(-50%, -150%);
}

.Language .languageFlag {
	width: 40px;
	height: 40px;
	display: block;
	background-image: url(../../../assets/images/mexico-flag.svg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	border-radius: 50%;
	background-color: rgba(0, 125, 195, 0.1);
}

.Language .languageFlag.es {
	background-image: url(../../../assets/images/usa-flag.svg) !important;
}

.Language .languageFlag.en {
	background-image: url(../../../assets/images/mexico-flag.svg) !important;
}
