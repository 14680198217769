.ancillar-card .card-image-holder {
	height: 140px;
	margin-bottom: 10px;
	overflow: hidden;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.ancillar-card {
	margin: 10px 0;
}

.ancillar-card h5 {
	margin: 10px 0;
}

.ancillar-card p {
	margin: 0;
}

.ancillar-card .blue-arrow {
	transform: translateY(5%);
}

.ancillar-card:hover .card-image-holder {
	transform: scale(1.1);
}

.modalWindowAncillaries .header {
	background: #e9f3f9 !important;
}

.modalWindowAncillaries .contentType .regular-text-content * {
	color: #0b2342 !important;
}

.small-text-alert-modal {
	color: white !important;
	font-size: 15px !important;
}
.small-text-alert-modal-2 {
	color: black !important;
	font-size: 15px !important;
}
