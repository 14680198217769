.ModalContWelcomeAng {
	width: 100%;
	height: 100vh;
	background: rgba(0, 0, 0, 0.6);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99999;
}
.ModalContWelcomeAng span.slider-read {
	width: 153px;
	height: 50px;
	background: rgba(0, 125, 195, 1);
	border: 1px solid rgba(255, 255, 255, 0.5);
	border-radius: 50px;
	color: #ffffff;
	text-align: center;
	padding: 10px;
	display: inline-block;
	margin-top: 25px;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	cursor: pointer;
	margin: 20px auto 0;
	display: block;
}

.ModalContWelcomeAng span.slider-read a {
	color: white;
	text-decoration: none;
	font-size: 14px;
	font-weight: 600;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.ModalContWelcomeAng .blue-arrow {
	width: 20px !important;
	margin-left: 4px;
}

.ModalContWelcomeAng .ModalWhiteWindow {
	background: white;
	width: 500px;
	height: auto;
	overflow: hidden;
	border-radius: 11px;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
}

.ModalContWelcomeAng .ModalWhiteWindow .paddingModal {
	padding: 35px 45px;
}

.ModalContWelcomeAng .ModalWhiteWindow .paddingModal img.angelica {
	margin: 0 auto;
	width: 170px;
	height: 170px;
	object-fit: cover;
	border-radius: 100%;
	display: block;
}

.ModalContWelcomeAng .ModalWhiteWindow .paddingModal h1 {
	line-height: 1;
	color: #0b2343;
	margin-top: 20px;
	text-align: center;
}

.ModalContWelcomeAng .ModalWhiteWindow .paddingModal p {
	margin-top: 20px;
	color: #0b2343;
	opacity: 0.8;
	width: 100%;
	color: #666;
	font-size: 16px;
	line-height: 1.5;
	margin-bottom: 0;
	text-align: center;
}
/* 


<div className="ModalContWelcomeAng">
          <div className="ModalWhiteWindow">
            <div className="paddingModal">
              <img src=""/>
              <h1>Bienvenido a tu experencia</h1>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus at odio non velit pretium finibus vel non metus. In sit amet sem elit. Mauris ut cursus eros, mollis condimentum orci.</p>
            </div>
          </div>
        </div>

.profile__aside {
    background: red;
    width: 240px;
    outline: black;
} */

.profile {
	display: flex;
}
.contProfileMenu {
	/* position: relative;
  overflow: hidden;
  height:calc(100vh - 60px);*/
	width:auto;
	/*overflow: hidden;*/
	/* z-index: 6; */
	background-color: #edf0f5;
}
#profileMenu {
	height: calc(100vh - 60px);
	position: fixed;
	left: 0;
	top: 60px;
	width: 270px;
	overflow: hidden;
	/*border-right: 1px solid #d6d6d6;*/
	/**/
	background-color: #edf0f5;

	position: sticky;
	/* top: 60px; */
	top: 0;
}

#profileContent {
	width: calc(100% - 270px);
	background-color: #f5f8f9;
	border-left: 1px solid #e6eaed;
}

.profile-tabs ul {
	list-style: none;
	margin: 0 auto;
	display: grid;
/* 	grid-template-columns: repeat(3, 1fr); */
}

.profile-tabs.no-travel ul {
	list-style: none;
	margin: 0 auto;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}

.twoTabsNew ul {
	grid-template-columns: repeat(2, 1fr);
}

.profile-tabs ul li {
	border-right: 1px solid #d6d6d6;
	width: 100%;
	justify-content: space-between;
	cursor: pointer;
	
}
.profile-tabs ul li:last-child {
	border-right: none;
	width: 100%;
	justify-content: space-between;
	cursor: pointer;
}
/* .profile-tabs ul li:nth-child(2) {
	border-right: 1px solid #d6d6d6;
	border-left: 1px solid #d6d6d6;
} */
.profile-tabs ul li:hover {
	background-color: rgba(28, 134, 158, 0.07);
}
.profile-tabs ul li .textTabsCont {
	display: table;
	margin: 17px auto 0;
}

.profile-tabs ul li .textTabsCont img,
.profile-tabs ul li .textTabsCont p {
	float: left;
	color: #666;
}
.profile-tabs ul li .textTabsCont img {
	width: 30px;
	height: 30px;
	margin-top: -2px;
}
.profile-tabs ul li .textTabsCont p {
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 0;
}
.profile-tabs ul li.profile-tab-active .textTabsCont p {
	color: rgba(28, 134, 158, 1);
}

.profile-tabs ul li:hover .textTabsCont p {
	color: #1c869e;
}

.profile-tab-active {
	background-color: rgba(28, 134, 158, 0.2);
	color: rgba(28, 134, 158, 1);
	height: 56px;
	width: 308px;
}

.profile-tab {
	width: 308px;
	height: 56px;
}
.profile-tabs {
	border: 1px solid #1c869e;
	border-radius: 7px;
	background: white;
	margin: 40px 0;
	box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
	z-index: 4;
	position: sticky;
	top: 80px;
	margin-top: -26px;
}

.profile-tabs .container-tabs {
	width: 100%;
	height: auto;
	overflow: hidden;
}

.profile-tabs-content {
	width: 100%;
	margin-bottom: 80px;
}
.profile-tab-active.rewards {
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
}
.profile-tab-active.benefits {
	border-bottom-right-radius: 6px;
	border-top-right-radius: 6px;
}
img.icon_profile {
	margin-right: 10px;
}

.ProfileWrapperCont {
	width: 90%;
	height: auto;
	margin: 0 auto;
	max-width: 1050px;
}
@media (max-width: 800px) {
	.profile-tabs {
		top: 40px;
	}
	.profile-tabs-content {
		--safe-area-inset-bottom: env(safe-area-inset-bottom);
		margin-bottom: 80px;
		margin-bottom: calc(80px + var(--safe-area-inset-bottom));
	}
}
@media (max-width: 600px) {
	.profile-tabs ul li .textTabsCont img {
		display: none;
	}
	.profile-tabs ul li .textTabsCont p {
		font-size: 12px;
		text-align: center;
		margin: 0 auto;
		float: initial;
	}
	.profile-tabs ul li {
		display: table;
	}
	.profile-tabs ul li .textTabsCont {
		display: table-cell;
		vertical-align: middle;
	}
}
