.fake-benefits-menu {
	padding: 0 30px;
}

.menu-item-benefits {
	background: rgba(255, 255, 255, 0);
	cursor: pointer;
	padding: 8px 15px;
}

.menu-item-benefits:hover {
	background: rgba(255, 255, 255, 0.5);
}

.menu-item-benefits a {
	color: '#1B869E';
}

.menu-item-benefits a:hover {
	background: none;
	color: '#1B869E' !important;
	padding: 0;
}

.benefit-category-item-img {
	background-position: center;
	height: 155px;
	width: 100%;
}

.right-side-card {
	grid-template-columns: repeat(2, minmax(0, 1fr));
}

.item-holder-benefit {
	cursor: pointer;
}

.item-holder-benefit:hover {
	opacity: 0.5;
}

.benefits-skeleton-container {
	/* grid-template-columns: repeat(2, minmax(0, 1fr)); */
}

.totalBenefits__title__container a:hover {
	color: '#1B869E' !important;
}
