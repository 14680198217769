.form-wrapper {
  width: 50%;
  margin: 0 auto;
  background: #fff;
  max-width: 560px;
  min-width: 320px;
  padding: 40px;
  border-radius: 3px;
}

.control-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 30px;
  position: relative;
}

.label-form {
  width: 40%;
  color: #0b2343;
}

.input-form {
  width: 60%;
}

.input-field {
  text-decoration: none;
  width: 100%;
  font-family: NeueHaasUnicaPro-Medium, sans-serif;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  font-size: 14px;
  height: 50px;
  line-height: 20px;
  color: #0b2343;
  padding: 14px 16px;
  text-transform: capitalize;
  overflow: hidden;
}

.input-email .input-field,
.input-CV .input-field {
  text-transform: lowercase;
}
.input-CV .input-field {
  padding: 0;
}

#pregunta_abierta {
  height: 150px;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  text-transform: initial;
}

.input-field:focus {
  border-color: #007cc2;
  outline: none;
}
.vacantesWrapper.form-container {
  background-color: #007cc2;
  color: #fff;
  padding-bottom: 80px;
}
button#btn-job {
  background-color: #e91b2f;
  border-color: #e91b2f;
  color: #fff;
  opacity: 1;
  font-family: NeueHaasUnicaPro-Medium, sans-serif;
  border: 1px solid #e5e5e5;
  border-radius: 40px;
  font-size: 14px;
  line-height: 1;
  min-height: 61px;
  padding: 22px 40px 23px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
}
.input-field.error {
  border-color: #e91b2f;
}
button#btn-job:disabled {
  background-color: #666;
  border-color: #666;
  opacity: 0.3;
}
h1.form-title {
  width: 50%;
  margin: 0 auto;
  padding: 40px 0;
  margin-top: 30px;
  max-width: 560px;
  padding-right: 20px;
}
p.succes-msg-job {
  color: #085d26;
  margin-top: 10px;
  border: 1px solid #085d26;
  padding: 10px;
  border-left-width: 10px;
  background: rgba(8, 93, 38, 0.2);
}
p.succes-msg-job {
  color: #085d26;
  margin-top: 20px;
  border: 1px solid #085d26;
  padding: 10px;
  border-left-width: 10px;
  background: rgba(8, 93, 38, 0.2);
  text-align: center;
}
.input-CV button {
  border: none;
  border-right: 1px solid #d6d6d6;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 1px solid #f0f;
}

.control-pregunta {
  margin-bottom: 0;
}

/*******/

input[type="file"] {
    /* cursor: inherit;
      display: block;
      font-size: 999px;
      filter: alpha(opacity=0);
      min-height: 100%;
      min-width: 100%;
      opacity: 0;
      position: absolute;
      right: 0;
      text-align: right;
      top: 0; */
      border: none;
      color: #007cc2;
      font-weight: 600;
      font-size: 14px;
      padding-left: 15px;
      height: initial;
      margin: 15px;
  }
  .uploadyourfile{
    color: #007cc2;
    font-weight: 500;
    font-size: 14px;
    width:100%;
    display: block;
    height: auto;
    margin: 0;
    padding: 0;
    padding-left: 16px;
    padding-top: 15px;
    padding-bottom: 15px;
    transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
    display: none;
  }
  /* .input-CV:hover .input-field{
    background-color: rgba(0, 125, 195, 0.07);
  } */
  
  .input-CV .input-field{
    text-transform: capitalize;
  }


/*******/

.control-pregunta .input-field {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: none;
}
.input-CV {
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

/* ***** Responsive Styles ***** */

@media screen and (max-width: 1300px) {
}

@media screen and (max-width: 1044px) {
  .form-wrapper {
    width: 90%;
  }

  h1.form-title {
    width: 90%;
  }
}

@media screen and (max-width: 800px) {
  h1.form-title {
    width: 90%;
  }
}

@media screen and (max-width: 600px) {
  .control-form {
    display: flex;
    flex-direction: column;
  }

  .label-form {
    width: 100%;
  }

  .input-form {
    width: 100%;
  }
}

@media screen and (max-width: 424px) {
}

/* ***** Responsive Styles ***** */
