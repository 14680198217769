.grid-container {
    width: 100%;
    float: none;
    height: auto;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* grid-gap: 40px; */
    color: #0b2343;
}
.servicioPageAM{
    height: auto;
    overflow: hidden;
}
.servicioPageAM .headerServicioPage{
    height: auto;
    overflow: hidden;
    width: 100%;
    background-color: #0D2B59;
    /* background-image: radial-gradient(ellipse at center -50%, #18334f 0%, #18334f 18%, #192532 57%, #192532 100%); */
    background-image: linear-gradient(to right top, #0b2343, #0a2c55, #083668, #043f7c, #004990);
    background-image: url(../../../assets/serviciobg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.servicioPageAM .headerServicioPage .midPage{
    max-width: 1200px;
    width: 90%;
    height: auto;
    overflow: hidden;
    margin: 0 auto;
    padding: 100px 0;
}
.servicioPageAM .headerServicioPage .midPage .leftHeader{
    float: none;
    width: 100%;
    margin-bottom: 40px;
}
.servicioPageAM .headerServicioPage .midPage .rightHeader{
    float: right;
    width: 100%;
    display: flex;
}
.servicioPageAM .headerServicioPage .midPage .rightHeader .windowWhite{
    background-color: white;
    height: auto;
    border-radius: 5px;
    padding: 32px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    float: left;
    margin-right: 30px;
    display: flex;
    width: 99%;
}
.servicioPageAM .headerServicioPage .midPage .iconPases{
    width: 100px;
    height: 100px;
    background-image: url(../../../assets/avion-blanco.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-repeat: no-repeat;
}
.servicioPageAM .headerServicioPage .midPage p.title{
    font-size: 52px;
    max-width: 600px;
    font-weight: 600;
    text-align: left;
    margin: 14px 0 10px;
    line-height: 1.1;
    color: white;
}
.servicioPageAM .headerServicioPage .midPage p.subtitle{
    font-size: 20px;
    font-weight: 300;
    max-width: 900px;
    margin-top: 30px;
    opacity: 1;
    line-height: 1.4;
    margin-bottom: 10px;
    margin-top: 15px;
    text-align: left;
    max-width: 540px;
    color: white;
}

.bannerBottomServicio{
    width: 99%;
    height: auto;
    float: right;
    overflow: hidden;
}
.bannerBottomServicio .bannerBox{
    position: relative;
    width: 100%;
    margin: 0 auto;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 4px 4px 16px rgba(0,0,0,.1);
    border: 1px solid #e5e5e5;
    background-color: white;
    height: 100%;
    display: grid;
    grid-template-rows: repeat(2,auto);
}
.bannerBottomServicio .bannerBox .top{
    border-bottom: 1px solid #e5e5e5;
}
.bannerBottomServicio .bannerBox .top .padding{
    padding: 30px;
    height: auto;
    overflow: hidden;
}
.bannerBottomServicio .bannerBox .top .padding .logoCore{
    width:150px;
    height: 70px;
    background-image: url(../../../assets/core.png);
    background-repeat: no-repeat;
    background-position: -17px center;
    background-size: contain;
}
.leftNewBox{
    width: 100%;
    max-width:678px;
    height: auto;
    overflow: hidden;
}
.bannerBottomServicio .bannerBox .top .padding p.inside{
    font-size: 18px;
    padding: 0;
    margin: 0;
    color: #0b2343;
    float: left;
}
.bannerBottomServicio .bannerBox .top .padding p{
    float: left;
}
.bannerBottomServicio .bannerBox .top .padding p span{
    font-weight: 600;
}
.bannerBottomServicio .bannerBox .top .padding a{
    display: block;
    width: 150px;
    height: 50px;
    border-radius: 100px;
    background-color: #e91b2f;
    color: white;
    font-weight: 600;
    font-size: 14px;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
    margin-top: 20px;
}
.bannerBottomServicio .bannerBox .top .padding a:hover{
    background-color: #0b2343;
}
.btnPolitica {
    display: inline-block;
    width: 180px;
    height: 40px;
    border-radius: 20px;
    background-color: #e91b2f;
    color: white;
    font-weight: 600;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
    margin-top: 20px;
}
.btnPolitica:hover{
    background-color: #ee6270;
    color: white;
}
.bannerBottomServicio .bannerBox .bottom{
    height: auto;
    overflow: hidden;
    width: 100%;
}
.bannerBottomServicio .bannerBox .bottom p{
    text-align: center;
    background-color: rgba(0, 125, 195, .06);
    padding: 18px 20px;
    margin: 0 auto;
    font-size: 16px;
    color: #0b2343;
    font-weight: 500;
    height: 100%;
}
.windowWhite .titleRight{
    text-align: left;
    font-size: 24px;
    color: #0b2343;
    font-weight: 500;
    margin-top: 15px;
    margin-bottom: 0;
}
.windowWhite ol{
    margin: 0;
    padding: 0;
    list-style: none;
}
.windowWhite ol li{
    border-bottom: 1px solid #e5e5e5;
    height: auto;
    overflow: hidden;
    padding: 20px 0;
}
.windowWhite ol li p.smallOl{
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    color: #666;
}
.windowWhite ol li p.phone{
    color: #0b2343;
    font-size: 24px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}
.windowWhite ol li:last-child{
    padding: 0;
    border-bottom: none;
}
.windowWhite ol li p.horario{
    font-weight: 400;
    margin-top: 20px;
    text-align: left;
    color: #007dc3;
    font-size: 14px;
}
.windowWhite ol li p.horario span{
    font-weight: 500;
}
.windowWhite .yoouwillNeed{
    border-radius: 4px;
    background-color: rgba(0, 125, 195, .05);
    height: 100%;
    overflow: hidden;
}
.windowWhite .yoouwillNeed .icon {
    float: left;
    width: 100px;
    height: 100px;
    margin-left: 30%;
    margin-top: 15%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
   /*  background-image: url(/static/media/notes.1ca4b7dd.svg); */
}
.windowWhite .yoouwillNeed .important{
    float: left;
    width: 100%;
    overflow: hidden;
    height: auto;
}
.windowWhite .yoouwillNeed .important p{
    font-weight: 500;
    font-size: 24px;
    margin: 0 0 10px 0;
    line-height: 1.4;
}
.windowWhite .yoouwillNeed .important ul{
    margin-left: 20px;
    font-size: 16px;
    font-weight: 400;
    color: #0b2343;
    margin-bottom: 0;
    margin-top: 20px;
    padding-right: 15px;
}
.windowWhite .yoouwillNeed .important ul li{
    margin-bottom: 10px;
}
.stepPasesServicio{
    color: white!important;
    font-size: 12px!important;
    font-weight: 600;
    background-color: rgba(0, 125, 195, 1);
    border-radius: 100px;
    display: table!important;
    padding: 5px 15px;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 3px;
}
.StepOneBenefits{
    width: 100%;
}
.firstmarginRight{
    width: 100%;
}

@media (max-width: 800px) {
    .grid-container {
        grid-template-columns: repeat(2, 1fr);
    }
    .bannerBottomServicio {
        grid-column: 1/span2;
    }
    .bannerBottomServicio .bannerBox {
        margin-top: 5px;
    }
}

@media (max-width: 600px) {
    .grid-container {
        grid-template-columns: repeat(1, 1fr);
    }
    .bannerBottomServicio {
        grid-column: 1;
    }
    .bannerBottomServicio .bannerBox {
        margin-top: 5px;
    }
    .servicioPageAM .headerServicioPage .midPage .rightHeader .windowWhite {
        margin-top: 5px;
    }
}
@media (max-width: 400px) {
    .servicioPageAM .headerServicioPage .midPage {
        padding: 5px 0;
    }
    .servicioPageAM .headerServicioPage .midPage p.title {
        font-size: 29px;
    }
}
/*
div className="yoouwillNeed">
                                <div className="icon"></div>
                                <div className="important">
                                    <p>Para tramitar tu boleto, necesitarás indicar: </p>
                                    <ul>
                                        <li>Nombre, número de empleado y correo corporativo.</li>
                                        <li>Ruta y vuelo en el que deseas viajar.</li>
                                    </ul>
                                </div>
<div className="windowWhite">
                            <p className="titleRight">Llama al Call Center COBUS</p>

                            <ol>
                                <li>
                                    <p className="smallOl">Telefono 1</p>
                                    <p className="phone">(55) 5133 4033</p>
                                </li>
                                <li>
                                    <p className="smallOl">Telefono 2</p>
                                    <p className="phone">01 800 737 4700</p>
                                </li>
                                <li>
                                    <p className="horario">Horario de atención: Lunes a viernes de 09:00 a 19:00h</p>
                                </li>
                            </ol>
                        </div>

<div className="bannerBottomServicio">
                <div className="bannerBox">
                    <div className="top">
                        <div className="padding">
                            <div className="logoCore"></div>
                            <p>Una vez tengas tu clave de reservación, deberás ingresar a tu Autoservicio de CORE y seguir los pasos en el manual: </p>
                            <a className="verManual">Ver manual</a>
                        </div>
                    </div>
                    <div className="bottom">
                        <p className="note">Una vez hayas reservado los fondos, se enviará para aprobación directamente a tu jefe directo</p>
                    </div>
                </div>
            </div>


<div className="servicioPageAM">
            <div className="headerServicioPage">
                <div className="midPage">
                    <p className="title">Tramita tu pase en comisión de servicio con Aeroméxico</p>
                    <p className="subtitle">Si necesitas viajar en comisión de servicio a cualquiera de los destinos a los que volamos como Grupo Aeroméxico, tienes que seguir estos sencillos pasos para tramitar tus boletos.</p>
                </div>
            </div>
        </div>
        */