.gamReportInside {
	width: 100%;
	height: auto;
	padding: 0 0 40px;
	position: relative;
	z-index: 2;
}
.gamReportInside .accessText {
	margin-bottom: 0 !important;
}
.gamReportInside .smallText {
	color: #666;
	font-size: 18px;
	margin: 0;
	padding: 0;
	font-weight: 400;
}
.gamReportInside .smallText:nth-child(3) {
	font-weight: 400;
	/* color: rgba(0, 125, 195, 1); */
}
.gamReportInside .wantToHearYou {
	margin-top: 30px;
	height: auto;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 25px;
}
.gamReportInside .wantToHearYou .hearyouWrapper {
	/* border:1px solid #d6d6d6; */
	border-radius: 11px;
	background-color: white;
	cursor: pointer;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
	overflow: hidden;
	display: block;
	text-decoration: none;
	height: 182px;
}
.gamReportInside .wantToHearYou .hearyouWrapper:nth-child(2) {
	/* border:1px solid #d6d6d6; */
	box-shadow: none;
}
.gamReportInside .wantToHearYou .hearyouWrapper:hover {
	/*transform: scale(1.02, 1.02);*/
	box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
}
.gamReportInside .wantToHearYou .hearyouWrapper:nth-child(1) {
	/* background-color: rgba(0, 125, 195, .07); */
	/* border:2px solid #004990; */
}

.gamReportInside .wantToHearYou .hearyouWrapper .imageWrapper {
	width: 35%;
	height: 100%;
	float: left;
	position: relative;
}
.gamReportInside .wantToHearYou .hearyouWrapper .imageWrapper {
	background-color: #002b5c;
}

.gamReportInside .wantToHearYou .hearyouWrapper:nth-child(2) .imageWrapper {
	background-color: #1c869e;
	/* background-color: #70727d; */
	background-image: url(../../../../assets/codigo-es.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.gamReportInside .wantToHearYou .hearyouWrapper:nth-child(2) .imageWrapper.es {
	background-image: url(../../../../assets/codigo-es.png);
}

.gamReportInside .wantToHearYou .hearyouWrapper:nth-child(2) .imageWrapper.en {
	background-image: url(../../../../assets/codigo-en.png);
}

.gamReportInside .wantToHearYou .hearyouWrapper .imageWrapper .imageabsolute {
	background-repeat: no-repeat;
	background-position: bottom;
	background-size: contain;
	background-image: url(../../../../assets/gamphone.png);
	position: absolute;
	width: 105%;
	height: 105%;
	left: 18px;
	top: 8px;
}

.gamReportInside .wantToHearYou .hearyouWrapper .imageWrapper .logo {
	position: absolute;
	top: 20px;
	left: 20px;
	width: 125px;
	height: 39px;
	background-repeat: no-repeat;
	background-position: left;
	background-size: contain;
	background-image: url(../../../../assets/gam-white.png);
	z-index: 2;
}
.gamReportInside
	.wantToHearYou
	.hearyouWrapper:nth-child(2)
	.imageWrapper
	.logo {
	display: none;
}
.gamReportInside .wantToHearYou .hearyouWrapper .textWrapper {
	padding: 25px;
	width: 65%;
	float: left;
	height: 100%;
}

.gamReportInside .wantToHearYou .hearyouWrapper .textWrapper p.name {
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 2px;
	margin: 0;
	padding: 0;
	color: #0b2343;
}
.gamReportInside .wantToHearYou .hearyouWrapper .textWrapper p.intro {
	font-size: 16px;
	color: #0b2343;
	line-height: 1.4;
	margin-top: 10px;
}
.gamReportInside .wantToHearYou .hearyouWrapper .textWrapper p.callAction {
	color: rgba(0, 125, 195, 1);
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 0;
}

@media (max-width: 1044px) {
	.gamReportInside .wantToHearYou .hearyouWrapper {
		width: 100%;
	}

	.gamReportInside
		.wantToHearYou
		.hearyouWrapper:nth-child(2)
		.imageWrapper.es {
		background-size: 100%;
	}

	.react-reveal.wantToHearYou {
		display: flex;
		flex-wrap: wrap;
	}

	.gamReportInside .wantToHearYou .hearyouWrapper .imageWrapper {
		min-width: 100px;
	}

	.gamReportInside .wantToHearYou .hearyouWrapper .textWrapper p.intro {
		font-size: 14px;
	}

	.gamReportInside .wantToHearYou .hearyouWrapper .imageWrapper .logo {
		width: 80px;
	}
}

@media (min-width: 723px) {
	.react-reveal.wantToHearYou {
		flex-wrap: nowrap;
	}
}

/*
<div className="gamReportInside">
          <p className="accessText">¡Queremos escucharte!</p>
          <p>Utiliza estas herramientas para reportar temas de seguridad y de cumplimiento. </p>
          <p>¡Levantar la mano es poner la seguridad primero!</p>

          <div className="wantToHearYou">
            <div className="hearyouWrapper">
                <div className="imageWrapper">
                    <div className="logo"></div>
                </div>
                <div className="textWrapper">
                    <p className="name">GAM e-Report</p>
                    <p className="intro">¿Has detectado algún riesgo potencial o quieres reportar alguna omisión de seguridad?</p>
                    <p className="Call to action">Enviar reporte</p>
                </div>
            </div>
            */
