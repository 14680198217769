.idQuotes{
    width: 100%;
    padding:30px 0 110px;
    margin:40px 0 0;
    border-top: 1px solid rgba(255, 255, 255, .2);
    border-bottom: 1px solid rgba(255, 255, 255, .2);
    margin-top: 0;
}

.videoCXEvolution{
    position: absolute;
    width:100%;
    height: 100%;
    top:0;
    left: 0;
    z-index: 1;
}

.videoCXEvolution video{
    top:0;
    bottom: initial;
}

.filterBlue{
    position: absolute;
    width:100%;
    height: 100%;
    top:0;
    left: 0;
    z-index: 2;
    background-color: #0b2343;
    opacity: .8;
}
.videoTextTop{
    position: relative;
    z-index: 3;
}
.cxGalleryPicture{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.cxGalleryPicture{ background-image: url(../../../assets/cxsite/galeria/cxgallery62.jpg); }
.cxGalleryPicture.cx02{ background-image: url(../../../assets/cxsite/galeria/cxgallery21.jpg); }
.cxGalleryPicture.cx03{ background-image: url(../../../assets/cxsite/galeria/cxgallery3.jpg); }
.cxGalleryPicture.cx04{ background-image: url(../../../assets/cxsite/galeria/cxgallery4.jpg); }
.cxGalleryPicture.cx05{ background-image: url(../../../assets/cxsite/galeria/cxgallery40.jpg); }
.cxGalleryPicture.cx06{ background-image: url(../../../assets/cxsite/galeria/cxgallery6.jpg); }
.cxGalleryPicture.cx07{ background-image: url(../../../assets/cxsite/galeria/cxgallery7.jpg); }
.cxGalleryPicture.cx08{ background-image: url(../../../assets/cxsite/galeria/cxgallery8.jpg); }
.cxGalleryPicture.cx09{ background-image: url(../../../assets/cxsite/galeria/cxgallery9.jpg); }
.cxGalleryPicture.cx10{ background-image: url(../../../assets/cxsite/galeria/cxgallery10.jpg); }
.cxGalleryPicture.cx11{ background-image: url(../../../assets/cxsite/galeria/cxgallery11.jpg); }
.cxGalleryPicture.cx12{ background-image: url(../../../assets/cxsite/galeria/cxgallery12.jpg); }
.cxGalleryPicture.cx13{ background-image: url(../../../assets/cxsite/galeria/cxgallery13.jpg); }
.cxGalleryPicture.cx14{ background-image: url(../../../assets/cxsite/galeria/cxgallery14.jpg); }
.cxGalleryPicture.cx15{ background-image: url(../../../assets/cxsite/galeria/cxgallery15.jpg); }
.cxGalleryPicture.cx16{ background-image: url(../../../assets/cxsite/galeria/cxgallery55.jpg); }
.cxGalleryPicture.cx17{ background-image: url(../../../assets/cxsite/galeria/cxgallery130.jpg); }
.cxGalleryPicture.cx18{ background-image: url(../../../assets/cxsite/galeria/cxgallery18.jpg); }
.cxGalleryPicture.cx19{ background-image: url(../../../assets/cxsite/galeria/cxgallery30.jpg); }
.cxGalleryPicture.cx20{ background-image: url(../../../assets/cxsite/galeria/cxgallery20.jpg); }


.idQuotes .cxQuotes{
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    max-width: 800px;
}

.midQuotes{
    width:100%;
    margin: 0 auto;
    height: auto;
}

.idQuotes .cxQuotes p{
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    color: white;
    padding: 0;
    max-width: 75%;
    margin: 0 auto;
    padding-bottom: 40px;
    line-height: 1.4;
}

.cxLeftWrapper .slider-read{
    margin-left: auto;
    margin-right: auto;
    display: table;
    width: initial;
    padding-left: 20px;
    padding-right: 20px;
}

.gridButtonsCX .slider-read:nth-child(1){
    border:1px solid #e91b2f;
}

.cxBlockVideo{
    background-image: url(../../../assets/cxsite/cxbg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-bottom: 1px solid rgba(255, 255, 255, .2);
    padding-bottom: 50px;
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    z-index: 2;
}


.gridButtonsCX{
    display: grid;
    grid-template-columns: repeat(2, auto);
    width: 382px;
    margin: 0 auto;
    grid-gap: 11px;
}

.gridButtonsCX .slider-read:nth-child(1){
    background: #e91b2f;
}

.cxWrapperData{
    width:100%;
    height: auto;
    overflow: hidden;
    background-color: white;
    padding: 20px 0 0;
}
.cxWrapperData.divCardWhite{
    max-width: 1200px;
    margin: 0 auto;
        margin-top: 0px;
        margin-bottom: 0px;
    margin-top: -80px;
    border-radius: 11px;
    margin-bottom: 80px;
    width: 90%;
}

.cxMiddleWrap{
    margin: 0 auto;
    width: 100%;
    height: auto;
}
.cxLeftWrapper .swiper-container, .cxLeftWrapper .swiper-container-horizontal{
    overflow: hidden;
}
.calendarioSesiones{
    width:100%;
    min-height:170px;
    overflow: hidden;
    background-color:#0b2343;
    border-top: 1px solid rgba(255,255,255,.2);
}
.calendarioSesiones .calWrapper{
    width:90%;
    height: 100%;
    overflow: hidden;
    margin: 0 auto;
    max-width: 1400px;
    display: table;
}

.calendarioSesiones .calWrapper .calWrapperCell{
    display: table-cell;
    vertical-align: middle;
    padding: 40px 20px 80px 20px;
}

.calendarioSesiones .calWrapper .calWrapperCell .calButton{
    width:477px;
    height: auto;
    overflow: hidden;
    float: left;
}

.calendarioSesiones .calWrapper .calWrapperCell .calButton a{
    display: block;
    width:100%;
    height: auto;
    overflow: hidden;
}

.calendarioSesiones .calWrapper .calWrapperCell .calButton a .circleImage{
    width:66px;
    height: 66px;
    border-radius: 100%;
    background-color: #e91b2f;
    float: left;
    margin-top: 11px;
    background-image: url(../../../assets/logo/icon-calendar.svg);
}

.calendarioSesiones .calWrapper .calWrapperCell .calButton a .TextWrapper{
    float: left;
    width: calc(100% - 66px);
    padding-left: 20px;
}

.calendarioSesiones .calWrapper .calWrapperCell .calButton a .TextWrapper h4{
    color: white;
    font-size: 20px;
    font-weight: 500;
}

.calendarioSesiones .calWrapper .calWrapperCell .calButton a .TextWrapper p{
    color: white;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 0;
}
.calendarioSesiones .calWrapper .calWrapperCell .calButton a .TextWrapper p a{
    display: table;
    color: #007dc3;
    font-weight: 500;
    width: 110px;
    margin-top: 7px;
}

.calendarioSesiones .calWrapper .calWrapperCell .cxLogo{
    float: right;
    width: 250px;
    height: 60px;
    margin: 0 auto;
    margin-top: 26px;
    background-image: url(../../../assets/logo/cx-evolution.svg);
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
}

.cxDataTop{
    background-color: #004990;
    height: auto;
    overflow: hidden;
}

.cxDataTop .cxBackgroundImage{
    background-color:#004990;
    background-size: cover;
    background-position: center;
    height: auto;
    overflow: hidden;
    background-image: linear-gradient(to left bottom, #004990, #043f7c, #083668, #0a2c55, #0b2343);
}

.cxDataTop .cxBackgroundImage .cxBackgroundBlue{

    width:100%;
    height: auto;
    overflow: hidden;
}

.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx{
    /*width:90%;
    max-width: 1400px;*/
    width: 100%;
    height: auto;
    margin: 0 auto;
    /*overflow: hidden;*/
/*
    display: flex;
    width: 100%;*/
}

.wrapperFixedCX{
    width: 90%;
    max-width: 1100px;
    margin: 0 auto;
}

.wrapperIconsCX{
    width:100%;
    color: white;
    overflow: hidden;
    
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 30px;
    

    /*
    display: -webkit-box;     
	display: -moz-box;        
	display: -ms-flexbox;      
	display: -webkit-flex;     
	display: flex;             
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap; 
	-webkit-align-items: center; 
    align-items: center;
    justify-content: center;
	-webkit-justify-content: center;
    margin: 0 auto;
    overflow: hidden;
    */
}
.wrapperIconsCX .quejasFrecuente{
    /*width: calc((100% / 4) - 40px);*/
    width: 100%;
    margin: 30px 20px;
}
.wrapperIconsCX .quejasFrecuente .quejaIcon{
    width: 60px;
    height: 60px;
    float: left;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.wrapperIconsCX .quejasFrecuente:nth-child(1) .quejaIcon{background-image: url(../../../assets/cxsite/airports.svg)}
.wrapperIconsCX .quejasFrecuente:nth-child(2) .quejaIcon{background-image: url(../../../assets/cxsite/callcenters.svg)}
.wrapperIconsCX .quejasFrecuente:nth-child(3) .quejaIcon{background-image: url(../../../assets/cxsite/cargo.svg)}
.wrapperIconsCX .quejasFrecuente:nth-child(4) .quejaIcon{background-image: url(../../../assets/cxsite/mantenimiento.svg)}
.wrapperIconsCX .quejasFrecuente:nth-child(5) .quejaIcon{background-image: url(../../../assets/cxsite/admin.svg)}
.wrapperIconsCX .quejasFrecuente:nth-child(6) .quejaIcon{background-image: url(../../../assets/cxsite/pilotos.svg)}
.wrapperIconsCX .quejasFrecuente:nth-child(7) .quejaIcon{background-image: url(../../../assets/cxsite/sobrecargos.svg)}

.wrapperIconsCX .quejasFrecuente .quejaText{
    /*float: left;*/
}
.wrapperIconsCX .quejasFrecuente .quejaText p.number{
    margin: 0;
    padding: 0;
    font-size: 32px;
    font-weight: 500;
    margin-top: -10px;
    color: #0b2343;
}
.wrapperIconsCX .quejasFrecuente .quejaText p.number::after{
    content: "%";
    font-size: 20px;
}
.wrapperIconsCX .quejasFrecuente .quejaText p.description{
    margin: 0;
    padding: 0;
    font-size: 14px;
    opacity: .7;
    color: #0b2343;
}

/*

 <div className="wrapperIconsCX">
                    
                    <div class="quejasFrecuente">
                      <div class="quejaIcon"></div>
                      <div class="quejaText">
                        <p class="number">13</p>
                        <p class="description">Aeropuertos</p>
                      </div>
                    </div>


*/

.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxLeftWrapper{
    width: 100%;
    border-right: 1px solid rgba(255, 255, 255, .2);
}

.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxRightWrapper{
    width: 100%;
    height: auto;
    overflow: hidden;
    border-top: 1px solid #e5e5e5;
    padding: 30px 0 50px;
    background-color: white;
}

.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxLeftWrapper .cxLogo{
    width:240px;
    height: 70px;
    margin: 0 auto;
    margin-top: 78px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../../../assets/logo/cx-evolution.svg);
}

.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxLeftWrapper h2{
    color: white;
    font-size: 62px;
    font-weight: 500;
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 56px;
    font-weight: 600;
}

.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxLeftWrapper p.subH3{
    font-size: 22px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    color: white;
    max-width: 800px;
    text-align: center;
    margin: 20px auto 0;
}

.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxLeftWrapper p.titleTop{
    font-size: 14px;
    text-transform: uppercase;
    opacity: .7;
    color: white;
    font-weight: 600;
    letter-spacing: 2px;
    margin-top: 30px;
    text-align: center;
}
 .cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxLeftWrapper .cxWrapperData p.titleTop{
    color: #0b2343;
    opacity: 1;
}

.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxLeftWrapper .cxWrapperData .cxDataNumbers .cxDataWrapper p.cxNumber{
    color: #0b2343;
}

.asisteWrapeCx{
    /* border: 1px solid #f0f; */
    border-radius: 11px;
    padding: 30px;
    margin-top: 20px;
    background: rgb(0, 125, 195, .05);
}

.asisteWrapeCx p:nth-child(2){
    font-size: 24px;
    color: #0b2343;
    font-weight: 500;
    text-align: center;
    margin: 0;
    margin-bottom: 7px;
    padding: 0;
    margin-top: -15px;
}

.asisteWrapeCx p:nth-child(3){
    font-size: 18px;
    color: #666;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
}

.asisteWrapeCx .iconImgCX{
    width:87px;
    height: 80px;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 10px;
    background-image: url(../../../assets/nosotros/numero.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxLeftWrapper .cxGraphAvance{
    width:100%;
    background-color: #f5f5f5;
    border:1px solid #d6d6d6;
    height: 60px;
    border-radius: 40px;
    position: relative;
    width: 90%;
    margin: 0 auto;
}

.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxLeftWrapper .cxGraphAvance .cxGraphRed{
    width:calc(51% - 20px);
    background-color: #e91b2f;
    height: 75%;
    border-radius: 40px;
    position: absolute;
    top:50%;
    left: 10px;
    transform: translate(-0%,-50%);
}

.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxLeftWrapper .cxDataNumbers{
    width:80%;
    height: auto;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(3,auto);
    margin: 0 auto;
}

.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxLeftWrapper .cxDataNumbers .cxDataWrapper{
    width:100%;
}

.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxLeftWrapper .cxDataNumbers .cxDataWrapper p.cxNumber{
    font-size: 64px;
    color: white;
    margin: 0;
    padding: 0;
    margin-top: -30px;
    text-align: center;
    font-weight: 300;
}

.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxRightWrapper .targetData{
    width: 100%;
    height: auto;
    overflow: hidden;
}

.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxRightWrapper .targetData ul{
    border-radius: 8px;
    display: block;
    margin: 0;
    padding: 0;
    overflow: hidden;
    height: auto;
}

.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxRightWrapper .targetData ul li{
    width:94%;
    margin:0 auto;
    border-radius: 8px;
    list-style: none;
    height: 64px;
    padding: 10px;
}

.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxRightWrapper .targetData ul li:nth-child(1){
    margin-top: 15px;
}

.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxRightWrapper .targetData ul li:last-child{
    margin-bottom: 15px;
}

.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxRightWrapper .targetData ul li:nth-child(odd){
    background-color: white;
    border:1px solid #e5e5e5;
}

.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxRightWrapper .targetData ul li span{
    width:100%;
    height: auto;
    overflow: hidden;
    display: block;
}

.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxRightWrapper .targetData ul li span p:nth-child(1){
    float: left;
    font-size: 14px;
}

.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxRightWrapper .targetData ul li span p:nth-child(2){
    float: right;
    font-weight: 500;
    font-size: 16px;
}

.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxRightWrapper .targetData ul li span p{
    margin: 0;
    padding: 0;
}

.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxRightWrapper .targetData ul li .graphTarget{
    width:100%;
    height: 7px;
    background-color: #d6d6d6;
    border-radius: 20px;
    margin-top: 4px;
    display: block;
    overflow: hidden;
}

.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxRightWrapper .targetData ul li .graphTarget .graphRed{
    width:0%;
    background-color:#e91b2f;
    height: 100%;
    border-radius: 20px;
}

.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxRightWrapper .targetData ul li:nth-child(1) .graphTarget .graphRed{width:13%}
.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxRightWrapper .targetData ul li:nth-child(2) .graphTarget .graphRed{width:9%}
.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxRightWrapper .targetData ul li:nth-child(3) .graphTarget .graphRed{width:9%}
.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxRightWrapper .targetData ul li:nth-child(4) .graphTarget .graphRed{width:30%}
.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxRightWrapper .targetData ul li:nth-child(5) .graphTarget .graphRed{width:29%}
.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxRightWrapper .targetData ul li:nth-child(6) .graphTarget .graphRed{width:39%}
.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxRightWrapper .targetData ul li:nth-child(7) .graphTarget .graphRed{width:24%}

.cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxRightWrapper .targetData h4{ 
    color:#0b2343;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 11px;
}

.idQuotes .cxQuotes p.titleTop{
    margin-top: 15px!important;
    padding-bottom: 10px;
}
@media (max-width: 1024px) {
    .cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxLeftWrapper h2{
        font-size: 44px;
    }
    .cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxLeftWrapper p.subH3{
        font-size: 18px;
        padding: 0 40px;
    }
    .idQuotes .cxQuotes p{
        font-size: 24px;
    }
    .cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxLeftWrapper p.titleTop{
        font-size: 12px;
    }
    .cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxLeftWrapper .cxDataNumbers .cxDataWrapper p.cxNumber{
        font-size: 44px;
    }
    .wrapperIconsCX .quejasFrecuente .quejaIcon{
        float: none;
    }
    .wrapperIconsCX .quejasFrecuente .quejaText p{
        text-align: center;
    }
    .wrapperIconsCX .quejasFrecuente .quejaIcon{
        margin: 0 auto;
    }
    .wrapperIconsCX .quejasFrecuente .quejaText p.number{
        margin-top: 10px;
    }
    .wrapperIconsCX .quejasFrecuente{
        margin: 15px 0;
    }
}

@media (max-width: 800px) {
    .calendarioSesiones .calWrapper .calWrapperCell .calButton{
        float: none;
    }

    .calendarioSesiones .calWrapper .calWrapperCell .cxLogo{
        float: none;
    }

    .calendarioSesiones .calWrapper .calWrapperCell .calButton a .TextWrapper{
        width: 350px;
    }

    
}



@media (max-width: 730px) {
    .cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxLeftWrapper h2{
        font-size: 30px;
    }
    .cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxLeftWrapper p.subH3{
        font-size: 16px;
    }
    .idQuotes .cxQuotes p{
        font-size: 18px;
    }
    .cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxLeftWrapper .cxGraphAvance{
        width: 100%;
    }
    .cxDataTop .cxBackgroundImage .cxBackgroundBlue .midWrapperCx .cxLeftWrapper .cxDataNumbers{
        grid-template-columns: repeat(1,auto);
    }
    .wrapperIconsCX{
        grid-template-columns: repeat(3,1fr);
    }
}

@media (max-width: 414px) {
    .calendarioSesiones .calWrapper .calWrapperCell .calButton a .circleImage{
        
        float:none;
        margin-bottom: 20px;
        margin-left: 20px;
    }

    .calendarioSesiones .calWrapper .calWrapperCell .calButton a .TextWrapper{
        float:none;
        width: 300px;
    }
}


@media (max-width: 414px) {
    .wrapperIconsCX .quejasFrecuente .quejaIcon{
        margin: 0 0;
        float: none;
    }

    .gridButtonsCX{
        grid-template-columns: repeat(1, auto);
        width: 100%;
        grid-gap: 0px;
    }
}