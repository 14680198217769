.header-main-holder {
	padding: 0;
}
.desde-header {
	align-items: center;
	animation: backgroundAnimation 10s ease infinite;
	background: linear-gradient(270deg, rgb(0, 125, 195), rgb(0, 217, 170));
	background-size: 400% 400%;
	display: flex;
	height: 250px;
	justify-content: center;
	/* padding-top: 40px; */
	position: relative;
}

.desde-header h1 {
	align-items: center;
	display: flex;
	flex-direction: column;
	color: white;
	font-size: 50px;
	font-weight: bold;
	margin: 0;
}

.desde-header h1 svg {
	display: block;
	fill: white;
	height: 0.65em;
	margin: 0;
	width: auto;
}

.nav-container {
	left: 0;
	position: sticky;
	width: 100%;
	top: 75px;
	z-index: 2;
}

.nav-container.scrolled {
	top: 60px;
}

.nav-container .section-nav {
	align-items: center;
	background: rgba(255, 255, 255, 0.07);
	border-radius: 40px;
	box-shadow: 0 20px 40px rgba(0, 0, 0, 0.05);
	display: flex;
	height: 60px;
	justify-content: center;
	left: 50%;
	list-style-type: none;
	overflow: hidden;
	padding: 8px;
	position: absolute;
	top: 0;
	transform: translate(-50%);
	transition: all 0.25s ease-in-out;
	min-width: 887px;
}

.nav-container .section-nav.scrolled {
	background: rgba(34, 57, 85, 1);
	border-radius: 0;
	min-width: 100%;
}

.nav-container .section-nav li {
	border-radius: 40px;
	display: flex;
	margin-right: 10px;
	height: 100%;
}

.nav-container .section-nav li:last-child {
	margin-right: 0;
}

.nav-container .section-nav li a {
	align-items: center;
	background: transparent;
	border-radius: 40px;
	color: white;
	display: flex;
	font-size: 14px;
	height: 100%;
	padding: 0 15px;
	transition: background 0.25s ease-in-out;
	width: 100%;
}
.nav-container .section-nav li a:hover {
	background: rgba(255, 255, 255, 0.3);
}

.nav-container .section-nav li.is-active {
	background: white;
}
.nav-container .section-nav li.is-active a {
	color: #0b2343;
}

@keyframes backgroundAnimation {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.main-content-container {
	padding-bottom: 60px;
	padding-top: 60px;
}

.desde-title {
	align-items: center;
	color: #0b2343;
	display: flex;
	justify-content: flex-start;
	margin-bottom: 20px;
}

.desde-title h2 {
	font-size: 24px;
	margin: 0;
}

.desde-title svg {
	fill: transparent;
	height: 35px;
	margin: 0 15px 0 0;
	stroke: #027dc3;
	stroke-width: 3px;
	width: auto;
}

.featured-item {
	background: #0b2343;
	border-radius: 11px;
	box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
	color: white !important;
	display: block;
	height: 250px;
	overflow: hidden;
	padding: 20px;
	position: relative;
	text-decoration: none;
	transform: scale(1);
	transition: all 0.15s ease-in-out;
	width: 100%;
}

.featured-item * {
	position: relative;
	z-index: 1;
}

.featured-item .bg-overlay {
	background-size: cover !important;
	background-position: center !important;
	height: 100%;
	width: 100%;
	opacity: 0.35;
	position: absolute;
	left: 0;
	top: 0;
	transition: opacity 0.15s linear;
	z-index: 0;
}

.featured-item .category {
	font-size: 14px;
	margin: 0;
}

.featured-item .title {
	font-size: 20px;
	font-weight: bold;
	margin: 0.25em 0 0.5em;
}

.featured-item .description {
	font-size: 13px;
	margin: 0;
	width: 50%;
}

.featured-item .go2 {
	font-weight: bold;
	margin: 0;
	position: absolute;
	left: 20px;
	bottom: 20px;
	display: flex;
	align-items: center;
	transform: translateY(10px);
	transition: all 0.15s ease-in-out;
	opacity: 0;
}

.featured-item .go2 svg {
	height: 1.75em;
	margin: 0;
	stroke: rgba(0, 125, 195, 1);
	width: auto;
	position: absolute;
	right: 0;
	top: 60%;
	transform: translate(100%, -50%);
}

.featured-item:hover {
	box-shadow: 0 25px 40px rgba(0, 0, 0, 0.3);
	transform: scale(1.025);
}

.featured-item:hover .bg-overlay {
	opacity: 0.25;
}

.featured-item:hover .go2 {
	opacity: 1;
	transform: translateY(0);
}

.regular-section-holder {
	background: rgba(0, 125, 195, 0.06);
	border: 1px solid #e5e5e5;
	border-radius: 11px;
	margin-left: 0;
	margin-right: 0;
	padding: 25px 15px;
	transition: all 0.25s ease-in-out;
}
.regular-section-holder:hover {
	background: rgba(0, 125, 195, 0.08);
	border: 1px solid rgba(0, 125, 195, 1);
}

.items-regular-holder {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 25px;
}

.post-item {
	background-size: cover !important;
	background-position: center !important;
	border-radius: 11px;
	box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
	color: white !important;
	height: 150px;
	overflow: hidden;
	position: relative;
	transform: scale(1);
	transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.post-item * {
	position: relative;
	z-index: 2;
}

.post-item .bg-overlay {
	background: #0b2343;
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	opacity: 0;
	z-index: 1;
	transition: opacity 0.5s ease-in-out;
	will-change: auto;
}

.intel-holder {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	padding: 20px;
	transform: translateY(65%);
	transition: transform 0.5s ease-in-out;
}

.post-item-holder-parent .title {
	color: #0b2343 !important;
	font-size: 16px;
	margin: 0.5em 0.25em;
}

.post-item .description {
	font-size: 14px;
	line-height: 1.3;
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
}

.post-item .go2 {
	font-weight: bold;
	margin: 0;
	position: absolute;
	left: 20px;
	bottom: 20px;
	display: flex;
	align-items: center;
	transform: translateY(10px);
	transition: all 0.15s ease-in-out;
	opacity: 0;
}

.post-item .go2 svg {
	height: 1.75em;
	margin: 0;
	stroke: rgba(0, 125, 195, 1);
	width: auto;
	position: absolute;
	right: 0;
	top: 60%;
	transform: translate(100%, -50%);
}

.post-item-holder-parent:hover .post-item {
	box-shadow: 0 25px 40px rgba(0, 0, 0, 0.3);
	transform: scale(1.05);
}

.post-item-holder-parent:hover .post-item .intel-holder {
	transform: translateY(0);
}

.post-item-holder-parent:hover .post-item .description {
	opacity: 1;
}

.post-item-holder-parent:hover .post-item .bg-overlay {
	opacity: 0.85;
}

.post-item-holder-parent:hover .post-item .go2 {
	opacity: 1;
}
