.planeNotFound{
  position: absolute;
  right: 4%;
  bottom: 10%;
  width: 700px;
  height: 350px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.search-container-notfound {
  height: calc(100vh - 60px);
  background-color: #004990;
  display: block;
  width: 100%;
  min-height: 600px;
  position: relative;
}
.a-search-notfound {
  color: white;
  font-size: 16px;
}
.title-search-notfound {
  color: #ffffff;
  font-size: 70px;
  font-weight: 500;
  letter-spacing: -0.015em;
  line-height: 1.2;
  max-width: 400px;
  max-width: 900px;
}
p.subtitle-search-notfound {
  color: #ffffff;
  font-size: 24px;
  line-height: 1.38105;
  font-weight: 400;
  letter-spacing: 0.011em;
  font-weight: 400;
  margin-top: 80px;
}

.search-sub-container-notfound {
  background-color: #ffffff;
  border: none;
  display: inline-block;
  width: 45%;
  border-radius: 50px;
  padding: 20px;
  text-align: left;
  position: relative;
}
.form-search-notfound {
  text-align: left;

  padding: 0 40px;
}
.box-container-search-notfound {
  width: 100%;
  height:100%;
  border:1px solid #000;
  overflow: hidden;
}

.box-container-search-notfound .MidNofFound{
  width:90%;
  max-width: 1400px;
  height:100%;
  overflow: hidden;
  margin: 0 auto;
  display: table;
}
.box-container-search-notfound .MidNofFound .MidNofFoundCell{
  display: table-cell;
  vertical-align: middle;
}
.input-search-notfound {
  border: none;
  outline-width: 0;
  width: 100%;
  color: #0b2343;
  font-size: 24px;
}
.button-search-notfound {
  position: absolute;
  right: 25px;
  background: #e91b2f;
  border: none;
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
.button-search-icon-notfound {
  color: #fff;
  font-size: 20px;
}
.input-searchnotfound-notfound::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #0b2343;
}

.input-search-notfound::-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #0b2343;
}

.input-search-notfound::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #0b2343;
}
.Info-search-notfound {
  height: 75px;
  text-align: left;
  width: 100%;
}
.item-list-search-notfound {
  display: grid;
  grid-template-columns: repeat(5, auto);
  max-width: 550px;
}
li.search-list-notfound {
  color: white;
  font-size: 14px;
  list-style: none;
  cursor: pointer;
  font-weight: 500;
  line-height: 35px;
  opacity: 0.7;
  padding: 0;
  letter-spacing: 0.4px;
  height: 35px;
  margin: 0;
  margin-top: 10px;
}

img.notfound {
  float: right;
  padding: 0 40px;
  margin-top: -17%;
}

/* Responsive */
@media (max-width: 1044px) {
  ul.item-list-search-notfound {
    display: block;
    padding: 0;
  }
  p.title-search-notfound {
    font-size: 45px;
  }
}
@media (max-width: 1044px) {
  .img-notfound {
    max-width: 100%;
  }
}
.box-container-search-notfound {
  background-repeat: no-repeat;
  background-position-y: 135%;
  background-position-x: 90%;
}
@media (max-width: 1044px) {
  p.title-search-notfound {
    float: none;
    font-size: 20px;
    column-count: 1;
  }
  div.box-container-search-notfound {
    background-size: contain;
    background-position: bottom;
  }
  p.subtitle-search-notfound {
    margin-top: 20px;
    font-size: 20px;
  }
  div.search-sub-container {
    width: 100%;
    padding: 10px;
  }
  div.Info-search-notfound {
    height: auto;
    padding-left: 40px;
  }
  div.search-container-notfound {
    height: auto;
  }
  ul li.search-list-notfound {
    color: #565656;
    opacity: 1;
  }
  li a.a-search-notfound {
    color: #fff;
  }
}
@media (max-width: 1044px) {
  div.search-sub-container-notfound {
    width: 100%;
    padding: 10px;
  }
}
