.headerJCA {
	width: 100%;
	padding: 140px 0 60px;
	background-color: #0b2343;
	background: linear-gradient(45deg, #020c41, #030d75, #e91b2f, #e01933);
	animation: Gradient 10s ease infinite;
	background-size: 400% 400%;
}

.headerJCA .midWrapJCA {
	width: 90%;
	max-width: 1200px;
	margin: 0 auto;
}

.headerJCA .midWrapJCA .title {
	font-size: 62px;
	font-weight: 600;
	color: white;
	margin: 0;
	padding: 0;
	text-align: center;
}

.headerJCA .midWrapJCA .subtitle {
	font-size: 32px;
	font-weight: 300;
	color: white;
	margin: 0;
	padding: 0;
	text-align: center;
}

.headerJCA .midWrapJCA .imageDelta {
	width: 340px;
	height: 46px;
	margin: 0 auto;
	background-image: url(../../../assets/jca/logojca.svg);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}

.bannerIntroJCA {
	width: 100%;
	/* height: 400px; */
	overflow: hidden;
	background-image: url(../../../assets/jca/clouds.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.bannerIntroJCA .midWrapJCAdetla {
	max-width: 1200px;
	width: 90%;
	margin: 0 auto;
	padding: 140px 0;
}

.bannerIntroJCA .midWrapJCAdetla p:nth-child(1) {
	color: white;
	font-weight: 600;
	font-size: 42px;
	max-width: 561px;
	line-height: 1.2;
}

.bannerIntroJCA .midWrapJCAdetla p:nth-child(2) {
	color: white;
	max-width: 520px;
	font-size: 18px;
}

.deltainNumbers {
	height: auto;
	width: 100%;
	overflow: hidden;
	background-color: #f1f6f8;
}

.deltainNumbers .midWrapNumbers {
	max-width: 1200px;
	width: 90%;
	margin: 0 auto;
	height: auto;
	overflow: hidden;
	padding: 40px 0;
}

.deltainNumbers .midWrapNumbers .titleNumbers {
	font-size: 42px;
	font-weight: 600;
	text-align: center;
	color: #002b5c;
}

.deltainNumbers .midWrapNumbers .gridnumbers {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 40px;
	margin-top: 40px;
}

.deltainNumbers .midWrapNumbers .gridnumbers .imageGrid {
	background-color: #002b5c;
	width: 160px;
	height: 160px;
	margin: 0 auto;
	border-radius: 50%;
	background-image: linear-gradient(to right top, #0b2343, #0f2d60, #22367d, #3e3c98, #5f3fb1);
	margin-bottom: 10px;
	float: none;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.deltainNumbers .midWrapNumbers .gridnumbers .boxWrappergrid:nth-child(1) .imageGrid {
	background-image: url(../../../assets/jca/plane-circle.png),
		linear-gradient(to right top, #0b2343, #0f2d60, #22367d, #3e3c98, #5f3fb1);
}

.deltainNumbers .midWrapNumbers .gridnumbers .boxWrappergrid:nth-child(2) .imageGrid {
	background-image: url(../../../assets/jca/flag-circle.png),
		linear-gradient(to right top, #0b2343, #0f2d60, #22367d, #3e3c98, #5f3fb1);
}

.deltainNumbers .midWrapNumbers .gridnumbers .boxWrappergrid:nth-child(3) .imageGrid {
	background-image: url(../../../assets/jca/delta-tierra.png),
		linear-gradient(to right top, #0b2343, #0f2d60, #22367d, #3e3c98, #5f3fb1);
}

.deltainNumbers .midWrapNumbers .gridnumbers .boxWrappergrid:nth-child(4) .imageGrid {
	background-image: url(../../../assets/jca/delta-pasajero.png),
		linear-gradient(to right top, #0b2343, #0f2d60, #22367d, #3e3c98, #5f3fb1);
}

.deltainNumbers .midWrapNumbers .gridnumbers .textWrapper {
	float: none;
	width: 100%;
}

.deltainNumbers .midWrapNumbers .gridnumbers p {
	text-align: center;
	margin: 0;
	padding: 0;
}

.deltainNumbers .midWrapNumbers .gridnumbers p.percentage {
	font-weight: 600;
	font-size: 38px;
	color: #004990;
}

.deltainNumbers .midWrapNumbers .gridnumbers p.percentage span {
	font-size: 16px;
}

.deltainNumbers .midWrapNumbers .gridnumbers p.description {
	margin-top: -4px;
	color: #666;
	font-size: 14px;
}

.weflytoimage {
	background-image: url(../../../assets/jca/delta.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	height: auto;
	overflow: hidden;
}

.weflytoimage .midWrapFly {
	max-width: 1200px;
	width: 90%;
	margin: 0 auto;
	padding: 140px 0;
	height: auto;
	overflow: hidden;
}

.weflytoimage .midWrapFly .textWrapper {
	width: 50%;
	float: right;
}

.weflytoimage .midWrapFly p.title {
	font-weight: 600;
	font-size: 42px;
	max-width: 561px;
	line-height: 1.2;
	color: white;
}

.weflytoimage .midWrapFly p.subtitle {
	max-width: 520px;
	font-size: 18px;
	color: white;
}

#newsPageJCA .ArticleCardWrap {
	grid-template-columns: repeat(4, 1fr);
}

#newsPageJCA .ArticleCardWrap .DeltaTag {
	display: none;
}

.patrociniosWrapper {
	height: auto;
	overflow: hidden;
	width: 100%;
}

.patrociniosWrapper .midWrapperPatrocinios {
	max-width: 1200px;
	margin: 0 auto;
	height: auto;
	overflow: hidden;
	width: 90%;
	padding: 70px 0;
}

.patrociniosWrapper .midWrapperPatrocinios p.title {
	font-size: 42px;
	line-height: 1.2;
	font-weight: 600;
	color: #002b5c;
	margin: 0;
	padding: 0;
	margin-bottom: 10px;
}

.patrociniosWrapper .midWrapperPatrocinios p.sibtittle {
	max-width: 620px;
	font-size: 18px;
	color: #666;
}

.patrociniosWrapper .midWrapperPatrocinios .wrapperBoxGridPatro {
	height: auto;
	overflow: hidden;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-gap: 15px;
	margin-top: 30px;
}

.patrociniosWrapper .midWrapperPatrocinios .wrapperBoxGridPatro .oneBoxPrato {
	width: 100%;
	height: 110px;
}

.patrociniosWrapper .midWrapperPatrocinios .wrapperBoxGridPatro .oneBoxPrato .img {
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.patrociniosWrapper .midWrapperPatrocinios .wrapperBoxGridPatro .oneBoxPrato:nth-child(1) .img {
	background-image: url(../../../assets/jca/seleccion.jpg);
}

.patrociniosWrapper .midWrapperPatrocinios .wrapperBoxGridPatro .oneBoxPrato:nth-child(2) .img {
	background-image: url(../../../assets/jca/cruz.jpg);
}

.patrociniosWrapper .midWrapperPatrocinios .wrapperBoxGridPatro .oneBoxPrato:nth-child(3) .img {
	background-image: url(../../../assets/jca/music.jpg);
}

.patrociniosWrapper .midWrapperPatrocinios .wrapperBoxGridPatro .oneBoxPrato:nth-child(4) .img {
	background-image: url(../../../assets/jca/tele.jpg);
}

.patrociniosWrapper .midWrapperPatrocinios .wrapperBoxGridPatro .oneBoxPrato:nth-child(5) .img {
	background-image: url(../../../assets/jca/green.jpg);
}

.patrociniosWrapper .midWrapperPatrocinios .wrapperBoxGridPatro .oneBoxPrato:nth-child(6) .img {
	background-image: url(../../../assets/jca/frida.jpg);
}

/***
<div className="patrociniosWrapper">
           <div className="midWrapperPatrocinios">
             <p className="title">Nuestros patrocionios</p>
             <p className="sibtittle">A través de distintos patrocinios y acciones de responsabilidad social, ambas aerolíneas han apoyadodistintos eventos que promueven la diversidad, inclusión, actividades deportivas, artísticas y culturales.</p>
             <div className="wrapperBoxGridPatro">
               <div className="oneBoxPrato">
                 <div className="img"></div>
               </div>
             </div>
           </div>
         </div>
***/

.experienceDelta .midWrapper .boxExperienceGrid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	width: 100%;
	/* overflow: hidden; */
	grid-gap: 30px;
	margin-top: 50px;
}

.experienceDelta .midWrapper .boxExperienceGrid .gridEx {
	box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
	width: 100%;
	height: 30vw;
	overflow: hidden;
	/* border: 1px solid rgba(255, 255, 255, 0.2); */
	border-radius: 7px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	max-height: 360px;
}

.experienceDelta .midWrapper .boxExperienceGrid .gridEx.gridExSmall {
	height: auto;
	background-color: #f47723;
}

.experienceDelta .midWrapper .boxExperienceGrid .gridEx.gridExSmall .logo {
	width: 45px;
	height: 45px;
	margin-bottom: 10px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.experienceDelta .midWrapper .boxExperienceGrid .gridEx.gridExSmall .logo {
	background-image: url(../../../assets/jca/sky-team.png);
}

.experienceDelta .midWrapper .boxExperienceGrid .gridEx.gridExSmall:nth-child(5) .logo {
	background-image: url(../../../assets/jca/family.svg);
}

.experienceDelta .midWrapper .boxExperienceGrid .gridEx.gridExWidthBigger {
	/* width: calc(200% + 26px); */
	background-color: #1c869e;
	grid-column: 1 / span 2;
}

.experienceDelta .midWrapper .boxExperienceGrid .gridEx:nth-child(1) {
	background-image: url(../../../assets/jca/food.jpg);
}

.experienceDelta .midWrapper .boxExperienceGrid .gridEx:nth-child(2) {
	background-image: url(../../../assets/jca/free.jpg);
}

.experienceDelta .midWrapper .boxExperienceGrid .gridEx:nth-child(3) {
	background-image: url(../../../assets/jca/internet.jpg);
}

.experienceDelta .midWrapper .boxExperienceGrid .gridEx .padding {
	padding: 30px 34px;
}

.experienceDelta .midWrapper .boxExperienceGrid .gridEx .padding p.GridText {
	color: white;
	font-weight: 600;
	font-size: 24px;
	margin: 0;
	padding: 0;
	line-height: 1.2;
	margin-bottom: 10px;
}

.experienceDelta .midWrapper .boxExperienceGrid .gridEx .padding p.subtitleGrid {
	color: white;
	font-size: 14px;
	margin: 0;
	padding: 0;
	padding-right: 20px;
}

.sectionNumbers {
	background-color: #f1f6f8;
	height: auto;
	overflow: hidden;
	padding-bottom: 0;
	padding-top: 80px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.sectionNumbers .wrapperMid {
	width: 90%;
	height: auto;
	overflow: hidden;
	margin: 0 auto;
	position: relative;
	z-index: 4;
	max-width: 1300px;
}

.sectionNumbers .wrapperMid .text-float {
	width: 100%;
	float: none;
	height: auto;
	overflow: hidden;
	margin-bottom: 40px;
}

.sectionNumbers .wrapperMid .grid-icons {
	width: 100%;
	float: none;
	height: auto;
	overflow: hidden;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	/* grid-gap: 40px; */
	color: #0b2343;
	margin-top: 60px;
	margin-bottom: 60px;
}

.sectionNumbers .wrapperMid .grid-icons .IconsOpcs {
	border-right: 1px solid #e5e5e5;
}

.sectionNumbers .wrapperMid .grid-icons .IconsOpcs:last-child {
	border-right: none;
}

.sectionNumbers .wrapperMid .grid-icons .IconsOpcs p:nth-child(2) {
	font-weight: 500;
	font-size: 18px;
	text-align: center;
	margin: 0 auto;
	margin-top: 30px;
	font-weight: 600;
	max-width: 250px;
	line-height: 1.3;
	margin-bottom: 20px;
}

.sectionNumbers .wrapperMid .grid-icons .IconsOpcs p:nth-child(3) {
	font-weight: 400;
	font-size: 14px;
	text-align: center;
	max-width: 320px;
	margin: 0 auto;
	color: #666;
	margin-bottom: 60px;
}

.sectionNumbers .wrapperMid .grid-icons .iconsImg {
	width: 70px;
	height: 70px;
	background-color: rgba(28, 134, 158, 0.07);
	border-radius: 50%;
	margin: 0 auto;
	margin-top: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.sectionNumbers .wrapperMid .grid-icons .iconsImg .fa {
	width: inherit;
	margin: auto;
	text-align: center;
	line-height: inherit;
	font-size: 35px;
	color: #40849b;
}

.JCASectionOne {
	/* background-color: #1c869e; */
	width: 100%;
	height: auto;
	padding: 74px 0;
	position: relative;
	padding-bottom: 150px;
	background-repeat: no-repeat;
	background-position: top;
	background-size: cover;
	/* background-image: url(../../../assets/seguridad/seguridad.jpg); */
	height: 900px;
}

.JCASectionOne .wrapperMid {
	width: 90%;
	height: auto;
	overflow: hidden;
	margin: 0 auto;
	position: relative;
	z-index: 4;
	max-width: 1300px;
}

.JCASectionOne .wrapperMid .slider-read {
	width: 153px;
	height: 50px;
	background: #e91b2f;
	border-radius: 50px;
	color: #ffffff;
	text-align: center;
	padding: 10px 30px;
	display: table;
	width: auto;
	cursor: pointer;
}

.JCASectionOne .wrapperMid .slider-read .blue-arrow {
	transform: rotate(90deg);
}

.JCASectionOne .wrapperMid h2 {
	font-size: 56px;
	max-width: 700px;
	font-weight: 600;
	color: white;
	line-height: 1.2;
	/* margin: 0 auto; */
	margin-top: 30px;
	text-align: left;
	color: #007dc3;
}

.JCASectionOne .wrapperMid p {
	font-size: 22px;
	font-weight: 400;
	color: white;
	max-width: 900px;
	margin-top: 20px;
	opacity: 1;
	line-height: 1.4;
	margin-bottom: 10px;
	/* margin: 0 auto; */
	margin-top: 15px;
	text-align: left;
	color: #0b2343;
	max-width: 600px;
}

.JCASectionOne .wrapperMid p:nth-child(4) {
	font-size: 18px;
	font-weight: 300;
	margin-top: 0;
}

.JCASectionOne .wrapperMid h4 {
	font-size: 24px;
	font-weight: 500;
	color: white;
	margin-top: 40px;
	opacity: 1;
	line-height: 1.3;
	text-align: center;
	max-width: 700px;
	margin: 20px auto 0;
}

.blueFilter {
	background-color: rgba(11, 35, 67, 0.7);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
}

.jca h3 {
	/* font-size: 36px; */
	color: #40849b;
	text-align: center;
	font-weight: 600;
}

.jca h3 span {
	color: #1c869e;
}

.borderDiv {
	border-bottom: none;
	padding-bottom: 0;
}

@media (max-width: 1360px) {
	.backgroundCont .peopleHappy {
		left: 80%;
	}
}

@media (max-width: 1360px) {
	.sectionNumbers .wrapperMid .text-float {
		width: 100%;
		padding-bottom: 30px;
	}
	.sectionNumbers .wrapperMid .grid-icons {
		width: 100%;
	}
}

@media (max-width: 1224px) {
	.SeguridadSectionTwo .backgroundImagePlane .planeHappy {
		margin-top: 137px;
	}
	.SeguridadSectionTwo .backgroundImagePlane .planeHappy {
		background-position: left 90px;
	}
	.SeguridadSectionTwo {
		height: auto;
		padding-bottom: 33px;
	}
	.SeguridadSectionThree .wrapperMid p {
		font-size: 18px;
	}
	/* .SeguridadSectionThree .wrapperMid .areas {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  } */
	.SeguridadSectionThree .wrapperMid .areas .opcAreas {
		height: auto;
	}
	.SeguridadSectionThree .wrapperMid .areas .opcAreas .padding p.subtitle {
		font-size: 12px;
		line-height: 1.5;
		padding-bottom: 34px;
		max-width: 100%;
	}
	.SeguridadSectionThree .wrapperMid .areas .opcAreas .padding .icon {
		width: 40px;
		height: 40px;
		margin-top: -10px;
		right: 20px;
	}
	.SeguridadSectionThree .wrapperMid .areas .opcAreas .padding {
		padding: 25px;
	}
	.SeguridadSectionThree .wrapperMid .areas .opcAreas .padding p.title {
		font-size: 20px;
	}
	.SeguridadSectionThree .wrapperMid .areas .opcAreas .padding a {
		left: 25px;
		width: calc(100% - 50px);
	}
}

@media (max-width: 1044px) {
	.menu .menuWrap {
		width: 90%;
		overflow: hidden;
	}
	.sectionNumbers .wrapperMid .text-float {
		width: 100%;
	}
	.sectionNumbers h3,
	.SeguridadSectionThree h3 {
		text-align: center;
	}
	.sectionNumbers .wrapperMid .grid-icons {
		width: 100%;
	}
	.SeguridadSectionThree .wrapperMid p.center {
		text-align: center;
		margin: 0 auto;
	}
	.sectionNumbers h3 {
		margin-bottom: 20px;
	}
	.sectionNumbers .wrapperMid .grid-icons .IconsOpcs p:nth-child(2) {
		font-size: 16px;
		margin-bottom: 5px;
	}
	.sectionNumbers .wrapperMid .grid-icons .IconsOpcs p:nth-child(3) {
		font-size: 12px;
	}
	.SeguridadSectionThree .wrapperMid .areas {
		padding-bottom: 140px;
	}
	.JCASectionOne {
		padding: 60px 0;
		height: 750px;
	}
	.JCASectionOne .wrapperMid h2 {
		font-size: 44px;
		max-width: 500px;
	}
	.JCASectionOne .wrapperMid p {
		margin-top: 19px;
		font-size: 18px;
		max-width: 400px;
	}
	.JCASectionOne .wrapperMid .logoSeguridadFirst {
		width: 270px;
		height: 65px;
		margin-top: 23px;
	}
	.JCASectionOne .wrapperMid h4 {
		font-size: 20px;
		max-width: 375px;
		margin-top: 29px;
	}
	.backgroundCont .peopleHappy {
		height: 690px;
		width: 494px;
		top: 47%;
	}
	.SeguridadSectionTwo .wrapperMid .textWrapper p {
		font-size: 18px;
	}
	.SeguridadSectionTwo .wrapperMid p.Intro {
		font-size: 20px;
		width: 70%;
	}
	.SeguridadSectionTwo .wrapperMid p.small {
		font-size: 18px;
	}
	.bannerIntroJCA .midWrapJCAdetla {
		padding: 70px 0;
	}
}

/* @media (max-width: 1024px) {
  .SeguridadSectionTwo .wrapperMid p.Intro {
    margin: 244px auto 0;
  }
} */

@media (max-width: 914px) {
	.backgroundCont .peopleHappy {
		left: 81%;
	}
	.experienceDelta .midWrapper .boxExperienceGrid {
		grid-template-columns: repeat(2, 1fr);
	}
	.experienceDelta .midWrapper .boxExperienceGrid .gridEx.gridExWidthBigger {
		grid-column: 1;
	}
	.experienceDelta .midWrapper .boxExperienceGrid .gridEx:nth-child(3) {
		grid-column: 1 / span 2;
	}
	#newsPageJCA .ArticleCardWrap {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media (max-width: 830px) {
	.backgroundCont .peopleHappy {
		left: 89%;
		width: 402px;
	}
	.SeguridadSectionTwo .backgroundImagePlane .planeHappy {
		background-position: left 131px;
	}
	.deltainNumbers .midWrapNumbers .gridnumbers {
		grid-template-columns: repeat(2, 1fr);
	}
	.patrociniosWrapper .midWrapperPatrocinios .wrapperBoxGridPatro {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media (max-width: 742px) {
	.sectionNumbers .wrapperMid .grid-icons {
		grid-template-columns: repeat(2, 1fr);
	}
	.sectionNumbers .wrapperMid .grid-icons .IconsOpcs p:nth-child(3) {
		font-size: 12px;
	}
	.JCASectionOne {
		background-color: #1c869e;
	}
	.JCASectionOne .wrapperMid p {
		max-width: 100%;
	}
	.JCASectionOne .wrapperMid h4 {
		max-width: 100%;
	}
	.backgroundCont {
		width: 100%;
		height: 300px;
		position: relative;
		top: 0;
		left: 0;
	}
	.JCASectionOne .wrapperMid h2 {
		font-size: 30px;
	}
	.JCASectionOne .wrapperMid h4 {
		font-size: 18px;
	}
	.JCASectionOne .wrapperMid p {
		font-size: 16px;
	}
	.JCASectionOne .wrapperMid h2 {
		max-width: 100%;
	}
	.backgroundCont .tealColor {
		display: none;
	}
	.backgroundCont .imgeBGColor {
		display: none;
	}
	.backgroundCont .peopleHappy {
		position: relative;
		top: 0;
		height: 95%;
		right: 0;
		left: initial;
		transform: translate(0);
		float: right;
		width: 100%;
	}
	.JCASectionOne {
		padding-bottom: 0;
		height: auto;
	}
	.SeguridadSectionTwo .backgroundImagePlane {
		position: relative;
	}
	.SeguridadSectionTwo .wrapperMid .textWrapper {
		width: 100%;
		float: none;
	}
	.jca h3 {
		font-size: 24px;
	}
	.SeguridadSectionTwo {
		padding: 36px 0;
		height: auto;
		overflow: hidden;
	}
	.SeguridadSectionTwo .wrapperMid .textWrapper p {
		font-size: 16px;
		line-height: 1.3;
	}
	.borderDiv {
		padding-bottom: 20px;
		border-bottom: none;
	}
	.SeguridadSectionTwo .wrapperMid p.Intro {
		width: 100%;
		margin: 0;
		font-size: 18px;
		text-align: left;
		line-height: 1.3;
		margin-top: 20px;
	}
	.SeguridadSectionTwo .wrapperMid p.small {
		font-size: 16px;
		margin-top: 10px;
		text-align: left;
	}
	.SeguridadSectionTwo .backgroundImagePlane {
		height: 200px;
	}
	.SeguridadSectionTwo .backgroundImagePlane .waveSecond {
		display: none;
	}
	.SeguridadSectionTwo .backgroundImagePlane .planeHappy {
		width: 80%;
		height: 105%;
		margin-top: 23px;
		background-position: left top;
	}
	.SeguridadSectionThree .wrapperMid p {
		font-size: 16px;
		line-height: 1.3;
	}
	/* .SeguridadSectionThree .wrapperMid .areas {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    margin-top: 30px;
  } */
	.SeguridadSectionThree .wrapperMid .areas .opcAreas {
		height: auto;
		overflow: hidden;
	}
	.SeguridadSectionThree .wrapperMid .areas .opcAreas .padding {
		padding: 15px;
	}
	.SeguridadSectionThree .wrapperMid .areas .opcAreas .padding a {
		font-size: 12px;
		position: relative;
		width: 100%;
		bottom: inherit;
		left: inherit;
		margin-top: 15px;
		font-weight: 600;
	}
	.SeguridadSectionThree .wrapperMid .areas .opcAreas .padding p.title {
		font-size: 16px;
	}
	/* .SeguridadSectionThree .wrapperMid .areas .opcAreas .padding p.subtitle {
    display: none;
  } */
	.SeguridadSectionThree .wrapperMid .areas .opcAreas .padding .icon {
		width: 35px;
		height: 35px;
		position: initial;
		margin-top: 0;
		margin-bottom: 10px;
	}
	.SeguridadSectionThree .wrapperMid .areas .opcAreas:hover {
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
		box-shadow: 0 30px 60px rgba(0, 0, 0, 0.2);
	}
	.sectionNumbers {
		padding-bottom: 40px;
	}
	.SeguridadSectionThree {
		padding-top: 40px;
	}
	.SeguridadSectionThree .blueTop {
		height: 260px;
	}
	.experienceDelta .midWrapper .boxExperienceGrid {
		grid-template-columns: repeat(1, 1fr);
	}
	.experienceDelta .midWrapper .boxExperienceGrid .gridEx.gridExWidthBigger {
		grid-column: 1;
	}
	.experienceDelta .midWrapper .boxExperienceGrid .gridEx:nth-child(3) {
		grid-column: 1;
	}
	.experienceDelta .midWrapper .boxExperienceGrid .gridEx {
		height: auto;
	}
	#newsPageJCA .ArticleCardWrap {
		grid-template-columns: repeat(2, 1fr);
	}
}

.sectionNumbers {
	margin-bottom: 40px;
}

.sectionNumbers p {
	color: #666;
}

.text-40849B {
	color: #40849b;
}

.text-55baf2 {
	color: #55baf2;
}

.text-0b2343 {
	color: #0b2343;
}

.bigNumber {
	display: block;
	font-size: 4rem;
}

.grid-span-2 {
	grid-column: span 2;
}

.grid-span-2 p {
	max-width: 90% !important;
}

.flex-space-between {
	max-width: 80%;
	margin: auto;
	color: #666;
	display: flex;
	justify-content: space-between;
}

.flex-space-between > div {
	max-width: 40%;
	margin-bottom: 20px;
}

.bg-0b2343 {
	background-color: #0b2343;
}

.text-white {
	color: #fff !important;
}

.travelExpierence {
	padding: 40px 0;
}

.travelFlex {
	display: flex;
	margin: 40px auto;
	max-width: 1300px;
	height: 300px;
}

.travelFlex > * {
	width: 40%;
}

.travelFlex > *:first-child {
	border-right: thin solid #fff;
	width: 60%;
}

.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.skyTeamFigure {
	max-width: 70%;
	font-size: 0.85rem;
}

.skyTeamFigure img {
	max-width: 20%;
	margin-bottom: 20px;
}

.travelFlexLogos {
	display: grid;
	width: 100%;
	grid-template-rows: repeat(2, 130px);
	grid-template-columns: repeat(2, 1fr);
}

.travelFlexLogos > div {
	display: flex;
	flex-direction: column;
}

.jc-flex-end {
	justify-content: flex-end;
}

.font-75 {
	font-size: 0.75rem;
}

.font-1_25 {
	font-size: 1.25rem;
}

.react-reveal.partnersLogos {
	color: white;
	display: grid;
	justify-items: center;
	width: 100%;
	grid-template-rows: repeat(2, 1fr);
	grid-template-columns: repeat(3, 1fr);
}

.react-reveal.partnersLogos img {
	max-width: 200px;
}

.react-reveal.partnersLogos figcaption {
	font-size: 1.25rem;
}

.bg-007dc3 {
	background-color: #007dc3;
}

.salesFlex {
	display: flex;
	justify-content: space-around;
	margin: 0 auto;
	padding: 80px 0;
	max-width: 1300px;
}

.salesFlex > * {
	width: 40%;
}

.salesFlex h3 {
	color: #fff;
}

.salesFlex p {
	max-width: 240px;
	margin: auto;
	font-size: 1.15rem;
}

.salesIcons {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 70px;
	height: 70px;
}

.salesIcons .fa {
	width: inherit;
	margin: auto;
	text-align: center;
	line-height: inherit;
	font-size: 35px;
	color: #007dc3;
}

.loyaltyFlex {
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin: 0 auto;
	padding: 20px 0;
	max-width: 1300px;
	color: #fff;
}

.loyaltyFlex > * {
	padding: 2rem;
}

.loyaltyFlex p {
	font-size: 1.25rem;
}

.loyaltyFlex img {
	max-width: 200px;
}

.newsJCA {
	width: 100%;
	height: auto;
	overflow: hidden;
	/* background-color: rgba(0, 125, 195, .07); */
}

.newsJCA .LatestWrap.newsWrapperWidth {
	width: 90%;
	max-width: 1200px;
	margin: 0 auto;
	height: auto;
	padding: 20px 0 50px;
}

.experienceDelta {
	height: auto;
	overflow: hidden;
	width: 100%;
	background-color: #020c41;
	border-bottom: 1px solid rgba(255, 255, 255, 0.07);
	padding: 80px 0;
}

.experienceDelta .midWrapper {
	max-width: 1200px;
	width: 90%;
	height: auto;
	overflow: hidden;
	margin: 0 auto;
}

.experienceDelta .midWrapper p.title {
	font-size: 42px;
	line-height: 1.2;
	font-weight: 600;
	color: white;
	margin: 0;
	padding: 0;
	margin-bottom: 10px;
	max-width: 400px;
}

.experienceDelta .midWrapper p.subtitle {
	max-width: 520px;
	font-size: 18px;
	color: white;
	max-width: 600px;
	margin: 0;
	padding: 0;
}

/**
<div className="boxExperienceGrid">
               <div className="gridEx">
                 <div className="padding">
                   <p className="GridText">Alimentos a bordo</p>
                 </div>
               </div>

***/

@media screen and (max-width: 800px) {
	.react-reveal.partnersLogos {
		grid-template-columns: repeat(2, 1fr);
	}
	.loyaltyFlex {
		display: block;
	}
	.bannerIntroJCA .midWrapJCAdetla {
		padding: 40px 0;
	}
	.weflytoimage .midWrapFly .textWrapper {
		width: 100%;
	}
}

@media screen and (max-width: 600px) {
	.react-reveal.partnersLogos {
		grid-template-columns: repeat(1, 1fr);
	}
	.deltainNumbers .midWrapNumbers .gridnumbers {
		grid-template-columns: repeat(1, 1fr);
	}
	#newsPageJCA .ArticleCardWrap {
		grid-template-columns: repeat(1, 1fr);
	}
}

@media screen and (max-width: 400px) {
	.headerJCA {
		padding: 40px 0 60px;
	}
	.headerJCA .midWrapJCA .title {
		font-size: 30px;
	}
	.headerJCA .midWrapJCA .subtitle {
		font-size: 23px;
	}
	.bannerIntroJCA .midWrapJCAdetla p:nth-child(1) {
		font-size: 25px;
	}
	.deltainNumbers .midWrapNumbers .titleNumbers {
		font-size: 25px;
	}
	.weflytoimage .midWrapFly p.title {
		font-size: 25px;
	}
	.patrociniosWrapper .midWrapperPatrocinios p.title {
		font-size: 25px;
	}
	.experienceDelta .midWrapper p.title {
		font-size: 25px;
	}
}

.jcaHero {
	width: 100%;
	height: auto;
}

.jcaHero img {
	width: 100%;
	height: 400px;
	object-fit: cover;
}

.jcaHero aside {
	grid-row: 1 / 2;
	grid-column: 3 / 4;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	color: #fff;
}

.jcaHero h3 {
	color: #fff !important;
}

.jcaHero article {
	grid-row: 2 / 3;
	grid-column: 1 / 4;
}

.jcaHero p {
	max-width: 700px;
	margin: 0 auto;
	color: #fff;
	padding: 4rem 0;
	text-align: center;
}

.jcaMap {
	background-color: #fff;
}

.jcaMap article {
	max-width: 1000px;
	margin: 0 auto;
	display: grid;
	grid-template-rows: repeat(2, auto);
	grid-template-columns: repeat(3, 1fr);
	color: #666;
}

.jcaMap-info {
	grid-column: 1 / 4;
	grid-row: 1 / 2;
	max-width: 600px;
	margin: 0 auto;
	text-align: center;
}

.jcaMap-info p:first-child {
	font-size: 150%;
}

.jcaMap-info p:last-child {
	margin-top: -2.25rem;
}

.jcaMap-map {
	grid-column: 1 / 3;
	grid-row: 2 / 3;
}

.jcaMap-countries {
	grid-column: 3 / 4;
	grid-row: 2 / 3;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.jcaMap-countries aside > * {
	max-width: 250px;
	margin-bottom: 40px;
}
