.WhiteBackgrounds {
	width: 100%;
	padding-bottom: 80px;
	background-color: white;
	display: flex;
	position: relative;
	z-index: 6;
}
.text-row {
	margin-top: 6px !important;
	border-radius: 4px;
	margin-bottom: 6px;
}

.BackMiddleWrap {
	max-width: 1400px;
	width: 90%;
	margin: 0 auto;
	height: auto;
	display: flex;
}

.fixedStates {
	position: -webkit-sticky;
	position: sticky;
	top: 100px;
	z-index: 5;
}

.WrapperContent {
	/*
  float: left;
  width: calc(((100% / 4) * 1) - 30px);
  height: auto;
  margin-left: 30px;*/

	float: left;
	width: 320px;
	margin-left: 30px;
	margin-top: -70px;
	z-index: 2;
}
.ProfileWrapBox {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 130px;
	background-color: #fff;
	position: relative;
	z-index: 10;
	border-radius: 8px;
	/*border: 1px solid #e5e5e5;*/
	box-shadow: 0 0.15em 0.4em 0.065em rgba(0, 0, 0, 0.075);
	display: block;
	transition: all 0.15s linear;
	text-decoration: none;
	color: #0b2343;
	overflow: hidden;
}
.ProfileWrapBox:hover {
	box-shadow: 2px 3px 2px rgba(0, 0, 0, 0.08);
	color: #0b2343;
}
.ProfileWrapBox:hover .PaddingProfileBox {
	background-color: rgba(0, 125, 195, 0.09);
}
.PaddingProfileBox {
	padding: 20px;
	height: 100%;
	transition: 0.25s linear;
}
.GridProfileBox {
	width: 100%;
	height: 100%;
	align-items: center;
}

.GridProfileBox a {
	color: rgba(0, 125, 195, 1);
	font-size: 14px;
	font-weight: 500;
	transition: 0.25s linear;
	text-decoration: none;
	background-color: rgba(0, 125, 195, 0.09);
	padding: 2px 10px;
	border-radius: 100px;
}
.ProfileWrapBox:hover .GridProfileBox a {
	background-color: rgba(0, 125, 195, 1);
	color: white;
}
.UserProfilePic {
	width: 70px;
	height: 70px;
	background-color: #004990;
	border-radius: 50%;
	float: left;
	margin-top: 7px;
	background-image: url(../../../assets/images/profile/default-profile.svg);
}
.TextProfileWrap {
	float: left;
	margin-left: 20px;
	width: calc(100% - 90px);
}
.WelcomeText {
	font-weight: 500;
	font-size: 18px;
	margin: 0;
	margin-bottom: 5px;
	line-height: 1.2;
	margin-top: 7px;
	text-transform: capitalize;
}
.WelcomeText span {
	text-transform: lowercase;
}
.BenefitsText {
	color: #666;
	margin: 0;
	font-size: 13px;
	line-height: 1.5;
	margin-bottom: 5px;
}
.WrapperCards {
	width: calc(100% - 348px);

	float: left;
}
/****ACCESSOS*/
.accessText {
	color: #0b2343;
	font-size: 24px;
	font-weight: 500;
	line-height: 1.2;
	margin-top: 0;
}

ol.buttonsLinks {
	width: 100%;
	height: auto;
	margin-top: 10px;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-gap: 30px;
	margin-bottom: 0;
}

ol.buttonsLinks li {
	border-radius: 8px;
	border: 1px solid #e5e5e5;
	box-shadow: 0 0.15em 0.4em 0.065em rgba(0, 0, 0, 0.075);
	/* background-color: #fff; */
	height: auto;
	list-style: none;
	/* padding-bottom: 25px; */
	overflow: hidden;
	background: white;
	transition: all 0.15s linear;
}

ol.buttonsLinks li:nth-child(1) {
	margin-top: 0;
}

ol.buttonsLinks li:hover {
	background: rgba(3, 13, 117, 1);
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

ol.buttonsLinks li a {
	text-decoration: none;
	cursor: pointer;
	height: 100%;
	width: 100%;
	transition: all 0.15s linear;
}

ol.buttonsLinks li a .IconLinks {
	float: left;
	height: auto;
	width: 100%;
}

ol.buttonsLinks li a .IconLinks svg {
	/* width: 30px;
	height: 30px; */
	vertical-align: baseline;
	display: flex;
	margin-top: 5px;
	transition: all 0.15s linear;
}

ol.buttonsLinks li a .IconLinks img {
	width: 50px;
	height: 50px;
	vertical-align: baseline;
	display: block;
	margin: 11px auto;
}

ol.buttonsLinks li a .TextLinks {
	display: block;
	height: auto;
	float: left;
	padding: 0 10px;
}

ol.buttonsLinks li a .TextLinks .TextLinksCell {
	display: table-cell;
	vertical-align: middle;
}

ol.buttonsLinks li a .TextLinks .TextLinksCell .TitleLink {
	font-weight: 500;
	font-size: 16px;
	margin: 0;
	margin-bottom: 5px;
	/* color: #020c41; */
	margin: 0;
	transition: 0.25s linear;
	padding: 0;
	text-align: center;
	line-height: 1.3;
}

ol.buttonsLinks li:hover a .TextLinks .TextLinksCell .TitleLink {
	/* color: #fa0073; */
}

ol.buttonsLinks li a .TextLinks .TextLinksCell .CopyLink {
	font-weight: 400;
	font-size: 14px;
	margin: 0;
	margin-bottom: 5px;
	color: #666;
	margin: 0;
	padding: 0;
}

.SwipperAppWrap {
	box-shadow: none;
}

.face1 {
	background-image: url("../../../assets/images/profile/default-profile.svg") !important;
}
.face2 {
	background-image: url("../../../assets/images/profile/user-profile01.svg") !important;
}
.face3 {
	background-image: url("../../../assets/images/profile/user-profile02.svg") !important;
}
.face4 {
	background-image: url("../../../assets/images/profile/user-profile03.svg") !important;
}
.face5 {
	background-image: url("../../../assets/images/profile/user-profile04.svg") !important;
}
.face6 {
	background-image: url("../../../assets/images/profile/user-profile05.svg") !important;
}
.face7 {
	background-image: url("../../../assets/images/profile/user-profile06.svg") !important;
}
.face8 {
	background-image: url("../../../assets/images/profile/user-profile07.svg") !important;
}
.face9 {
	background-image: url("../../../assets/images/profile/user-profile08.svg") !important;
}
.face10 {
	background-image: url("../../../assets/images/profile/user-profile09.svg") !important;
}
.face11 {
	background-image: url("../../../assets/images/profile/user-profile10.svg") !important;
}
.face12 {
	background-image: url("../../../assets/images/profile/user-profile11.svg") !important;
}
.face13 {
	background-image: url("../../../assets/images/profile/user-profile12.svg") !important;
}
.face14 {
	background-image: url("../../../assets/images/profile/user-profile13.svg") !important;
}
.face15 {
	background-image: url("../../../assets/images/profile/user-profile14.svg") !important;
}
.face16 {
	background-image: url("../../../assets/images/profile/user-profile15.svg") !important;
}
.face17 {
	background-image: url("../../../assets/images/profile/user-profile16.svg") !important;
}
.face18 {
	background-image: url("../../../assets/images/profile/user-profile17.svg") !important;
}
.face19 {
	background-image: url("../../../assets/images/profile/user-profile18.svg") !important;
}
.face20 {
	background-image: url("../../../assets/images/profile/user-profile19.svg") !important;
}
.face21 {
	background-image: url("../../../assets/images/profile/user-profile20.svg") !important;
}
.face22 {
	background-image: url("../../../assets/images/profile/user-profile21.svg") !important;
}
.face23 {
	background-image: url("../../../assets/images/profile/user-profile22.svg") !important;
}

.welcomeName {
	color: #0b2343;
	text-align: center;
	font-size: 24px;
	font-weight: 500;
	padding: 0 30px;
	margin-bottom: 0;
	line-height: 1.3;
	font-weight: 500;
}

.imageWelcomeUser {
	width: 110px;
	height: 110px;
	border-radius: 50%;
	background-color: #004990;
	margin: 0 auto;
	margin-top: 15px;
	margin-bottom: 20px;
}

.smallerWelcome {
	color: #0b2343;
	text-align: center;
	margin-top: 10px;
	font-weight: 500;
	font-size: 20px;
	color: #666;
	line-height: 1.4;
}

.welcomeName span {
	text-transform: capitalize;
}

.workToolsSliderWrapper {
	grid-template-columns: repeat(3, auto);
}

@media (max-width: 1366px) {
	.IntroSwipe,
	.TitleSwipe {
		max-width: 50%;
	}
	ol.buttonsLinks {
		grid-gap: 20px;
	}
	.TitleSwipe {
		font-size: 42px;
	}
	.IntroSwipe {
		margin-top: 10px;
	}
}

@media (max-width: 1200px) {
	ol.buttonsLinks {
		grid-template-columns: repeat(4, 1fr);
	}
}

@media (max-width: 1044px) {
	div.WrapperContent {
		width: 100%;
		float: none;
		height: auto;
	}
	ol.buttonsLinks {
		grid-template-columns: repeat(3, 1fr);
	}
	.workToolsSliderWrapper {
		grid-template-columns: repeat(2, auto);
	}
}

@media (max-width: 1000px) {
	.imageWelcomeUser {
		margin-top: 70px;
	}
}

@media (max-width: 800px) {
	.WhiteBackgrounds {
		--safe-area-inset-bottom: env(safe-area-inset-bottom);
		padding-bottom: 200px;
		padding-bottom: calc(200px + var(--safe-area-inset-bottom));
	}
	.accessText {
		font-size: 18px;
		margin-left: 18px;
	}

	.ProfileWrapBox {
		margin-top: 20px;
		display: none;
	}

	ol.buttonsLinks {
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 0;
		width: 90%;
		margin: 0 auto;
	}
	ol.buttonsLinks li {
		height: auto;
		width: 100%;
		border: none;
		border-bottom: 1px solid #e5e5e5;
		box-shadow: none;
		border-radius: 0;
	}
	/* .WorkToolWrap .descriptionWorkTool{
    display: none;
  } */
	div.WorkToolWrap {
		height: initial;
		display: table;
	}
	.swiper-wrapper div.WorkToolWrap:nth-child(1) {
		margin-left: 5%;
	}
	.swiper-wrapper div.WorkToolWrap:last-child {
		margin-right: 5% !important;
	}
	.WrapperCards {
		width: 100%;
		float: none;
		height: auto;
	}

	.BackMiddleWrap {
		display: block;
		width: 100%;
	}
	.Worktools-container {
		padding-top: 0;
	}
	ol.buttonsLinks li a .IconLinks img {
		margin-bottom: 0;
	}
	ol.buttonsLinks li a .TextLinks .TextLinksCell .TitleLink {
		padding-bottom: 20px;
	}
	.SwipeHome {
		height: 40vh;
	}
	.TitleSwipe {
		font-size: 19px;
		max-width: 500px;
		line-height: 1.2;
		margin-top: -9px;
		text-align: center;
		padding: 0 30px;
		margin: 0 auto;
	}
	.slider-read a {
		font-size: 12px;
	}

	.Swiperfilter {
		opacity: 0.7;
	}

	.SwiperImage {
		height: 100%;
	}

	.IntroSwipe {
		max-width: 90%;
		margin-top: 10px;
	}
	.wrapperContGam {
		display: none;
	}

	.cookieConsent {
		width: 100%;
		height: 100vh;
		background-color: rgba(255, 255, 255, 0.5);
		position: fixed;
		top: 0;
		left: 0;
	}

	/*
  .ArticleCardWrap{
    grid-gap: 0;
    display: flex;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 25px;
    margin-bottom: 20px;
    margin-top: 0;
  }
  */
	.sub-menu-container {
		margin-top: 30px;
	}
	.workToolsSliderWrapper {
		padding: 0px 30px;
	}
}

@media (max-width: 800px) {
	.Worktools-container {
		padding-top: 0;
		z-index: 0;
		position: relative;
	}
	ol.buttonsLinks {
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 0;
	}
	ol.buttonsLinks li {
		padding: 0;
		height: auto;
	}
	ol.buttonsLinks li a {
		padding: 10px 0;
		display: block;
		height: auto;
		overflow: hidden;
	}
	ol.buttonsLinks li a .IconLinks {
		width: 60px;
		object-fit: contain;
	}
	ol.buttonsLinks li a .IconLinks img {
		margin: 0;
		height: 30px;
	}
	ol.buttonsLinks li a .TextLinks .TextLinksCell .TitleLink {
		padding-bottom: 0;
		margin-top: 4px;
	}
	.WrapperContent {
		margin-left: 0;
		margin-top: 0;
	}
	ol.buttonsLinks li a .TextLinks {
		padding-left: 0;
	}
	div.WrapperContent {
		width: 90%;
		margin: 0 auto;
	}
}

@media (max-width: 1040px) {
	.TitleState {
		position: initial;
	}
}

@media (max-width: 400px) {
	.workToolsSliderWrapper {
		grid-template-columns: repeat(1, auto);
	}
}

.ambot-welcome-holder {
	background: #11b1a2;
	background: linear-gradient(to right, #11b1a2 50%, #15d8c6 95%);
	border-radius: 15px;
	box-shadow: 0 20px 40px rgba(17, 177, 162, 0.25);
	display: flex;
	margin-top: 2em;
	padding: 1em 2em;
	text-decoration: none;
	transform: scale(1);
	transition: all 0.8s linear;
	align-items: center;
	width: 100%;
}

.ambot-welcome-holder:hover {
	box-shadow: 0 30px 60px rgba(17, 177, 162, 0.5);
	transform: scale(1.025);
	z-index: 100;
}

.ambot-welcome-holder svg {
	height: 50px;
	margin-right: 2em;
	width: auto;
}

.ambot-welcome-holder p {
	/* color: #012b5c; */
	color: white;
	font-size: 18px;
	font-weight: 400;
	line-height: 1.2;
	margin: 0 1em 0 0;
}

.ambot-welcome-holder button {
	/* background: #fecb2f; */
	background: white;
	border: 1px solid white;
	border-radius: 5px;
	color: #012b5c;
	cursor: pointer;
	font-weight: 700;
	padding: 0.5em 1.5em;
}

@media (max-width: 1024px) {
	.ambot-welcome-holder {
		flex-direction: column;
	}
	.ambot-welcome-holder svg {
		margin-bottom: 1em;
	}
	.ambot-welcome-holder button {
		margin-top: 1em;
	}
}
