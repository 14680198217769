.myLearning-header {
	background-size: cover !important;
	background-position: center !important;
}
.myLearning-header-text,
.myLearning-header-description {
	color: #fff;
	opacity: 1;
}

/* .table-holder-my-learning {
	border: 1px solid rgba(209, 213, 219, 1) !important;
} */

.table-holder-my-learning tr {
	background: transparent !important;
}

.my-learning-td {
	min-width: 209px !important;
}

.my-learning-subject {
	height: 100px;
}
