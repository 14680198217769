.modal-image {
  height: 50px;
}
.modal-footer-card-top {
  display: flex;
  flex-wrap: wrap;
}
.modal-image-container {
  width: 30%;
}
.container-content-top-modal {
  width: 70%;
}
.type-title {
  color: #007dc3;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}
.type-title-modal {
  color: #0b2343;
  font-size: 24px;
  font-weight: 500;
  line-height: 18px;
}
.discount-modal {
  color: #e91b2f;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  line-height: 18px;
}
.policy-container-modal {
  display: flex;
  flex-wrap: wrap;
}
.policy-content {
  color: #666666;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.policy-content h3{
  color: #0b2343;
  font-size: 16px;
  font-weight: 500;
}

.policy-content ul li{
  font-weight: 400;
  line-height: 1.4;
}

.policy-content ul{
  margin-left: 15px;
}


.policy-content p{
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
}

.policy-content p b{
  font-weight: 500;
  color: #0b2343;
}

.policy-content a{
  color: #007dc3;
  font-weight: 600;
}

.policy-content a:hover{
  color:#0b2343 ;
}

.modal-footer-card-content {
  margin: 20px 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 20px 0;
}
.modal-footer-card-content .DonnotShowDiscount{
  display: none;
}
.container-policy-modal-image {
  width: 20%;
}
.policy-content {
  width: 100%;
}
.info-modal {
  color: #0b2343;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}
.agrements-modal {
  color: #666666;
  font-size: 12px;
  line-height: 18px;
}

