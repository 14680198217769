.classified-header {
	align-items: center;
	display: flex;
	/* height: 450px; */
	justify-content: center;
	position: relative;
}

.classified-header .my-classified-menu {
	background: rgba(1, 43, 92, 0.2);
	position: absolute;
	left: 50%;
	list-style-type: none;
	top: 20px;
	padding: 10px 10px;
	border-radius: 40px;
	backdrop-filter: blur(20px);
	box-shadow: 0 20px 40px rgba(1, 43, 92, 0.15);
	transform: translateX(-50%) translateZ(0);
	height: 60px;
	display: grid;
	grid-template-columns: repeat(3, auto);
	grid-gap: 10px;
	z-index: 10;
}

.classified-header .my-classified-menu li {
	background: rgba(1, 43, 92, 0.15);
	border-radius: 50px;
	color: rgba(1, 43, 92);
	cursor: pointer;
	font-size: 13px;
	font-weight: 700;
	margin-right: 0.5em;
	height: 40px;
	padding: 0 1em 0.1em;
	display: flex;
	align-items: center;
	justify-content: center;
}

.classified-header .my-classified-menu li:hover {
	background: rgba(1, 43, 92, 0.75);
	color: white;
}

.classified-header .my-classified-menu li.active-item {
	background: rgba(1, 43, 92, 1);
	color: white;
}

.classified-header .my-classified-menu li:last-child {
	margin-right: 0;
}

.classified-header .container,
.classified-header .row {
	height: 100%;
}

.classified-header .row {
	align-items: center;
	display: flex;
}

.img-header-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.classified-header .flying-bg {
	object-fit: contain;
	height: auto;
	max-width: 486px;
	width: 90%;
}
.classified-header .flying-bg.smaller {
	width: 60%;
}
.classified-header .rounded-img {
	object-fit: cover;
	border-radius: 50%;
	height: 300px;
	transform: translateY(80px);
	max-width: 486px;
	width: 300px;
}

.classified-header h1 {
	color: #0b2342;
	font-size: 40px;
	font-weight: 700;
	line-height: 1.1;
}

.quote-part {
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
	padding: 30px 0px 30px 40px;
	/* transform: translateY(-30%); */
}
.quote-part .right-quote {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
}

.quote-part .right-quote .fake-separator {
	background: rgba(0, 0, 0, 0.1);
	height: 100%;
	width: 2px;
	position: absolute;
	left: 8%;
	top: 50%;
	transform: translateY(-50%);
}

.quote-part .right-quote p.big {
	font-size: 25px;
	font-weight: 700;
	line-height: 1;
	margin: 0;
}
.quote-part .right-quote p.small {
	line-height: 1;
	margin: 0.5em 0 0;
	opacity: 0.75;
}

.quote-part .left-quote p {
	color: #0b2342;
	margin: 0;
	padding-left: 30px;
	position: relative;
	font-size: 14px;
}
.quote-part .left-quote p:before {
	content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMy42OTEgNi4yOTJDNS4wOTQgNC43NzEgNy4yMTcgNCAxMCA0aDF2Mi44MTlMMTAuMTk2IDYuOThjLTEuMzcuMjc0LTIuMzIzLjgxMy0yLjgzMyAxLjYwNEM3LjA1IDkuMDcxIDYuOTQ3IDkuNTkxIDYuOTI1IDEwSDloMWMuNTUzIDAgMSAuNDQ3IDEgMXY3YzAgMS4xMDMtLjg5NyAyLTIgMkgzYy0uNTUzIDAtMS0uNDQ3LTEtMXYtNWwuMDAzLTIuOTE5QzEuOTk0IDEwLjk3IDEuODA0IDguMzQgMy42OTEgNi4yOTJ6TTIwIDIwaC02Yy0uNTUzIDAtMS0uNDQ3LTEtMXYtNWwuMDAzLTIuOTE5Yy0uMDA5LS4xMTEtLjE5OS0yLjc0MSAxLjY4OC00Ljc4OUMxNi4wOTQgNC43NzEgMTguMjE3IDQgMjEgNGgxdjIuODE5TDIxLjE5NiA2Ljk4Yy0xLjM3LjI3NC0yLjMyMy44MTMtMi44MzMgMS42MDRDMTguMDUgOS4wNzEgMTcuOTQ3IDkuNTkxIDE3LjkyNSAxMEgyMGgxYy41NTMgMCAxIC40NDcgMSAxdjdDMjIgMTkuMTAzIDIxLjEwMyAyMCAyMCAyMHoiLz48L3N2Zz4=);
	fill: #0b2342;
	position: absolute;
	line-height: 1;
	left: 0;
	margin: 0;
	top: 0;
}

.default-text {
	padding: 15px 0 40px;
}

.default-text .btn.btn-aeromexico {
	background: #e91c30;
	border-radius: 50px;
	color: white;
	font-weight: 700;
	padding: 0.5em 1em;
}

.default-text p {
	margin: 1.5em 0;
}

.classified-title {
	color: #0b2342;
	font-size: 20px;
	font-weight: 700;
}

.classified-title.class-sub-title {
	color: #0b2342;
	font-size: 18px;
	font-weight: 700;
	opacity: 0.5;
}

.class-questions {
	padding: 70px 0 100px;
}

.class-questions.smaller-padding {
	padding: 0px 0 100px;
}

.rc-collapse .rc-collapse-item {
	background: transparent !important;
	border: none !important;
}

.rc-collapse {
	background: white;
	border: none;
}

.rc-collapse .rc-collapse-item {
	border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;
	outline: none !important;
}

.rc-collapse .rc-collapse-item .rc-collapse-header {
	box-shadow: none !important;
	outline: none !important;
	color: #012b5c !important;
	font-weight: 700;
	padding: 20px 20px;
	position: relative;
}

.rc-collapse .rc-collapse-item .rc-collapse-header:hover {
	background: rgba(0, 0, 0, 0.025) !important;
}

.rc-collapse .rc-collapse-item .rc-collapse-header:after {
	position: absolute;
	right: 20px;
	top: 50%;
	content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDE4IDE4Ij48cGF0aCBkPSJNNSA4bDQgNCA0LTR6Ii8+PC9zdmc+");
	transform: translateY(-50%) scale(1.5);
	transition: transform 0.15s linear;
	background-position: contain;
	height: 20px;
	width: auto;
	will-change: auto;
}

.rc-collapse .rc-collapse-item-active .rc-collapse-header:after {
	transform: translateY(-50%) scale(-1.5);
}

.rc-collapse .rc-collapse-item .rc-collapse-header .class-question-accordion {
	background: transparent !important;
	outline: none !important;
}
.rc-collapse
	.rc-collapse-item
	.rc-collapse-header
	.class-question-accordion
	.arrow {
	display: none !important;
	opacity: 0 !important;
}
.rc-collapse .rc-collapse-item i.arrow {
	display: none !important;
}

.rc-collapse .rc-collapse-item .rc-collapse-content {
	color: black;
	padding-bottom: 20px;
}

.with-some-background {
	background: #e9f3f9;
	margin-bottom: 40px;
	padding: 20px 0;
}

.class-main-title {
	color: #0b2342;
	font-size: 40px;
	font-weight: 700;
	opacity: 1;
	line-height: 1.1;
}

.faqs-container-class {
	padding-top: 90px;
}

.no-style-list-type {
	list-style-type: none;
}

.with-internal-ul-items ul {
	list-style-type: none;
	margin-left: 3em;
}

.with-internal-ul-items ul li,
.li-with-check-class {
	padding-left: 1em;
	position: relative;
}

.with-internal-ul-items ul li:before,
.li-with-check-class:before {
	left: 0;
	top: 60%;
	transform: translate(-35%, -50%);
	position: absolute;
	content: "";
	height: 15px;
	width: 15px;
	background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNzkyIiBoZWlnaHQ9IjE3OTIiIHZpZXdCb3g9IjAgMCAxNzkyIDE3OTIiPjxwYXRoIGQ9Ik0xNjcxIDU2NnEwIDQwLTI4IDY4bC03MjQgNzI0LTEzNiAxMzZxLTI4IDI4LTY4IDI4dC02OC0yOGwtMTM2LTEzNi0zNjItMzYycS0yOC0yOC0yOC02OHQyOC02OGwxMzYtMTM2cTI4LTI4IDY4LTI4dDY4IDI4bDI5NCAyOTUgNjU2LTY1N3EyOC0yOCA2OC0yOHQ2OCAyOGwxMzYgMTM2cTI4IDI4IDI4IDY4eiIvPjwvc3ZnPg==);
	background-size: contain;
}

.li-with-no-class {
	position: relative;
	padding-left: 1em;
}

.li-with-no-class:before {
	background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNzkyIiBoZWlnaHQ9IjE3OTIiIHZpZXdCb3g9IjAgMCAxNzkyIDE3OTIiPjxwYXRoIGQ9Ik0xNDkwIDEzMjJxMCA0MC0yOCA2OGwtMTM2IDEzNnEtMjggMjgtNjggMjh0LTY4LTI4bC0yOTQtMjk0LTI5NCAyOTRxLTI4IDI4LTY4IDI4dC02OC0yOGwtMTM2LTEzNnEtMjgtMjgtMjgtNjh0MjgtNjhsMjk0LTI5NC0yOTQtMjk0cS0yOC0yOC0yOC02OHQyOC02OGwxMzYtMTM2cTI4LTI4IDY4LTI4dDY4IDI4bDI5NCAyOTQgMjk0LTI5NHEyOC0yOCA2OC0yOHQ2OCAyOGwxMzYgMTM2cTI4IDI4IDI4IDY4dC0yOCA2OGwtMjk0IDI5NCAyOTQgMjk0cTI4IDI4IDI4IDY4eiIvPjwvc3ZnPg==);
	background-size: contain;
	content: "";
	height: 15px;
	width: 15px;
	left: 0;
	top: 8%;
	position: absolute;
	transform: translate(-35%, 0);
}

.li-with-check-class:before {
	top: 5px;
	transform: translate(-35%, 0);
}

@media (max-width: 767px) {
	.classified-header {
		padding-top: 100px;
	}
	.title-stuff-container-class {
		position: relative;
		z-index: 1;
	}
	.img-header-container .flying-bg {
		width: 280px;
		position: relative;
		transform: translate(-40px, -50px);
		z-index: 0;
	}
	.img-header-container .flying-bg.smaller {
		width: 250px;
		position: relative;
		transform: translate(-40px, 20px);
		z-index: 0;
	}
	.img-header-container .rounded-img {
		height: 220px;
		width: 220px;
		position: relative;
		transform: translateY(20px);
	}
	.faqs-container-class {
		padding-top: 120px;
	}
	.quote-part {
		transform: translateY(-15%) !important;
	}
	.quote-part .left-quote p {
		padding-left: 20px;
		padding-right: 34px;
		margin-bottom: 2em;
	}
	.quote-part .left-quote p:before {
		background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMy42OTEgNi4yOTJDNS4wOTQgNC43NzEgNy4yMTcgNCAxMCA0aDF2Mi44MTlMMTAuMTk2IDYuOThjLTEuMzcuMjc0LTIuMzIzLjgxMy0yLjgzMyAxLjYwNEM3LjA1IDkuMDcxIDYuOTQ3IDkuNTkxIDYuOTI1IDEwSDloMWMuNTUzIDAgMSAuNDQ3IDEgMXY3YzAgMS4xMDMtLjg5NyAyLTIgMkgzYy0uNTUzIDAtMS0uNDQ3LTEtMXYtNWwuMDAzLTIuOTE5QzEuOTk0IDEwLjk3IDEuODA0IDguMzQgMy42OTEgNi4yOTJ6TTIwIDIwaC02Yy0uNTUzIDAtMS0uNDQ3LTEtMXYtNWwuMDAzLTIuOTE5Yy0uMDA5LS4xMTEtLjE5OS0yLjc0MSAxLjY4OC00Ljc4OUMxNi4wOTQgNC43NzEgMTguMjE3IDQgMjEgNGgxdjIuODE5TDIxLjE5NiA2Ljk4Yy0xLjM3LjI3NC0yLjMyMy44MTMtMi44MzMgMS42MDRDMTguMDUgOS4wNzEgMTcuOTQ3IDkuNTkxIDE3LjkyNSAxMEgyMGgxYy41NTMgMCAxIC40NDcgMSAxdjdDMjIgMTkuMTAzIDIxLjEwMyAyMCAyMCAyMHoiLz48L3N2Zz4=);
		background-size: contain;
		content: "";
		height: 14px;
		width: 14px;
	}
	.quote-part .fake-separator {
		display: none;
	}
	.quote-part .right-quote {
		align-items: flex-start;
		padding-left: 34px;
	}
	.no-style-list-type {
		padding: 0 30px !important;
	}
}
