.CeoTag {
  font-size: 12px;
  font-weight: 600;
  background: rgb(0, 73, 144, 1);
  display: table;
  color: #fff;
  border-bottom-right-radius: 11px;
  backdrop-filter: blur(20px);
  border-top-left-radius: 11px;
  border-radius: 6px;
  margin: 8px;
  padding: 4px 7px;
  position: relative;
  z-index: 3;
}
.SkyTeamTag{
  font-size: 12px;
  font-weight: 600;
  background: #604a9e;
  display: table;
  margin: 0;
  padding: 10px 15px;
  color: #fff;
  border-bottom-right-radius: 11px;
  backdrop-filter: blur(20px);
  border-top-left-radius: 11px;
  border-radius: 6px;
  margin: 8px;
  padding: 4px 7px;
  position: relative;
  z-index: 3;
}
.SecurityTag {
  font-size: 12px;
  font-weight: 600;
  background: #1c869e;
  display: table;
  color: #fff;
  border-bottom-right-radius: 11px;
  backdrop-filter: blur(20px);
  border-top-left-radius: 11px;
  border-radius: 6px;
  margin: 8px;
  padding: 4px 7px;
  position: relative;
  z-index: 3;
}
.CustomerExTag {
  font-size: 12px;
  font-weight: 600;
  background: rgb(233, 27, 47, 1);
  display: table;
  margin: 0;
  padding: 10px 15px;
  color: #fff;
  border-bottom-right-radius: 11px;
  backdrop-filter: blur(20px);
  border-top-left-radius: 11px;
  border-radius: 6px;
  margin: 8px;
  padding: 4px 7px;
  position: relative;
  z-index: 3;
}
.PeopleTag {
  font-size: 12px;
  font-weight: 600;
  background: rgb(249, 119, 34, 1);
  display: table;
  margin: 0;
  padding: 10px 15px;
  color: #fff;
  border-bottom-right-radius: 11px;
  backdrop-filter: blur(20px);
  border-top-left-radius: 11px;
  border-radius: 6px;
  margin: 8px;
  padding: 4px 7px;
  position: relative;
  z-index: 3;
}
.DeltaTag {
  font-size: 12px;
  font-weight: 600;
  background: rgb(28, 134, 158, 1);
  display: table;
  margin: 0;
  padding: 10px 15px;
  color: #fff;
  border-bottom-right-radius: 11px;
  backdrop-filter: blur(20px);
  border-top-left-radius: 11px;
  border-radius: 6px;
  margin: 8px;
  padding: 4px 7px;
  position: relative;
  z-index: 3;
}
.CommercialTag {
  font-size: 12px;
  font-weight: 600;
  background: rgb(0, 125, 195, 1);
  display: table;
  margin: 0;
  padding: 10px 15px;
  color: #fff;
  border-bottom-right-radius: 11px;
  backdrop-filter: blur(20px);
  border-top-left-radius: 11px;
  border-radius: 6px;
  margin: 8px;
  padding: 4px 7px;
  position: relative;
  z-index: 3;
}
.OperationsTag {
  font-size: 12px;
  font-weight: 600;
  background: rgb(72, 168, 62, 1);
  display: table;
  margin: 0;
  padding: 10px 15px;
  color: #fff;
  border-bottom-right-radius: 11px;
  backdrop-filter: blur(20px);
  border-top-left-radius: 11px;
  border-radius: 6px;
  margin: 8px;
  padding: 4px 7px;
  position: relative;
  z-index: 3;
}

.SusteTag {
  font-size: 12px;
  font-weight: 600;
  background: rgb(233, 27, 47, 1);
  display: table;
  margin: 0;
  padding: 10px 15px;
  color: #fff;
  border-bottom-right-radius: 11px;
  backdrop-filter: blur(20px);
  border-top-left-radius: 11px;
  border-radius: 6px;
  margin: 8px;
  padding: 4px 7px;
  position: relative;
  z-index: 3;
}

.AchievementsExTag {
  font-size: 12px;
  font-weight: 600;
  background: rgb(249, 119, 34, 1);
  display: table;
  margin: 0;
  padding: 10px 15px;
  color: #fff;
  border-bottom-right-radius: 11px;
  backdrop-filter: blur(20px);
  border-top-left-radius: 11px;
  border-radius: 6px;
  margin: 8px;
  padding: 4px 7px;
  position: relative;
  z-index: 3;
}

@media (max-width: 1044px) {
  .OperationsTag,
  .CommercialTag,
  .DeltaTag,
  .PeopleTag,
  .CustomerExTag,
  .CeoTag {
    font-size: 10px;
  }
}
