.logrosCardCX .ArticleCardWrap {
	grid-template-columns: repeat(4, 1fr);
}
.logrosCardCX .ArticleImageWrap p {
	display: none;
}
.grapghCX {
	height: auto;
	overflow: hidden;
	width: 100%;
}
.grapghCX .graphResponsive {
	float: right;
	width: 100%;
	margin-top: 20px;
}
.customerExperienceSite .grapghCX h2 {
	color: #0b2343;
	font-size: 36px;
	text-align: center;
}
.customerExperienceSite .grapghCX h2 span {
	color: #666666;
}
.customerExperienceSite .grapghCX p {
	color: #666;
	margin-top: 20px;
	font-size: 20px;
	line-height: 1.4;
	text-align: center;
	margin: 0 auto;
	margin-top: 0px;
	margin-top: 10px;
	max-width: 811px;
}
.wave {
	color: #fff;
	overflow: hidden;
	position: absolute;
	transform: translateY(-50%);
	width: 100%;
	z-index: 1;
}
.wave.wavebottom {
	transform: rotate(180deg) translateY(60px);
}
.wave .svgWave {
	fill: currentColor;
	height: auto;
	margin-left: -1%;
	width: 102%;
}
.modalLogrosBlack {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 9999;
}

.modalLogrosBlack .modalwindow {
	width: 90%;
	height: 80%;
	max-width: 700px;
	max-height: 265px;
	background-color: white;
	border-radius: 11px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	box-shadow: 0 30px 60px rgba(0, 0, 0, 0.7);
	overflow: hidden;
}

.modalLogrosBlack .modalwindow .leftImage {
	background-repeat: no-repeat;
	background-position: right;
	background-size: cover;
	background-image: url(../../../assets/cxsite/cx-logro-01.jpg);
	width: 40%;
	height: 100%;
	float: left;
}

.modalLogrosBlack .modalwindow .leftImage.servicioImage {
	background-image: url(../../../assets/cxsite/cx-logro-02.jpg);
}
.modalLogrosBlack .modalwindow .leftImage.servicioCheck-in {
	background-image: url(../../../assets/cxsite/cx-logro-04.jpg);
}
.modalLogrosBlack .modalwindow .leftImage.comida-in {
	background-image: url(../../../assets/cxsite/cx-comida.jpg);
}
.modalLogrosBlack .modalwindow .rightImage {
	width: 60%;
	height: 100%;
	padding: 40px;
	float: left;
	overflow-y: scroll;
}

.modalLogrosBlack .modalwindow .rightImage h3 {
	color: #0b2343;
}

/*

<div className="modalLogrosBlack">
          <div className="modalwindow">
            <div className="leftImage"></div>
            <div className="rightImage">
              <h3>Title</h3>
              <p>Subtitle</p>
            </div>
          </div>
        </div>

*/

.wrapperCxMeaning {
	width: 100%;
	margin: 0 auto;
	overflow: hidden;
	max-width: initial;
	padding: 50px 0 80px;
	clear: both;
}

.wrapperCxMeaning .wrapperLeft {
	width: 450px;
	height: auto;
	overflow: hidden;
	float: right;
}

.wrapperCxMeaning .wrapperRight {
	float: none;
	width: 100%;
	height: auto;
	overflow: hidden;
	max-width: 900px;
	margin: 0 auto;
}

.wrapperCxMeaning .wrapperRight .facesCxWrapper {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 20px;
	margin-top: 40px;
}

.wrapperCxMeaning .wrapperRight .facesCxWrapper .faceWrapper {
	width: 100%;
	height: 100%;
}
.wrapperCxMeaning .wrapperRight .facesCxWrapper .faceWrapper .imgWrapper {
	width: 140px;
	height: 140px;
	margin: 0 auto;
	border-radius: 50%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}
.wrapperCxMeaning
	.wrapperRight
	.facesCxWrapper
	.faceWrapper:nth-child(1)
	.imgWrapper {
	background-image: url(../../../assets/cxsite/cx-face02.png);
}
.wrapperCxMeaning
	.wrapperRight
	.facesCxWrapper
	.faceWrapper:nth-child(2)
	.imgWrapper {
	background-image: url(../../../assets/cxsite/cx-face01.png);
}
.wrapperCxMeaning
	.wrapperRight
	.facesCxWrapper
	.faceWrapper:nth-child(3)
	.imgWrapper {
	background-image: url(../../../assets/cxsite/cx-face03.png);
}

.wrapperCxMeaning .wrapperRight .facesCxWrapper .faceWrapper p {
	text-align: center;
}

.wrapperCxMeaning .wrapperRight .facesCxWrapper .faceWrapper p:nth-child(2) {
	font-weight: 500;
	color: #0b2343;
	font-size: 18px;
	margin: 0;
	padding: 0;
	margin-top: 10px;
}

.wrapperCxMeaning .wrapperRight .facesCxWrapper .faceWrapper p span {
	color: #666;
	margin: 0;
	padding: 0;
	font-weight: 400;
}
/*
<div className="wrapperRight">
                <div className="facesCxWrapper">
                  
                  <div className="faceWrapper">
                    <div className="imgWrapper"></div>
                    <p>Detractores</p>
                    <p>1, 2 o 3</p>
                  </div>
                  */

.wrapperCxMeaning p.title {
	font-size: 20px;
	font-weight: 500;
	color: #0b2343;
	margin-bottom: 10px;
}

.wrapperCxMeaning p.subtitleCx {
	color: #0b2343;
	font-size: 16px;
	margin-bottom: 0;
}

.backgroundImageCX {
	width: 100%;
	height: 215vw;
	background-repeat: no-repeat;
	background-size: contain;
	display: none;
}
.logrosCX {
	width: 100%;
	height: auto;
	overflow: hidden;
	position: relative;
}
.customerExperienceSite .logrosCX h2 {
	color: #0b2343;
	float: left;
	font-size: 24px;
	font-weight: 500;
}
.titleCXEvolution {
	width: 100%;
	height: auto;
	overflow: hidden;
}
.logrosCardCX {
	width: 100%;
	height: auto;
}
.titleCXEvolution .viewAllAchie {
	float: right;
	color: rgba(0, 125, 195, 1) !important;
	font-size: 14px;
	font-weight: 500;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	cursor: pointer;
	padding: 2px 10px;
	border-radius: 100px;
	background-color: rgba(0, 125, 195, 0.09);
}
.titleCXEvolution .viewAllAchie:hover {
	color: white !important;
	text-decoration: none;
	opacity: 1;
	background-color: rgba(0, 125, 195, 1);
}

.logrosCX .bluebgLogros {
	width: 100%;
	height: 200px;
	position: absolute;
	top: 0;
	/*background-color:#0b2343;*/
}
.logrosCX .midLogrosCX {
	width: 90%;
	height: auto;
	margin: 0 auto;
	position: relative;
	z-index: 1;
	padding: 60px 0 50px;
	max-width: 1200px;
}
.customerExperienceSite h2 {
	color: white;
	margin: 0;
	padding: 0;
	text-align: center;
	font-weight: 600;
}
.textQuejasClientes h2 {
	text-align: left;
}
.recharts-text tspan {
	color: #f0f;
	font-size: 14px;
}
.recharts-rectangle {
	border-radius: 20px;
	border: 1px solid #f0f;
}
ul.recharts-default-legend {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	max-width: 400px;
	margin: 20px auto 0 !important;
	grid-gap: 20px;
}
.recharts-default-legend li {
	margin-right: 0 !important;
}
.logrosCX .midLogrosCX .wrapperLogrosGrid {
	width: 100%;
	height: auto;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 20px;
	margin-top: 40px;
}

.logrosCX .midLogrosCX .wrapperLogrosGrid .logrosOpc {
	width: 100%;
	height: auto;
	overflow: hidden;
	background-color: white;
	border-radius: 11px;
	box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
	border: 1px solid #d6d6d6;
	position: relative;
	cursor: pointer;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-image: url(../../../assets/cxsite/cx-comida.jpg);
	position: relative;

	-webkit-box-shadow: inset 62px 84px 138px 27px rgba(11, 35, 67, 0.77),
		0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
	-moz-box-shadow: inset 62px 84px 138px 27px rgba(11, 35, 67, 0.77),
		0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
	box-shadow: inset 62px 84px 138px 27px rgba(11, 35, 67, 0.77),
		0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
}

.logrosCX .midLogrosCX .wrapperLogrosGrid .logrosOpc:nth-child(1) {
	background-image: url(../../../assets/cxsite/cx-logro-01.jpg);
}
.logrosCX .midLogrosCX .wrapperLogrosGrid .logrosOpc:nth-child(2) {
	background-image: url(../../../assets/cxsite/cx-logro-02.jpg);
}
.logrosCX .midLogrosCX .wrapperLogrosGrid .logrosOpc:nth-child(3) {
	background-image: url(../../../assets/cxsite/cx-comida.jpg);
}
.logrosCX .midLogrosCX .wrapperLogrosGrid .logrosOpc:nth-child(4) {
	background-image: url(../../../assets/cxsite/cx-logro-04.jpg);
}

.logrosCX .midLogrosCX .wrapperLogrosGrid .logrosOpc .blueFilter-Traveler {
	width: 100%;
	height: 100%;
	background-color: rgba(11, 35, 67, 0);
	position: absolute;
	top: 0;
	left: 0;
}

.logrosCX .midLogrosCX .wrapperLogrosGrid .logrosOpc:hover {
	-webkit-transform: scale(1.04, 1.04);
	transform: scale(1.04, 1.04);
	box-shadow: inset 62px 84px 138px 27px rgba(11, 35, 67, 0.1),
		0 20px 30px rgba(0, 0, 0, 0.1);
}

.logrosCX .midLogrosCX .wrapperLogrosGrid .logrosOpc .padding {
	padding: 25px;
	padding-bottom: 76px;
	z-index: 1;
	position: relative;
	width: 100%;
	height: 100%;
}
.logrosCX .midLogrosCX .wrapperLogrosGrid .logrosOpc .padding a {
	position: absolute;
	bottom: 25px;
	left: 30px;
	border-top: 1px solid rgba(255, 255, 255, 0.3);
	display: block;
	width: calc(100% - 50px);
	padding-top: 10px;
	font-weight: 500;
	font-size: 14px;
	color: white;
	font-weight: 500;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.logrosCX .midLogrosCX .wrapperLogrosGrid .logrosOpc:hover .padding a {
	opacity: 0;
}
.logrosCX .midLogrosCX .wrapperLogrosGrid .logrosOpc .padding p.title {
	font-size: 22px;
	font-weight: 600;
	color: white;
	margin: 0;
	padding: 0;
	line-height: 1.2;
	opacity: 1;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.logrosCX .midLogrosCX .wrapperLogrosGrid .logrosOpc:hover .padding p.title {
	opacity: 0;
}

.logrosCX .midLogrosCX .wrapperLogrosGrid .logrosOpc .padding p.subtitle {
	color: white;
	font-size: 14px;
	line-height: 1.5;
	margin-top: 10px;
	margin-bottom: 0;
	display: none;
}

.quejasClientes {
	width: 100%;
	height: auto;
	/*overflow: hidden;*/
	background-color: #0b2343;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	position: relative;
}
.quejasClientes .midWrap {
	max-width: 1200px;
	width: 90%;
	margin: 0 auto;
}
.quejasClientes .midWrap .bottomQuejas {
	width: 100%;
	height: auto;
	padding-bottom: 50px;
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	padding-top: 48px;
}
.quejasClientes .midWrap .bottomQuejas .gridQuejasTop {
	color: white;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-gap: 15px;
}

/* .quejasClientes
	.midWrap
	.bottomQuejas
	.gridQuejasTop
	.quejasFrecuente
	.quejaIcon {
	width: 60px;
	height: 60px;

	float: left;
	margin-right: 10px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
} */
.quejasClientes
	.midWrap
	.bottomQuejas
	.gridQuejasTop
	.quejasFrecuente
	.quejaText {
	float: left;
	width: calc(100% - 85px);
}
.quejasClientes
	.midWrap
	.bottomQuejas
	.gridQuejasTop
	.quejasFrecuente
	.quejaText
	p {
	margin: 0;
	padding: 0;
}
.quejasClientes
	.midWrap
	.bottomQuejas
	.gridQuejasTop
	.quejasFrecuente
	.quejaText
	p.number {
	font-size: 32px;
	font-weight: 500;
	margin-top: -10px;
}
.quejasClientes
	.midWrap
	.bottomQuejas
	.gridQuejasTop
	.quejasFrecuente
	.quejaText
	p.description {
	font-size: 14px;
	opacity: 0.7;
}
.quejasClientes .midWrap .bottomQuejas p.titleQuejas {
	font-size: 14px;
	text-transform: uppercase;
	opacity: 0.7;
	color: white;
	font-weight: 700;
	letter-spacing: 2px;
	margin-top: 0;
}
.quejasClientes
	.midWrap
	.bottomQuejas
	.gridQuejasTop
	.quejasFrecuente:nth-child(1)
	.quejaIcon {
	background-image: url(../../../assets/cxsite/equipaje.svg);
}
.quejasClientes
	.midWrap
	.bottomQuejas
	.gridQuejasTop
	.quejasFrecuente:nth-child(2)
	.quejaIcon {
	background-image: url(../../../assets/cxsite/boleto.svg);
}
.quejasClientes
	.midWrap
	.bottomQuejas
	.gridQuejasTop
	.quejasFrecuente:nth-child(3)
	.quejaIcon {
	background-image: url(../../../assets/cxsite/retro.svg);
}
.quejasClientes
	.midWrap
	.bottomQuejas
	.gridQuejasTop
	.quejasFrecuente:nth-child(4)
	.quejaIcon {
	background-image: url(../../../assets/cxsite/pc.svg);
}
.quejasClientes
	.midWrap
	.bottomQuejas
	.gridQuejasTop
	.quejasFrecuente:nth-child(5)
	.quejaIcon {
	background-image: url(../../../assets/cxsite/serv.svg);
}

.quejasClientes .midWrap .topQuejas {
	width: 100%;
	height: auto;
	overflow: hidden;
	margin-top: 80px;
}

.quejasClientes .midWrap .topQuejas .textQuejasClientes {
	float: left;
	width: 300px;
}
.quejasClientes .midWrap .topQuejas .textQuejasClientes .wrapper {
	margin-top: 30px;
}
.quejasClientes .midWrap .topQuejas .textQuejasClientes .wrapper .titleQuejas {
	font-size: 14px;
	text-transform: uppercase;
	opacity: 0.7;
	color: white;
	font-weight: 700;
	letter-spacing: 2px;
}
.quejasClientes .midWrap .topQuejas .textQuejasClientes .wrapper .number {
	font-size: 42px;
	color: white;
	margin: 0;
	margin-top: 0px;
	padding: 0;
	margin-top: -30px;
}
.quejasClientes
	.midWrap
	.topQuejas
	.textQuejasClientes
	.wrapper
	.number.numberPax {
	line-height: 0.9;
	margin-top: -16px;
	margin-bottom: 10px;
}
.quejasClientes .midWrap .topQuejas .textQuejasClientes .wrapper .comparisson {
	font-size: 18px;
	color: white;
	opacity: 0.8;
}
.quejasClientes .midWrap .topQuejas .imageWrapperQuejas {
	height: 345px;
	width: calc(100% - 300px);
	float: left;
}

.wavesClientes {
	background-image: url(../../../assets/cxsite/wave01.png);
	background-repeat: no-repeat;
	width: 100%;
	height: 100px;
	background-position: bottom;
	background-size: contain;
	display: none;
}
.NPS-number {
	background: white;
	width: 100%;
	height: auto;
	overflow: hidden;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	position: relative;
	z-index: 4;
}
.NPS-number .NPSWrapper {
	width: 100%;
	height: auto;
	overflow: hidden;
	padding: 30px 0 0;
	margin-top: 24px;
}
.NPS-number .NPSWrapper .wrapperMid {
	width: 1200px;
	height: auto;
	margin: 0 auto;
	width: 90%;
	max-width: 1200px;
	padding: 10px 0;
	overflow: hidden;
}
.NPS-number .NPSWrapper .wrapperMid .npsNumber {
	width: 100%;
	height: auto;
	overflow: hidden;
	float: none;
	border-bottom: 1px solid #e5e5e5;
	margin-bottom: 70px;
}
.NPS-number .NPSWrapper .wrapperMid .npsNumber h2 {
	color: #0b2343;
}
.NPS-number .NPSWrapper .wrapperMid .npsNumber h7 {
	font-size: 22px;
	color: #e91b2f;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 2px;
}
.NPS-number .NPSWrapper .wrapperMid .npsNumber p {
	color: #666;
	margin-top: 20px;
	font-size: 20px;
	line-height: 1.4;
	text-align: center;
	margin: 0 auto;
	margin-top: 10px;
	max-width: 911px;
}
.NPS-number .NPSWrapper .wrapperMid .npsNumber p.title {
	color: #0b2343;
	font-weight: 500;
	font-size: 24px;
}
.NPS-number .NPSWrapper .wrapperMid .npsNumber p.subtitleCx {
	font-size: 18px;
}

.NPS-number .NPSWrapper .wrapperMid .npsNumber p.subtitleCx b {
	font-weight: 500;
	color: #0b2343;
	font-size: 20px;
}

.NPS-number .NPSWrapper .wrapperMid .npsNumber span.slider-read {
	width: auto;
	height: 50px;
	background: #e91b2f;
	border-radius: 50px;
	color: #ffffff;
	text-align: center;
	padding: 10px;
	display: block;
	margin-top: 15px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 20px;
	padding-right: 20px;
	display: table;
}

.NPS-number .NPSWrapper .wrapperMid .npsNumber .special-item {
	width: 198px;
	height: 50px;
	background: #e91b2f;
	border-radius: 50px;
	color: #ffffff;
	cursor: pointer;
	text-align: center;
	padding: 10px;
	display: block;
	margin-top: 15px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 20px;
	padding-right: 20px;
}

.NPS-number .NPSWrapper .wrapperMid .npsGraph {
	width: 100%;
	height: auto;
}
.NPS-number .NPSWrapper .wrapperMid .npsGraph .graphCont {
	background-color: white;
	border-radius: 11px;
	height: auto;
	width: 100%;
	float: right;
	/*box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);*/
	position: relative;
	z-index: 4;
	margin-top: 6px;
	overflow: hidden;
	border: 1px solid #e5e5e5;
}

.NPS-number .NPSWrapper .wrapperMid .npsGraph .graphCont .bottomGraph {
	float: right;
	width: calc(100% - 196px);
	border-left: 1px solid #e5e5e5;
}

.NPS-number
	.NPSWrapper
	.wrapperMid
	.npsGraph
	.graphCont
	.bottomGraph
	.gridGraph {
	display: grid;
	grid-template-columns: repeat(13, 1fr);
	grid-gap: 15px;
	height: 249px;
	margin-top: 23px;
	width: 90%;
	margin: 45px auto 32px;
}
.NPS-number
	.NPSWrapper
	.wrapperMid
	.npsGraph
	.graphCont
	.bottomGraph
	.gridGraph
	.barGraph {
	position: relative;
	width: 100%;
	height: 250px;
}
.NPS-number
	.NPSWrapper
	.wrapperMid
	.npsGraph
	.graphCont
	.bottomGraph
	.gridGraph
	.barGraph
	.colorGraph {
	position: relative;
	width: 100%;
	background-color: #004990;
	border-radius: 100px;
	position: absolute;
	bottom: 30px;
}
.NPS-number
	.NPSWrapper
	.wrapperMid
	.npsGraph
	.graphCont
	.bottomGraph
	.gridGraph
	.barGraph
	.month {
	position: absolute;
	bottom: 0;
	font-size: 14px;
	text-align: center;
	margin: 0;
	padding: 0;
	margin: 0 auto;
	width: 100%;
}

.NPS-number
	.NPSWrapper
	.wrapperMid
	.npsGraph
	.graphCont
	.bottomGraph
	.gridGraph
	.barGraph
	.colorGraph
	.percentage {
	position: absolute;
	top: -30px;
	font-size: 12px;
	text-align: center;
	font-weight: 500;
	transform: translate(-50%, 0);
	left: 50%;
}
.NPS-number
	.NPSWrapper
	.wrapperMid
	.npsGraph
	.graphCont
	.bottomGraph
	.gridGraph
	.barGraph
	.colorGraph
	.grayColor {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: #cbd2d6;
	border-radius: 100px;
	position: absolute;
	bottom: 0;
}
.NPS-number
	.NPSWrapper
	.wrapperMid
	.npsGraph
	.graphCont
	.bottomGraph
	.gridGraph
	.barGraph:nth-child(1)
	.colorGraph {
	height: calc(55.6% - 30px);
}
.NPS-number
	.NPSWrapper
	.wrapperMid
	.npsGraph
	.graphCont
	.bottomGraph
	.gridGraph
	.barGraph:nth-child(2)
	.colorGraph {
	height: calc(93.3% - 30px);
}
.NPS-number
	.NPSWrapper
	.wrapperMid
	.npsGraph
	.graphCont
	.bottomGraph
	.gridGraph
	.barGraph:nth-child(3)
	.colorGraph {
	height: calc(90.8% - 30px);
}
.NPS-number
	.NPSWrapper
	.wrapperMid
	.npsGraph
	.graphCont
	.bottomGraph
	.gridGraph
	.barGraph:nth-child(4)
	.colorGraph {
	height: calc(91.8% - 30px);
}
.NPS-number
	.NPSWrapper
	.wrapperMid
	.npsGraph
	.graphCont
	.bottomGraph
	.gridGraph
	.barGraph:nth-child(5)
	.colorGraph {
	height: calc(100% - 30px);
}
.NPS-number
	.NPSWrapper
	.wrapperMid
	.npsGraph
	.graphCont
	.bottomGraph
	.gridGraph
	.barGraph:nth-child(6)
	.colorGraph {
	height: calc(99.7% - 30px);
}
.NPS-number
	.NPSWrapper
	.wrapperMid
	.npsGraph
	.graphCont
	.bottomGraph
	.gridGraph
	.barGraph:nth-child(7)
	.colorGraph {
	height: calc(97.7% - 30px);
}
.NPS-number
	.NPSWrapper
	.wrapperMid
	.npsGraph
	.graphCont
	.bottomGraph
	.gridGraph
	.barGraph:nth-child(8)
	.colorGraph {
	height: calc(86.3% - 30px);
}
.NPS-number
	.NPSWrapper
	.wrapperMid
	.npsGraph
	.graphCont
	.bottomGraph
	.gridGraph
	.barGraph:nth-child(9)
	.colorGraph {
	height: calc(90.8% - 30px);
}
.NPS-number
	.NPSWrapper
	.wrapperMid
	.npsGraph
	.graphCont
	.bottomGraph
	.gridGraph
	.barGraph:nth-child(10)
	.colorGraph {
	height: calc(95.5% - 30px);
}
.NPS-number
	.NPSWrapper
	.wrapperMid
	.npsGraph
	.graphCont
	.bottomGraph
	.gridGraph
	.barGraph:nth-child(11)
	.colorGraph {
	height: calc(69.5% - 30px);
}
.NPS-number
	.NPSWrapper
	.wrapperMid
	.npsGraph
	.graphCont
	.bottomGraph
	.gridGraph
	.barGraph:nth-child(12)
	.colorGraph {
	height: calc(64.6% - 30px);
}
.NPS-number
	.NPSWrapper
	.wrapperMid
	.npsGraph
	.graphCont
	.bottomGraph
	.gridGraph
	.barGraph:nth-child(13)
	.colorGraph {
	height: calc(74.5% - 30px);
}

.NPS-number
	.NPSWrapper
	.wrapperMid
	.npsGraph
	.graphCont
	.bottomGraph
	.gridGraph
	.barGraph:nth-child(1)
	.colorGraph
	.grayColor {
	height: calc(100% - 20%);
}
.NPS-number
	.NPSWrapper
	.wrapperMid
	.npsGraph
	.graphCont
	.bottomGraph
	.gridGraph
	.barGraph:nth-child(2)
	.colorGraph
	.grayColor {
	height: calc(100% - 2%);
}
.NPS-number
	.NPSWrapper
	.wrapperMid
	.npsGraph
	.graphCont
	.bottomGraph
	.gridGraph
	.barGraph:nth-child(13)
	.colorGraph
	.grayColor {
	height: calc(100% - 11%);
}

.checklistIcons {
	padding: 0;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	/*border-bottom: 1px solid #d6d6d6;*/
	margin-bottom: 30px;
	margin-top: 60px;
}
.planesborderTop {
	border-top: 1px solid #d6d6d6;
	border-bottom: 1px solid #d6d6d6;
}
.checklistIcons .wrapperMid {
	width: 90%;
	max-width: 1300px;
	margin: 0 auto;
	padding-bottom: 40px;
}
.checklistIcons .wrapperMid p.bold {
	font-size: 20px;
	font-weight: 500;
	color: #0b2343;
	max-width: 715px;
	margin-bottom: 35px;
	text-align: center;
	margin: 0 auto 35px;
}

.checklistIcons .wrapperMid .gridopcs {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 20px;
}
.checklistIcons .wrapperMid .gridopcs p:nth-child(2) {
	color: #0b2343;
	font-weight: 600;
	font-size: 18px;
	margin-bottom: 0;
	text-align: center;
	margin-top: 30px;
	margin-bottom: 20px;
}
.checklistIcons .wrapperMid .gridopcs p:nth-child(3) {
	font-size: 16px;
	color: #666;
	text-align: center;
	font-weight: 400;
	font-size: 14px;
	margin: 0 auto;
	max-width: 370px;
	margin-top: 10px;
}
.checklistIcons .wrapperMid .gridopcs .iconImg {
	width: 70px;
	height: 70px;
	margin-bottom: 10px;
	background-position: center;
	background-repeat: no-repeat;
	margin: 0 auto;
	background-size: contain;
	background-color: rgba(0, 125, 195, 0.08);
	border-radius: 50%;
}
.checklistIcons .wrapperMid .gridopcs .gridOpcsCont:nth-child(1) .iconImg {
	background-image: url(../../../assets/cxsite/reloj.svg);
}
.checklistIcons .wrapperMid .gridopcs .gridOpcsCont:nth-child(2) .iconImg {
	background-image: url(../../../assets/cxsite/maleta.svg);
}
.checklistIcons .wrapperMid .gridopcs .gridOpcsCont:nth-child(3) .iconImg {
	background-image: url(../../../assets/cxsite/avion.svg);
}

.wavesClientesTop {
	height: 100px;
	background-repeat: no-repeat;
	width: 100%;
	height: 100px;
	background-position: bottom;
	background-size: contain;
	display: none;
}

.HeaderFirst {
	width: 100%;
	/* background-color: #0b2343; */
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 -50px;
	/* background-image: url(../../../assets/cxsite/cxbg.png); */
	position: relative;
}
.imagebgCX {
	background-image: url(../../../assets/cxsite/cxbg.png);
	width: 100%;
	height: 300px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom;
	position: absolute;
	top: 0;
	display: none;
}
.HeaderFirst .midWrap {
	max-width: 1200px;
	margin: 0 auto;
	width: 90%;
	padding: 150px 0 134px;
	position: relative;
	z-index: 1;
}

.HeaderFirst .midWrap h2 {
	font-size: 56px;
	max-width: 400px;
	font-weight: 600;
	text-align: left;
}
.HeaderFirst .midWrap p {
	font-size: 22px;
	font-weight: 400;
	color: white;
	max-width: 398px;
	margin-top: 20px;
	opacity: 1;
	line-height: 1.4;
	margin-bottom: 10px;
	margin-top: 7px;
}
.HeaderFirst .midWrap p:nth-child(4) {
	font-size: 16px;
	font-weight: 400;
	margin-top: 0;
	max-width: 450px;
}
.HeaderFirst .midWrap .logo {
	width: 164px;
	height: 103px;
	background-image: url(../../../assets/cxsite/cxlogo.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	display: none;
}
.imagebgHug {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 55%;
	background-image: url(../../../assets/cxsite/hugcx.png);
	background-repeat: no-repeat;
	background-position: right;
	background-size: contain;
	top: -28px;
	right: 0;
	height: 32vw;
	display: none;
}
.imagebgmostrador {
	position: absolute;
	top: -103px;
	right: 0;
	height: 90%;
	width: 65%;
	background-image: url(../../../assets/cxsite/mostrador.png);
	background-repeat: no-repeat;
	background-position: right;
	background-size: contain;
}

.topGraphNew .month {
	position: absolute;
	background-color: #004990;
	padding: 2px 11px;
	border-radius: 7px;
	top: 30px;
	left: 30px;
	color: white;
	font-weight: 500;
	text-transform: uppercase;
	box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
}

.topGraphNew {
	float: left;
	height: auto;
	overflow: hidden;
	width: 196px;
	background-color: rgba(0, 125, 195, 0.05);
	padding-bottom: 40px;
}
.topGraphNew .padding {
	padding: 30px;
}
.topGraphNew .padding .number {
	font-size: 42px;
	margin-top: 35px;
	font-weight: 400;
	color: #0b2343;
	margin-bottom: 0;
}
.topGraphNew .padding .smallnum {
	font-weight: 400;
	color: #0b2343;
	padding: 0;
	margin: 0;
	color: #666;
	margin-bottom: 64px;
	font-size: 14px;
	margin-top: -4px;
	font-weight: 500;
	color: rgba(0, 125, 195, 1);
}

.meaning .widthColor {
	width: 40px;
	float: left;
	height: 8px;
	margin-top: 10px;
	background-color: #004990;
	border-radius: 20px;
	margin-right: 10px;
}

.meaning p {
	float: left;
	font-size: 14px;
	color: #666;
	margin-top: 2px;
}

.graycolor {
	background-color: #cbd2d6 !important;
}

@media screen and (max-width: 1300px) {
}

@media screen and (max-width: 1150px) {
	.WrapperMenu {
		display: none;
	}
}

@media screen and (max-width: 1044px) {
	.parallax-back:after {
		content: '';
		display: block;
		background-color: rgba(0, 0, 0, 0.6);
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 2;
	}

	.parallax-back .react-parallax-content,
	.wave {
		z-index: 3;
	}
	.quejasClientes .midWrap .bottomQuejas {
		margin-bottom: 40px;
	}
}

@media screen and (max-width: 800px) {
	.imagebgmostrador {
		position: static;
		height: 400px;
		width: 500px;
	}

	.quejasClientes .midWrap .topQuejas .textQuejasClientes {
		float: none;
		width: 100%;
	}

	.quejasClientes .midWrap .bottomQuejas .gridQuejasTop {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media screen and (max-width: 600px) {
	.wrapperCxMeaning .wrapperRight .facesCxWrapper {
		grid-template-columns: repeat(1, 1fr);
	}

	.checklistIcons .wrapperMid .gridopcs {
		grid-template-columns: repeat(1, 1fr);
	}
	.quejasClientes .midWrap .bottomQuejas .gridQuejasTop {
		grid-template-columns: repeat(2, 1fr);
	}
	.logrosCardCX .ArticleCardWrap {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (max-width: 424px) {
	.quejasClientes .midWrap .bottomQuejas .gridQuejasTop {
		grid-template-columns: repeat(1, 1fr);
	}
	.logrosCardCX .ArticleCardWrap {
		grid-template-columns: repeat(1, 1fr);
	}
	.titleCXEvolution {
		display: grid;
	}
	.titleCXEvolution .viewAllAchie {
		text-align: center;
		width: 150px;
		padding: 2px 5px;
		margin: 5px 25%;
	}
	.textQuejasClientes h2 {
		font-size: 33px;
	}
}

.recharts-default-tooltip {
	margin: 0px;
	padding: 10px;
	background-color: white;
	border: 1px solid rgb(204, 204, 204);
	white-space: nowrap;
}

.recharts-default-label {
	margin: 0px;
}

.recharts-tooltip-item-list {
	padding: 0px;
	margin: 0px;
}

.recharts-tooltip-item {
	display: block;
	padding-top: 4px;
	padding-bottom: 4px;
	color: rgb(214, 214, 214);
}

.recharts-tooltip-item.m2019 {
	color: rgb(214, 214, 214);
}

.recharts-tooltip-item.m2020 {
	color: rgb(0, 73, 144);
}

.recharts-tooltip-item.target {
	color: rgb(254, 203, 47);
}

.element.header {
	position: relative;
}
