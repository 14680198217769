.vacantesWrapper p.gray {
	color: #666;
	text-align: center;
	font-size: 14px;
	margin-top: 20px;
}

.areasVacantes {
	width: 90%;
	height: auto;
	margin: 0 auto;
	max-width: 1050px;
	margin-top: -60px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 30px;
}

.areasVacantes .FiledArea {
	border-radius: 8px;
	background-color: #f5f5f5;
	box-shadow: 0 0.15em 0.4em 0.065em rgba(0, 0, 0, 0.075);
	height: 250px;
	position: relative;
	overflow: hidden;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	cursor: pointer;
}

.areasVacantes .FiledArea:hover {
	-webkit-transform: scale(1.1, 1.1);
	transform: scale(1.1, 1.1);
	box-shadow: 0 0.15em 0.4em 0.065em rgba(0, 0, 0, 0.075);
}

.areasVacantes .FiledArea .padding {
	padding: 20px;
	position: relative;
	z-index: 2;
}

.areasVacantes .FiledArea .imageBgvacantes {
	width: 100%;
	height: 100%;
	background-position: center bottom;
	background-size: cover;
	background-repeat: no-repeat;
	background-image: url(../../../assets/vacantes/vacante02.png);
	top: 0;
	left: 0;
	position: absolute;
}

.areasVacantes .FiledArea:nth-child(1) .imageBgvacantes {
	background-image: url(../../../assets/vacantes/vacante01.png);
}
.areasVacantes .FiledArea:nth-child(2) .imageBgvacantes {
	background-image: url(../../../assets/vacantes/vacante02.png);
}
.areasVacantes .FiledArea:nth-child(3) .imageBgvacantes {
	background-image: url(../../../assets/vacantes/vacante03.png);
}
.areasVacantes .FiledArea:nth-child(4) .imageBgvacantes {
	background-image: url(../../../assets/vacantes/vacante04.png);
}
.areasVacantes .FiledArea:nth-child(5) .imageBgvacantes {
	background-image: url(../../../assets/vacantes/vacante05.png);
}
.areasVacantes .FiledArea:nth-child(6) .imageBgvacantes {
	background-image: url(../../../assets/vacantes/vacante06.png);
}
.areasVacantes .FiledArea:nth-child(7) .imageBgvacantes {
	background-image: url(../../../assets/vacantes/vacante07.png);
}
.areasVacantes .FiledArea:nth-child(8) .imageBgvacantes {
	background-image: url(../../../assets/vacantes/vacante08.png);
}
.areasVacantes .FiledArea:nth-child(9) .imageBgvacantes {
	background-image: url(../../../assets/vacantes/vacante09.png);
}

.areasVacantes .FiledArea .padding p:nth-child(1) {
	font-size: 14px;
	font-weight: 400;
	line-height: 1.2;
	margin: 0;
	padding: 0;
	margin-bottom: 10px;
}

.areasVacantes .FiledArea .padding p:nth-child(2) {
	font-size: 24px;
	font-weight: 600;
	line-height: 1.2;
}

.areasVacantes .FiledArea .padding p.new {
	font-size: 14px;
	font-weight: 500;
	background-color: #fecb2f;
	display: table;
	padding: 4px 7px;
	border-radius: 4px;
	margin-top: 5px;
}
.hottoplay {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	margin-bottom: 50px;
	background: white;
	padding: 20px 0;
	border-radius: 11px;
}
.hottoplay .opciones {
	width: 100;
	border-right: 1px solid #e5e5e5;
}
.hottoplay .opciones:last-child {
	border-right: none;
}
.hottoplay .opciones img {
	width: 40px;
	height: 40px;
	margin-bottom: 20px;
	display: block;
	margin: 0 auto;
	margin-top: 20px;
}

.hottoplay .opciones p {
	color: #0b2343;
	font-size: 12px;
	line-height: 120%;
	margin-left: 0px;
	margin-bottom: 5px;
	color: #0b2343;
	margin: 0;
	margin-left: 10px;
	margin-top: 10px;
	font-size: 13px;
	line-height: 1.5;
	opacity: 0.8;
	width: 100%;
	line-height: 1.3em;
	margin: 0 0 1em 0;
	margin: 0;
	margin-top: 10px;
	font-size: 14px;
	line-height: 1.5;
	margin: 0 auto;
	text-align: center;
	margin-top: 20px;
	font-weight: 500;
	padding: 0 15px;
}

.hottoplay .opciones p:nth-child(3) {
	color: #666;
	margin-top: 7px;
	margin-bottom: 20px;
	font-size: 12px;
	padding: 0 30px;
}

.vacantesHeader {
	width: 100%;
	height: 300px;
	min-height: 244px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url(../../../assets/images/profile/vacantesbg.jpg);
}

.vacantesHeader .cellFilterHeader {
	background-color: rgba(11, 35, 67, 0.75);
	height: 100%;
	width: 100%;
}

.vacantesHeader .cellFilterHeader .midHeader {
	width: 90%;
	height: 100%;
	margin: 0 auto;
	max-width: 1050px;
	display: table;
}

.vacantesHeader .cellFilterHeader .midHeader .midCellHeader {
	vertical-align: middle;
	display: table-cell;
}

.vacantesHeader .cellFilterHeader .midHeader .midCellHeader h1 {
	font-size: 50px;
	font-weight: 600;
	margin: 0;
	padding: 0;
	margin-bottom: 6px;
	color: white;
	text-align: center;
}

.vacantesHeader .cellFilterHeader .midHeader .midCellHeader p {
	font-size: 20px;
	font-weight: 300;
	margin: 0;
	padding: 0;
	margin-bottom: 6px;
	color: white;
	max-width: 900px;
	text-align: center;
	margin: 0 auto;
}

.vacantesHeader .cellFilterHeader .midHeader .midCellHeader p.carareas {
	display: table;
	color: rgba(0, 125, 195, 1);
	font-weight: 500;
	border-radius: 100px;
	padding: 0 15px;
	font-size: 18px;
	text-transform: uppercase;
}

/*
<div class="vacantesHeader">
                <div class="cellFilterHeader">
                    <div class="midHeader">
                        <div class="midCellHeader">
                            <h1>
                                Nuestras vacantes
                            </h1>
                        </div>
                    </div>
                </div>
            </div>*/

.vacantesWrapper {
	width: calc(100% - 270px);
	background-color: #f5f8f9;
	/* padding-bottom: 80px; */
	position: absolute;
	top: 0;
	right: 0;
}

.midWrapVacantes {
	width: 90%;
	height: auto;
	margin: 0 auto;
	max-width: 1050px;
	margin-top: 40px;
}

.midWrapVacantes .vacantesCardWrap {
	width: 100%;
	height: auto;
	/* overflow: hidden; */
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 20px;
}

.midWrapVacantes .vacantesCardWrap .vacantesCard {
	width: 100%;
	height: auto;
	overflow: hidden;
	background-color: white;
	border-bottom: 1px solid #e5e5e5;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	position: relative;
	cursor: pointer;
}

/* .midWrapVacantes .vacantesCardWrap .vacantesCard:hover{
} */

.midWrapVacantes .vacantesCardWrap .vacantesCard .position,
.midWrapVacantes .vacantesCardWrap .vacantesCard .recruiterData,
.midWrapVacantes .vacantesCardWrap .vacantesCard .apply {
	width: 100%;
	height: 93px;
}

.midWrapVacantes .vacantesCardWrap .vacantesCard .position img {
	width: 40px;
	height: 40px;
	float: left;
	margin-top: 7px;
	display: none;
}

.midWrapVacantes .vacantesCardWrap .vacantesCard .position .TopVacantes {
	width: calc(100% - 640px);
	padding: 20px;
	height: auto;
	overflow: hidden;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	float: left;
	padding-right: 0;
}

.midWrapVacantes .vacantesCardWrap .vacantesCard .position .LocationLevel {
	width: 255px;
	padding: 20px;
	height: auto;
	overflow: hidden;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	float: left;
	padding-right: 0;
}

.midWrapVacantes .vacantesCardWrap .vacantesCard:hover {
	background-color: rgba(0, 125, 195, 0.05);
}

.midWrapVacantes .vacantesCardWrap .vacantesCard .position .TopVacantes:hover {
	/* background-color: rgba(0, 125, 195, .05); */
}

.midWrapVacantes .vacantesCardWrap .vacantesCard .position .applyHere:hover {
	/* background-color: rgba(0, 125, 195, .05); */
}

.midWrapVacantes .vacantesCardWrap .vacantesCard .position .date {
	float: left;
	width: calc(100% - 40px);
	padding-left: 10px;
}

.midWrapVacantes .vacantesCardWrap .vacantesCard .position .applyHere {
	width: 400px;
	/* border-top: 1px solid #e5e5e5; */
	padding: 20px 25px;
	height: 100%;
	overflow: hidden;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	float: right;
}

.midWrapVacantes .vacantesCardWrap .vacantesCard .position {
	display: flex;
	height: auto;
	overflow: hidden;
	justify-content: space-between;
}

.aplyBoxValid .tableTestVacantes {
	padding-right: 30px;
}

.midWrapVacantes
	.vacantesCardWrap
	.vacantesCard
	.position
	.TopVacantes
	.date
	p:nth-child(2) {
	font-size: 14px;
	font-weight: 400;
	margin-top: 10px;
	color: #666;
	margin-bottom: 0;
	margin-top: 0;
	font-weight: 500;
	float: left;
	margin-right: 10px;
	font-weight: 400;
	margin-top: 5px;
}

.midWrapVacantes
	.vacantesCardWrap
	.vacantesCard
	.position
	.TopVacantes
	.date
	p:nth-child(1) {
	display: block;
	margin: 0;
	padding: 0;
	font-size: 18px;
	font-weight: 500;
	color: #0b2343;
	line-height: 1.3;
}

.LocationLevelTag {
	width: 200px;
}
.levelsWidth {
	width: 100px;
}
.validthrouDate {
	width: 100px;
}
.aplyBoxValid {
	width: 160px;
}
.tableTestVacantes {
	display: table;
	height: 100%;
	width: 100%;
}
.levelsWidth .tableTestVacantes {
	padding-left: 15px;
}
.LocationLevelTag .LocationLevelTagCell {
	vertical-align: middle;
	display: table-cell;
}
.LocationLevelTag .LocationLevelTagCell a {
	color: rgba(0, 125, 195, 1);
	font-size: 14px;
	font-weight: 500;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	padding: 2px 10px;
	border-radius: 100px;
	text-decoration: none;
	background-color: rgba(0, 125, 195, 0.09);
	margin: 0 auto;
	display: table;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.LocationLevelTag .LocationLevelTagCell a:hover {
	color: white;
	background-color: #007dc3;
}
.LocationLevelTag .locationTag {
	font-size: 12px;
	margin: 0;
	padding: 0;
	color: #666;
}
.LocationLevelTag .vacanteLevel {
	margin: 0;
	padding: 0;
	font-size: 16px;
	color: #0b2343;
	font-weight: 400;
}

.midWrapVacantes .vacantesCardWrap .vacantesCard .position .LocationLevel p {
	font-size: 16px;
	font-weight: 400;
	margin-top: 10px;
	color: #666;
	margin-bottom: 0;
	margin-top: 12px;
	float: left;
}
.midWrapVacantes
	.vacantesCardWrap
	.vacantesCard
	.position
	.LocationLevel
	p:nth-child(2) {
	float: right;
	font-weight: 500;
}
.midWrapVacantes
	.vacantesCardWrap
	.vacantesCard
	.position
	.TopVacantes
	a.details {
	color: #007dc3;
	font-weight: 500;
	font-size: 14px;
}
.midWrapVacantes
	.vacantesCardWrap
	.vacantesCard
	.position
	.TopVacantes
	a.details:hover {
	color: #0b2343;
}
.midWrapVacantes .vacantesCardWrap .vacantesCard .position .applyHere p {
	float: left;
	margin-bottom: 0;
	font-size: 14px;
	color: #666;
	margin-top: 14px;
}

.midWrapVacantes
	.vacantesCardWrap
	.vacantesCard
	.position
	.applyHere
	a.applynow {
	border: 1px solid #e5e5e5;
	float: right;
	padding: 7px 30px;
	border-radius: 30px;
	color: #007dc3;
	font-weight: 500;
	font-size: 14px;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	cursor: pointer;
	margin-top: 8px;
}

.midWrapVacantes
	.vacantesCardWrap
	.vacantesCard:hover
	.position
	.applyHere
	a.applynow {
	color: white;
	background-color: #e91b2f;
}

.midWrapVacantes
	.vacantesCardWrap
	.vacantesCard
	.position
	.applyHere
	a.applynow:hover {
	background-color: #0b2343;
}

.midWrapVacantes .vacantesCardWrap .vacantesCard .recruiterData p.small {
	font-size: 12px;
	font-weight: 500;
	margin-top: 10px;
	color: rgba(0, 125, 195, 1);
	padding-left: 20px;
	margin-bottom: 0;
	margin-top: 0;
}

.midWrapVacantes .vacantesCardWrap .vacantesCard .recruiterData p:nth-child(2) {
	display: block;
	margin: 0;
	padding: 0;
	font-size: 14px;
	font-weight: 500;
	color: #0b2343;
	padding-left: 20px;
}

.midWrapVacantes .vacantesCardWrap .vacantesCard .recruiterData a {
	display: block;
	margin: 0;
	padding: 0;
	font-size: 14px;
	font-weight: 500;
	color: #0b2343;
	padding-left: 20px;
}

.midWrapVacantes .vacantesCardWrap .vacantesCard .apply a {
	background-color: rgba(0, 125, 195, 1);
	color: white;
	border-radius: 100px;
	padding: 8px 20px;
	cursor: pointer;
	margin-top: 10px;
	display: table;
}

.midWrapVacantes .vacantesCardWrap .vacantesCard .apply p {
	font-size: 12px;
	color: #666;
	max-width: 89px;
	margin-top: 11px;
	margin-bottom: 0;
}

/*****MODALES********/
/*****MODALES********/
/*****MODALES********/
/*****MODALES********/
/*****MODALES********/
.whiteVacantes {
	position: absolute;
	background-color: white;
	border-radius: 11px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 90%;
	height: 90vh;
	max-width: 1000px;
	overflow: hidden;
	max-height: 800px;
}

.whiteVacantes .padding {
	overflow: hidden;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	height: calc(100% - 100px);
}
.whiteVacantes .padding .header {
	background-color: #004990;
	width: 100%;
	height: auto;
	overflow: hidden;
	padding: 50px 60px 40px;
}
.whiteVacantes .padding .wrapperContenfutlVacantes {
	padding: 40px 60px 30px;
	width: 100%;
	height: auto;
	overflow: hidden;
	border-bottom: 1px solid #e5e5e5;
	display: flex;
}
.whiteVacantes .padding .wrapperContenfutlVacantes h4 {
	float: left;
	width: 172px;
	height: auto;
	overflow: hidden;
	margin-right: 30px;
	font-size: 18px;
	color: #0b2343;
}
.whiteVacantes .padding .description {
	padding: 0;
	float: left;
	width: calc(100% - 150px);
	height: auto;
	overflow: hidden;
}
.whiteVacantes .padding .description h3 {
	font-size: 18px;
	margin-top: 0;
}
.whiteVacantes .padding .description p {
	color: #666;
	font-size: 16px;
	padding-left: 16px;
}
.whiteVacantes .padding .description ul,
.whiteVacantes .padding .description ol {
	color: #666;
	font-size: 16px;
	/* margin-left: 16px; */
}
.whiteVacantes .padding .description ul li,
.whiteVacantes .padding .description ol li {
	/* padding-left: 10px; */
}
.whiteVacantes .padding .header p {
	color: white;
	margin: 0;
	padding: 0;
	margin-top: 10px;
}
.whiteVacantes .padding .header p:nth-child(1) {
	font-size: 14px;
	margin-bottom: 0;
}
.whiteVacantes .padding .header p.titleVacante {
	font-size: 42px;
	font-weight: 500;
	max-width: 600px;
	line-height: 1.1;
	margin: 0;
}
.whiteVacantes .padding .header p.titleVacante span {
	font-size: 18px;
	font-weight: 400;
}
.whiteVacantes .padding .header p:nth-child(3) {
	font-size: 24px;
	font-weight: 300;
	margin: 0;
	padding: 0;
}
.whiteVacantes .applyHere {
	border-top: 1px solid #e5e5e5;
	height: 100px;
	background-color: rgba(0, 125, 195, 0.05);
}

.applyHere a.applynowModal {
	text-align: center;
	background-color: #e91b2f;
	color: white;
	font-weight: 500;
	font-size: 14px;
	padding: 14px 20px;
	border-radius: 150px;
	margin: 15px auto;
	display: block;
	max-width: 300px;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.applyHere a.applynowModal:hover {
	background-color: #0b2343;
}

.displayVacantesDefault {
	display: none;
}

.tableVacantes {
	width: 100%;
	height: auto;
	overflow: hidden;
	background-color: white;
	border-radius: 7px;
	border: 1px solid #e5e5e5;
	box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
	-webkit-transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	position: relative;
	cursor: pointer;
}

#auditoriaVacantes .Auditoria {
	display: block;
}
#comercialVacantes .Comercial {
	display: block;
}
#finanzasVacantes .Finanzas {
	display: block;
}
#marketingVacantes .MarketingExperienciaCliente {
	display: block;
}
#operacionesVacantes .OperacionesMantenimiento {
	display: block;
}
#recursosHumanos .RecursosHumanos {
	display: block;
}
#relacionesInstitu .RelacionesInstitucionales {
	display: block;
}
#tecnologiaIT .ITecnologia {
	display: block;
}
#estrategiaNeg .EstrategiaNegocio {
	display: block;
}

.boxVacantesContainer {
	display: none;
}

#NoMostrarCampo {
	display: none;
}

#MostrarCampo {
	display: block;
}

@media (max-width: 858px) {
	.vacantesHeader .cellFilterHeader .midHeader .midCellHeader h1 {
		font-size: 30px;
	}
	.midWrapVacantes .vacantesCardWrap .vacantesCard .position .TopVacantes {
		width: calc(100% - 152px);
		float: left;
		padding: 15px;
	}
	/* .midWrapVacantes .vacantesCardWrap .vacantesCard .position{
    display: block;
  } */
	.LocationLevelTag {
		width: 100%;
		float: none;
		padding-left: 20px;
		display: none;
	}
	.levelsWidth .tableTestVacantes {
		padding-left: 0;
	}
	.aplyBoxValid {
		display: block;
		float: left;
		width: 152px;
		padding: 0;
	}
	.aplyBoxValid .tableTestVacantes {
		padding-right: 20px;
	}
}

@media (max-width: 1215px) {
	#profileMenu {
		width: auto;
		transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	}
	.nameContainer h6 {
		opacity: 0;
		transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
		height: 0;
		overflow: hidden;
	}
	.asideMenu1 h1 {
		transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
		width: 270px;
		opacity: 0;
	}
	#profileMenu:hover {
		width: 270px;
	}

	#profileMenu:hover {
		width: 270px;
	}
	#profileMenu:hover .nameContainer h6 {
		display: block;
		opacity: 1;
		height: auto;
	}
	#profileMenu:hover .asideMenu1 h1 {
		height: auto;
		margin-top: 30px;
		opacity: 1;
	}
	.contProfileMenu {
		width: 80px;
	}

	.vacantesWrapper {
		width: calc(100% - 80px);
	}
	.vacantesHeader .cellFilterHeader .midHeader .midCellHeader p {
		font-size: 16px;
		padding: 0 20px;
	}
	.vacantesHeader {
		height: 200px;
	}
	#profileContent {
		width: calc(100% - 80px);
	}
	.container-info-employee {
		width: 370px;
	}
	.type-rewards {
		width: calc(100% - 370px);
	}
}

@media (max-width: 1044px) {
	#profileMenu {
		display: none;
	}
	.contProfileMenu {
		display: none;
	}
	#profileContent {
		width: 100%;
		border-left: none;
	}
}

@media (max-width: 800px) {
	.personalInformation .rowPersonal p {
		font-size: 12px;
	}
	.personalInformation .rowPersonal p:nth-child(1) {
		font-size: 12px;
	}
	.info-employee {
		display: block;
	}
	.PositionFixed {
		position: relative;
		top: initial;
	}
	.personalInformation {
		margin-right: 0;
		width: 100%;
	}
	.container-info-employee {
		width: 100%;
	}
	.type-rewards {
		width: 100%;
		margin-top: 40px;
	}
	.totalBenefits__title__container .logoTabBenefit img.Travel {
		width: 200px;
		display: block;
		margin: 0 auto;
	}
	.totalBenefits__title__container {
		margin-top: 30px;
		margin-bottom: 10px;
	}
	.TableSalaryBonus .concepSalary p {
		font-size: 12px;
	}
	.SalarioMoney .concepSalary p {
		font-size: 12px;
	}
	.TableSalaryBonus .concepSalary {
		width: 179px;
	}
	.SalarioMoney .concepSalary,
	.SalarioMoney .MonthlySalary {
		width: 179px;
	}
	.SalarioMoney {
		overflow-x: scroll;
	}
	.widthMovilSalario {
		width: calc(179px * 3);
	}
	.TableSalaryBonus .MonthlySalary {
		width: 179px;
	}
	.contactHRBP {
		border-radius: 7px;
	}
	.TableSalaryBonus {
		overflow: hidden;
		overflow-x: scroll;
		-webkit-overflow-scrolling: touch;
	}
	.TableSalaryBonus .widthConecpSalary {
		width: calc(179px * 3);
	}
	.okBenefitsChange {
		display: block;
	}
	.totalBenefitsMidWrapper {
		width: 100%;
	}
	.totalLeftBenefitsContacts {
		width: 100%;
	}
	.totalBenefitsTravel__cards__container {
		grid-template-columns: repeat(2, 1fr);
	}
	.moreInformationWrap .boxMore {
		width: calc(86% - 15px);
	}
	.boletosEmbargo {
		margin-top: 0;
	}
	.totalBenefitsTravel__container {
		margin-bottom: 0;
	}
	.totalLeftBenefitsContacts {
		padding-left: 0;
	}
	h5.download-bnf {
		display: none;
	}
	h3.download-benefits {
		line-height: 1.2;
		font-size: 18px;
	}
	.whiteVacantes .padding .wrapperContenfutlVacantes h4 {
		width: 100%;
		float: none;
	}
	.whiteVacantes .padding .description {
		width: 100%;
		float: none;
	}
	.whiteVacantes .padding .wrapperContenfutlVacantes {
		display: block;
	}
	.whiteVacantes .padding .header p.titleVacante {
		font-size: 30px;
	}
}

/* ***** Responsive Styles ***** */
@media screen and (max-width: 1300px) {
}

@media screen and (max-width: 1044px) {
	.vacantesWrapper {
		width: 100%;
	}
}

@media screen and (max-width: 800px) {
	h3.download-benefits {
		font-size: 14px;
	}
	.icondownload img {
		width: 30px;
		height: 30px;
	}
	.container-download {
		margin-left: 10px;
	}
	.icondownload {
		margin-right: 10px;
	}
	.download-button h2.download {
		display: none;
	}
}

@media screen and (max-width: 600px) {
	.hottoplay {
		grid-template-columns: repeat(1, 1fr);
	}
	.tableBoletosHeader p.cantidad,
	.tableBoletosHeader p.impuestos {
		display: none;
	}
	.whiteVacantes .padding .header p.titleVacante {
		font-size: 25px;
	}
}

@media screen and (max-width: 424px) {
	.midWrapVacantes .vacantesCardWrap {
		display: initial;
	}

	/* .midWrapVacantes .vacantesCardWrap .vacantesCard {
    margin-left: -1rem;
  } */
	.whiteVacantes .padding .header p.titleVacante {
		font-size: 20px;
	}

	.whiteVacantes .padding .wrapperContenfutlVacantes {
		padding: 35px 15px 15px;
	}

	.whiteVacantes .padding .header {
		padding: 40px 15px 40px;
	}

	.whiteVacantes .padding .description ul {
		margin-left: 20px;
	}

	.whiteVacantes .padding .description p {
		font-size: 15px;
	}

	.whiteVacantes .padding .description ul li,
	.whiteVacantes .padding .description ol li {
		padding-left: 0px;
	}

	.applyHere a.applynowModal {
		margin: 25px;
		padding: 15px;
	}

	.midWrapVacantes .vacantesCardWrap .vacantesCard .position .date {
		padding-left: 5px;
	}

	.aplyBoxValid .tableTestVacantes {
		padding-right: 0px;
	}

	.LocationLevelTag .LocationLevelTagCell a {
		font-size: 12px;
		padding: 2px 5px;
	}

	.midWrapVacantes
		.vacantesCardWrap
		.vacantesCard
		.position
		.TopVacantes
		.date
		p:nth-child(1) {
		font-size: 15px;
	}

	.midWrapVacantes .vacantesCardWrap .vacantesCard .position .TopVacantes {
		width: calc(100% - 100px);
	}
}
/* ***** Responsive Styles ***** */
