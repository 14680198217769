/* .main_reward {
  margin: 150px auto;
  width: 600px;
} */
.Container-rewards {
	width: 100%;
}

.contTitleComs {
	width: 100%;
	height: auto;
	overflow: hidden;
}

.loader-rewards {
	text-align: center;
	margin-top: 150px;
	color: #0b2343;
	font-weight: 500;
	font-size: 24px;
}

.rewards_title {
	color: #0b2343;
	font-size: 24px;
	font-weight: 500;
	margin-bottom: 20px;
	float: left;
}

.contTitleComs .download {
	float: right;
	color: #0b2343;
	margin-top: 2px;
	background-image: url(../../../assets/images/download-icon-02.svg);
	padding-left: 0;
	font-weight: 500;
	font-size: 14px;
}

.contTitleComs .download:hover {
	color: #0b2343;
}

.table-employ {
	border-collapse: inherit;
	background: white;
}
th,
td {
	padding: 10px;
}
tr:nth-child(even) {
	background-color: rgba(0, 125, 195, 0.07);
}
.table-Compensation:hover {
	border: 1px solid #007dc3;
}
.table-Compensation {
	border-collapse: inherit;
	border: 0.5px solid #d6d6d6;
	background-color: #ffffff;
	border-spacing: 0px;
}

.table-Compensation {
	border-collapse: inherit;
	border: 0.5px solid #d6d6d6;
	background-color: #ffffff;
	border-radius: 5px;
}
.variable-compensation {
	border: 0.5px solid #d6d6d6;
	background-color: #ffffff;
}
.Variable_Bonus {
	background-color: #fecb2f;
	padding: 0 15px;
	border-radius: 50px;
	text-align: center;
}
.info-employee {
	display: flex;
	width: 100%;
}
.table-Compensation.guaranted-table {
	width: 100%;
}
.type-rewards {
	width: calc(100% - 400px);
}
.container-info-employee {
	width: 400px;
}
.table-Compensation {
	width: 80%;
}
.variable-compensation {
	width: 100%;
	border-collapse: inherit;
	border-spacing: 0;
	border-radius: 5px;
}
tr.special-row {
	background-color: #007dc3;
}
.container-variable {
	margin-top: 20px;
}
.variable-compensation:hover {
	border: 1px solid #007dc3;
}

button#print-button {
	position: relative;
}

.contactHRBP {
	font-size: 14px;
	padding: 10px 30px;
	background-color: rgba(0, 125, 195, 0.07);
	border-radius: 100px;
	color: #0b2343;
	margin-top: 40px;
	text-align: center;
	margin-bottom: 0;
}

.contactHRBP span.name {
	font-weight: 600;
	padding-left: 4px;
}

.contactHRBP .name::before {
	content: '';
}

.contactHRBP a {
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	cursor: pointer;
	font-weight: 500;
	color: rgba(0, 125, 195, 1) !important;
	font-size: 14px;
}
.contactHRBP a:hover {
	background-color: rgba(0, 125, 195, 1);
	color: white !important;
	padding: 2px 10px;
	border-radius: 100px;
	text-decoration: none;
	opacity: 1;
}
