
.modal-faces{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 20px;
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;
  }

  .modal-circle-face{
    background-color: #007dc3;
    width: 75px;
    height: 75px;
    border-radius: 100%;
    transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
    cursor: pointer;
    border: solid #ffffff 2px;
    justify-self: center;
  }

  .active{
    border: solid #48a83e 3px;
  }

  .seguridad a.activeSeguridad,
  .customerExperienceSite a.activeSeguridad,
  .nosotrosPage a.activeSeguridad,
  .jca a.activeJCA{
    text-decoration: none;
    color: #0b2343!important;
    background: rgba(255,255,255,.8);
  }

  .modal-circle-face:hover{
    background: #0b2343;
    border: solid #0b2343 2px;
  }

  .backgroundBlueFaces{
    background-color: #f5f8f9;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 20;
    height: 104px;
  }

  @media (max-width: 500px) {
    .modal-faces{
      grid-template-columns: repeat(3,1fr);
    }
    .modal-content button{
      width: 90%;
    }
    }
