.blue-header {
	height: auto;
	background: #035cf7 !important;
}

.blue-header.TakeCareYourself {
	background: #004990;
}

.blue-header.FinanceAdmin {
	background: #5f3fb1;
}

/*
.aeromexicoBenefits{
  background: #007dc3;
}
*/

.HeaderWrapper {
	width: 90%;
	max-width: 1400px;
	margin: 0 auto;
	display: table;
	height: auto;
	display: block;
	padding: 40px 0;
}

.HeaderWrapper .HeaderCell {
	height: auto;
	width: 100%;
	display: table;
	margin: 0 auto;
}

.backContainer {
	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
	display: block;
	width: 100%;
	height: 55px;
	margin: 0 auto;
}

.backContainer .backContainerWrapper {
	display: block;
	width: 90%;
	height: 100%;
	margin: 0 auto;
	max-width: 1400px;
}

.newsContainer {
	width: 100%;
	height: 100%;
	vertical-align: middle;
	display: table-cell;
}

#backLink {
	display: block;
	text-decoration: none;
}

#backLink span {
	float: left;
	height: 100%;
	display: block;
}
#backLink span svg {
	width: 12px;
	height: 12px;
	margin-top: 23px;
	fill: white;
}

#backLink p {
	line-height: 55px;
	margin: 0;
	padding: 0;
	margin-left: 15px;
	color: #fff;
	font-size: 14px;
	float: left;
}

#backIcon {
	margin-right: 10px;
	font-weight: bold;
	font-size: inherit;
}

.newsContainer p {
	color: #ffffff;
	font-size: 52px;
	font-weight: 500;
	margin: 0;
	padding: 0;
}

p.IntroHeader {
	font-size: 16px;
	line-height: 1.5;
	max-width: 700px;
	font-weight: 400;
	margin: 0;
	padding: 0;
}

/* p {
    color: #ffffff;
    line-height: 130px;
    margin-left: 145px; 
    font-weight: 500px;
  } */

.notificationimg {
	margin-right: 8px;
	width: 50px;
	height: 50px;
}

@media (max-width: 800px) {
	.newsContainer p {
		font-size: 24px;
		line-height: 1.2;
	}
	p.IntroHeader {
		font-size: 14px;
		line-height: initial;
		margin-top: 10px;
	}
	.backContainer {
		--safe-area-inset-top: env(safe-area-inset-top);
		padding-top: 20px;
		padding-top: calc(20px + var(--safe-area-inset-top));
		padding-bottom: 20px;
		height: auto;
		overflow: hidden;
	}
	#backLink p {
		line-height: initial;
	}
	#backLink span svg {
		margin-top: 4px;
	}
}
