.newTable {
	width: 100%;
	height: auto;
	overflow: hidden;
	background-color: white;
	border-radius: 7px;
	border: 1px solid #e5e5e5;
	box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	position: relative;
	margin-right: 30px;
	margin-bottom: 20px;
	background-color: rgba(0, 125, 195, 0.07);
}

.newTable .tableWidth {
	float: left;
	width: calc(100% - 200px);
	box-sizing: border-box;
	height: auto;
	overflow: hidden;
}

.newTable .tableWidth.tableText {
	width: 200px;
}

.newTable .tableWidth.tableText .tableCell {
	padding: 20px;
}

.newTable .tableWidth.tableText .tableCell img {
	width: 35px;
	margin-bottom: 10px;
}

.newTable .tableWidth.tableText .tableCell .title {
	font-size: 18px;
	color: #0b2343;
	margin: 0;
	color: #0b2343;
	font-weight: 500;
	font-size: 18px;
	line-height: 1.2;
}

.newTable .tableWidth.tableText .tableCell .sub {
	color: #666666;
	font-size: 12px;
	line-height: 120%;
	margin-left: 0px;
	margin-bottom: 5px;
	color: #0b2343;
	margin: 0;
	margin-left: 10px;
	margin-top: 10px;
	font-size: 13px;
	line-height: 1.5;
	opacity: 0.8;
	width: 100%;
	line-height: 1.3em;
	margin: 0 0 1em 0;
	margin: 0;
	margin-top: 10px;
	font-size: 12px;
	line-height: 1.5;
	max-width: 400px;
}

.newTable .tableWidth .travelPases {
	height: auto;
	overflow: hidden;
	width: 100%;
	border-bottom: 1px solid #e5e5e5;
	background-color: white;
	display: flex;
}

.newTable .tableWidth .travelPases .rowPases {
	width: calc((100% / 2) - 70px);
	min-height: 136px;
	float: left;
	padding: 20px;
	box-sizing: border-box;
	border-left: 1px solid #e5e5e5;
}
.newTable .tableWidth .travelPases .rowPases:nth-child(3) {
	width: 136px;
}
.newTable .tableWidth .travelPases .rowPases:nth-child(3) p:nth-child(2) {
	list-style: none;
	margin-left: 0;
}
.newTable .tableWidth .travelPases .rowPases p:nth-child(1) {
	display: block;
	margin: 0;
	padding: 0;
	font-size: 14px;
	font-weight: 500;
	color: #0b2343;
	margin-bottom: 0;
}

.newTable .tableWidth .travelPases .rowPases p {
	margin-left: 20px;
	display: list-item;
	list-style: disc outside none;
}

.newTable .tableWidth .travelPases .rowPases p strong {
	font-weight: 600;
}

.newTable .tableWidth .travelPases .rowPases p:nth-child(1) span {
	color: rgba(0, 125, 195, 1);
	font-size: 16px;
}
.newTable .tableWidth .travelPases .rowPases p:nth-child(2),
.newTable .tableWidth .travelPases .rowPases p {
	font-size: 12px;
	font-weight: 400;
	color: #666;
	margin-bottom: 0;
}

/*

<div className="newTable">
          <div className="tableWidth tableText">
            <div className="tableCell">
              <p className="title">Pases</p>
              <p className="sub">Pases Boletos con recho a reservación en Clase Turista</p>
            </div>
          </div>
          <div className="tableWidth">
          </div>
        </div>

/* .main_reward {
  margin: 150px auto;
  width: 600px;
} */
.Container-rewards {
	width: 100%;
}

.rewards_title {
	color: #0b2343;
	font-size: 24px;
	font-weight: 500;
}
.table-employ {
	border-collapse: collapse;
	background: white;
}
th,
td {
	padding-left: 20px;
	font-size: 14px;
	text-align: left;
	color: #666;
	font-weight: 400;
}

tr.headerTable {
	padding: 10px;
	padding-left: 20px;
	font-size: 12px;
	font-size: 12px;
	font-weight: 500;
	margin-top: 10px;
	color: rgba(0, 125, 195, 1);
}
tr:nth-child(even) {
	background-color: rgba(0, 125, 195, 0.07);
}
.table-Compensation:hover {
	border: 1px solid #007dc3;
}
.table-Compensation {
	border-collapse: collapse;
	border: 0.5px solid #d6d6d6;
	background-color: #ffffff;
}

.table-Compensation {
	border-collapse: collapse;
	border: 0.5px solid #d6d6d6;
	background-color: #ffffff;
}
.variable-compensation {
	border: 0.5px solid #d6d6d6;
	background-color: #ffffff;
}
.Variable_Bonus {
	background-color: #fecb2f;
	padding: 0 15px;
	border-radius: 50px;
	text-align: center;
}
.info-employee {
	display: flex;
	width: 100%;
}
.table-Compensation.guaranted-table {
	width: 100%;
}

.table-Compensation {
	width: 80%;
}
.variable-compensation {
	width: 100%;
}
tr.special-row {
	background-color: #007dc3;
}
.container-variable {
	margin-top: 20px;
}
.variable-compensation:hover {
	border: 1px solid #007dc3;
}
table.table-Compensation.travel-table:hover {
	border: 1px solid #007dc3;
}

table.table-Compensation.travel-table {
	border: 1px solid #ccc;
}
tr.travel-row > td.head-table-travel {
	font-size: 13px;
	line-height: 1.5;
	opacity: 0.8;
	line-height: 1.3em;
	margin: 0 0 1em 0;
	color: #666;
	margin: 0;
	margin-top: 20px;
	font-size: 13px;
	line-height: 1.5;
	padding-left: 20px;
	padding-right: 20px;
}

td.head-table-travel span,
.title-second-row {
	margin: 0;
	margin-top: 15px;
	color: #0b2343;
	font-weight: 500;
	font-size: 18px;
	line-height: 1.2;
}

.TravelBenefitsDestktop {
	display: block;
}

.TravelBenefitsMovil {
	display: none;
}

@media (max-width: 800px) {
	.rewards_title {
		font-size: 18px;
		text-align: center;
		float: none;
	}
	.TravelBenefitsDestktop {
		display: none;
	}

	.TravelBenefitsMovil {
		display: block;
	}
}

.handle-travelbenefits2020-strong strong {
	color: #f9ae2a;
}

.imoportantMesaggePrestacion.blue {
	background: #0f4b91;
}

.imoportantMesaggePrestacion.blue p {
	color: white;
	font-size: 14px;
}

.imoportantMesaggePrestacion.blue p a {
	color: #f9ae2a;
}
