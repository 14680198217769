.magazine {
    margin: 0 auto;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .headerBackgroundInforme{
      background-color: #004990;
      padding:70px 0;
  }
  .informeTitle{
      text-align: center;
      padding: 0 40px;
      font-size: 32px;
      font-weight: 500;
      color: white;
  }
  .downloadInforme{
      background-color: #0b2343;
      color: white;
      font-size: 14px;
      text-align: center;
      margin: 0 auto;
      display: table;
      font-weight: 500;
      padding: 15px 30px;
      border-radius: 100px;
      margin-top: 20px;
      margin-bottom: 30px;
      cursor: pointer;
      transition: 400ms cubic-bezier(0.2, 0.8, 0.2, 1);
  }
  .downloadInforme:hover{
      background-color: white;
      color: #0b2343;
  }
  .pagesBackground{
      background-color: #f5f8f9;
      width: 100%;
      height: auto;
  }
  .magazineMiam{
      margin: 0 auto;
      left: 50%;
      transform: translate(-50%,-70px)!important;
      /* border: 1px solid #e5e5e5; */
  }
  .magazine .page {
    height: 100%;
  }
  
  .magazine .page img {
    max-width: 100%;
    height: 100%;
  }
  
  .magazine .shadow {
    -webkit-transition: -webkit-box-shadow 0.5s;
    -moz-transition: -moz-box-shadow 0.5s;
    -o-transition: -webkit-box-shadow 0.5s;
    -ms-transition: -ms-box-shadow 0.5s;
  
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    -o-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    -ms-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  }
  
  .magazine .even .gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(0.95, rgba(0, 0, 0, 0)),
      color-stop(1, rgba(0, 0, 0, 0.2))
    );
    background-image: -webkit-linear-gradient(
      left,
      rgba(0, 0, 0, 0) 95%,
      rgba(0, 0, 0, 0.2) 100%
    );
    background-image: -moz-linear-gradient(
      left,
      rgba(0, 0, 0, 0) 95%,
      rgba(0, 0, 0, 0.2) 100%
    );
    background-image: -ms-linear-gradient(
      left,
      rgba(0, 0, 0, 0) 95%,
      rgba(0, 0, 0, 0.2) 100%
    );
    background-image: -o-linear-gradient(
      left,
      rgba(0, 0, 0, 0) 95%,
      rgba(0, 0, 0, 0.2) 100%
    );
    background-image: linear-gradient(
      left,
      rgba(0, 0, 0, 0) 95%,
      rgba(0, 0, 0, 0.2) 100%
    );
  }
  
  .magazine .odd .gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  
    background: -webkit-gradient(
      linear,
      right top,
      left top,
      color-stop(0.95, rgba(0, 0, 0, 0)),
      color-stop(1, rgba(0, 0, 0, 0.15))
    );
    background-image: -webkit-linear-gradient(
      right,
      rgba(0, 0, 0, 0) 95%,
      rgba(0, 0, 0, 0.15) 100%
    );
    background-image: -moz-linear-gradient(
      right,
      rgba(0, 0, 0, 0) 95%,
      rgba(0, 0, 0, 0.15) 100%
    );
    background-image: -ms-linear-gradient(
      right,
      rgba(0, 0, 0, 0) 95%,
      rgba(0, 0, 0, 0.15) 100%
    );
    background-image: -o-linear-gradient(
      right,
      rgba(0, 0, 0, 0) 95%,
      rgba(0, 0, 0, 0.15) 100%
    );
    background-image: linear-gradient(
      right,
      rgba(0, 0, 0, 0) 95%,
      rgba(0, 0, 0, 0.15) 100%
    );
  }
  