.bubble-holder {
	background: #11b1a2;
	border-radius: 50%;
	bottom: 10px;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
	height: 80px;
	position: fixed;
	transform: scale(0.95);
	transition: transform 0.25s ease-in-out;
	right: 100px;
	width: 80px;
	z-index: 100;
}

.bubble-holder:hover {
	transform: scale(1.05);
}

.bubble-holder svg {
	height: auto;
	width: 60px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -55%);
}
