.barNavButtons {
	height: 60px;
	width: 90%;
	max-width: 1000px;
	padding: 0;
	position: absolute;
	z-index: 4;
	top: 95px;
	left: 50%;
	transform: translate(-50%, 0%);
}
.article-container {
	color: #020c41;
	display: block;
	flex-direction: row;
	width: 100%;
	/*height:calc(100vh - 60px);*/
	height: auto;
	background-color: #f2f2f2;
	overflow: hidden;
}

.small-font {
	font-size: 12pt;
	height: 34px;
	width: 30px;

	background-color: white;
	border: 1px solid #ccc;
}
.big-font {
	font-size: 12pt;
	height: 34px;
	width: 30px;
	margin: 2px;
	background-color: white;
	border: 1px solid #ccc;
}

.left-container p {
	color: #020c41;
	line-height: 140%;
	margin-left: 0;
	margin-bottom: 30px;
	text-align: left;
}
.left-container .text-article-benefits p {
	margin-bottom: 20px;
}

.left-container {
	height: auto;
	width: 100%;
	padding: 0;
	overflow: hidden;
	margin: 32vh auto 80px;
	position: relative;
	z-index: 1;
	background-color: #fff;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	border-radius: 8px;
	max-width: 1000px;
}

.paddingarticle {
	height: 100%;
	width: 100%;
	margin: 0 auto;
	/* overflow: scroll;
  overflow-x: hidden; */
	/* -webkit-overflow-scrolling: touch; */
}

.paddingTextArticle {
	width: 100%;
	margin: 0 auto;
}

.paddingTextArticle h1 {
	font-size: 16px;
}

.paddingTextArticle h2 {
	font-size: 52px;
	text-align: left;
	color: #0b2343;
	line-height: 1.1;
	margin-top: 8px;
	font-weight: 600;
	margin-left: 0;
}
.text-article h2 {
	font-size: 32px;
	color: #0b2343;
	margin-bottom: 20px;
	font-weight: 500;
}
.paddingTextArticle p {
	text-align: left;
	font-size: 16px;
	color: #666;
	line-height: 1.7;
}

.paddingTextArticle ul {
	margin-left: 20px;
	color: #666;
	margin-bottom: 27px;
}
.paddingTextArticle ul.botones {
	padding: 0;
	margin: 0;
	margin-bottom: 20px;
	height: auto;
	overflow: hidden;
}

.paddingTextArticle ul.botones li {
	float: left;
	list-style: none;
	padding-right: 10px;
}

.paddingTextArticle p.introTextArticle {
	font-size: 20px;
	font-weight: 400;
	text-align: left;
	color: #020c41 !important;
	line-height: 1.5;
}

/* .paddingTextArticle p.introTextArticle::first-letter{
  font-size: 96px;
  float: left;
  padding: 0px 8px 0px 0px;
  letter-spacing: 1.54px;
  margin: 0px 0px 0px -7px;
} */

.left-container::-webkit-scrollbar-thumb {
	background-color: darkgrey;
	outline: 1px solid slategrey;
}

.rigth-container {
	width: 100%;
}

.article-small-image {
	width: 100%;
	height: auto;
	border-radius: 8px;
	display: block;
	margin: auto;
	margin: 30px 0;
}

.article-big-image {
	width: 100%;
	height: 100%;
}

.buttons-container {
	box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
	background-color: #f5f5f5;
	border-radius: 42px;
	width: 135px;
	height: 50px;
	overflow: hidden;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.imageHeaderContainer {
	width: 100%;
	overflow: hidden;
	min-height: 185px;
	position: absolute;
	z-index: 1;

	height: auto;
}

.imgDivArticle {
	width: 100%;
	height: 36vw;
	min-height: 185px;
	overflow: hidden;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.text-article img {
	object-fit: cover;
	width: 100%;
	height: auto;
	border-radius: 11px;
	margin-bottom: 30px;
	margin-top: 20px;
}

.text-article.text-article-benefits img {
	margin-top: 10px;
	margin-bottom: 10px;
}

.text-article h3,
.text-article h1,
.text-article h4,
.text-article h5,
.text-article h6 {
	color: #0b2343;
	font-weight: 500;
}

.text-article h3 {
	font-size: 24px;
}

.text-article h4 {
	font-size: 18px;
}

.text-article h5 {
	font-size: 16px;
}

.text-article a :not(.text-article.text-article-benefits) {
	background-color: #035cf7;
	padding: 10px 20px;
	color: white;
	border-radius: 40px;
	margin-bottom: 20px;
	display: table;
	cursor: pointer;
	text-decoration: none;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.text-article a:not(.text-article.text-article-benefits):hover {
	background-color: #020c41;
}

.text-article.text-article-benefits a {
	cursor: pointer;
}

.Subtitle-art {
	color: #035cf7;
	font-size: 14px;
	margin-top: 80px;
	/* text-transform: uppercase;
  letter-spacing: .9px; */
	font-weight: 600;
}
.Date_articles {
	font-size: 14px;
	color: #666666;
	margin-bottom: 40px;
	margin-top: 10px;
	font-weight: 400;
}

.Container_date {
	display: flex;
	justify-content: space-between;
}

.benefit-forms ul {
	list-style: none;
	margin-left: 0px;
	margin-top: 15px;
}

.benefit-forms ul li {
	margin-top: 10px;
	margin-bottom: 5px;
}

.benefit-forms ul li a:before {
	content: url("../img/pdf_icon.png");
	margin-right: 10px;
}

.benefit-forms ul li a {
	color: #035cf7;
	font-weight: 500;
}

.benefit-forms ul li a:hover {
	color: #020c41;
	font-weight: 500;
}

.border-link {
	height: 50px;
	width: auto;
	overflow: hidden;
	display: block;
	padding: 0;
	margin: 0;
	padding-left: 20px;
	padding-right: 28px;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	cursor: pointer;
	float: left;
}

.border-link:hover {
	background-color: rgba(0, 125, 195, 0.09);
}

.border-link span,
.border-link p {
	float: left;
	height: 100%;
	display: block;
}

.border-link p {
	line-height: 50px;
	margin: 0;
	padding: 0;
	margin-left: 15px;
	color: #020c41;
	font-size: 14px;
}

.border-link span svg {
	width: 12px;
	height: 12px;
	margin-top: 20px;
}

.border-link-home {
	float: left;
	width: 60px;
	height: 100%;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	cursor: pointer;
}
.border-link-home:hover {
	background-color: rgba(0, 125, 195, 0.09);
}

.font {
	font-size: 1rem;
	width: 30px;
	height: 40px;
	border-radius: 5px;
}

.big {
	font-size: 1.3rem;
}

@media (min-width: 1045px) {
	.mobile-buttons {
		display: none;
	}
	img.article-small-image.mobile-image {
		display: none;
	}
	.mobile-head-article {
		display: none;
	}
}
@media (max-width: 800px) {
	.barNavButtons {
		--safe-area-inset-top: env(safe-area-inset-top);
		top: 30px;
		top: calc(30px + var(--safe-area-inset-top));
	}
	.buttons-container {
		height: 40px;
		width: 129px;
	}
	.border-link p {
		height: 40px;
		line-height: 40px;
		margin-left: 10px;
	}
	.border-link span svg {
		margin-top: 15px;
	}
	.Date_articles {
		margin-bottom: 20px;
	}
	.imgDivArticle {
		min-height: 185px;
	}
	.imageHeaderContainer {
		position: relative;
	}

	div.article-container {
		flex-wrap: wrap;
		margin: 0;
		width: 100%;
		position: relative;
	}

	div.left-container {
		width: 100%;
		margin-top: 200px;
		background: #fff;
		border-top-right-radius: 20px;
		border-top-left-radius: 20px;
		box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.1);
	}

	.paddingTextArticle h2 {
		font-size: 24px;
		line-height: 1.2;
	}

	.paddingTextArticle p.introTextArticle {
		line-height: 1.5;
		font-size: 16px;
	}

	.paddingTextArticle h1 {
		margin-top: 20px;
	}

	div.rigth-container {
		order: 1;
		width: 100%;
		padding: 0;
	}

	.buttons-container {
		position: absolute;
		top: 0;
	}
	.desktop-buttons {
		display: none;
	}
	img.article-small-image.destop-image {
		display: none;
	}
	.buttons-container button {
		color: #343a40;
		background: #fff;
		border: none;
		padding: 10px;
		margin-top: 20px;
	}
	.container-text-article {
		display: flex;
	}
	.mobile-buttons {
		width: 10%;
		padding-right: 20px;
		display: none;
	}
	.text-article {
		padding-left: 0;
		width: 100%;
	}
	.mobile-buttons button {
		background: #fff;
		border: 1px solid #ccc;
		width: 100%;
		color: #002b5c;
		display: none;
	}
	.Subtitle-art {
		color: #035cf7;
		font-size: 14px;
		margin-top: 38px;
		text-transform: uppercase;
		letter-spacing: 0.9px;
		font-weight: 500;
	}
	.text-article p {
		color: #020c41;
		line-height: 1.5;
		font-size: 14px;
		margin-bottom: 15px;
	}
	div.left-container {
		margin-top: 0;
		border-radius: 0;
		margin-bottom: 0;
	}
	.paddingTextArticle h1 {
		font-size: 12px;
	}
	.container-scroll-article {
		margin-top: 60px;
	}
	#insideArticle {
		padding-top: 20px;
		padding-bottom: 50px;
		height: auto;
		margin-top: 20px;
		padding-left: 20px;
		padding-right: 20px;
	}
	/* h2.desktop-title {
  } */
}

@media (max-width: 1044px) {
	#insideArticle .ArticleCardWrap {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media (max-width: 800px) {
	#insideArticle .ArticleCardWrap {
		grid-template-columns: repeat(2, 1fr);
	}

	#insideArticle .ArticleCardWrap a:last-child {
		margin-right: 0;
	}

	#insideArticle .ArticleCardWrap a:nth-child(1) {
		margin-left: 0px;
	}
}

@media (max-width: 600px) {
	#insideArticle .ArticleCardWrap {
		grid-template-columns: repeat(1, 1fr);
	}
}

.container-text-article a {
	color: #035cf7 !important;
	text-decoration: underline !important;
}

.container-text-article a:hover {
	background: transparent !important;
	color: #035cf7 !important;
	text-decoration: underline;
}
