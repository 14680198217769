.modal-evaluacion-md {
  display: none;
}

.modal-evaluacion-sm {
  display: none;
}

.headerDesempeno {
  height: auto;
  overflow: hidden;
  width: 100%;
  background-image: url(../../../assets/talento/talento.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-bottom: 1px solid #e5e5e5;
  /* min-height: 950px; */
}
.headerDesempeno .midWrapperDesempeno {
  max-width: 1200px;
  margin: 0 auto;
  height: auto;
  overflow: hidden;
  width: 90%;
}
.headerDesempeno .midWrapperDesempeno .textDesempeno {
  height: auto;
  overflow: hidden;
  width: 100%;
  max-width: 900px;
  /* margin: 80px auto 60px; */
  margin: 80px 0 60px;
}
.headerDesempeno .midWrapperDesempeno .textDesempeno p.smallDese {
  font-size: 20px;
  text-align: left;
  color: #007dc3;
  margin: 0;
  padding: 0;
  font-weight: 600;
}
.headerDesempeno .midWrapperDesempeno .textDesempeno p.bigDese {
  font-size: 52px;
  text-align: left;
  font-weight: 600;
  line-height: 1.1;
  color: #0b2343;
}
.headerDesempeno .midWrapperDesempeno .textDesempeno p.intro {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.4;
  text-align: left;
}
.headerDesempeno .midWrapperDesempeno .textDesempeno .infinitoImage {
  width: 400px;
  height: 200px;
  margin: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: 30px;
}

.infinitoImage.es{background-image: url(../../../assets/talento/infinite.png);}
.infinitoImage.en{background-image: url(../../../assets/talento/en_infinite.png);}

.infinito {
  background-color: #0b2343;
  height: auto;
  overflow: hidden;
  width: 100%;
  background-image: url(../../../assets/talento/plane-black.jpg);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}
.infinito .midWrapInfinito {
  max-width: 1200px;
  margin: 0 auto;
  width: 90%;
}
.infinito .midWrapInfinito .textInfinito {
  width: 60%;
  float: left;
  height: auto;
  overflow: hidden;
  padding: 150px 0 150px;
  color: white;
}
.infinito .midWrapInfinito .textInfinito .circuloLibera {
  width: 170px;
  height: 170px;
  float: left;
  margin-right: 30px;
  background-image: url(../../../assets/talento/talento-circulo.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.infinito .midWrapInfinito .textInfinito p.infinitoUno {
  color: white;
  font-weight: 600;
  font-size: 42px;
  max-width: 500px;
  line-height: 1.2;
}
.infinito .midWrapInfinito .textInfinito p.infinitoDos {
  color: white;
  font-size: 18px;
}
.infinito .midWrapInfinito .textInfinito .textFloat {
  float: left;
  width: 100%;
  max-width: 370px;
}
.infinito .midWrapInfinito .textInfinito .textFloat a {
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 500px;
  padding: 10px 20px;
  margin-top: 25px;
  display: table;
  color: white;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.infinito .midWrapInfinito .textInfinito .textFloat a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.liberarPotencial {
  width: 100%;
  height: auto;
  overflow: hidden;
  /* background-image: url(../../../assets/talento/talent-people.jpg); */
  /* min-height: 1300px; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.liberarPotencial .midWrapperPotencial {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
}
.liberarPotencial .midWrapperPotencial .textLiberar {
  padding: 70px 0 30px;
}
.liberarPotencial .midWrapperPotencial .textLiberar p.titulo {
  font-weight: 600;
  font-size: 36px;
  line-height: 1.2;
  max-width: 839px;
  color: #0b2343;
}
.liberarPotencial .midWrapperPotencial .textLiberar p.titulo span {
  color: #007dc3;
}
.liberarPotencial .midWrapperPotencial .textLiberar p.subtitle {
  color: #0b2343;
  font-size: 18px;
  max-width: 600px;
}
.liberarPotencial .midWrapperPotencial .gridLiberar {
  grid-gap: 50px 100px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-bottom: 150px;
}
.liberarPotencial .midWrapperPotencial .gridLiberar p.titulo {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  color: #0b2343;
  line-height: 1.3;
}
.liberarPotencial .midWrapperPotencial .gridLiberar .imageLiberar {
  width: 80px;
  height: 80px;
  /* border: 2px solid #007dc3; */
  border-radius: 50%;
  margin-bottom: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.liberarPotencial
  .midWrapperPotencial
  .gridLiberar
  .textGridLiberar:nth-child(1)
  .imageLiberar {
  background-image: url(../../../assets/talento/areas/area2.svg);
}
.liberarPotencial
  .midWrapperPotencial
  .gridLiberar
  .textGridLiberar:nth-child(2)
  .imageLiberar {
  background-image: url(../../../assets/talento/areas/area1.svg);
}
.liberarPotencial
  .midWrapperPotencial
  .gridLiberar
  .textGridLiberar:nth-child(3)
  .imageLiberar {
  background-image: url(../../../assets/talento/areas/area3.svg);
}
.liberarPotencial
  .midWrapperPotencial
  .gridLiberar
  .textGridLiberar:nth-child(4)
  .imageLiberar {
  background-image: url(../../../assets/talento/areas/area4.svg);
}
.liberarPotencial
  .midWrapperPotencial
  .gridLiberar
  .textGridLiberar:nth-child(5)
  .imageLiberar {
  background-image: url(../../../assets/talento/areas/area5.svg);
}

/* .liberarPotencial .midWrapperPotencial .gridLiberar .textGridLiberar:nth-child(1) a{display: table;}
.liberarPotencial .midWrapperPotencial .gridLiberar .textGridLiberar:nth-child(1) a:nth-child(2){margin-top: 20px;} */

.liberarPotencial .midWrapperPotencial .gridLiberar p.smallito {
  font-size: 14px;
  color: #666;
  font-weight: 500;
  margin: 0;
  padding: 0;
}
.liberarPotencial .midWrapperPotencial .gridLiberar p.contendo {
  color: #0b2343;
  font-size: 18px;
  max-width: 600px;
  margin-top: 15px;
}
.liberarPotencial .midWrapperPotencial .gridLiberar a {
  color: #007dc3;
  font-weight: 500;
  font-size: 14px;
  padding-right: 25px;
}
.liberarPotencial .midWrapperPotencial .gridLiberar a {
  background-image: url(../../../assets/images/arrow-blue.svg);
  background-position: right;
  background-repeat: no-repeat;
}
#backgroundBlue {
  background-color: rgba(0, 125, 195, 0.05);
  border-radius: 11px;
  grid-column: 2 / span 2;
  display: flex;
  overflow: hidden;
  border: 1px solid #e5e5e5;
}
#backgroundBlue .textGrid {
  padding: 45px;
  float: left;
  width: 60%;
  padding-left: 50px;
}
#backgroundBlue .imgGridText {
  float: left;
  width: 40%;
  height: auto;
  background-color: #007dc3;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.imgGridText.es{background-image: url(../../../assets/talento/liderazgo.jpg);}
.imgGridText.en{background-image: url(../../../assets/talento/en_liderazgo.png);}

/* Modales */
.modalHRProcesos{
  width: 95vw;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 !important;
  overflow: hidden;
}
.modalHRProcesos .modal-body{
  padding: 40px;
}
.modalHRProcesos .modal-body p{
  font-size: 16px;
  color:#0b2343;
  font-weight: 400;
}
.modalHRProcesos .modal-body p.h1p{
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 30px;
}
.modalHRProcesos .imageConversacion{
  width: 100%;
  height: 250px;
  background-color: #0b2343;
  position: relative;
  background-image: url(../../../assets/talento/myhrheader.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.modalHRProcesos .imageConversacion.objetivosimage{
  background-image: url(../../../assets/talento/myhrheaderobjetivos.jpg);
}
.modalHRProcesos .imageConversacion.talentoimage{
  background-image: url(../../../assets/talento/myhrheadertalento.jpg);
}
.modalHRProcesos .imageConversacion .textWrapper{
  display: table;
  width: auto;
  height: auto;
  overflow: hidden;
  position: absolute;
  left: 40px;
  bottom: 30px;
}
.modalHRProcesos .imageConversacion .textWrapper p{
  color: white;
  text-align: left;
  margin: 0;
  padding: 0;
}
.modalHRProcesos .imageConversacion .textWrapper p.subtitle{
  font-size: 14px;
  font-weight: 400;
}
.modalHRProcesos .imageConversacion .textWrapper p.title{
  font-size: 42px;
  font-weight: 500;
  margin-top: -8px;
}
.modalHRProcesos .modal-body .gridIconsConsidera{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  margin-top: 40px;
}
.modalHRProcesos .modal-body .gridIconsConsidera p{
  text-align: center;
  font-size: 14px;
  padding: 0 28px;
}
.modalHRProcesos .modal-body .gridIconsConsidera p span{
  font-weight: 600;
}
.modalHRProcesos .modal-body .gridIconsConsidera .imgConsidera{
  width: 60px;
  height: 60px;
  /* border:1px solid #f0f; */
  margin: 0 auto;
  margin-bottom: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.modalHRProcesos .modal-body .gridIconsConsidera .optionConsidera{
  border-right: 1px solid #e5e5e5;
}
.modalHRProcesos .modal-body .gridIconsConsidera .optionConsidera:nth-child(3){
  border-right: none;
}
.modalHRProcesos .modal-body .gridIconsConsidera .optionConsidera:nth-child(1) .imgConsidera{ background-image: url(../../../assets/talento/areas/desarrollo/icon01.svg); }
.modalHRProcesos .modal-body .gridIconsConsidera .optionConsidera:nth-child(2) .imgConsidera{ background-image: url(../../../assets/talento/areas/desarrollo/icon02.svg); }
.modalHRProcesos .modal-body .gridIconsConsidera .optionConsidera:nth-child(3) .imgConsidera{ background-image: url(../../../assets/talento/areas/desarrollo/icon03.svg); }

.modalHRProcesos .modal-body .downloadContainerConversacion{
  background-color: #0b2343;
  border-radius: 100px;
  margin-top: 30px;
  display: table;
  width: 100%;
  padding: 0 11px;
}
.modalHRProcesos .modal-body .downloadContainerConversacion .downloadContainerConversacionCell{
  display: table-cell;
  vertical-align: middle;
}
.modalHRProcesos .modal-body .downloadContainerConversacion .downloadContainerConversacionCell p{
  float: left;
  color: white;
  font-weight: 500;
  margin: 0;
  padding: 0;
  font-size: 14px;
  margin-top: 22px;
  margin-left: 25px;
}
.modalHRProcesos .modal-body .downloadContainerConversacion .downloadContainerConversacionCell a{
  float: right;
  background-color: #e91b2f;
  color: white;
  height: 50px;
  margin: 10px 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 50px;
  border-radius: 100px;
  padding: 0 45px;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.modalHRProcesos .modal-body .downloadContainerConversacion .downloadContainerConversacionCell a:hover{
  background-color: white;
  color: #0b2343;
}
.objetivosEtapas{
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-top: 40px;
}
.objetivosEtapas .objetivosGridEtapas{
  width: 100%;
  height: auto;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
.objetivosEtapas .objetivosGridEtapas .containerObjetivos{
  border: 2px solid #0b2343;
  border-radius: 7px;
  height: auto;
  overflow: hidden;
  display: grid;
  grid-template-rows: repeat(2,auto);
}
.objetivosEtapas .objetivosGridEtapas .containerObjetivos:nth-child(2){border: 2px solid #134b8e;}
.objetivosEtapas .objetivosGridEtapas .containerObjetivos:nth-child(3){border: 2px solid #087dc2;}
.objetivosEtapas .objetivosGridEtapas .containerObjetivos p.headerObjetivos{
  background-color: #0b2343;
  color: white;
  font-size: 16px;
  text-align: center;
  padding: 15px 20px;
  font-weight: 500;
  line-height: 1.2;
  align-self: start;
}
.objetivosEtapas .objetivosGridEtapas .containerObjetivos:nth-child(2) p.headerObjetivos{background-color: #134b8e;}
.objetivosEtapas .objetivosGridEtapas .containerObjetivos:nth-child(3) p.headerObjetivos{background-color: #087dc2;}
.objetivosEtapas .objetivosGridEtapas .containerObjetivos p.bodyText{
  color: #666;
  font-size: 14px;
  padding: 0 15px;
  text-align: center;
}
.objetivosEtapas .objetivosGridEtapas .containerObjetivos .downloadGuiaGrid{
  border-top: 1px solid #e5e5e5;
  align-self: end;
}
.objetivosEtapas .objetivosGridEtapas .containerObjetivos .downloadGuiaGrid a{
  background-color: #0b2343;
  color: white;
  font-size: 14px;
  font-weight: 500;
  display: table;
  padding: 10px 20px;
  border-radius: 100px;
  margin: 10px auto;
  width: 90%;
  text-align: center;
  cursor: pointer;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.objetivosEtapas .objetivosGridEtapas .containerObjetivos:nth-child(2) .downloadGuiaGrid a{background-color: #134b8e;}
.objetivosEtapas .objetivosGridEtapas .containerObjetivos:nth-child(3) .downloadGuiaGrid a{background-color: #087dc2;}
.objetivosEtapas .objetivosGridEtapas .containerObjetivos .downloadGuiaGrid a:hover{
  background-color: #e91b2f;
}

.pasosProcesoTalent{
  border-radius: 7px;
  width: 100%;
  height: auto;
  overflow: hidden;
  border: 2px solid #0b2343;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  text-align: center;
}
.pasosProcesoTalent .optionGridPasosProceso{
  border-left: 1px solid #e5e5e5;
}
.pasosProcesoTalent .optionGridPasosProceso:nth-child(1){
  border-left: none;
}
.pasosProcesoTalent .optionGridPasosProceso .topPasosProcesos{
  background-color: #0b2343;
  overflow: hidden;
  height: auto;
}
.pasosProcesoTalent .optionGridPasosProceso .topPasosProcesos p{
  color: white;
  font-size: 16px;
  font-weight: 500;
  margin: 20px 0;
}
.pasosProcesoTalent .optionGridPasosProceso .bottomPasosProcesos p{
  color: #666;
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 0;
}
.pasosProcesoTalent .optionGridPasosProceso .bottomPasosProcesos p:last-child{
  border-bottom: none;
}
/*

<div className="pasosProcesoTalent">
                  <div className="optionGridPasosProceso">
                    <div className="topPasosProcesos">
                      <p className="titloPasosProcesos">Pre-trabajo / Preparación</p>
                    </div>
                    <div className="bottomPasosProcesos">
                      <p className="widthMitad">IDENTIFICANDO POSICIONES CLAVE</p>
                      <p className="widthMitad">IDENTIFICANDO EL POTENCIAL</p>
                    </div>
                  </div>


div className="objetivosEtapas">
                  <div className="objetivosGridEtapas">
                    <div className="containerObjetivos">
                      <p className="headerObjetivos">Definición de Objetivos</p>
                      <p className="bodyText">Los objetivos nos ayudan a tener claridad sobre las expectativas de la empresa hacia el colaborador y su desempeño. Se alinean al plan de Vuelo, agregando un mayor enfoque en las prioridades que generan impacto.</p>
                      <div className="downloadGuiaGrid">
                        <a>Descargar Guía</a>
                      </div>
                    </div>
*/
@media (max-width: 800px) {
  .objetivosEtapas .objetivosGridEtapas{
    grid-template-columns: repeat(1, 1fr);
  }
  .modal-dialog{
    margin: 4.5rem;
  }
  .modalHRProcesos .modal-body .gridIconsConsidera{
    grid-template-columns: repeat(1,1fr);
  }

  .modalHRProcesos .modal-body .gridIconsConsidera .optionConsidera{
    border-right: none;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 20px;
  }
  .headerDesempeno .midWrapperDesempeno .textDesempeno p.smallDese {
    font-size: 14px;
  }
  .headerDesempeno .midWrapperDesempeno .textDesempeno p.bigDese {
    font-size: 24px;
    line-height: 1.3;
  }
  .headerDesempeno .midWrapperDesempeno .textDesempeno p.intro {
    font-size: 16px;
  }
  .headerDesempeno .midWrapperDesempeno .textDesempeno .infinitoImage {
    width: 90%;
    background-size: contain;
    margin: 0 auto;
  }
  .headerDesempeno .midWrapperDesempeno .textDesempeno {
    margin: 80px auto 32px;
  }
  .liberarPotencial .midWrapperPotencial .textLiberar p.titulo {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
  }
  .liberarPotencial .midWrapperPotencial .textLiberar {
    padding: 44px 0 30px;
  }
  .liberarPotencial .midWrapperPotencial .textLiberar p.subtitle {
    text-align: center;
    font-size: 14px;
  }
  .liberarPotencial .midWrapperPotencial .gridLiberar {
    grid-template-columns: repeat(1, 1fr);
    display: block;
  }
  .liberarPotencial .midWrapperPotencial .gridLiberar .textGridLiberar {
    margin-bottom: 50px;
  }
  .liberarPotencial .midWrapperPotencial .gridLiberar p.titulo {
    font-size: 18px;
  }
  .liberarPotencial .midWrapperPotencial .gridLiberar p.contendo {
    font-size: 14px;
  }
  #backgroundBlue {
    display: block;
  }
  #backgroundBlue .imgGridText {
    display: none;
  }
  #backgroundBlue .textGrid {
    width: 100%;
    padding: 30px;
  }
  .modalHRProcesos .imageConversacion .textWrapper p.title{
    font-size: 32px;
    line-height: 1.2;
  }

  .pasosProcesoTalent {
    grid-template-columns: repeat(2,1fr);
  }
  
  .pasosProcesoTalent .optionGridPasosProceso:nth-child(1){
    grid-column: 1/span2;
  }

  .text-blue-am1 {
    font-size: 17px;
  }

  .modal-evaluacion-lg {
    display: none;
  }

  .modal-evaluacion-md {
    display: block;
  }
  
  .modal-evaluacion-sm {
    display: none;
  }
  
}
@media (max-width: 600px) {
  .modalHRProcesos .modal-body .downloadContainerConversacion{
    border-radius: 7px;
  }
  .modalHRProcesos .modal-body .downloadContainerConversacion .downloadContainerConversacionCell p{
    float: none;
    text-align: center;
  }
  .modalHRProcesos .modal-body .downloadContainerConversacion .downloadContainerConversacionCell a{
    float: none;
    width: 90%;
    display: table;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
    height: 40px;
    line-height: 40px;
  }
  .modal-dialog {
    margin: 3rem;
  }

  .modal-evaluacion-lg {
    display: none;
  }

  .modal-evaluacion-md {
    display: none;
  }
  
  .modal-evaluacion-sm {
    display: block;
  }
}

@media (max-width: 400px) {
  .modal-dialog {
    margin: 1rem;
  }
}
/* 

    <div className="gridLiberar">
                      <div className="textGridLiberar">
                          <p className="titulo">Talent Planning</p>
                          <p className="contendo">Nuestro proceso de Talent Planning busca identificar el potencial, construir y acelerar un robusto Plan de Sucesión y una Banca de Talento multifuncional que ayude a segmentar el talento impulsando decisiones de talento diferenciadas, asegurando la continuidad del liderazgo en posiciones clave.</p>
                      </div>
                  </div>


    <div className="liberarPotencial">
              <div className="midWrapperPotencial">
                  <p className="titulo">Libero mi pootencial</p>
                  <p className="subtitle">Nuestro compromiso es el de desarrollar las herramientas necesarias para:</p>
              </div>
          </div>

    <div className="infinito">
              <div className="midWrapInfinito">
                  <div className="textInfinito">
                      <p className="infinitoUno">Desde Recursos Humanos llevamos a cabo diferentes procesos durante el año con un mismo objetivo:</p>
                      <p className="infinitoDos">Liberar tu potencial</p>
                  </div>
              </div>
          </div>
<div className="headerDesempeno">
              <div className="midWrapperDesempeno">
                  <div className="textDesempeno">
                      <p className="smallDese">Mi desempeño y desarrollo</p>
                      <p className="bigDese">¿Sabes todo lo que estamos haciendo en Grupo Aeroméxico para que puedas desarrollar tu talento?</p>
                  </div>
              </div>
          </div>
          */

/* Modales */
.bg-blue-am1 {
  background-color: #0b2343;
}

.bg-blue-am2 {
  background-color: #007dc3;
}

.img-fluid{
  width: 146px;
  height: 55px;
  margin-bottom: 5px;
  object-fit: contain;
}
.actualizaAdvice{
  font-size: 14px;
  color: #0b2343;
}
.l6Advice{
  font-size: 12px;
  color: #666;
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px;
  border-bottom-left-radius: 7px;
  background-color: rgba(0, 0, 0, 0.05)
}
.bg-blue-am3 {
  background-color: rgba(0, 125, 195, 0.7);
}

.bg-purple-am {

}

.text-blue-am1 {
  color: #0b2343;
}

.text-blue-am2 {
  color: #007dc3;
  font-size: 18px;
}

.text-blue-am3 {
  color: rgba(0, 125, 195, 0.1);
}

.ligamasTalentosa{
  border: 1px solid #e5e5e5;
  border-radius: 7px;
  padding: 20px;
  color: #007dc3;
  font-weight: 500;
  cursor: pointer;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  margin-top: -20px;
  position: relative;
}
.ligamasTalentosa:hover{
  border: 1px solid #007dc3;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  
}
.visitPageMyHR{
  width: 100%;
  font-size: 14px;
  display: block;
  border-top: 1px solid #e5e5e5;
  padding-top: 15px;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 90%;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 80%;
  }
}
