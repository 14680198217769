.seccTh {
	height: auto;
	overflow: hidden;
	width: 100%;
	padding: 30px 0 0;
}
.midWrap {
	width: 95%;
	max-width: 1400px;
	margin: 0 auto;
	height: auto;
	overflow: hidden;
}

p.tituloC06 {
	font-size: 24px;
	padding: 0;
	padding-bottom: 30px;
	text-align: center;
	font-weight: 300;
}
.c063UP {
	overflow: hidden;
	height: auto;
}
.c063UP .contWrap01 {
	min-height: 500px;
	height: auto;
	overflow: hidden;
	width: calc(100% / 4);
	float: left;
	box-sizing: border-box;
	border-right: 1px solid rgba(30, 30, 30, 0.1);
}
.c063UP .contWrap01 .cirWrap {
	height: 200px;
	width: 200px;
	border-radius: 50%;
	margin: 49px auto 0;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: #0b2343;
	position: relative;
}
.c063UP .contWrap01 .cirWrap .imgGif {
	height: 80%;
	background-position: left center;
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	left: 0;
	width: 100%;
	top: 0;
	animation: notiAnima 3s steps(39) infinite;
}
.cirWrap {
	background: url('../../../assets/images/ComiteEjecutivo/andres-uno.png');
	/* url("../../../assets/images/ComiteEjecutivo/ricardo.png"),
    url("../../../assets/images/ComiteEjecutivo/anko.png"),
    url("../../../assets/images/ComiteEjecutivo/sergio.png"),
    url("../../../assets/images/ComiteEjecutivo/james.jpg"),
    url("../../../assets/images/ComiteEjecutivo/angelica.png"),
    url("../../../assets/images/ComiteEjecutivo/andres.png"),
    url("../../../assets/images/ComiteEjecutivo/bejamin.png"); */

	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.contWrap01:nth-child(2) .cirWrap {
	background-image: url('../../../assets/images/ComiteEjecutivo/ricardo.png');
}

.contWrap01:nth-child(3) .cirWrap {
	background-image: url('../../../assets/images/ComiteEjecutivo/Aaron-M.png');
}

.contWrap01:nth-child(4) .cirWrap {
	/* SANTIAGO */
	background-image: url('../../../assets/images/ComiteEjecutivo/santiagoDiago.png');
}

.contWrap01:nth-child(5) .cirWrap {
	/* ANGELICA */
	background-image: url('../../../assets/images/ComiteEjecutivo/angelica.png');
}

.contWrap01:nth-child(6) .cirWrap {
	/* SERGIO */
	background-image: url('../../../assets/images/ComiteEjecutivo/pombo.png');
}

.contWrap01:nth-child(7) .cirWrap {
	background-image: url('../../../assets/images/ComiteEjecutivo/andres.png');
}

.contWrap01:nth-child(8) .cirWrap {
	background-image: url('../../../assets/images/ComiteEjecutivo/bejamin.png');
}

.c063UP .contWrap01 p.steps {
	font-size: 18px;
	padding: 0 30px;
	color: #0b2343;
	margin-top: 50px;
	font-weight: 400;
}
.c063UP .contWrap01 p {
	text-align: center;
	margin: 0;
	padding: 0;
}
.c063UP .contWrap01 p.normal {
	padding: 0 50px;
	font-size: 12px;
	color: #666;
	margin-top: 12px;
	font-weight: 400;
}
.c063UP .contWrap01 p {
	text-align: center;
	margin: 0;
	padding: 0;
}
.c063UP .contWrap01 {
	min-height: 500px;
	height: auto;
	overflow: hidden;
	width: calc(100% / 3);
	float: left;
	box-sizing: border-box;
	border-right: 1px solid rgba(30, 30, 30, 0.1);
}

.c063UP {
	justify-content: center;
	align-content: start;
}

@media (max-width: 1044px) {
	div.ContentGrid-block.ContentGrid-block--twoInRow.border-none {
		border-bottom: none;
	}
	div.ContentGrid-row {
		display: block;
	}
	div.ContentGrid-block.ContentGrid-block--twoInRow {
		display: block;
		width: 100%;
	}

	div.ContentGrid-block.ContentGrid-block--twoInRow.border-none {
		border-bottom: none;
	}

	div.c03-world-aeromexico
		.wrapSide
		.textWrap
		.textWrapCell.textWrapCellLeft {
		padding: 20px;
		text-align: center;
	}

	.c03-world-aeromexico .wrapSide .textWrap .textWrapCell p {
		text-align: center;
	}
	div.c063UP .contWrap01 {
		border-right: none;
	}
	p.ContentGrid-blockIntro.ContentGrid-blockIntro--twoInRow {
		color: #666;
		padding: 0 50px;
		font-size: 12px;
	}
	.contWrap01.borders {
		border-bottom: 1px solid #ccc;
	}
	div.midWrap {
		margin: 0;
		width: 100%;
	}
	div.c03-world-aeromexico .wrapSide .textWrap .textWrapCell h3 {
		text-align: center;
	}
	.contWrap01 {
		padding: 20px 0;
	}
	div.ContentGrid-block.ContentGrid-block--twoInRow {
		padding: 30px 0;
	}
	div.c063UP .contWrap01 .cirWrap {
		margin-top: 0;
	}

	div.c063UP .contWrap01 p.normal {
		padding: 0 22px;
	}

	div.c063UP .contWrap01 p.steps {
		padding: 0;
		margin-top: 20px;
	}
}

@media screen and (max-width: 1300px) {
	.c063UP {
		grid-template-columns: repeat(3, 1fr);
	}
	.c063UP .contWrap01:nth-child(3n + 3) {
		border-right: none;
	}

	.c063UP .contWrap01:nth-child(4),
	.c063UP .contWrap01:nth-child(8) {
		border-right: 1px solid rgba(30, 30, 30, 0.1);
	}
}

@media screen and (max-width: 1044px) {
	div.c063UP .contWrap01 {
		border-right: none;
	}

	.c063UP .contWrap01:nth-child(4),
	.c063UP .contWrap01:nth-child(8) {
		border-right: none;
	}
}

@media screen and (max-width: 800px) {
	.c063UP {
		grid-template-columns: repeat(2, 1fr);
	}
	.c063UP .contWrap01:nth-child(2n + 2) {
		border-right: none;
	}
}

@media screen and (max-width: 600px) {
}

@media screen and (max-width: 424px) {
	.c063UP {
		grid-template-columns: repeat(1, 1fr);
	}
}
