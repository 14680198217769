.newsCategoryHeaderContainer {
	/* outline: 1px solid blue; */
	box-sizing: border-box;
	background: #0b2343;
}

.backgroundContainer {
	/* background: rgba(11, 35, 67, 0.6); */
	background: #fa0073;
	background-repeat: no-repeat;
	background-size: cover;
	color: white;
	position: relative;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.newsCategoryMenuContainer {
	/* height: 50px; */
	display: flex;
	height: 70px;
	position: relative;
	width: 100%;
}

.miniContainer {
	width: 90%;
	margin: 0 auto;
	display: flex;
	max-width: 1400px;
	position: relative;
}

.backLinkContainer1 {
	width: 20%;
	font-size: 12px;
	padding: 20px 5px;
	display: none;
}

#backLink1 {
	text-decoration: none;
	color: #ffffff;
	display: inline-flex;
	align-items: center;
}

#backIcon1 {
	margin-right: 10px;
	font-weight: bold;
	font-size: inherit;
}

.newsCategoryMenu {
	background-color: rgba(255, 255, 255, 0.07);
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 1200px;
	height: 60px;
	margin-top: 20px;
	border-radius: 40px;
	overflow: hidden;
	box-shadow: 0 20px 40px rgba(0, 0, 0, 0.05);
}

.newsCategoryMenu ul {
	display: block;
	list-style: none;
	height: calc(100% - 15px);
	width: calc(100% - 15px);
	display: grid;
	grid-template-columns: repeat(10, auto);
	grid-gap: 10px;
	margin: 0 auto;
	transform: translate(-50%, -50%);
	left: 50%;
	top: 50%;
	position: absolute;
}

.newsCategoryMenu .img {
	width: 35px;
	height: 35px;
	margin: 5px auto 0;
	background-image: url(../../../assets/cultura-justa.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.buttons.circulosMenuOptions {
	width: 48px;
}

.buttonText {
	display: inline;
}

.buttons {
	color: white;
	border-radius: 30px;
	font-size: 12px;
	text-align: center;
	font-weight: 500;
	line-height: 43px;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.buttons:hover {
	text-decoration: none;
	color: #ffffff;
	background: rgba(255, 255, 255, 0.2);
}

.buttonactiveNew {
	text-decoration: none;
	color: #0b2343;
	background: #ffffff;
}

.newsCategoryText {
	width: 90%;
	max-width: 1400px;
	margin: 0 auto;
	z-index: 0;
	position: relative;
}

.miniContainerText {
	max-width: 100%;
}

.h6Container {
}

.h6Container h6 {
	font-size: 12px;
	color: white;
	text-transform: uppercase;
	font-weight: 600;
	padding: 0;
	margin: 0;
	margin-top: 0px;
	margin-top: 25px;
}

.newsCategoryText h6 {
	font-size: 12px;
	color: #ffffff;
	line-height: 25px;
}

.newsCategoryText h1 {
	font-size: 52px;
	font-weight: 500;
	color: white;
	margin: 0;
	padding: 0;
	line-height: 1;
	text-align: center;
	margin-top: 36px;
	position: relative;
	z-index: -1;
}

.pContainer {
	display: block;
}

.newsCategoryText p {
	font-size: 16px;
	font-weight: 400;
	color: white;
	margin: 0;
	margin-top: 0px;
	padding: 0;
	line-height: 1.5;
	margin-top: 20px;
}

.LinkArticleContainer {
	display: flex;
}

#readArticle {
	line-height: 50px;
	text-decoration: none;
	margin-top: 24px;

	width: 153px;
	height: 50px;
	background: rgba(255, 255, 255, 0.1);
	border: 1px solid rgba(255, 255, 255, 0.5);
	border-radius: 50px;
	color: #ffffff;
	text-align: center;
	padding: 10px;
	display: inline-block;
	margin-top: 25px;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	cursor: pointer;
}

#buttonReadArticle {
	width: 153px;
	height: 50px;
	border-radius: 50px;
	color: #ffffff;
	text-align: center;
	font-weight: 500;
}

.popularSection {
	/* outline: 3px solid blue; */
	display: flex;
	margin-top: -50px;
	display: none;
}

.popularContainer {
	display: flex;
	width: 80%;
	margin-left: 9%;
	margin-right: auto;
	justify-content: space-around;
	flex-wrap: wrap;
}

.bigCard {
	width: 430px;
	height: 200px;
	background-image: url(../img/popularbig.jpg);
	border-radius: 10px;
	margin: 13px;
	background-size: cover;
	background-position: center;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.bigCard:hover {
	transform: scale(1.1, 1.1);
	box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5);
}
.twinCards {
	width: 260px;
	height: 200px;
	border-radius: 10px;
	margin: 11px;
	background-size: cover;
	background-position: center;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.twinCards:hover {
	transform: scale(1.1, 1.1);
	box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5);
}

#twinCard1 {
	background-image: url(../img/populartwin1.jpg);
}

#twinCard2 {
	background-image: url(../img/populartwin2.jpg);
}

.popularText {
	background: black;
	margin-top: 10px;
	margin-left: 10px;
	padding: 5px 15px;
	color: white;
	display: flex;
	border-radius: 5px;
	opacity: 0.7;
	width: 70px;
	font-size: 12px;
	font-weight: bold;
	/* text-align: center; */
}
.newsCategoryTextContainer {
	padding: 40px 0 130px;
}

#AllNewsAM .ArticleCardWrap {
	grid-template-columns: repeat(4, 1fr);
	margin: 0 auto;
	margin-top: -68px;
	width: 90%;
	max-width: 1400px;
	z-index: 0;
	position: relative;
}
.categoryNews {
	width: 100%;
	height: auto;
	overflow: hidden;
	background-color: #f1f6f8;
	padding-bottom: 60px;
	padding-top: 50px;
}
.categoryNews .titleCategory {
	color: #0b2343;
	font-size: 24px;
	font-weight: 500;
	/* text-align: center; */
	width: 90%;
	max-width: 1400px;
	margin: 0 auto;
	margin-bottom: 40px;
	text-align: center;
}
.categoryNews .midCategoryNews {
	width: 90%;
	max-width: 1400px;
	height: auto;
	margin: 0 auto;
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	/* TODO: Uncomment    
  grid-template-columns: repeat(8,1fr);
  TODO: Uncomment */
	grid-gap: 30px;
}
.categoryNews .midCategoryNews .categoryBox {
	text-decoration: none;
	border-radius: 11px;
	cursor: pointer;
	/* box-shadow: 0 0.15em 0.4em 0.065em rgba(0, 0, 0, 0.075); */
	/* border:1px solid #e5e5e5; */
	/* background-color: white; */
	/* padding: 20px 0 20px; */
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.categoryNews .midCategoryNews .categoryBox:hover {
	transform: translateY(-5px);
}
.categoryNews .midCategoryNews .categoryBox p.title {
	text-align: center;
	font-weight: 500;
	margin: 0;
	padding: 0;
	color: #0b2343;
	font-size: 16px;
	line-height: 1.2;
	padding: 0 10px;
}
.categoryNews .midCategoryNews .categoryBox:hover p.title {
	color: #007dc3;
}
.categoryNews .midCategoryNews .categoryBox p.link {
	text-align: center;
	font-weight: 500;
	margin: 0;
	padding: 0;
	border-top: 1px solid #e5e5e5;
	font-size: 14px;
	font-weight: 600;
	color: #007dc3;
	padding-top: 16px;
	display: none;
}
.categoryNews .midCategoryNews .categoryBox .icon {
	height: 100px;
	width: 100px;
	margin: 0 auto;
	margin-bottom: 20px;
	border-radius: 50%;
	background-color: rgba(0, 125, 195, 0.08);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	border: 2px solid transparent;
}
.categoryNews .midCategoryNews .categoryBox:hover .icon {
	background-color: rgba(0, 125, 195, 0.14);
}
.categoryNews .midCategoryNews .categoryBox:nth-child(1) .icon {
	background-image: url(../../../assets/news/seg.svg);
}
.categoryNews .midCategoryNews .categoryBox:nth-child(2) .icon {
	background-image: url(../../../assets/news/gente.svg);
}
.categoryNews .midCategoryNews .categoryBox:nth-child(3) .icon {
	background-image: url(../../../assets/news/client.svg);
}
.categoryNews .midCategoryNews .categoryBox:nth-child(4) .icon {
	background-image: url(../../../assets/news/delta.svg);
}
.categoryNews .midCategoryNews .categoryBox:nth-child(5) .icon {
	background-image: url(../../../assets/news/comer.svg);
}
.categoryNews .midCategoryNews .categoryBox:nth-child(6) .icon {
	background-image: url(../../../assets/news/ops.svg);
}
.categoryNews .midCategoryNews .categoryBox:nth-child(7) .icon {
	background-image: url(../../../assets/news/skyteam.svg);
}
.categoryNews .midCategoryNews .categoryBox:nth-child(8) .icon {
	background-image: url(../../../assets/news/exp_empleado.svg);
}

.categoryNews .midCategoryNews .categoryBox:nth-child(1):hover .icon {
	background-image: url(../../../assets/news/seg.svg);
	border: 2px solid #1c869e;
}
.categoryNews .midCategoryNews .categoryBox:nth-child(2):hover .icon {
	background-image: url(../../../assets/news/gente.svg);
	border: 2px solid #f97722;
}
.categoryNews .midCategoryNews .categoryBox:nth-child(3):hover .icon {
	background-image: url(../../../assets/news/client.svg);
	border: 2px solid #e91b2f;
}
.categoryNews .midCategoryNews .categoryBox:nth-child(4):hover .icon {
	background-image: url(../../../assets/news/delta.svg);
	border: 2px solid #007dc3;
}
.categoryNews .midCategoryNews .categoryBox:nth-child(5):hover .icon {
	background-image: url(../../../assets/news/comer.svg);
	border: 2px solid #fecb2f;
}
.categoryNews .midCategoryNews .categoryBox:nth-child(6):hover .icon {
	background-image: url(../../../assets/news/ops.svg);
	border: 2px solid #48a83e;
}
.categoryNews .midCategoryNews .categoryBox:nth-child(7):hover .icon {
	background-image: url(../../../assets/news/skyteam.svg);
	border: 2px solid #5f3fb1;
}
.categoryNews .midCategoryNews .categoryBox:nth-child(8):hover .icon {
	background-image: url(../../../assets/news/exp_empleado.svg);
	border: 2px solid #f97722;
}
/*
<div className="categoryNews">
            <div className="midCategoryNews">
              <Link to="/noticias/seguridad" className="categoryBox">
                <div className="icon">Seguridad</div>
                <p></p>
              </Link>*/

@media (min-width: 1045px) {
	.mobile-popularSection.mobile {
		display: none;
	}
}

@media (max-width: 1044px) {
	.newsCategoryMenu {
		display: none;
		position: relative;
		width: 100%;
		background: transparent;
		height: 40px;
		overflow: hidden;
		border-radius: 0;
	}
	div.newsCategoryMenu ul {
		display: flex;
		flex-wrap: nowrap;
		white-space: nowrap;
		overflow-y: hidden;
		overflow-x: scroll;
		-webkit-overflow-scrolling: touch;
		padding-left: 0;
		background: transparent;
		transition: transform 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) 0s;
		height: 50px;
		width: 100%;
		padding-bottom: 10px;
		top: 0;
		left: 0;
		transform: translate(0, 0);
	}

	div.newsCategoryMenu a.buttons {
		display: inline-block;
		box-sizing: border-box;
		white-space: normal;
		margin: 0;
		margin-right: 17px;
		-webkit-transform-origin: left center 0px;
		transform-origin: left center 0px;
		flex: 1 0 auto;
		transition: -webkit-transform 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) 0s;
		transition: transform 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) 0s;
		transition: transform 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) 0s,
			-webkit-transform 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) 0s;
		line-height: 40px;
		padding: 0 15px;
		text-decoration: none;
		color: white;
		background: rgba(255, 255, 255, 0.2);
	}

	div.newsCategoryMenu a.buttons:nth-child(1) {
		margin-left: 20px;
	}

	div.newsCategoryMenu a.buttons:last-child {
		margin-right: 15px;
	}
	div.miniContainer {
		width: 100%;
	}
	div.newsCategoryMenu a.buttonactiveNew {
		text-decoration: none;
		color: #0b2343;
		background: #ffffff;
	}
	div.mobile-popularSection.mobile::-webkit-scrollbar {
		width: 0;
		background: transparent;
	}
	.mobile-popularContainer::-webkit-scrollbar {
		width: 0;
		background: transparent;
	}
	.newsCategoryMenu::-webkit-scrollbar {
		width: 0;
		background: transparent;
	}
	.newsCategoryHeaderContainer {
		margin: 0;
	}
	div.newsCategoryText p {
		width: auto;
		height: auto;
	}
	.categoryNews .midCategoryNews {
		grid-template-columns: repeat(5, 1fr);
	}
}

/*
.backgroundContainer:after {
  content: "";
  width: 100%;
  height: 100%;
  background: #0b2343;
  position: absolute;
  top: 0;
  z-index: -1;
  opacity: 0.75;
}*/
.popularContainer > div {
	z-index: 3;
}
div#buttonReadArticle:hover {
	background: #e91b2f;
}
a#backLink1 span {
}

a#backLink .fa-long-arrow-left:before {
	vertical-align: sub;
}
.btn-read-article:hover {
	width: 153px;
	height: 50px;
	background: #e91b2f;
	border-radius: 50px;
	color: #ffffff;
	text-align: center;
}

@media (max-width: 800px) {
	#AllNewsAM .ArticleCardWrap {
		grid-template-columns: repeat(3, 1fr);
		margin-top: 0;
		width: 100%;
	}
	/* .ArticleCardWrap a:nth-child(1){
    margin-left: 0;
  } */
	.backgroundContainer {
		padding-top: env(safe-area-inset-top);
	}
	.newsCategoryText h1 {
		font-size: 32px;
		text-align: center;
	}
	.newsCategoryTextContainer {
		padding: 20px 0 90px;
	}
	.categoryNews .titleCategory {
		font-size: 18px;
		margin-bottom: 30px;
	}
	.categoryNews .midCategoryNews {
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 0;
	}
	.categoryNews .midCategoryNews .categoryBox .icon {
		width: 70px;
		height: 70px;
		margin-top: 20px;
	}
	.categoryNews .midCategoryNews .categoryBox {
		border-right: 1px solid #e5e5e5;
		border-bottom: 1px solid #e5e5e5;
		border-radius: 0;
	}
	.categoryNews .midCategoryNews .categoryBox:nth-child(even) {
		border-right: none;
	}
	.categoryNews .midCategoryNews .categoryBox p.title {
		margin-bottom: 20px;
	}
	.categoryNews .midCategoryNews .categoryBox:hover {
		transform: translateY(0);
	}
	.newsCategoryMenu ul {
		grid-gap: 0;
	}
	.categoryNews {
		--safe-area-inset-bottom: env(safe-area-inset-bottom);
		padding-bottom: 50px;
		padding-bottom: calc(50px + var(--safe-area-inset-bottom));
	}
}

@media (max-width: 600px) {
	#AllNewsAM .ArticleCardWrap {
		grid-template-columns: repeat(2, 1fr);
	}
	div.recentCardsContainer {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 20px;
	}
	.recentCard__text {
		display: none;
	}
	.categoryNews .midCategoryNews {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media (max-width: 400px) {
	.categoryNews .midCategoryNews {
		grid-template-columns: repeat(2, 1fr);
	}
}

.newsCategoryMenu {
	position: fixed;
	left: 0;
	left: 0;
	top: 40px;
	transform: none;
	width: 100%;
	border-radius: 0;
	background: #0b2343;
	z-index: 1;
}
