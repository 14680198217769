.recentCards {
	width: 100%;
	height: auto;
}

.recentCardsContainer {
	width: 90%;
	max-width: 1400px;
	height: auto;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	margin: 0 auto;
	margin-top: 0px;
	margin-bottom: 0px;
	grid-gap: 30px;
	margin-bottom: 50px;
	margin-top: -68px;
	position: relative;
	z-index: 0;
	margin-bottom: 0;
	padding-bottom: 0;
}
.recentCard {
	/* overflow-y: hidden; */
	width: 100%;
}

.recentCard__image {
	width: 100%;
	height: 182px;
	border-radius: 11px;
	position: relative;
	box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
	overflow: hidden;
	-webkit-transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-color: white;
}
.image-recent-news {
	width: 100%;
}
.recentCard__image:hover {
	transform: scale(1.1, 1.1);
	box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5);
}

.recentText {
	font-size: 12px;
	font-weight: 600;
	background: rgb(72, 168, 62, 0.8);
	display: table;
	margin: 0;
	padding: 10px 15px;
	color: #fff;
	border-bottom-right-radius: 11px;
	-webkit-backdrop-filter: blur(20px);
	backdrop-filter: blur(20px);
	border-top-left-radius: 11px;
	border-radius: 6px;
	margin: 8px;
	padding: 4px 7px;
}
.recentCard__title {
	margin: 0;
	margin-top: 0px;
	margin-left: 0px;
	margin-left: 10px;
	margin-top: 20px;
	color: #0b2343;
	font-weight: 500;
	font-size: 18px;
	line-height: 1.3;
}

.recentCard__text {
	color: #666666;
	font-size: 14px;
	line-height: 1.4;
	margin-left: 10px;
	margin-bottom: 5px;
	margin-top: 11px;
	height: 42px;
	overflow: hidden;
}
.recentCard__text::after {
	content: '...';
}

.recentCard__time {
	color: #999999;
	font-size: 13px;
	margin: 10px;
	display: none;
}

@media (max-width: 500px) {
	.recentCard__title {
		font-size: 14px;
		margin-left: 0px;
		margin-top: 10px;
		margin-bottom: 0;
		height: 37px;
		overflow: hidden;
	}
	.recentCard__image {
		height: 40vw;
		min-height: 128px;
	}
}
