.img-container-footer {
	margin-top: -110px;
}

.footerWrapper {
	position: relative;
	z-index: 0;
}

.footerWrapper .footerMidWrapper {
	width: 90%;
	height: auto;
	overflow: hidden;
	margin: 0 auto;
	max-width: 1400px;
}

.footerWrapper .footerMidWrapper .wrapperleftfotter {
	width: 35%;
	float: left;
	padding-bottom: 50px;
	padding-right: 50px;
	padding-top: 50px;
}

.footerWrapper .footerMidWrapper .wrapperrightfotter {
	width: 65%;
	float: left;
	padding-right: 20px;
	border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.footerWrapper .footerMidWrapper h3 {
	color: white;
	opacity: 1;
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 1px;
}

.footerWrapper .footerMidWrapper .socialNetwork {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	width: 300px;
	margin-top: 20px;
}

.footerWrapper .footerMidWrapper .socialNetwork .boxCont {
	width: 30px;
	height: 30px;
	text-decoration: none;
	cursor: pointer;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.footerWrapper .footerMidWrapper .socialNetwork .boxCont:hover {
	/*transform: scale(.9,.9);*/
	opacity: 0.5;
}

.footerWrapper .footerMidWrapper .socialNetwork .boxCont img {
	object-fit: contain;
	width: 100%;
}

.footerWrapper .footerMidWrapper .Contactus {
	margin-bottom: 40px;
}

.footerWrapper .footerMidWrapper .Contactus p {
	color: white;
	font-weight: 400;
	font-size: 20px;
	font-weight: 400;
	line-height: 1.5;
	padding-left: 40px;
	position: relative;
	margin-top: 20px;
}

.footerWrapper .footerMidWrapper .Contactus p span.image {
	width: 25px;
	height: 25px;
	font-size: 20px;
	display: block;
	position: absolute;
	left: 0px;
	top: 7px;
	background-image: url(../../../assets/mail.svg);
	background-size: contain;
}

.footerWrapper .footerMidWrapper .Contactus h3{
	margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2; 
}

.footerWrapper .footerMidWrapper a.button {
	display: table;
	border: 1px solid rgba(255, 255, 255, 0.5);
	color: white;
	font-size: 14px;
	font-weight: 500;
	padding: 15px 47px;
	border-radius: 100px;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.footerWrapper .footerMidWrapper a.button:hover {
	background-color: #fa0073;
	transform: translateY(-3px);
}

.footerWrapper .footerMidWrapper .wrapperrightfotter .WrappedGrid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}

.footerWrapper .footerMidWrapper .wrapperrightfotter .WrappedGrid .gridOption {
	padding-left: 50px;
	padding-top: 50px;
	height: 372px;
}

.footerWrapper .footerMidWrapper .wrapperrightfotter .WrappedGrid .gridOption ul {
	color: white;
	padding: 0;
	margin: 0;
	list-style: none;
	margin-top: 20px;
}

.footerWrapper .footerMidWrapper .wrapperrightfotter .WrappedGrid .gridOption ul li {
	padding-left: 40px;
	margin-bottom: 30px;
	max-width: 296px;
	position: relative;
	opacity: 1;
	text-decoration: none;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	cursor: pointer;
}

.footerWrapper .footerMidWrapper .wrapperrightfotter .WrappedGrid .gridOption ul li a {
	display: block;
	width: 100%;
	text-decoration: none;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	color: white;
	color: white;
	font-weight: 400;
	font-size: 20px;
	font-weight: 400;
	line-height: 1.5;
}

.footerWrapper .footerMidWrapper .wrapperrightfotter .WrappedGrid .gridOption ul li a:hover {
	color: #fa0073;
}

.footerWrapper .footerMidWrapper .wrapperrightfotter .WrappedGrid .gridOption ul li:hover {
	opacity: 1;
}

.footerWrapper .footerMidWrapper .wrapperrightfotter .WrappedGrid .gridOption ul li span {
	width: 25px;
	height: 25px;
	display: block;
	position: absolute;
	left: 0px;
	top: 7px;
	background-image: url(../../../assets/plane.svg);
	background-size: contain;
}

.footerBottom {
	/*border-bottom: 1px solid rgba(255,255,255,.2);*/
	padding: 40px 0;
	overflow: hidden;
	/* background-color: #002b5c; */
}

.footerBottom .midWrapper {
	width: 90%;
	max-width: 1400px;
	margin: 0 auto;
}

.footerBottom p.legal {
	color: white;
	opacity: 0.5;
	float: right;
	font-size: 14px;
	margin: 0;
	margin-top: 13px;
}

.footerBottom .miamlogo {
	background-image: url(../../../assets/images/logo/logofooter.svg);
	float: left;
	width: 200px;
	height: 46px;
	background-size: contain;
	background-repeat: no-repeat;
}

.footerWrapper .footerMidWrapper .wrapperrightfotter .WrappedGrid .gridOption:nth-child(2) {
	border-left: 1px solid rgba(255, 255, 255, 0.1);
	padding-bottom: 60px;
}

.footerWrapper .footerMidWrapper .wrapperrightfotter .WrappedGrid .gridOption:nth-child(2) ul li {
	font-size: 18px;
	margin-bottom: 26px;
	padding-left: 50px;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.footerWrapper .footerMidWrapper .wrapperrightfotter .WrappedGrid .gridOption:nth-child(2) ul li span {
	top: 0px;
	width: 35px;
	height: 35px;
}

.footerWrapper .footerMidWrapper .wrapperrightfotter .WrappedGrid .gridOption:nth-child(2) ul li:nth-child(1) span {
	background-image: url(../../../assets/networks/footer01.svg);
}

.footerWrapper .footerMidWrapper .wrapperrightfotter .WrappedGrid .gridOption:nth-child(2) ul li:nth-child(2) span {
	background-image: url(../../../assets/networks/footer02.svg);
}

.footerWrapper .footerMidWrapper .wrapperrightfotter .WrappedGrid .gridOption:nth-child(2) ul li:nth-child(3) span {
	background-image: url(../../../assets/networks/footer03.svg);
}

.footerWrapper .footerMidWrapper .wrapperrightfotter .WrappedGrid .gridOption:nth-child(2) ul li:nth-child(4) span {
	background-image: url(../../../assets/networks/footer04.svg);
}

.footerWrapper .footerMidWrapper .wrapperrightfotter .WrappedGrid .gridOption:nth-child(2) ul li:nth-child(5) span {
	background-image: url(../../../assets/networks/footer05.svg);
}

.footerBottom p.Language {
	color: white;
	float: right;
	font-size: 14px;
	margin: 0;
	margin-top: 13px;
	margin-left: 25px;
}

.footerBottom p.Language span {
	width: 30px;
	height: 30px;
	display: block;
	float: right;
	margin-left: 14px;
	margin-top: -4px;
	background: url(../../../assets/images/mexico-flag.svg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	border-radius: 50%;
	color: white;
}

.footerBottom p.terms {
	color: white;
	float: right;
	font-size: 14px;
	margin: 0;
	margin-top: 13px;
	margin-left: 15px;
}

@media (max-width: 1044px) {
	.footerWrapper .footerMidWrapper {
		display: none;
	}
}

@media (max-width: 800px) {
	.footerWrapper .footerMidWrapper {
		display: none;
	}
	.footerBottom .midWrapper {
		margin-bottom: 100px;
	}
	.footerBottom p.legal {
		width: 100%;
		text-align: center;
	}
	.footerBottom {
		/* border-bottom: 1px solid rgba(255,255,255,.2); */
		padding: 20px 0 40px;
	}
}

@media (max-width: 600px) {
	.footerWrapper .footerMidWrapper {
		display: none;
	}
	.footerBottom .midWrapper {
		margin-bottom: 100px;
	}

	.footerBottom .miamlogo {
		width: 120px;
		height: 35px;
	}

	.footerBottom p.legal {
		width: 100%;
	}

	.footerBottom p.Language {
		margin-top: 0px;
	}

	.footerBottom p.terms {
		margin-top: 0px;
	}
}

@media (max-width: 420px) {
	.footerWrapper .footerMidWrapper {
		display: none;
	}
	.footerBottom .miamlogo {
		display: none;
	}
	.footerBottom p.legal {
		margin-top: 0px;
	}
	.footerBottom p.terms {
		float: left;
	}
}

/*


 <p className="Language">Español - México <span></span></p>

<div className="WrappedGrid">

                <div className="gridOption">
                  <h3>Latest Tweets</h3>

                  <ul>
                    <li><span></span><a>Nos seguimos riendo con la experencia de nuestro #EmbajadorAeromexico en las Vegas. ¿Tú que hubieras hecho? Ve el video completo aquí</a></li>
                    <li><span></span><a>Vamos a Santiago de Chile por unas empanadas, ¿Quién quiere unas?</a></li>
                  </ul>
                </div>

                
              </div>
            </div>

<div className="Contactus">
                <h3>Follow us</h3>
                <p>¿Any question or want to post something in Buy & Sell section? Please write us</p>
                <a>Escribenos</a>
              </div>

<div className="footerMidWrapper">
            
            <div class="wrapperleftfotter">
              <h3>Follow us</h3>
              <div className="socialNetwork">
                <div className="boxCont"><img></img></div>
                <div className="boxCont"><img></img></div>
                <div className="boxCont"><img></img></div>
                <div className="boxCont"><img></img></div>
                <div className="boxCont"><img></img></div>
              </div>
            </div>

            */
