.LatestWrap {
  width: 100%;
  height: auto;
  margin-bottom: 50px;
}
.LatestWrap.newsWrapperWidth{
  margin-bottom: 50px;
}
.container-scroll-article{
  padding-left: 12%;
  padding-right: 12%;
  padding-bottom: 30px;
}

#insideArticle{
  background-color: rgba(0,125,195,.02);
  padding-left: 10%;
  padding-right: 10%;
  border-top: 1px solid #e5e5e5;
  padding-bottom: 15px;
  margin-bottom: 0;
}
.LatestGrid {
  width: 100%;
  height: auto;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  margin-top: 34px;
}
.LatestGrid .link-all {
  justify-self: end;
  color: rgba(0, 125, 195, 1);
  font-size: 14px;
  font-weight: 500;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.LatestGrid .link-all:hover {
  background-color: rgba(0, 125, 195, 1);
  color: white;
  padding: 2px 10px;
  border-radius: 100px;
}
.LatestTitle {
  margin: 30px 0 30px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  justify-items: center;
}



.ArticleCardWrap {
  margin: 30px 0 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  justify-items: center;
}
.ArticleCardWrap a {
  height: auto;
  cursor: pointer;
  width: 100%;
  text-decoration: none;
  display: block;
}
@media (max-width: 1044px) {
  .ArticleCardWrap {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 11px;
  }
}

@media (max-width: 800px) {
  .ArticleCardWrap {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 11px;
    margin-bottom: 5px;
  }
}

.ArticleImageWrap {
  width: 100%;
  height: 177px;
  border-radius: 11px;
  position: relative;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.cxLogrosWrapperhidden .wrapperNewsLogros{
  display: none;
}
.cxLogrosWrapperhidden #CustomerExTag{
  display: block;
}

.exLogrosWrapperhidden .wrapperNewsLogros{
  display: none;
}
.exLogrosWrapperhidden #AchievementsExTag{
  display: block;
}

.ArticleImageWrap:hover {
  transform: scale(1.1, 1.1);
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5);
}

#insideArticle .ArticleImageWrap{
  height: 145px;
}
#insideArticle .ArticleImageWrap p{
  display: none;
}
#insideArticle .ArticleTitle p{
  font-size: 16px;
}
@media (max-width: 1044px) {
  .ArticleImageWrap {
    height: 20vw;
  }
}

@media (max-width: 800px) {
  .ArticleImageWrap {
    height: 37vw;
    min-height: 128px;
  }
  .ArticleImageWrap:hover {
    transform: scale(1, 1);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  }
}
.ArticleTag {
  font-size: 12px;
  font-weight: 600;
  background: rgb(249, 119, 34, 0.8);
  display: table;
  margin: 0;
  padding: 10px 15px;
  color: #fff;
  border-bottom-right-radius: 11px;
  backdrop-filter: blur(20px);
}
@media (max-width: 1044px) {
  .ArticleTag {
    font-size: 10px;
  }
}
.ArticleImage {
  height: 110%;
  position: absolute;
  top: -1px;
  z-index: 1;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  object-fit: cover;
  width: 100%;
}
.ArticleImageWrap:hover .ArticleImage {
  transform: translateY(-10px);
}
.ArticleTitle {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  margin: 0;
  overflow: hidden;
}
.ArticleTitle p {
  margin: 0;
  margin-left: 10px;
  margin-top: 20px;
  color: #0b2343;

  font-weight: 500;
  font-size: 18px;
  line-height: 1.3;
}
@media (max-width: 1044px) {
  .ArticleTitle p {
    font-size: 14px;
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 0;
  }
}

.ArticleIntro {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* autoprefixer: off /
    -webkit-box-orient: vertical;
    / autoprefixer: on */
  width: 98%;
  margin: 0;
  overflow: hidden;
  height: 52px;
  display: none;
}

.ArticleIntro p {
  color: #0b2343;
  margin: 0;
  margin-left: 10px;
  margin-top: 10px;
  font-size: 13px;
  line-height: 1.5;
  opacity: 0.8;
  width: 100%;
  line-height: 1.3em;
  max-height: 2.9em;
  margin: 0 0 1em 0;

  color: #666;
  margin: 0;
  margin-top: 10px;
  font-size: 13px;
  line-height: 1.5;
  margin-left: 10px;
  
}
@media (max-width: 1044px) {
  .ArticleIntro p {
    display: block;
    font-size: 12px;
    margin-left: 0px;
  }
}
@media (max-width: 500px) {
  .ArticleIntro p {
    display: none;
  }
}

@media (max-width: 800px) {
.ArticleCardWrap a{
  display: inline-block;
  flex: 1 0 40%;
  transform-origin: left center 0px;
  white-space: normal;
  margin-right: 17px;
}
.ArticleImageWrap{
  z-index: initial;
}
.ArticleImage{
  z-index: initial;
}
.ArticleImageWrap:hover .ArticleImage {
  transform: translateY(0);
}
.LatestWrap.newsWrapperWidth{
  margin-bottom: 35px;
}
.ArticleCardWrap a{
  flex: 1 0 40%;
}
.ArticleCardWrap a:nth-child(1){
  margin-left: 5%;
}
.ArticleCardWrap a:last-child{
  margin-right: 5%;
}
.container-scroll-article{
  padding-left: 6%;
  padding-right: 6%;
}
}
