.dataSaved{
  width:100%;
  height: auto;
  overflow: hidden;
  background-color: #1c869e;
  top: 15px;
  position: absolute;
  z-index: 99;
  width: 94%;
  border-radius: 11px;
  left: 50%;
  transform: translate(-50%,0);
  padding: 10px 20px;
  animation-name: animatedata;
  animation-delay: 3s;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes animatedata {
  0%   {opacity: 1;}
  100% {opacity: 0;}
}

.dataSaved p{
  color: white;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
}

/*

<div className="dataSaved">
          <div className="icon"></div>
          <div className="message">
            <p>Tus datos han sido guardados y se verán reflejados en tu proximo inicio de sesión</p>
          </div>
        </div>

*/

.backgroundImageWrapper{
  margin-top: 40px;
  margin-bottom: 40px;
}
.divPicture{
  position: relative;
  justify-self: center;
  width:79px;
}
.divPicture .activeProfileArrow{
  width:22px;
  height: 22px;
  background-color: #48a83e;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(../../../assets/checked.svg);
}
.activeProfileArrow.active{
  opacity: 1;
}
.modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 10px;
  background-color: transparent;
  width: 95vw;
  max-width: 650px;
  height: 90vh;
  transform: translate(-50%,-50%);
  z-index: 9;
  min-height: 200px;
  max-height: 700px;
}

.modal-content {
  font-size: 20px;
  font-weight: 600px;
  text-align: center;
  display: flex;
  border-radius: 10px;
  background-color: #fff;
  flex: 2;
}

/* .modal-content span {
  color: #0b2343;
} */

.modal-content button {
  margin: 30px 0;
  border-radius: 50px;
  color: #fff;
  border: none;
  width: 331px;
  height: 45px;
  background-color: #dc2b32;
  align-self: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.modal-content button:hover{
  background-color: #0b2343;
}

.modal-menu-left {
  background-color: rgba(255,255,255,.8);
  border-radius: 10px;
  width:230px;
  float: left;
  height: 100%;
}
.SettingsTitle {
  padding-left: 9px;
  font-weight: 500;
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 20px;
  color: #0b2343;
}
.modal-menu-left ul {
  list-style: none;
}

.modal-menu-left li:hover {
  background-color: rgba(28, 134, 158, 0.15);
}

.menu-item {
  padding: 15px 5px;
}

.input_er {
  width: 100px;
}

.modal-test-back {
  border-radius: 5px;
  width: 88%;
  margin: 0 auto;
  height: 90px;
}

.modal-title {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 16px;
  text-align: left;
  padding: 10px 20px;
  margin: 15px 0;
  color: #0b2343;
  line-height: 4px;
}

.face-test-img {
  background-color: teal;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  position: relative;
  bottom: 30px;
  left: 30px;
}

.modal-subtitle {
  color: #666666;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 14px;
  text-align: left;
  padding: 10px 20px;
  /* margin: 15px 0; */
}

.modal-backs {
  display: grid;
  grid-template-columns: repeat(4,1fr);
  grid-gap: 20px;
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;
}

.modal-circle-back {
  background-color: #007dc3;
    width: 75px;
    height: 75px;
    border-radius: 100%;
    -webkit-transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
    transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
    cursor: pointer;
    border: solid #ffffff 3px;
    justify-self: center;
    object-fit: cover;
}

.modal-circle-back:hover {
  border: solid #ec3549 3px;
}

.modal-sub-subtitle {
  color: #666666;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 12px;
  text-align: left;
  padding: 10px 20px;
  margin: 15px 0;
}

.modal-sub-subtitle a {
  font-size: inherit;
}

.modal-input {
  margin: 30px auto;
  width: 268px;
  height: 50px;
}
.modal-info-label {
  text-align: left;
  margin-left: 30px;
}

.modal-info-text {
  margin: 0 20px;
}
.modal-menu-left .settings-item.menu-active {
  float: none;
  margin-left: 0;
  background-color: rgba(31, 135, 159, 0.14);
}
.modal-menu-left {
  padding: 25px;
}

.modal-menu-left .settings-item.menu-active {
  float: none;
  margin-left: 0;
}
.modal-menu-left ul {
  margin: 0 -25px;
}
.modal-menu-left .settings-item.menu-active {
  float: none;
  margin-left: 0;
}
.modal-menu-left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.headerProfile .modal-content {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: none;
  position: relative;
  overflow: hidden;
  width: calc(100% - 230px);
  float: left;
  height: 100%;
}
.scrollModal{
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 40px 0;
  height:calc(100% - 104px);
}
li.settings-item {
  padding: 15px 25px;
  height: auto;
  overflow: hidden;
  cursor: pointer;
}
li.settings-item img{
  width:35px;
  height: 35px;
  margin-left: 9px;
  float: left;
  object-fit: contain;
}
li.settings-item p{
  float: left;
  color: #0b2343;
  font-weight: 500;
  font-size: 16px;
  line-height: 35px;
  margin: 0;
  padding: 0;
  padding-left: 10px;
}
.upload-container {
  text-align: left;
  width: 100%;
  padding: 10px 0;
}

p.title-upload {
  color: #6666;
  font-size: 12px;
}

p.title-upload {
  padding-left: 25px;
}

.modal-content button.upload-button {
  width: auto;
  color: #007dc3;
  background: transparent;
  border: 1px solid;
  height: auto;
  margin: 0;
  font-size: 14px;
  padding: 5px 10px;
}

.action-upload {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  padding-left: 25px;
}

p.modal-title {
  margin-bottom: 0;
}
/* .headerProfile .modal-container {
  height: auto;
  margin-top: 40px;
}
.upload-container {
  text-align: left;
  width: 100%;
  padding: 15px 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  width: 85%;
  margin-top: 10px;
} */
p.modal-info-label {
  margin-left: 20px;
  text-transform: uppercase;
  font-size: 12px;
  border-bottom: 1px solid #ccc;
  padding: 5px 0;
}
.modal-content input#email {
  padding-bottom: 5px;
  padding-top: 5px;
}
.modal-content .focus-input100::after {
  font-size: 12px;
}
.modal-title_profile {
  font-size: 16px;
  text-align: left;
  margin: 0 20px;
  color: #0b2343;
  margin-top: 40px;
  /* opacity: 0.87; */
}

@media (max-width: 800px) {
.modal-menu-left{
  display: none;
}
.headerProfile .modal-content{
  width: 100%;
  border-top-left-radius: 10px;
border-bottom-left-radius: 10px;
}
}


