#gamreportDiv {
	width: 100%;
	margin: 0 auto;
	overflow: hidden;
	padding: 0;
}
#gamreportDiv .gamreportMid {
	max-width: 1000px;
	width: 90%;
	margin: 0 auto;
}
#gamreportDiv .gamReportInside .accessText {
	font-size: 30px;
	text-align: center;
	margin-top: 40px;
	margin-bottom: 20px;
	font-weight: 500;
}
#gamreportDiv .gamReportInside .smallText {
	text-align: center;
	color: white;
	font-size: 16px;
	margin-top: 20px;
	background-color: rgba(255, 255, 255, 0.2);
	border-radius: 20px;
	display: table;
	padding: 4px 20px;
	margin: 17px auto 0;
}
#gamreportDiv .gamReportInside .accessText span {
	color: #1c869e;
}

.iconCulturaJusta {
	width: 70px;
	height: 70px;
	margin-bottom: 10px;
	background-image: url(../../../assets/cultura-justa.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}
.menu {
	width: 100%;
	height: 50px;
	border-bottom: 1px solid #e5e5e5;
	position: initial;
	top: 60px;
	left: 0;
	z-index: 90;
	background-color: white;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08);
	display: none;
}

.menu .menuWrap {
	width: 96%;
	margin: 0 auto;
}

.menu .menuWrap ol {
	float: right;
	display: grid;
	grid-template-columns: repeat(4, auto);
	list-style: none;
	grid-gap: 25px;
	padding: 0;
	margin: 0;
	margin-top: 15px;
}

.menu .menuWrap ol li a {
	font-size: 14px;
	font-weight: 500;
	color: #0b2343;
}

.menu .menuWrap p.titleSeg {
	float: left;
	font-size: 20px;
	margin: 0;
	padding: 0;
	font-weight: 500;
	color: #1c869e;
	line-height: 50px;
}

.middleSectionBlue {
	background-color: white;
	height: auto;
	overflow: hidden;
	padding-bottom: 0;
	padding-top: 80px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.middleSectionBlue .wrapperMid {
	width: 90%;
	height: auto;
	overflow: hidden;
	margin: 0 auto;
	position: relative;
	z-index: 4;
	max-width: 1200px;
}

.middleSectionBlue .wrapperMid .text-float {
	width: 100%;
	float: none;
	height: auto;
	overflow: hidden;
	margin-bottom: 40px;
}

.middleSectionBlue .wrapperMid .grid-icons {
	width: 100%;
	float: none;
	height: auto;
	overflow: hidden;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	/* grid-gap: 40px; */
	color: #0b2343;
	margin-top: 60px;
}

.middleSectionBlue .wrapperMid .grid-icons .IconsOpcs {
	border-right: 1px solid #e5e5e5;
	border-bottom: 1px solid #e5e5e5;
}

.middleSectionBlue .wrapperMid .grid-icons .IconsOpcs:nth-child(3) {
	border-right: none;
}

.middleSectionBlue .wrapperMid .grid-icons .IconsOpcs:nth-child(6) {
	border-right: none;
}
.middleSectionBlue .wrapperMid .grid-icons .IconsOpcs:nth-child(4) {
	border-bottom: none;
}
.middleSectionBlue .wrapperMid .grid-icons .IconsOpcs:nth-child(5) {
	border-bottom: none;
}
.middleSectionBlue .wrapperMid .grid-icons .IconsOpcs:nth-child(6) {
	border-bottom: none;
}

.middleSectionBlue .wrapperMid .grid-icons .IconsOpcs p:nth-child(2) {
	font-weight: 500;
	font-size: 18px;
	text-align: center;
	margin: 0 auto;
	margin-top: 30px;
	font-weight: 600;
	max-width: 250px;
	line-height: 1.3;
	margin-bottom: 20px;
}

.middleSectionBlue .wrapperMid .grid-icons .IconsOpcs p:nth-child(3) {
	font-weight: 400;
	font-size: 14px;
	text-align: center;
	max-width: 320px;
	margin: 0 auto;
	color: #666;
	margin-bottom: 60px;
}

.middleSectionBlue .wrapperMid .grid-icons .iconsImg {
	width: 70px;
	height: 70px;
	background-image: url(../../../assets/seguridad/segicon01.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	background-color: rgba(28, 134, 158, 0.07);
	border-radius: 50%;
	margin: 0 auto;
	margin-top: 50px;
}

.middleSectionBlue .wrapperMid .grid-icons .IconsOpcs:nth-child(2) .iconsImg {
	background-image: url(../../../assets/seguridad/segicon02.svg);
}
.middleSectionBlue .wrapperMid .grid-icons .IconsOpcs:nth-child(3) .iconsImg {
	background-image: url(../../../assets/seguridad/segicon03.svg);
}
.middleSectionBlue .wrapperMid .grid-icons .IconsOpcs:nth-child(4) .iconsImg {
	background-image: url(../../../assets/seguridad/segicon04.svg);
}
.middleSectionBlue .wrapperMid .grid-icons .IconsOpcs:nth-child(5) .iconsImg {
	background-image: url(../../../assets/seguridad/segicon05.svg);
}
.middleSectionBlue .wrapperMid .grid-icons .IconsOpcs:nth-child(6) .iconsImg {
	background-image: url(../../../assets/seguridad/segicon06.svg);
}

.seguridad {
	width: 100%;
	overflow: hidden;
	height: auto;
	min-width: 320px;
}
.SeguridadSectionOne {
	/* background-color: #1c869e; */
	width: 100%;
	height: auto;
	padding: 74px 0;
	position: relative;
	padding-bottom: 120px;
	background-repeat: no-repeat;
	background-position: top;
	background-size: cover;
	/* background-image: url(../../../assets/seguridad/seguridad.jpg); */
	height: auto;
	background-color: #1c869e;
	/* background: linear-gradient(-45deg, #07182f, #004990, #1c869e, #23d5ab); */
	background: linear-gradient(-45deg, #020c41, #0000e3, #035cf7, #07e8cd);
	animation: Gradient 10s ease infinite;
	background-size: 400% 400%;
}

@keyframes Gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.backgroundCont {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}
.backgroundCont .tealColor {
	width: 70%;
	float: left;
	height: 100%;
	background-position: left top;
	background-repeat: no-repeat;
	background-size: contain;
	position: relative;
	z-index: 2;
	display: none;
}

.backgroundCont .imgeBGColor {
	width: 52%;
	float: left;
	height: 90%;
	background-image: url(../../../assets/seguridad.png);
	background-position: right top;
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	right: 0;
	display: none;
}

.backgroundCont .peopleHappy {
	height: 738px;
	width: 632px;
	position: absolute;
	z-index: 3;
	left: 63%;
	top: 45%;
	transform: translate(-50%, -50%);
	background-image: url(../../../assets/happy.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	display: none;
}
.SeguridadSectionOne .wrapperMid {
	width: 90%;
	height: auto;
	overflow: hidden;
	margin: 0 auto;
	position: relative;
	z-index: 4;
	max-width: 1300px;
}
.SeguridadSectionOne .wrapperMid .slider-read {
	width: 153px;
	height: 50px;
	background: #e91b2f;
	border-radius: 50px;
	color: #ffffff;
	text-align: center;
	padding: 10px 30px;
	display: table;
	width: auto;
	cursor: pointer;
}
.SeguridadSectionOne .wrapperMid .slider-read .blue-arrow {
	transform: rotate(90deg);
}
.SeguridadSectionOne .wrapperMid h2 {
	font-size: 52px;
	max-width: 700px;
	font-weight: 600;
	color: white;
	line-height: 1.2;
	/* margin: 0 auto; */
	margin-top: 30px;
	text-align: center;
	color: white;
	margin: 14px auto 10px;
	line-height: 1.1;
}
.SeguridadSectionOne .wrapperMid p {
	font-size: 20px;
	font-weight: 300;
	color: white;
	max-width: 900px;
	margin-top: 20px;
	opacity: 1;
	line-height: 1.4;
	margin-bottom: 10px;
	margin: 0 auto;
	margin-top: 15px;
	text-align: center;
	color: white;
	max-width: 615px;
}
.SeguridadSectionOne .wrapperMid p:nth-child(4) {
	font-size: 18px;
	font-weight: 300;
	margin-top: 0;
	max-width: 500px;
}
.SeguridadSectionOne .wrapperMid .logoSeguridadFirst {
	width: 230px;
	height: 32px;
	margin: 0 auto;
	margin-top: 60px;
	background-image: url(../../../assets/seguridad-logo.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.SeguridadSectionOne .wrapperMid .logoSeguridadFirst.es {
	background-image: url(../../../assets/seguridad-logo.png);
}

.SeguridadSectionOne .wrapperMid .logoSeguridadFirst.en {
	background-image: url(../../../assets/seguridad-logo-en.png);
}

.SeguridadSectionOne .wrapperMid h4 {
	font-size: 24px;
	font-weight: 500;
	color: white;
	margin-top: 40px;
	opacity: 1;
	line-height: 1.3;
	text-align: center;
	max-width: 700px;
	margin: 20px auto 0;
}

.SeguridadSectionTwo {
	background-color: #192139;
	width: 100%;
	height: auto;
	padding: 0;
	position: relative;
}
.SeguridadSectionTwo.safetyCulturaJusta {
	height: 800px;
	padding: 120px 0 0;
	background-color: transparent;
}

#reporta-denuncia {
	margin-top: -114px;
	position: relative;
	z-index: 4;
}
#widthBackground {
	position: relative;
	width: 90%;
	max-width: 1200px;
	margin: 0 auto;
	margin-top: 0px;
	margin-top: -50px;
	border-radius: 11px;
	overflow: hidden;
	box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.1);
}
#widthBackground .SeguridadSectionTwo {
	z-index: 3;
	position: relative;
	background: transparent;
	padding: 120px 70px;
	background-image: url(../../../assets/cultura-justa-bg.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}
.seguridad #widthBackground .blueFilter {
	background-color: white;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
}
.SeguridadSectionTwo .wrapperMid {
	width: 90%;
	height: auto;
	/* overflow: hidden; */
	margin: 0 auto;
	position: relative;
	z-index: 4;
	max-width: 1200px;
}

.SeguridadSectionTwo .wrapperWidthall {
	width: 100%;
	height: auto;
}
.wrpperWidth {
	width: 100%;
	height: auto;
	/* overflow: hidden; */
}

.SeguridadSectionTwo .wrapperMid .textWrapper {
	width: 100%;
	float: left;
	height: auto;
	overflow: visible;
	/* overflow: hidden; */
}
.seguridad h3 {
	font-size: 48px;
	color: #137d95;
	text-align: center;
	font-weight: 600;
}
.seguridad #areas-de-seguridad h3 {
	text-align: center;
}
.SeguridadSectionTwo .wrapperMid .textWrapper h3 {
	text-align: left;
	color: #137d95;
}

.seguridad h3 span {
	/* color: #1c869e; */
}

.SeguridadSectionTwo .wrapperMid .textWrapper p {
	font-size: 22px;
	color: #0b2343;
	/* max-width: 400px; */
	margin-top: 10px;
	opacity: 0.8;
	line-height: 1.3;
	float: left;
	font-weight: 300;
}

.SeguridadSectionTwo .wrapperMid .textWrapper p:nth-child(3) {
	font-size: 18px;
	font-weight: 400;
	float: left;
	max-width: 460px;
	line-height: 1.4;
}

.SeguridadSectionTwo .wrapperMid p.Intro {
	font-size: 30px;
	font-weight: 500;
	color: #0b2343;
	opacity: 1;
	line-height: 1.4;
	text-align: left;
	max-width: 400px;
}

.SeguridadSectionTwo .backgroundImagePlane {
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: none;
}

.SeguridadSectionTwo .backgroundImagePlane .planeHappy {
	width: 60%;
	height: 70%;
	background-image: url(../../../assets/planehug.png);
	background-position: left top;
	background-repeat: no-repeat;
	background-size: contain;
	margin-top: 90px;
}

.SeguridadSectionTwo .backgroundImagePlane .waveSecond {
	width: 80%;
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
	background-position: right top;
	background-repeat: no-repeat;
	background-size: contain;
}
.borderDiv {
	border-bottom: none;
	padding-bottom: 0;
}

.SeguridadSectionThree {
	background-color: rgba(28, 134, 158, 0.07);
	width: 100%;
	height: auto;
	position: relative;
	padding-top: 75px;
}

.SeguridadSectionThree .wrapperMid {
	width: 90%;
	height: auto;
	margin: 0 auto;
	position: relative;
	z-index: 4;
	max-width: 1200px;
}

.SeguridadSectionThree .blueTop {
	background-color: #192139;
	width: 100%;
	position: absolute;
	z-index: 1;
	height: 300px;
	top: 0;
	left: 0;
	display: none;
}

.SeguridadSectionThree .wrapperMid p {
	font-size: 20px;
	font-weight: 300;
	max-width: 500px;
	margin-top: 20px;
	opacity: 1;
	text-align: center;
	color: #0b2343;
	/* margin-left: auto;
    margin-right: auto; */
	text-align: center;
	margin: 0 auto;
}

.titleSecurityHeader {
	position: relative;
	height: 35px;
	overflow: hidden;
	margin-top: 40px;
}

.titleSecurityHeader .lineTitle {
	border: 1px solid #e5e5e5;
	width: 100%;
	height: 1px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
}

.SeguridadSectionThree .wrapperMid p.titleSeguridadTipoArea {
	font-weight: 600;
	color: #1c869e;
	font-size: 20px;
	z-index: 2;
	position: absolute;
	display: table;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #eff6f8;
	padding: 0 20px;
}

.SeguridadSectionThree .wrapperMid .nomarginTop {
	margin-top: 0;
}

.SeguridadSectionThree .wrapperMid .areas {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	margin-top: 30px;
	grid-gap: 40px;
	height: auto;
	padding-bottom: 80px;
}
.SeguridadSectionThree .wrapperMid .areas.justone {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	margin-top: 30px;
	grid-gap: 40px;
	height: auto;
	padding-bottom: 80px;
}
.SeguridadSectionThree .wrapperMid .areas .opcAreas {
	width: 100%;
	height: auto;
	border-radius: 11px;
	box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
	background-color: white;
	/* cursor: pointer; */
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	position: relative;
}

.in-middle {
	grid-column: 2;
}

.card-item-security-area {
	width: 100%;
	height: auto;
	border-radius: 11px;
	box-shadow: 0 0.08em 0.25em 0.075em rgba(0, 0, 0, 0.075);
	background-color: white;
	/* cursor: pointer; */
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	position: relative;
	max-width: 250px;
}

.gridpositionTop {
	grid-column: 2;
}

/* .SeguridadSectionThree .wrapperMid .areas .opcAreas:hover {
    transform: scale(1.04, 1.04);
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);  
} */

.SeguridadSectionThree .wrapperMid .areas .opcAreas .padding {
	padding: 30px;
}
.SeguridadSectionThree .wrapperMid .areas .opcAreas .padding .icon {
	width: 50px;
	height: 50px;
	background-image: url(../../../assets/seguridad/seguridad01.svg);
	background-size: contain;
	background-repeat: no-repeat;
}

.SeguridadSectionThree .wrapperMid .areas .opcAreas:nth-child(2) .padding .icon {
	background-image: url(../../../assets/seguridad/seguridad02.svg);
}
.SeguridadSectionThree .wrapperMid .areas .opcAreas:nth-child(3) .padding .icon {
	/* ESTE MODIFICAR */
	background-image: url(../assets/seguridad.svg);
	/* background-image: url(../../../assets/seguridad/seguridad03.svg); */
}
.SeguridadSectionThree .wrapperMid .areas .opcAreas:nth-child(4) .padding .icon {
	background-image: url(../../../assets/seguridad/seguridad04.svg);
}
.SeguridadSectionThree .wrapperMid .areas .opcAreas:nth-child(5) .padding .icon {
	background-image: url(../../../assets/seguridad/seguridad05.svg);
}
.SeguridadSectionThree .wrapperMid .areas .opcAreas:nth-child(6) .padding .icon {
	background-image: url(../assets/aseg-calidad.png);
	/* background-image: url(../../../assets/seguridad/seguridad06.svg); */
}
.SeguridadSectionThree .wrapperMid .areas .opcAreas:nth-child(7) .padding .icon {
	background-image: url(../assets/cont-negocio.png);
	/* background-image: url(../../../assets/seguridad/seguridad07.svg); */
}
.SeguridadSectionThree .wrapperMid .areas .opcAreas:nth-child(8) .padding .icon {
	background-image: url(../../../assets/seguridad/seguridad08.svg);
}

.SeguridadSectionThree .wrapperMid .areas .opcAreas.securityNews:nth-child(1) .padding .icon {
	background-image: url(../../../assets/seguridad/pro02.svg);
}
.SeguridadSectionThree .wrapperMid .areas .opcAreas.securityNews:nth-child(2) .padding .icon {
	background-image: url(../../../assets/seguridad/pro01.svg);
}

.SeguridadSectionThree .wrapperMid .areas .opcAreas .padding p.title {
	font-size: 18px;
	font-weight: 600;
	color: #0b2343;
	margin: 0;
	padding: 0;
	line-height: 1.2;
	margin-top: 10px;
	text-align: left;
}

.SeguridadSectionThree .wrapperMid .areas .opcAreas .padding p.subtitle {
	color: #666;
	font-size: 14px;
	line-height: 1.5;
	/* padding-bottom: 31px; */
	margin-top: 10px;
	text-align: left;
	margin-bottom: 0;
}

.SeguridadSectionThree .wrapperMid .areas .opcAreas .padding a {
	/* position: absolute; */
	bottom: 30px;
	left: 30px;
	display: block;
	width: calc(100% - 60px);
	padding-top: 10px;
	font-weight: 600;
	font-size: 14px;
	color: #1c869e;
}

.SeguridadSectionTwo .wrapperMid p.small {
	font-size: 20px;
	font-weight: 300;
	color: #0b2343;
	max-width: 500px;
	line-height: 1.4;
	text-align: left;
}

.compliance-end-alert-seguridad {
	background: #f5f8f9;
	display: flex;
	flex-direction: column;
	height: 200px;
	font-size: 16px;
	justify-content: center;
	padding: 100px 0;
	align-items: center;
	text-align: center;
}

.compliance-end-alert-seguridad.white-bg {
	background: white;
	font-size: 18px;
	font-weight: 700;
}

.compliance-end-alert-seguridad.white-bg p {
	margin: 0 0 15px;
}

.compliance-end-alert-seguridad img {
	height: 40px;
	margin-bottom: 10px;
	width: auto;
}

@media (max-width: 1044px) {
	.SeguridadSectionTwo .wrapperMid .textWrapper h3 {
		color: white;
	}
	.SeguridadSectionTwo .wrapperMid .textWrapper p {
		color: white;
	}

	.SeguridadSectionTwo .wrapperMid p.Intro {
		width: 100%;
		margin: 0;
		font-size: 18px;
		text-align: left;
		line-height: 1.3;
		margin-top: 20px;
	}

	.SeguridadSectionThree .wrapperMid .areas {
		grid-template-columns: repeat(3, 1fr);
	}

	.SeguridadSectionThree .wrapperMid .areas .opcAreas:nth-child(1) {
		grid-column: 1;
	}

	.compliance-end-alert-seguridad {
		display: block;
		padding: 20px;
	}
	/* .react-parallax.parallax-back:after {
		background-color: rgba(0, 0, 0, 0);
	} */
}

@media (max-width: 800px) {
	.SeguridadSectionOne {
		padding-bottom: 150px;
	}
	.SeguridadSectionOne .wrapperMid h2 {
		font-size: 24px;
	}
	.SeguridadSectionOne .wrapperMid p {
		font-size: 14px;
	}
	#gamreportDiv .gamReportInside .smallText {
		font-size: 12px;
		border-radius: 100px;
		line-height: 1.3;
		padding: 8px 20px;
		margin: 0;
	}
	#reporta-denuncia {
		margin-top: -136px;
	}
	.SeguridadSectionOne .wrapperMid .logoSeguridadFirst {
		margin-top: 20px;
	}
	.gamReportInside .wantToHearYou {
		grid-template-columns: repeat(1, 1fr);
	}
	.gamReportInside .wantToHearYou .hearyouWrapper .textWrapper p.intro {
		font-size: 12px;
	}
	.gamReportInside .wantToHearYou .hearyouWrapper {
		height: 160px;
	}
	#gamreportDiv .gamReportInside .accessText {
		font-size: 24px;
	}
	.SeguridadSectionThree .wrapperMid p.titleSeguridadTipoArea {
		font-size: 14px;
	}
	.gridpositionTop {
		grid-column: 1;
	}
	#proteccionCorporativa {
		padding-bottom: 40px;
	}
	.seguridad #areas-de-seguridad h3 {
		font-size: 24px;
	}
	.seguridad h3 {
		font-size: 24px;
	}
	.middleSectionBlue .wrapperMid .grid-icons {
		grid-template-columns: repeat(2, 1fr);
	}
	.middleSectionBlue .wrapperMid .grid-icons .IconsOpcs:nth-child(even) {
		border-right: none;
	}
	.middleSectionBlue .wrapperMid .grid-icons .IconsOpcs:nth-child(3) {
		border-right: 1px solid #e5e5e5;
	}
	.middleSectionBlue .wrapperMid .grid-icons .IconsOpcs:nth-child(4) {
		border-bottom: 1px solid #e5e5e5;
	}
	.middleSectionBlue .wrapperMid .grid-icons .IconsOpcs p:nth-child(2) {
		font-size: 16px;
		padding: 0 20px;
	}
	.middleSectionBlue .wrapperMid .grid-icons .IconsOpcs p:nth-child(3) {
		padding: 0 10px;
		font-size: 12px;
		margin-bottom: 30px;
	}
	#widthBackground .SeguridadSectionTwo {
		padding: 45px 30px 27px;
		background-image: none;
		background-color: white;
	}
	#gamreportDiv .gamReportInside .accessText {
		margin-left: 0;
	}
	.SeguridadSectionTwo.safetyCulturaJusta {
		padding: 55px 0 0;
	}
	.SeguridadSectionTwo .wrapperMid .textWrapper p:nth-child(3) {
		font-size: 16px;
	}
	.SeguridadSectionTwo.safetyCulturaJusta {
		padding-bottom: 80px;
		background-color: #137d95;
		height: 500px;
	}

	.SeguridadSectionThree .wrapperMid .areas {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (max-width: 500px) {
	.middleSectionBlue .wrapperMid .grid-icons {
		grid-template-columns: repeat(1, 1fr);
	}

	.SeguridadSectionThree .wrapperMid .areas {
		grid-template-columns: repeat(1, 1fr);
	}
}

.text-holder-grid {
	min-height: 320px;
}

.react-parallax.parallax-back:after {
	background-color: rgba(255, 255, 255, 0.35) !important;
	content: "";
	display: block;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 2;
}

@media (max-width: 1044px) {
	.react-parallax.parallax-back:after {
		background-color: rgba(0, 0, 0, 0.5) !important;
	}
	.text-holder-grid {
		min-height: auto;
	}
}

p strong {
	font-weight: 700;
}

.with-special-background-green {
	background: "#EDF6FB" !important;
	background-color: "#EDF6FB";
}
