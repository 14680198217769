.content-holder-talent {
	background: #fff;
	/* width: calc(100% - 270px); */
	width: 100%;
}

@media (max-width: 1215px) {
	.content-holder-talent {
		/* width: calc(100% - 80px); */
		width: 100%;
	}
}
@media (max-width: 1044px) {
	.content-holder-talent {
		width: 100%;
	}
}

/* .talent-card {
	border: 1px solid #edf0f5;
	max-width: 720px;
} */

/* .talent-card.active {
	border: 1px solid #0f4b91;
	max-width: 720px;
} */
