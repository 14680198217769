.wrapperContGam {
	background-color: #ffe873;
	/* border: 1px solid white; */
	border-radius: 8px;
	box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16);
	cursor: pointer;
	height: 85px;
	position: fixed;
	right: 20px;
	top: 131px;
	padding: 18px 26px;
	color: #020c41 !important;
	width: 424px;
	transition: right 800ms cubic-bezier(0.2, 0.8, 0.2, 1), opacity 400ms 0.5s cubic-bezier(0.2, 0.8, 0.2, 1),
		width 300ms cubic-bezier(0.2, 0.8, 0.2, 1), background-color 800ms cubic-bezier(0.2, 0.8, 0.2, 1);
	text-decoration: none;
	z-index: 30;
}

.wrapperContGam:hover {
	background-color: #ffd773;
	color: #020c41 !important;
}

.wrapperContGamScroll {
	right: -300px;
	top: 131px;
	transform: translateX(-102px);
	width: 69px;
	padding: 10px;
	height: 67px;
	border: 0;
	opacity: 0;
}

.wrapperContGamScroll .gamTextReport {
	opacity: 0;
	width: 0;
	height: 0;
	overflow: hidden;
}

.wrapperContGamScroll:hover {
	width: 340px;
	padding: 13px 20px;
	border-radius: 8px;
	height: 73px;
}

.wrapperContGamScroll:hover .gamTextReport {
	opacity: 1;
	width: calc(100% - 66px);
	height: auto;
	overflow: hidden;
}

.wrapperContGam:hover .gamTextReport p {
	/* color: white; */
	color: #020c41;
}

.wrapperContGamScroll:hover .gamTextReport p {
	color: #020c41;
}

@keyframes gamreportanimate {
	0% {
		width: 260px;
		height: 74px;
		padding: 7px;
	}

	50% {
		width: 60px;
	}

	100% {
		width: 60px;
		height: 60px;
		padding: 0;
	}
}

/*
  .wrapperContGam:hover{
    animation:gamreportanimate 3s 10s alternate cubic-bezier(0.2, 0.8, 0.2, 1);
  }*/

.wrapperContGam .gamreportWrapper {
	/*background-color: #1c869e;
    background-image: linear-gradient(to right top, #0b2343, #0a2c55, #083668, #043f7c, #004990);
    border-radius: 50%;
    box-shadow: 0 1px 6px 0 rgba(0,0,0,.06), 0 2px 32px 0 rgba(0,0,0,.16);
    */
	width: 50px;
	height: 50px;
	float: left;
	background-image: url(../../../../assets/logo/chat.svg);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	margin-top: 15px;
	margin-top: -2px;
}

.wrapperContGam .gamTextReport {
	/* height: 71px; */
	overflow: hidden;
	width: calc(100% - 50px);
	float: left;
	transition: 1s 0.4s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.wrapperContGam .gamTextReport p {
	margin: 0;
	padding: 0;
	padding-right: 0;
	padding-left: 0;
	color: #0b2343;
	margin-left: 22px;
}

.wrapperContGam .gamTextReport p:nth-child(1) {
	font-size: 16px;
	font-weight: 400;
	line-height: 1.4;
	margin-top: 0;
}

.wrapperContGam .gamTextReport p span {
	font-weight: 600;
}

.wrapperContGam .gamTextReport p:nth-child(2) {
	font-size: 14px;
	max-width: auto;
	padding-right: 0;
	line-height: 1.4;
	margin-top: 2px;
	font-weight: 500;
	opacity: 0.8;
	margin-bottom: 4px;
}

/*MODAL*/

.modalGamReportWrapper {
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 99;
}
.modalGamReportWrapper .windowWhiteGam {
	width: 650px;
	height: auto;
	background-color: white;
	border-radius: 11px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	overflow: hidden;
	box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16);
	position: relative;
}

.closeButton {
	position: absolute;
	top: 20px;
	right: 20px;
	width: 25px;
	height: 25px;
	opacity: 0.5;
}

.modalGamReportWrapper .windowWhiteGam .paddingText {
	background-color: #004990;
	background-image: linear-gradient(to right top, #0b2343, #0a2c55, #083668, #043f7c, #004990);
	color: white;
	padding: 30px;
	padding-bottom: 100px;
	padding-left: 50px;
}

.modalGamReportWrapper .windowWhiteGam .paddingText img {
	width: 45px;
	height: 45px;
	margin-bottom: 10px;
}

.modalGamReportWrapper .windowWhiteGam .paddingText h4 {
	margin: 0;
	padding: 0;
	font-size: 32px;
	font-weight: 400;
}

.modalGamReportWrapper .windowWhiteGam .paddingText p {
	margin: 0;
	padding: 0;
	font-size: 16px;
	font-weight: 400;
	opacity: 1;
	max-width: 500px;
	margin-top: 10px;
}

.modalGamReportWrapper .windowWhiteGam .optionsGamReport {
	width: 100%;
	height: auto;
	margin: 0 auto;
	min-height: 100px;
	margin-top: -80px;
	padding: 0 30px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 20px;
	margin-bottom: 30px;
}

.modalGamReportWrapper .windowWhiteGam .optionsGamReport .cardOptionGamReport {
	background-color: white;
	border-radius: 8px;
	height: 257px;
	box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 rgba(40, 110, 250, 0.5);
	text-decoration: none;
	color: #0b2343;
	padding: 20px;
	margin-bottom: 30px;
	position: relative;
	box-sizing: border-box;

	background-color: white;
	border-radius: 8px;
	height: auto;
	box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 rgba(40, 110, 250, 0.5);
	text-decoration: none;
	color: #0b2343;
	padding: 20px;
	margin-bottom: 0;
	position: relative;
	box-sizing: border-box;
	display: block;
	grid-template-columns: auto auto;
	grid-gap: 10px;
	padding-bottom: 10px;
}

.modalGamReportWrapper .windowWhiteGam .optionsGamReport .cardOptionGamReport div.textWrapper {
	width: 100%;
	/*padding-left: 10px;*/
}

.modalGamReportWrapper .windowWhiteGam .optionsGamReport .cardOptionGamReport:hover {
	border: 2px solid #007dc3;
}

.modalGamReportWrapper .windowWhiteGam .optionsGamReport .cardOptionGamReport img {
	width: 45px;
	border: 1px solid transparent;
	height: 45px;
}

.modalGamReportWrapper .windowWhiteGam .optionsGamReport .cardOptionGamReport:nth-child(1) img {
	width: 129px;
	height: 50px;
	margin-right: 10px;
}

.modalGamReportWrapper .windowWhiteGam .optionsGamReport .cardOptionGamReport p {
	margin: 0;
	padding: 0;
}

.modalGamReportWrapper .windowWhiteGam .optionsGamReport .cardOptionGamReport p.titleText {
	font-weight: 500;
	font-size: 16px;
	margin-bottom: 0;
}

.modalGamReportWrapper .windowWhiteGam .optionsGamReport .cardOptionGamReport:nth-child(1) p:nth-child(2) {
	/*display: none;*/
}

.modalGamReportWrapper .windowWhiteGam .optionsGamReport .cardOptionGamReport p.smallText {
	font-weight: 400;
	font-size: 13px;
	color: #666;
	margin-top: 3px;
}

.modalGamReportWrapper .windowWhiteGam .optionsGamReport .cardOptionGamReport p span {
	color: #999;
}

.modalGamReportWrapper .windowWhiteGam .optionsGamReport .buttonGam {
	/*width: calc(100% - 40px);*/
	/*border-left: 1px solid #e5e5e5;
    /*position: absolute;*/
	/*bottom: 20px;*/
	width: 90px;
	padding-left: 15px;

	/*width: calc(100% - 40px); */
	border-top: 1px solid #e5e5e5;
	/* position: absolute; */
	/* bottom: 20px; */
	width: 100%;
	padding-left: 0;
	margin-top: 10px;
}

.modalGamReportWrapper .windowWhiteGam .optionsGamReport .buttonGam a {
	font-size: 14px;
	font-weight: 600;
	color: #007dc3;
	margin: 0;
	padding: 0;
	margin-top: 7px;
	display: block;
}

/*
"optionsGamReport">
                        <div className="cardOptionGamReport"></div>
            */
