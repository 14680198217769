.sub-menu-container {
	margin-top: 60px;
}
.column-item-list {
	padding: 0;
	margin: 0;
	width: 100%;
}

.Nav-list {
	display: inline;
	margin: 0 17px 0 0;
	font-size: 14px;
}

.nav-title {
	display: inline-block;
	margin-right: 15px;
	margin-top: 0;
}

.Nav-list a {
	color: #666;
	font-weight: 500;
	text-decoration: none;
	opacity: 1;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.Nav-list a:hover {
	opacity: 0.3;
}

.nav-title {
	color: #0b2343;
	font-size: 24px;
}

.Nav-list a.link-all {
	justify-self: end;
	color: rgba(0, 125, 195, 1);
	font-size: 14px;
	font-weight: 500;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	margin-left: -2%;
	margin-top: 7px;
	padding: 2px 10px;
	border-radius: 100px;
	text-decoration: none;
	background-color: rgba(0, 125, 195, 0.09);
}

.Nav-list.all {
	margin-left: 50px;
}
.floatingmenu {
	float: right;
}
.Nav-list a.link-all:hover {
	background-color: rgba(0, 125, 195, 1);
	color: white;
	opacity: 1;
}
@media (max-width: 586px) {
	li.Nav-list {
		display: block;
		margin: 0;
		padding: 5px 0;
	}
	li.Nav-list.all {
		margin: 0;
	}
}

.margin-2em {
	margin-top: 2em !important;
}
