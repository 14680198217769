@media (max-width: 1044px) {
    .MobileMenuWrapper {
        width: 100%;
        height: auto;
        display: block;
    }
    header {
        height: 60px;
    }
}

@media (min-width: 1045px) {
    .MobileMenuWrapper {
        display: none;
    }
}


/*
html, body {
    overflow-x: hidden;
    height: 100%;
}
body {
    background: #fff;
    padding: 0;
    margin: 0;
    font-family: 'Varela Round', sans-serif;
}*/

.MobileMenuWrapper .clearfix:after {
    content: "";
    height: 0;
    line-height: 0;
    clear: both;
    display: block;
    *zoom: 1;
}

.MobileMenuWrapper .header {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    box-shadow: none;
    background-color: #ffffffef;
    position: fixed;
    height: 60px!important;
    /* overflow: hidden; */
    z-index: 55;
}

.MobileMenuWrapper .main {
    margin: 0 auto;
    display: block;
    height: 100%;
    margin-top: 60px;
}

.MobileMenuWrapper .mainInner {
    display: table;
    height: 100%;
    width: 100%;
    text-align: center;
}

.MobileMenuWrapper .mainInner div {
    display: table-cell;
    vertical-align: middle;
    font-size: 3em;
    font-weight: bold;
    letter-spacing: 1.25px;
}

.MobileMenuWrapper #sidebarMenu {
    height: 100%;
    position: fixed;
    left: 0;
    width: 250px;
    padding-top: 60px;
    transform: translateX(-250px);
    transition: transform 250ms ease-in-out;
    background: #000000b6;
    z-index: 50;
    overflow-y: scroll;
}

#navMenuMobile{
    background: linear-gradient(180deg, #0b2343 0%, #0057b9 100%);
    width: 250px;
    height: 100%;
}

.MobileMenuWrapper .sidebarMenuInner {
    margin: 0;
    padding: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.10);
    background: linear-gradient(180deg, #0b2343 0%, #0057b9 100%);
}

.MobileMenuWrapper .sidebarMenuInner li {
    list-style: none;
    color: #fff;
    /*text-transform: uppercase;*/
    font-weight: bold;
    padding: 15px;
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.10);
}

.MobileMenuWrapper .sidebarMenuInner li span {
    display: block;
    font-size: 14px;
    margin-left: -7px;
}

.subtitle {
    font-size: 12px;
    color: darkgray;
    margin-left: 0px;
}

.MobileMenuWrapper .sidebarMenuInner li a {
    color: #fff;
    /*text-transform: uppercase;*/
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    font-size: 14px;
}

.MobileMenuWrapper input[type="checkbox"]:checked~#sidebarMenu {
    transform: translateX(0);
    width: 100%;
}

.MobileMenuWrapper input[type=checkbox] {
    transition: all 0.3s;
    box-sizing: border-box;
    display: none;
}

.MobileMenuWrapper .sidebarIconToggle {
    transition: all 0.3s;
    box-sizing: border-box;
    cursor: pointer;
    position: fixed;
    z-index: 99;
    height: 100%;
    width: 100%;
    top: 22px;
    left: 15px;
    height: 22px;
    width: 22px;
}

.MobileMenuWrapper .spinner {
    transition: all 0.3s;
    box-sizing: border-box;
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: #0b2343;
}


.MobileMenuWrapper .horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
}

.MobileMenuWrapper .diagonal.part-1 {
    position: relative;
    transition: all 0.3s;
    box-sizing: border-box;
    float: left;
}

.MobileMenuWrapper .diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
}

.MobileMenuWrapper li.submenu>ul {
    padding-left: 0px;
}

.MobileMenuWrapper input[type=checkbox]:checked~.sidebarIconToggle>.horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    opacity: 0;
}

.MobileMenuWrapper input[type=checkbox]:checked~.sidebarIconToggle>.diagonal.part-1 {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(135deg);
    margin-top: 8px;
}

.MobileMenuWrapper input[type=checkbox]:checked~.sidebarIconToggle>.diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(-135deg);
    margin-top: -9px;
}

.cd-article-link {
    color: rgba(black, 0.5);
}

.cd-accordion {
    background: var(--cd-color-1);
    box-shadow: var(--shadow-lg);
}

.cd-accordion--animated .cd-accordion__label::before {
    transition: transform .3s;
}

.cd-accordion__sub {
    display: none;
    overflow: hidden;
}

.cd-accordion__sub--is-visible {
    display: block;
}

.cd-accordion__item {
    user-select: none;
}

.cd-accordion__input {
    position: absolute;
    opacity: 0;
}

.cd-accordion__label {
    position: relative;
    display: flex;
    align-items: center;
    padding: var(--space-sm) var(--space-md);
    background: var(--cd-color-1);
    --color-shadow: lightness(var(--cd-color-1), 1.2);
    box-shadow: inset 0 -1px var(--color-shadow);
    color: var(--color-white);
}

.cd-accordion__label span {
    order: 3;
}

.cd-accordion__label:hover {
    background: lightness(var(--cd-color-1), 1.1);
}

.cd-accordion__label::after,
.cd-accordion__label--icon-folder::before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background-image: url('../../../assets/menu/cd-icons.svg');
    background-repeat: no-repeat;
    margin-right: var(--space-xxxs);
}

.cd-accordion__label--icon-folder::before {
    order: 1;
}

.cd-accordion__label::after {
    order: 2;
}

.cd-accordion__label--icon-folder::before {
    background-position: 0 0;
    transform: rotate(-90deg);
}

.cd-accordion__label--icon-folder::after {
    background-position: -16px 0;
}

.cd-accordion__label--icon-img::after {
    background-position: -48px 0;
}

.cd-accordion__input:checked+.cd-accordion__label::before {
    transform: rotate(0);
}

.cd-accordion__input:checked+.cd-accordion__label::after {
    background-position: -32px 0;
}

.cd-accordion__input:checked~.cd-accordion__sub {
    display: block;
}

.cd-accordion__sub--l1 .cd-accordion__label {
    background: lightness(var(--cd-color-1), 0.65);
    --color-shadow: lightness(var(--cd-color-1), 0.85);
    box-shadow: inset 0 -1px var(--color-shadow);
    padding-left: calc(var(--space-md) + 16px);
}

.cd-accordion__sub--l1 .cd-accordion__label:hover {
    background: lightness(var(--cd-color-1), 0.75);
}

.cd-accordion__item:last-child .cd-accordion__label {
    box-shadow: none;
}

.cd-accordion__sub--l2 .cd-accordion__label {
    padding-left: calc(var(--space-md) + var(--space-xxxs) + 32px);
}

.cd-accordion__sub--l3 .cd-accordion__label {
    padding-left: calc(var(--space-md) + var(--space-xxxs) + 48px);
}

@media (max-width: 1044px) {
    .ExtraMenu {
        float: right;
      }
      .container-language.activeLang {
        top: 60px;
        left: -10px;
    }
    .ProfileUser {
        width: 70px;
        border-left: 0px solid #d6d6d6;
      }
    
    
    .notify-container {
        display: inline-table;
    }
    .container-notifications {
        right: -70px;
    }

    .container-language .notification-column {
        display: flex;
        padding-top: 8px;
    }

    .container-language.en .notification-column a.notification-item {
        border: none;
    }

    .container-language.en .notification-column a.notification-item:last-child,
    .container-language.es .notification-column a.notification-item:first-child {
        padding: 8px;
        border-radius: 50%;
        background-color: rgba(0, 125, 195, 0.1);
        width: 42px;
        display: flex;
        height: 42px;
        justify-content: center;
        align-items: center;
    }

    .container-language .notification-column p.notification-text {
        margin-bottom: 0;
    }

    .container-language .notification-column a.notification-item:last-child {
        background: none;
        margin-left: 5px;
    }
}

@media (max-width: 800px) {
    .notification-text {
        font-size: 14px;
    }
}

@media (max-width: 600px) {
    .container-notifications {
        width: 390px;
    }
}


@media (max-width: 400px) {
    .MobileMenuWrapper #sidebarMenu {
        height: 100%;
    }
    .container-notifications {
        width: 300px;
        right: -75px;
    }
}