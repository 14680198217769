h1.hiddenSearch {
	width: 100%;
	text-align: center;
	margin: 50px 0 20px;
	text-transform: uppercase;
}

form.hiddenSearch,
.carta-simple {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: white;
	box-shadow: 0 30px 15px -15px rgba(0, 0, 0, 0.15);
	border: solid 1px #1b2642;
	margin: 0 auto 50px;
	padding: 30px 50px;
	width: 50vw;
}
.carta-simple p {
	margin: 0;
	text-align: center;
}
label.hiddenSearch {
	color: black;
	font-size: 16px;
	text-align: left;
	width: 200px;
}
input.hiddenSearch {
	border: solid 1px #1b2642;
	padding: 5px 10px;
	width: 200px;
}
button.hiddenSearch {
	background: #1b2642;
	border: none;
	box-shadow: 0 30px 15px -15px rgba(0, 0, 0, 0.1);
	color: white;
	font-weight: bold;
	margin: 10px 0 0;
	padding: 10px 25px;
	width: 200px;
}
