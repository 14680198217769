.wrapperHeaderFlota{
    height: auto;
    overflow: hidden;
    padding: 120px 0;
    background-image: url(../../../../assets/flota/flota-header.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.wrapperHeaderFlota .midFlota{
    max-width: 1200px;
    width: 90%;
    height: auto;
    overflow: hidden;
    margin: 0 auto;
}
.wrapperHeaderFlota .midFlota .textWrapper{
    max-width: 500px;
    margin: 0 0 0;
}
.wrapperHeaderFlota .midFlota .textWrapper p.title{
    font-size: 62px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    color: white;
}
.wrapperHeaderFlota .midFlota .textWrapper p.subtitle{
    font-size: 20px;
    font-weight: 300;
    line-height: 1.4;
    margin: 0;
    padding: 0;
    margin-top: 0;
    color: white;
}

.loghaulfleet{
    padding: 0;
    padding-bottom: 60px;
}

.loghaulfleet .midFleet{
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
    height: auto;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
}
.loghaulfleet .midFleet .flettBox{
}
.loghaulfleet .midFleet .flettBox .fleetImage{
    height: 100px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.loghaulfleet .midFleet .flettBox:hover .fleetImage{
    transform: translateY(-15px) translateX(15px);
}
.loghaulfleet .midFleet .flettBox .fleetImage{background-image: url(../../../../assets/flota/equipos/B-787-9-QUETZALCOATL.png)}
.loghaulfleet .midFleet .flettBox:nth-child(2) .fleetImage{background-image: url(../../../../assets/flota/equipos/B-737-700.png)}
.loghaulfleet .midFleet .flettBox:nth-child(3) .fleetImage{background-image: url(../../../../assets/flota/equipos/B-737-800.png)}
.loghaulfleet .midFleet .flettBox:nth-child(4) .fleetImage{background-image: url(../../../../assets/flota/equipos/B-737-800-MAX.png)}

.loghaulfleet .midFleet .flettBox p{
    font-size: 20px;
    font-weight: 300;
    color: #666;
}
.loghaulfleet .midFleet .flettBox p span{
    font-weight: 300;
    color: #0b2343;
    font-size: 42px;
    margin-right: 5px;
}

.loghaulfleet .midFleetConnect{
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
    height: auto;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
}
.loghaulfleet .midFleetConnect .flettBox{
}
.loghaulfleet .midFleetConnect .flettBox .fleetImage{
    height: 100px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.loghaulfleet .midFleetConnect .flettBox:hover .fleetImage{
    transform: translateY(-15px) translateX(15px);
}

.loghaulfleet.embraer .midFleetConnect .flettBox:nth-child(1) .fleetImage{background-image: url(../../../../assets/flota/equipos/EMB-190.png)}
.loghaulfleet.embraer .midFleetConnect .flettBox:nth-child(2) .fleetImage{background-image: url(../../../../assets/flota/equipos/EMB-170.png)}
.loghaulfleet.embraer .midFleetConnect .flettBox:nth-child(1) {
    grid-column: 2;
}

.loghaulfleet .midFleetConnect .flettBox p{
    font-size: 20px;
    font-weight: 300;
    color: #666;
}
.loghaulfleet .midFleetConnect .flettBox p span{
    font-weight: 300;
    color: #0b2343;
    font-size: 42px;
    margin-right: 5px;
}


.fleetheaderTitle{
    position: relative;
    height: auto;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    max-width: 1200px;
    width: 90%;
    margin-top: 60px;
    margin-bottom: 20px;
}
.fleetheaderTitle p.titlefleetPlane{
    text-align: center;
    font-weight: 600;
    color: #0b2343;
    font-size: 32px;
  /*   background-color: white; */
    padding: 0;
    z-index: 2;
    position: relative;
}
.fleetheaderTitle p.subtitle{
    font-size: 18px;
    margin: 0 auto;
    text-align:center;
    font-weight: 300;
    max-width: 740px;
    margin-top: 10px;
    opacity: 1;
    color: #0b2343;
}
.fleetheaderTitle .lineTitleFleet{
    /* border: 1px solid #e5e5e5; */
    width: 100%;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    z-index: 1;
}
.lineFleetGrid{
    border-top: 1px solid #e5e5e5;
}

.bannerFleetPlanes{
    height: auto;
    overflow: hidden;
    padding: 100px 0;
    background-color: #004990;
    background-image: url(../../../../assets/flota/flotabg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.bannerFleetPlanes .midFleet{
    max-width: 1200px;
    margin: 0 auto;
    width: 90%;
    height: auto;
    overflow: hidden;
}
.bannerFleetPlanes .midFleet p.title{
    font-size: 42px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    max-width: 540px;
    line-height: 1.2;
    color: #0b2343;
}
.bannerFleetPlanes .midFleet p.subtitle{
    max-width: 600px;
    font-size: 18px;
    margin-top: 10px;
    color: #0b2343;
}

@media (max-width: 1044px) {    
    .wrapperHeaderFlota{
        height: auto;
        overflow: hidden;
        padding: 70px 0;
        background-image: url(../../../../assets/flota/flota-header.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}

@media (max-width: 800px) {    
    .wrapperHeaderFlota{
        height: auto;
        overflow: hidden;
        padding: 40px 0;
        background-image: url(../../../../assets/flota/flota-h-small.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .loghaulfleet .midFleet{
        max-width: 1200px;
        width: 90%;
        margin: 0 auto;
        height: auto;
        overflow: hidden;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    }

    .loghaulfleet .midFleetConnect{
        max-width: 1200px;
        width: 90%;
        margin: 0 auto;
        height: auto;
        overflow: hidden;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    }

    .loghaulfleet.embraer .midFleetConnect .flettBox:nth-child(1) {
        grid-column: 1;
    }

    .bannerFleetPlanes{
        height: auto;
        overflow: hidden;
        padding: 100px 0;
        background-color: #004990;
        background-image: url(../../../../assets/flota/flotabg-sm.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}

@media (max-width: 500px) {
    .wrapperHeaderFlota{
        height: auto;
        overflow: hidden;
        padding: 30px 0;
        background-image: url(../../../../assets/flota/flota-h-small.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .wrapperHeaderFlota .midFlota .textWrapper p.title{
        font-size: 30px;
        font-weight: 600;
        margin: 0;
        padding: 0;
        color: white;
    }
    .bannerFleetPlanes .midFleet p.title{
        font-size: 30px;
        font-weight: 600;
        margin: 0;
        padding: 0;
        max-width: 540px;
        line-height: 1.2;
        color: #0b2343;
    }
}
  
/****


 <div className="bannerFleetPlanes">
              <div className="midFleet">
                  <p className="title">8.6 años: edad promedio de la flota operativa</p>
                  <p className="subtitle">La flota operativa de Grupo Aeroméxico fue de 127 aviones al cierre del primer trimestre de 2019. Una disminución neta de tres aviones en comparación con el primer trimestre de 2018.</p>
              </div>
          </div>


<div class="fleetheaderTitle">
            <p class="titlefleetPlane">Flota Short Haul</p>
            <div class="lineTitleFleet"></div>
        </div>

        ****/


/*


<div className="loghaulfleet">
                <div className="midFleet">
                    <div className="flettBox">
                        <div className="fleetImage"></div>
                        <p><span>17</span> Boeing 787</p>
                    </div>

                    <div className="flettBox">
                        <div className="fleetImage"></div>
                        <p><span>13</span> Boeing 737-700s</p>
                    </div>

                    <div className="flettBox">
                        <div className="fleetImage"></div>
                        <p><span>36</span> Boeing 737-800s</p>
                    </div>

                    <div className="flettBox">
                        <div className="fleetImage"></div>
                        <p><span>5</span> Boeing 737-8 MAXs</p>
                    </div>
                </div>
          </div>


<div id="nuestraflota">
          
          <div className="wrapperHeaderFlota">
              <div className="midFlota">
                  <div className="textWrapper">
                      <p className="title">Nuestra flota</p>
                      <p className="subtitle">Aeroméxico cuenta con una flota en constante mantenimiento para asegurar el cumplimiento de los estándares de calidad.</p>
                  </div>
              </div>
          </div>


     </div>

     */